/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useHistory, useParams } from 'react-router-dom';
import { CircleWavyCheck, Diamond } from 'phosphor-react';
import dayjs from 'dayjs';
import NavbarContainer from '../../BasicComponents/navbar';
import {
  cross, webinarStep1, webinarStep2, webinarStep3,
} from '../../BasicComponents/icons';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree, { RouteParams } from './StepThree';
import 'react-datepicker/dist/react-datepicker.css';
import './webinar.css';
import FeatureFlag from '../../FeatureFlag';
import { hlbClient } from '../../../Clients/hlbClient';
import { getSeatOptionsValue, getWebinarPlatformValue } from '../../Utils';

const CreateWebinar = ({ isEdit = false } : { isEdit: boolean }) => {
  const [webinarData, setWebinarData] = useState<any>({});
  const [formData, setFormData] = useState<any>({
    title: webinarData?.title,
    summary: '',
    date: '',
    startTime: '',
    endTime: '',
    originalPrice: null,
    discountPrice: null,
    description: '',
    videoLink: '',
    seats: '',
    platform: '',
    platformLink: '',
    certificate: '',
    keyPoints: [],
    keyPoint: '',
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [newWebinarId, setNewWebinarId] = useState('');
  const { webinarId } = useParams<RouteParams>();
  // eslint-disable-next-line no-undef
  const portalRoot = document.getElementById('portal-root');
  const history = useHistory();

  // eslint-disable-next-line consistent-return
  const handleNextStep = (newData: any, isFinalStep = false) => {
    setFormData((prevData: any) => ({ ...prevData, ...newData }));
    if (isFinalStep) {
      setIsFormSubmitted(true);
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = (newData: any) => {
    setFormData((prevData: any) => ({ ...prevData, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };

  const steps = [
    <StepOne next={handleNextStep} data={formData} isWebinarPublished={webinarData?.isPublished} />,
    <StepTwo next={handleNextStep} prev={handlePrevStep} data={formData} />,
    <StepThree
      editMode={isEdit}
      next={handleNextStep}
      prev={handlePrevStep}
      data={formData}
      setNewWebinarId={setNewWebinarId}
    />,
  ];

  const webinarStepsImages = [
    webinarStep1,
    webinarStep2,
    webinarStep3,
  ];

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    const fetchWebinarDetails = async () => {
      try {
        const response: any = await hlbClient().get(`/api/webinar/${webinarId}`);
        setFormData({
          title: response.data.result.title,
          summary: response.data.result.shortSummary,
          date: dayjs(response.data.result.date).format('YYYY-MM-DD'),
          originalPrice: response.data.result.price,
          discountPrice: response.data.result.discountedPrice,
          startTime: dayjs(response.data.result.startTime).format('h:mm A'),
          endTime: dayjs(response.data.result.endTime).format('h:mm A'),
          description: response.data.result.detailedDescription,
          keyPoints: response.data.result.keyPoints,
          videoLink: response.data.result.introVideoLink,
          certificate: response.data.result.certificateOnBooking === true ? 'yes' : 'no',
          seats: getSeatOptionsValue(response.data.result.totalSeats),
          platform: getWebinarPlatformValue(response.data.result.platform),
          platformLink: response.data.result.joiningLink,
        });
        setWebinarData(response.data.result);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    // eslint-disable-next-line no-unused-expressions
    isEdit && fetchWebinarDetails();
  }, [isEdit]);

  const navigateToDashboard = () => {
    history.push('/dashboardV2');
  };

  // eslint-disable-next-line no-shadow
  const navigateToWebinar = (webinarId: string) => {
    // eslint-disable-next-line no-unused-expressions
    webinarId && history.push(`/webinar/${webinarId}`);
  };

  const FormSubmittedPopup = () => {
    if (portalRoot) {
      return ReactDOM.createPortal(
        <section className="webinar__card--wrapper">
          <div className="webinar__card">
            <div className="card__header">
              <CircleWavyCheck size={32} color="#9e81fc" />
              <h2>
                {
                isEdit ? 'Webinar Edited!' : 'Webinar Created!'
                }
              </h2>
            </div>
            <div className="card__actions">
              <button onClick={navigateToDashboard} type="button" className="card__actions--button">Dashboard</button>
              <button
                onClick={() => navigateToWebinar(webinarData?._id ? webinarData._id : newWebinarId)}
                type="button"
                className="card__actions--button"
              >
                View webinar
              </button>
            </div>
            <div className="card__tips">
              <div className="card__tips--tip">
                <Diamond size={16} color="#B38CFF" />
                <span>You can now access this webinar from your dashboard</span>
              </div>
              <div className="card__tips--tip">
                <Diamond size={16} color="#B38CFF" />
                <span>Share the webinar link with your connections, once it is published</span>
              </div>
              <div className="card__tips--tip">
                <Diamond size={16} color="#B38CFF" />
                <span>Updates will be sent to attendees if the event is updated</span>
              </div>
            </div>
          </div>
        </section>,
        portalRoot,
      );
    }
    return null;
  };

  return (
    <FeatureFlag name="webinar">
      <>
        <div className="container-fluid">
          <div className="row">
            <NavbarContainer />
          </div>
        </div>
        <div className="webinar__form--wrapper">
          {
            isFormSubmitted ? <FormSubmittedPopup /> : (
              <div className="webinar__formContainer">
                <div className="formContainer__head">
                  <button className="formContainer--close" type="button" onClick={() => history.push('/dashboardV2')}>
                    <img src={cross} alt="close" />
                  </button>
                  <h2>Create Your Webinar</h2>
                  <img className="formContainer--step" src={webinarStepsImages[currentStep]} alt="current step" />
                </div>
                {steps[currentStep]}
              </div>
            )
          }
        </div>
      </>
    </FeatureFlag>
  );
};

export default CreateWebinar;
