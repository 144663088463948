import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './StudentDashboard.css';
import { noop } from 'lodash';
import NavbarContainer from '../BasicComponents/navbar/index';
import UserProfile from './userProfile';
import NavButton from './navButton';
import PromotionCard from '../BasicComponents/promotionCard/PromotionCard';
import { AuthContext } from '../../provider/AuthContext';
import { getMessage } from '../Utils';
import { List, MagnifyingGlass, SignOut, X } from 'phosphor-react';
import { FILTER_OPTIONS, SORT_OPTIONS } from '../Constants';
import { DROPDOWN_STYLES, DROPDOWN_STYLES_MOBILE } from './Select.config';
import useIsMobileView from '../../hooks';
import { useHistory } from 'react-router';
import { Dashboard, Favourites, MyNotes } from './dashboardSections';
import { useSelector } from "react-redux";
import EntityShowcase from './entityShowcase';
import { AuthModal } from '../Auth/Modal';

type Tabs = "Dashboard" | "Favourites" | "My Notes";
type EntityTypes = "note" | "webinar" | "session";

export interface OptionType {
  label: string;
  value: string;
}

type DefaultEntityContainerConfigType = {
  title: string,
  entityType: EntityTypes,
  search: string,
  filter: any[],
  ids: string[],
  showcaseItems: any[],
}

const NAVIGATION_TABS: Tabs[] = [
  'Dashboard',
  'Favourites',
  'My Notes',
];

export const defaultEntityContainerConfig: DefaultEntityContainerConfigType = {
  title: "Notes",
  entityType: "note",
  search: "",
  filter: [],
  ids: [],
  showcaseItems: [],
}

export const buildEntitiesResponse = (
  entityList: any,
  totalResults: number,
  handleClicked?: (id?: any, entityDetails?: any) => void,
  handleFavourite?: (id?: any) => void,
  favouriteList?: string[],
) => {
  const response = entityList?.map((entity: any) => {
    return {
      id: entity?._id,
      image: entity?.image?.url || "",
      isTrending: false,
      title: entity?.name || "",
      subscriberCount: entity?.subscriberCount || 0,
      cardCount: entity?.cardCount || 0,
      tags: entity?.tags || [],
      buttonText: "Explore",
      isFavourite: favouriteList?.length ? favouriteList?.includes(entity?._id) : false,
      profileImage: entity?.mentorProfileDetails?.profilePic?.url || entity?.mentorImage?.url || "",
      description: entity?.description || "",
      creatorName: entity?.mentorDetails?.name || entity?.creatorName || "",
      creatorIntro: entity?.mentorProfileDetails?.shortBio || entity?.shortBio || "",
      cards: entity?.previewCards?.map((el: any) => (
        <div
          key={el?.deckId}
          className="cards"
          style={{
            width: '240px',
            height: '385px',
            borderRadius: '4px',
            boxShadow: '6px 6px 8px 0px rgba(0, 0, 0, 0.05)',
            background: 'hsl(260, 65%, 74%)',
          }}
        >
          {el?.questionText}
        </div>
      )) || [],
      handleClicked: handleClicked ? () => handleClicked(entity?._id, entity) : noop,
      handleFavourite: handleFavourite ? () => handleFavourite(entity?._id) : noop,
    }
  });
  return {
    results: response,
    totalResults: totalResults,
  }
};

const StudentDashboard = () => {
  const [activeTab, setActiveTab] = useState<Tabs>('Dashboard');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBy, setFilterBy] = useState<OptionType | null>(null);
  const [sortBy, setSortBy] = useState<OptionType | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [showAuthModal, setShowAuthModal] = useState(false);
  
  const [showNoSearchResults, setShowNoSearchResults] = useState(false);

  const IS_SHOWCASE_MODE = window.location.pathname === "/myDashboard/showcase";

  const token = window.localStorage.getItem('token');
  const { setToken, authState, setStateUpdate, stateUpdate } = useContext(AuthContext);
  const { name = '', user_type = '' } = authState;
  const { isMobileView } = useIsMobileView();
  const history = useHistory(); 
  const searchRef = useRef<HTMLInputElement | null>(null);

  const globalShowcaseData = useSelector((state: any) => state.showcaseConfig);

  useEffect(() => {
    if (token) {
      setStateUpdate(!stateUpdate);
    }

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // pushes user back if tries to access showcase mode when logged-in
    if (authState?.email && IS_SHOWCASE_MODE) {
      history.goBack();
    }
  }, [authState, IS_SHOWCASE_MODE]);  

  const handleClearSearch = () => {
    searchTerm && setSearchTerm("");
    showNoSearchResults && setShowNoSearchResults(false);
    if (searchRef.current) {
      searchRef.current.value = "";
    }
  };

  const updateActiveTab = (tabName: Tabs) => {
    setActiveTab(tabName); 
    handleClearSearch();  
  };

  const openAuthModal = () => {
    setShowAuthModal(true);
  }

  const closeAuthModal = () => {
    setShowAuthModal(false);
  };

  const handleLogout = useCallback(() => {
    setToken(localStorage.removeItem('token'));
    window.localStorage.setItem('hlUserId', '');
    window.localStorage.setItem('hlUserType', '');
    history.push('/login');
  }, []);

  const renderEntityContent = () => {
    if (IS_SHOWCASE_MODE && globalShowcaseData?.entityType) {
      const { title, showcaseItems, filter, search, entityType, ids } = globalShowcaseData;
      return (
        <EntityShowcase
          title={title}
          showcaseItems={showcaseItems}
          filter={filter}
          search={search}
          ids={ids}
          entityType={entityType}
        />
      );
    } else if (activeTab === "Dashboard") {
      return (
        <Dashboard
          searchTerm={searchTerm}
          clearSearch={() => {
            setSearchTerm("");
            if (searchRef.current) {
              searchRef.current.value = "";
            }
          }}
          openAuthModal={openAuthModal}
        />
      );
    } else if (activeTab === "My Notes") {
      return (
        <MyNotes
          searchTerm={searchTerm}
          clearSearch={() => {
            setSearchTerm("");
            if (searchRef.current) {
              searchRef.current.value = "";
            }
          }}
        />
      )
    } else if (activeTab === "Favourites") {
      return <Favourites />
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <NavbarContainer />
        </div>
      </div>
      <div className="sdashboard__wrapper">
        <div className="sdashboard">
          <aside className="sdashboard__sidebar">
            <UserProfile name={name} user_type={user_type} />
            <div className="sidebar__navigation">
              {
                NAVIGATION_TABS.map((tab) => (
                  <NavButton
                    onClick={() => updateActiveTab(tab)}
                    active={tab === activeTab}
                    key={tab}
                    disabled={IS_SHOWCASE_MODE}
                  >
                    {tab}
                  </NavButton>
                ))
              }
            </div>
            <div className="sidebar__promotions">
              <PromotionCard
                title="10 tips for building an effective marketing strategy"
                subtext="1X1 SESSION WITH AKSHAY M."
                buttonTitle="Book now"
                onClick={noop}
              />
            </div>
          </aside>
          <aside className={`sdashboard__sidebar--responsive ${isSidebarOpen && "open"}`}>
            <button className="sidebar__action--close" type="button" onClick={() => setIsSidebarOpen(false)}>
              <X size={20} color="black"/>
            </button>
            <div className="responsive__navigation">
              {
                NAVIGATION_TABS.map((tab) => (
                  <NavButton
                    onClick={() => {
                      updateActiveTab(tab);
                      setTimeout(() => {
                        setIsSidebarOpen(false);
                      }, 300);
                    }}
                    active={tab === activeTab}
                    key={tab}
                    variant='responsive'
                  >
                    {tab}
                  </NavButton>
                ))
              }
              <button className='sidebar__action--logout' onClick={handleLogout}>
                <SignOut size={22} color="#F61C1C" />
                <span>Logout</span>
              </button>
            </div>
          </aside>
          <section className="sdashboard__container">
            <div className="container__header">
              <div className="header__greetings">
                <h3 className='greetings__welcome'>{`Welcome ${name}!`}</h3>
                <span className="greetings__message">
                  {getMessage()}
                </span>
              </div>
              <div className="header__searchbox">
                {isMobileView && (
                  <button type="button" className="searchbox__menu" onClick={() => setIsSidebarOpen(true)}>
                    <List size={20} />
                  </button>
                )}
               <div className="searchbox__search">
                <input
                  placeholder='Search your favourite notes'
                  type="text"
                  className="search__input"
                  onKeyDown={(event) => {
                    if(event.key === "Enter") {
                      setSearchTerm(searchRef?.current?.value as string);
                    }
                  }}
                  ref={searchRef}
                  disabled={IS_SHOWCASE_MODE}
                />
                <button
                  className='search__button'
                  onClick={() => setSearchTerm(searchRef?.current?.value as string)}
                  disabled={IS_SHOWCASE_MODE}
                >
                  <MagnifyingGlass size={isMobileView ? 16 : 20} />
                </button>
               </div>
               {/* <Select
                options={FILTER_OPTIONS}
                isClearable={true}
                name='filters'
                onChange={(option) => {
                  if (option) {
                    setFilterBy(option as OptionType);
                  }
                }}
                isSearchable={false}
                placeholder={<Funnel size={isMobileView ? 16 : 24} color='#000000' />}
                styles={isMobileView ? DROPDOWN_STYLES_MOBILE : DROPDOWN_STYLES}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                isDisabled={IS_SHOWCASE_MODE}
               />
               <Select
               /> */}
               {/* <Select
                options={SORT_OPTIONS}
                isClearable={true}
                name='sort'
                onChange={(option) => {
                  if (option) {
                    setSortBy(option as OptionType);
                  }
                }}
                isSearchable={false}
                placeholder={<SlidersHorizontal size={isMobileView ? 16 : 24} color='#000000' style={{ transform: "rotate(-90deg)"}} />}
                styles={isMobileView ? DROPDOWN_STYLES_MOBILE : DROPDOWN_STYLES}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                isDisabled={IS_SHOWCASE_MODE}
               />
               /> */}
              </div>
            </div>

            {/* {searchTerm && (
              <h2 className='search__result-heading'>
                Results for
                {" "}
                <span className='search__term'>{`"${searchTerm}"`}</span>
                {" "}
                <X size={16} onClick={handleClearSearch} weight="bold" style={{ cursor: "pointer" }} />
              </h2>
            )} */}

            <div className='sdashboard__entityContainer'>
              {renderEntityContent()}
            </div>
            
            {
              isMobileView && (
                <PromotionCard
                  title="10 tips for building an effective marketing strategy"
                  subtext="1X1 SESSION WITH AKSHAY M."
                  buttonTitle="Book now"
                  onClick={noop}
                />
              )
            }
          </section>
        </div>
      </div>
      <AuthModal open={showAuthModal} closeModal={closeAuthModal} />
    </>
  );
};

export default StudentDashboard;
