import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { hlbClient } from '../../../Clients/hlbClient';
import { cross, downLite } from '../../BasicComponents/icons';
import { SmileySad } from 'phosphor-react';
import { debounce } from 'lodash';
import { addToast } from '../../../reducers/toast.reducer';
import { useDispatch } from 'react-redux';

type Props = {
  getTopic: (k: string) => void;
  topicValue: string;
  setTopErrorMessage: Dispatch<SetStateAction<string | null>>;
};

const AddTopics: React.FC<Props> = ({
  getTopic,
  topicValue,
  setTopErrorMessage,
}) => {
  const [query, setQuery] = useState<string>(topicValue || '');
  const [topicList, setTopicList] = useState<
    { label: string; value: string }[]
  >([]);
  const [keyword, setkeyword] = useState<string>('');
  const [loadingList, setLoadingList] = useState(false);
  const [show, setShow] = useState(false);

  const topicContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (!topicContainerRef.current?.contains(e.target as HTMLDivElement)) {
        setShow(false);
        console.log('closing...');
      }
    };

    document.addEventListener('click', handler);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  const filterList = async (inputValue: string) => {
    const resp: { data: { result: { name: string; key: string }[] } } =
      await hlbClient().get(`/api/topic?q=${inputValue}`);
    const options = resp?.data?.result.map(
      (item: { name: string; key: string }) => ({
        label: item.name,
        value: item.key,
      })
    );
    setLoadingList(false);
    setTopicList(options);
    return options;
  };

  const debounceFn = useCallback(debounce(filterList, 1000), []);

  function handleChange(value: string) {
    setLoadingList(true);
    setQuery(value);
    debounceFn(value);
  }

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 5px',
  };

  const dispatch = useDispatch();
  const addTopics = async () => {
    setTopErrorMessage(null);
    try {
      const resp = await hlbClient().post(`/api/topic`, { name: keyword });
      if (resp?.data?.success) {
        dispatch(addToast({ type: 'success', message: resp?.data?.msg }));
        setQuery(keyword);
        getTopic(keyword);
        setShow(false);
        setkeyword('');
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      setTopErrorMessage('Topic already exist!');
    }
  };

  return (
    <div>
      <style>{`
        .input-container .cross-position {
          top: 50%;
          right: 0;
          transform: translate(-100%, -50%);
        }
        .input-form-add-topic {
            border-radius: 4px;
            border: 1px solid #bdbdbd;
            outline: none;
            background: #FFF;
            width: 100%;
            padding: 5px 10px;
            line-height: normal;
            font-size: 14px;
        }
        .add-btn {
            padding: 2px;
            color: #8C52FF;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            cursor: pointer;
          }
          .input-container {
            position: relative;
            width: 100%;
          }
          .cross-position {
            position: absolute;
            top: 12px;
            right: 10px;
            cursor: pointer;
          }
          .max-height-for-option {
            height: 150px;
            overflow-y: scroll;
            padding-left: 0;
        }
        .option-container {
            position: absolute;
            width: 100%;
            border-radius: 6px;
            margin-top: 5px;
            background: #FFF;
            padding: 5px 0;
            border: 1px solid #cdcdcd;
            z-index: 1;
          }
          .addTopic--list {
            color: #757575;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 5px 15px;
            cursor: pointer;
            border-bottom: 1px solid #cdcdcd;
            list-style: none;

          }
          .max-height-for-option::-webkit-scrollbar {
            width: 6px;
            padding-right: 5px;
        }
                            
        .max-height-for-option::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.2); 
            border-radius: 10px;
        }
                            
        .max-height-for-option::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: rgba(142,82,255,0.5);
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
        }
        `}</style>
      <div style={{ position: 'relative' }} ref={topicContainerRef}>
        <div className="input-container" onFocus={() => setShow(true)}>
          <input
            placeholder="Search and select topic..."
            className="input-form-add-topic"
            onInput={() => setShow(true)}
            value={query}
            onChange={(e) => handleChange(e.target.value)}
          />
          {!query && (
            <img
              className="cross-position"
              width={14}
              height={14}
              src={downLite}
              alt="down"
              onClick={() => setShow(true)}
            />
          )}
          {query && (
            <img
              className="cross-position"
              width={12}
              height={12}
              src={cross}
              alt="cross"
              onClick={() => {
                handleChange('');
                getTopic('');
                setTimeout(() => {
                  setShow(true);
                }, 100);
              }}
            />
          )}
        </div>
        {show && (
          <div className="option-container">
            {topicList.length > 0 && !loadingList ? (
              <ul className="max-height-for-option">
                <span
                  style={{
                    color: '#8C52FF',
                    fontSize: '12px',
                    fontWeight: '500',
                    padding: '15px',
                  }}
                >
                  Recent topics
                </span>
                {topicList &&
                  topicList.map((list: { label: string; value: string }) => (
                    <li
                      className='addTopic--list'
                      onClick={() => {
                        getTopic(list?.value);
                        setShow(false);
                        setQuery(list?.label);
                      }}
                      key={list?.value}
                      value={list?.value}
                    >
                      {list?.label}
                    </li>
                  ))}
              </ul>
            ) : (
              <div className="d-flex align-items-center mt-3 mb-3">
                {loadingList ? (
                  <>
                    <p className="mx-2">Loading...</p>
                  </>
                ) : (
                  <>
                    <p className="mx-2">
                      {query
                        ? `No result found for "${query}"`
                        : `No result found!`}
                    </p>
                    <SmileySad />
                  </>
                )}
              </div>
            )}
            {/* add topics */}
            <div style={groupStyles}>
              <input
                onChange={(e) => setkeyword(e.target.value)}
                value={keyword}
                className="input-form-add-topic"
                style={{ height: '32px', fontSize: '12px', width: '75%' }}
                placeholder="Enter topic name..."
              />
              <button
                disabled={keyword === ''}
                onClick={() => addTopics()}
                className="btn cursor-pointer add-btn"
              >
                Add topic
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddTopics;
