/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import PropTypes from 'prop-types';
import { trash } from '../BasicComponents/icons';
import Tooltip from '../BasicComponents/Tooltip';

dayjs.extend(isBetween);
function TimeRange({
  day, setDays, days, slotNum, startTime,
  endTime, setSlotOverlapped,
}) {
  const DEFAULT_DURATION = 30;
  const Slots = [];
  const [error, SetError] = useState(false);
  for (let i = dayjs('1970-01-01T00:00:00.000Z').startOf('date');
    dayjs(i).isBefore(dayjs('1970-01-01T00:00:00.000Z').endOf('date'));
    i = dayjs(i).add(15, 'minute')) {
    Slots.push(dayjs(i).unix() * 1000);
  }
  const [slotsEnd, setEndSlots] = useState([]);
  const [start, setStart] = useState(dayjs(startTime).unix() * 1000);
  const [end, setEnd] = useState(dayjs(endTime).unix() * 1000);
  useEffect(() => {
    setStart(dayjs(startTime).unix() * 1000);
    setEnd(dayjs(endTime).unix() * 1000);
  }, [startTime, endTime]);
  useEffect(() => {
    let EndSlots = [];
    EndSlots = Slots.filter((t) => dayjs(t).isAfter(dayjs(parseInt(start, 10)).add(DEFAULT_DURATION - 15, 'minute')));
    setEndSlots(EndSlots);
  }, [start]);
  useEffect(() => {
    for (let i = 0; i < days[day].slots.length; i += 1) {
      if (i !== slotNum) {
        if (dayjs(days[day].slots[slotNum].startTime).isBetween(days[day].slots[i].startTime, days[day].slots[i].endTime, null, '[)')
        || dayjs(days[day].slots[slotNum].endTime).isBetween(days[day].slots[i].startTime, days[day].slots[i].endTime, null, '(]')) {
          SetError(true);
          setSlotOverlapped(true);
          break;
        } else {
          SetError(false);
        }
      }
    }
  }, [start, end, ...days[day].slots]);
  useEffect(() => {
    const newDays = { ...days };
    const newEnd = dayjs(parseInt(start, 10)).add(15, 'minute').unix() * 1000;
    newDays[day].slots[slotNum] = {
      startTime: dayjs(parseInt(start, 10)).toISOString(),
      endTime: dayjs(parseInt(end, 10) > newEnd ? parseInt(end, 10) : newEnd).toISOString(),
    };
    setDays(newDays);
  }, [start, end]);

  const DeleteSlot = () => {
    const newDays = { ...days };
    newDays[day].slots.splice(slotNum, 1);
    setDays(newDays);
  };

  return (
    <>
      <div className="availibility__slotbox">
        <select
          value={start}
          className={`availibility__timeslot--slot-1 ${error ? 'slot-overlap' : ''}`}
          onChange={(e) => {
            setStart(e.target.value);
          }}
        >
          { Slots.map((time) => (<option value={time} className={`${error} ? 'text-danger' : ''`}>{dayjs(time).format('hh:mm a')}</option>))}
          {' '}
        </select>
        {' '}
        -
        {' '}
        <select
          value={end}
          className={`availibility__timeslot--slot-2 ${error ? 'slot-overlap' : ''}`}
          onChange={(e) => {
            setEnd(e.target.value);
          }}
        >
          { slotsEnd.map((time) => (<option value={time}>{dayjs(time).format('hh:mm a')}</option>))}
          {' '}
        </select>
      </div>
      <Tooltip content="Delete this slot">
        <button className="trash--button" type="button" onClick={DeleteSlot}>
          <img src={trash} alt="delete" />
        </button>
      </Tooltip>
    </>
  );
}

TimeRange.propTypes = {
  day: PropTypes.number.isRequired,
  setDays: PropTypes.func.isRequired,
  slotNum: PropTypes.number.isRequired,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  days: PropTypes.shape(
  ).isRequired,
  setSlotOverlapped: PropTypes.bool.isRequired,
};
export default TimeRange;
