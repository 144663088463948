/* eslint-disable react/prop-types */
import React, {
  createContext, useEffect, useContext, useState,
} from 'react';
import { io } from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import dateFormat from 'dateformat';
import _ from 'lodash';
import { myConfig } from '../config';
import { AuthContext } from './AuthContext';
import {
  getInitialState, onGetChat, onNewMsg, onDeleteMsg, resetState,
} from '../reducers/chat.reducer';

export const SocketContext = createContext();
function SocketProdiver({ children }) {
  const { authState } = useContext(AuthContext);
  const [update, setUpdate] = useState(false);
  const dispatch = useDispatch();
  const socket = io(`${myConfig.url}`, { transports: ['websocket'] });
  function GetChats() {
    socket.emit('getChats', {
      senderId: authState._id,
    });
  }
  useEffect(() => {
    if (socket && authState._id) {
      socket.emit('joinServer', { username: authState._id });
      GetChats();
    }
  }, [authState._id, socket]);
  const [curEnrollmentId, setEnrollment] = useState('');
  const unread = useSelector((state) => state.chats.unreadCount);
  useEffect(() => {
    socket.on('sendChats', (user) => {
      dispatch(resetState());
      dispatch(getInitialState(
        {
          chat: user.sort(
            (a, b) => (_.get(a, 'message.createdAt', '') > _.get(b, 'message.createdAt', '') ? -1 : 1),
          ),
          userId: authState._id,
        },
      ));
    });
  }, [authState._id, update]);
  function GetChat(limit, studentId, instructor, enrollment) {
    socket.emit('getChat', {
      enrollmentId: enrollment,
      offset: 0,
      limit,
      secondUser: authState._id === instructor ? studentId : instructor,
    });
  }
  useEffect(() => {
    socket.on('sendChat', (messages) => {
      dispatch(onGetChat(messages.messages));
    });
  });
  useEffect(() => {
    socket.on('message', (message) => {
      dispatch(
        onNewMsg(
          { message: message.newMessage, userId: authState._id, curEnrollment: curEnrollmentId },
        ),
      );
      if (curEnrollmentId === message.newMessage.enrollmentId
         && authState._id !== message.newMessage.senderId) {
        const now = new Date();
        socket.emit('seenChatMessage', { enrollmentId: curEnrollmentId, time: dateFormat(now, 'isoUtcDateTime') });
      }
    });
  });
  useEffect(() => {
    socket.on('deleteMessage', (message) => {
      dispatch(onDeleteMsg(message.message));
    });
  });
  function sendMessage(messageSend, receiversId, courseId, instructorId, enrollment) {
    socket.emit('chatMessage', {
      msg: messageSend,
      sender: authState._id,
      receiver: receiversId,
      courseId,
      instructorId,
      enrollmentId: enrollment,
    });
  }
  function deleteMessage(msgId, receiversId, instructorId) {
    socket.emit('deleteChatMessage', {
      msgId, receiver: receiversId, sender: authState._id, instructorId,
    });
  }
  return (
    <SocketContext.Provider value={{
      socket,
      unread,
      curEnrollmentId,
      setEnrollment,
      GetChat,
      deleteMessage,
      sendMessage,
      GetChats,
      update,
      setUpdate,
    }}
    >
      {children}
    </SocketContext.Provider>
  );
}

export default SocketProdiver;
