/* eslint-disable react/prop-types */
import React from 'react';
import { Modal } from 'react-bootstrap';
import PreviewCard from '../../common/previewCard';

import './style.css';

type props = {
  previewModal: boolean,
   togglePreviewModal: () => void,
    previewData?: {
      displayPic?: string,
      displayPicTwo?: string,
      answer?: any,
      question?: any,
      hint?: string,
    }
}

// eslint-disable-next-line react/prop-types
const PreviewModal: React.FC<props> = ({ previewModal, togglePreviewModal, previewData = {} }) => {
  const queImg = {
    url: previewData?.displayPic || '',
  };
  const ansImg = {
    url: previewData?.displayPicTwo || '',
  };
  return (
    <Modal show={previewModal} fullscreen="sm" centered onHide={() => togglePreviewModal()}>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <p className="create__card--heading">Preview</p>
            <button
              type="button"
              onClick={() => togglePreviewModal()}
              className="btn-close float-end"
              style={{ width: '4px', height: '4px' }}
            />
          </div>
          <div className="d-flex justify-content-center">
            <PreviewCard
              answer={previewData?.answer}
              question={previewData?.question}
              queImg={queImg}
              ansImg={ansImg}
              hint={previewData?.hint}
              type="preview only"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PreviewModal;
