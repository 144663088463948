import React from 'react';
import { Link } from 'react-router-dom';
import { greenTick, lightGreyTick, plus } from '../../BasicComponents/icons';

export type ProfileProgressProps = {
  percentages: number;
  checklistData: any;
  processString: (str: string) => string;
}

const ProfileProgress = ({ percentages, checklistData, processString }: ProfileProgressProps) => {
  const outerbarStyles = {
    width: '100%',
    height: '10px',
    borderRadius: '100px',
    backgroundColor: '#C4C4C4',
    overflow: 'hidden',
  };

  const innerbarStyles = {
    height: '100%',
    width: `${percentages > 100 ? 100 : percentages}%`,
    backgroundColor: '#24AA42',
    borderRadius: '100px',
    transition: 'width 0.8s cubic-bezier(.27,.21,.99,1.14)',
  };

  return (
    <div className="mdashboard__card-rounded mdashboard__progress">
      <div className="progress__header">
        <h2 className="progress__header--heading">Complete your profile</h2>
        <p className="progress__header--desc">
          Complete your profile to get Verified and start earning!
        </p>
      </div>
      <div className="progress__progressbar">
        <div className="progressbar__container">
          <div style={outerbarStyles} className="progressbar--outer-bar">
            <div style={innerbarStyles} className="progressbar--inner-bar" />
          </div>
        </div>
        <div className="progressbar__percentages">
          <span>
            {percentages > 100 ? 100 : percentages}
            %
          </span>
        </div>
      </div>
      <div className="progress__checklist">
        <div className="checklist__container">
          {
            checklistData && checklistData
              .filter((item: any) => item.enabled === false)
              .slice(0, 5)
              .map((item: any, i: number) => (
              // eslint-disable-next-line react/no-array-index-key
                <div key={i} className="checklist__item">
                  <img src={item.enabled ? greenTick : lightGreyTick} alt="status" />
                  <h2 className={`checklist__item--label ${item.completed ? 'completed' : ''}`}>
                    {processString(item.name)}
                  </h2>
                  {
                    item.enabled ? null : (
                      <Link to="/mentor/editProfile" className="checklist__item--link">
                        <img src={plus} alt="add" />
                      </Link>
                    )
                  }
                </div>
              ))
          }
        </div>
        <div className="checklist__link">
          <Link to="/mentor/editProfile">Go to Edit Profile</Link>
        </div>
      </div>
    </div>
  );
};

export default ProfileProgress;
