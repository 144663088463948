import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: '',
};
export const toastSlice = createSlice({
  name: 'toastData',
  initialState,
  reducers: {
    addToast: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.data = action.payload;
    },
  },
});

export const { addToast } = toastSlice.actions;

export default toastSlice.reducer;
