export const show = (idName: string) => {
  const element: HTMLElement | null = document.getElementById(idName);
  if (element !== null) {
    element.style.display = 'block';
  }
};

export const hide = (idName: string) => {
  const element: HTMLElement | null = document.getElementById(idName);
  if (element !== null) {
    element.style.display = 'none';
  }
};
