import React, { useCallback, useEffect, useState } from 'react';
import { hlbClient } from '../../../Clients/hlbClient';
import { buildEntitiesResponse, defaultEntityContainerConfig } from '../StudentDashboard';
import { EmptyMyNotes, EmptySubscribedNotes } from '../emptyStates';
import { EntityContainer, NoteCard } from '@abhijeet_hyperlearn/hlf_lib';
import { noop } from 'lodash';
import { useHistory } from 'react-router';
import EntityShowcase from '../entityShowcase';
import { AuthModal } from '../../Auth/Modal';

interface MyNotesProps {
    searchTerm?: string;
    clearSearch?: () => void;
}

const MyNotes = ({ searchTerm, clearSearch = noop } : MyNotesProps) => {
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [renderEntityShowcase, setRenderEntityShowcase] = useState(false);
    const [entityContainerConfig, setEntityContainerConfig] = useState(defaultEntityContainerConfig);
    const [showEmptyContainers, setShowEmptyContainer] = useState({
        subscribedNotes: false,
        myNotes: false,
    });
    const [subscribedNotes, setSubscribedNotes] = useState<any[]>([]);
    const [myNotes, setMyNotes] = useState<any[]>([]);

    const history = useHistory();
    const token = window.localStorage.getItem('token');
    const { subscribedNotes: noSubscribedNotes, myNotes: noMyNotes } = showEmptyContainers;

    const handleExplore = (id: any) => history.push(`notes/flow-mode-v2/${id}`);

    const closeAuthModal = () => setShowAuthModal(false);

    const fetchSubscribedNotes = useCallback(async () => {
        try {
            const resp = await hlbClient().get('/api/srs/deck/subscribedDecks');
            if(resp.data?.result?.length > 0) {
                setSubscribedNotes(resp.data?.result);
                return buildEntitiesResponse(resp.data?.result, resp.data?.result?.length, handleExplore)
            } else {
                setShowEmptyContainer((prev) => {
                    return { ...prev, subscribedNotes: true }
                });
            }
        } catch {
            console.error("Something went wrong while fetching subscribed notes!");
        }
    }, []);

    const fetchMyNotes = useCallback(async () => {
        try {
          const resp = await hlbClient().get('/api/srs/mydecks');
          
          if(resp.data.result.length > 0) {
            setMyNotes(resp.data?.result);
            return buildEntitiesResponse(resp.data?.result, resp.data?.result?.length, handleExplore)
          } else {
            setShowEmptyContainer((prev) => {
                return { ...prev, myNotes: true }
            });
          }
        } catch {
          console.error("Something went wrong while fetching my notes!");
        }
    }, []);

    if (renderEntityShowcase) {
        return (
            <EntityShowcase
                entityType={entityContainerConfig.entityType}
                title={entityContainerConfig.title}
                search={entityContainerConfig.search}
                filter={entityContainerConfig.filter}
                ids={entityContainerConfig.ids}
                closeShowcase={() => setRenderEntityShowcase(false)}
                showcaseItems={entityContainerConfig.showcaseItems}
            />
        )
    }

    useEffect(() => {
        if (!token) {
            setShowAuthModal(true);
        }
    }, [token]);

    return (
        <>
            {
                noMyNotes ? (
                    <>
                        <h2 className='entityContainer__heading'>My Notes</h2>
                        <EmptyMyNotes />
                    </>
                ) : (
                    <EntityContainer
                        key="myNotes container"
                        title="My Notes"
                        entityDetails={{
                            component: NoteCard,
                            loadEntityData: fetchMyNotes,
                        }}
                        handleLoadMore={() => {
                            setRenderEntityShowcase(true);
                            setEntityContainerConfig({
                                title: "My Notes",
                                entityType: "note",
                                search: "",
                                filter: [],
                                ids: [],
                                showcaseItems: myNotes,
                            });
                        }}
                    />
                )
            }
            {
                noSubscribedNotes ? (
                    <>
                        <h2 className='entityContainer__heading'>Subscribed Notes</h2>
                        <EmptySubscribedNotes />
                    </>
                ) : (
                    <EntityContainer
                        key="subscribed notes container"
                        title="Subscribed Notes"
                        entityDetails={{
                            component: NoteCard,
                            loadEntityData: fetchSubscribedNotes,
                        }}
                        handleLoadMore={() => {
                            setRenderEntityShowcase(true);
                            setEntityContainerConfig({
                                title: "Subscribed Notes",
                                entityType: "note",
                                search: "",
                                filter: [],
                                ids: [],
                                showcaseItems: subscribedNotes,
                            });
                        }}
                    />
                )
            }
            <AuthModal open={showAuthModal} closeModal={closeAuthModal} />
        </>
    );
};

export default MyNotes;