/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import _, { noop } from 'lodash';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { hlbClient } from '../../../Clients/hlbClient';
import {
  greyTwitter,
  greyLinkedIn,
  greyGithub,
  redCautionIcon,
  edit,
  smallPlus,
} from '../icons';
import './socials.css';
import { SocialType } from '../socialMedia/SocialMedia';
import AddSocials from '../AddSocials';

/* eslint-disable react/require-default-props */
export type SocialsProps = {
  apiCall?: () => void;
  entityType?: string;
  socialList?: SocialType[];
  componentType?: string;
};

const socialsDefaultValue = {
  facebook: '',
  twitter: '',
  linkedIn: '',
  github: '',
  youtube: '',
  reddit: '',
  telegram: '',
  instagram: '',
  dribble: '',
  discord: '',
  medium: '',
  behance: '',
  portfolio: '',
};

function Socials({
  apiCall,
  entityType = '',
  socialList = [],
  componentType = '',
}: SocialsProps) {
  const socialObj = socialList.reduce(
    (obj, item) => Object.assign(obj, { [item.platform]: item }),
    {}
  );

  const [showSocials, setShowSocials] = useState(false);
  const [behanceLink, setBehanceLink] = useState<string>(
    _.get(socialObj, 'behance.url', '')
  );
  const [facebookLink, setFacebookLink] = useState<string>(
    _.get(socialObj, 'facebook.url', '')
  );
  const [twitterLink, setTwitterLink] = useState<string>(
    _.get(socialObj, 'twitter.url', '')
  );
  const [dribbbleLink, setDribbbleLink] = useState<string>(
    _.get(socialObj, 'dribble.url', '')
  );
  const [instagramLink, setInstagramLink] = useState<string>(
    _.get(socialObj, 'instagram.url', '')
  );
  const [githubLink, setGithubLink] = useState<string>(
    _.get(socialObj, 'github.url', '')
  );
  const [linkedInLink, setLinkedInLink] = useState<string>(
    _.get(socialObj, 'linkedIn.url', '')
  );
  const [redditLink, setRedditLink] = useState<string>(
    _.get(socialObj, 'reddit.url', '')
  );
  const [websiteLink, setWebsiteLink] = useState<string>(
    _.get(socialObj, 'portfolio.url', '')
  );
  const [socials, setSocials] = useState<SocialType[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAddSocialsOpen, setIsAddSocialsOpen] = useState(false);

  useEffect(() => {
    setFacebookLink(_.get(socialObj, 'facebook.url', ''));
    setBehanceLink(_.get(socialObj, 'behance.url', ''));
    setRedditLink(_.get(socialObj, 'reddit.url', ''));
    setDribbbleLink(_.get(socialObj, 'dribble.url', ''));
    setGithubLink(_.get(socialObj, 'github.url', ''));
    setInstagramLink(_.get(socialObj, 'instagram.url', ''));
    setLinkedInLink(_.get(socialObj, 'linkedIn.url', ''));
    setTwitterLink(_.get(socialObj, 'twitter.url', ''));
    setWebsiteLink(_.get(socialObj, 'portfolio.url', ''));
  }, [socialList]);
  // eslint-disable-next-line max-len
  const checkUrl = socials.reduce((acc, next) => {
    acc[next.platform] = next.url;
    return acc;
  }, socialsDefaultValue);
  // eslint-disable-next-line no-undef
  const portalRoot = document.querySelector('#portal-root');
  const renderAddSocials = ({ open }: { open: boolean }) => {
    if (!open) return null;
    if (!portalRoot) return null;
    // eslint-disable-next-line no-undef, max-len
    return ReactDOM.createPortal(
      <AddSocials
        handleClose={() => setIsAddSocialsOpen(false)}
        apiCall={apiCall}
      />,
      portalRoot
    );
  };

  const addWebsite = (e: any) => {
    if (e.target.checked) {
      setSocials([...socials, { platform: 'portfolio', url: websiteLink }]);
    } else {
      setSocials(
        socials.filter((social) => social.platform !== e.target.value)
      );
      setWebsiteLink('');
    }
  };
  const addTwitter = (e: any) => {
    if (e.target.checked) {
      setSocials([...socials, { platform: 'twitter', url: twitterLink }]);
    } else {
      setSocials(
        socials.filter((social) => social.platform !== e.target.value)
      );
      setTwitterLink('');
    }
  };
  const addLinkedin = (e: any) => {
    if (e.target.checked) {
      setSocials([...socials, { platform: 'linkedIn', url: linkedInLink }]);
    } else {
      setSocials(
        socials.filter((social) => social.platform !== e.target.value)
      );
      setLinkedInLink('');
    }
  };
  const addFacebook = (e: any) => {
    if (e.target.checked) {
      setSocials([...socials, { platform: 'facebook', url: facebookLink }]);
    } else {
      setSocials(
        socials.filter((social) => social.platform !== e.target.value)
      );
      setFacebookLink('');
    }
  };
  const addBehance = (e: any) => {
    if (e.target.checked) {
      setSocials([...socials, { platform: 'behance', url: behanceLink }]);
    } else {
      setSocials(
        socials.filter((social) => social.platform !== e.target.value)
      );
      setBehanceLink('');
    }
  };
  const addInstagram = (e: any) => {
    if (e.target.checked) {
      setSocials([...socials, { platform: 'instagram', url: instagramLink }]);
    } else {
      setSocials(
        socials.filter((social) => social.platform !== e.target.value)
      );
      setInstagramLink('');
    }
  };
  const addgithub = (e: any) => {
    if (e.target.checked) {
      setSocials([...socials, { platform: 'github', url: githubLink }]);
    } else {
      setSocials(
        socials.filter((social) => social.platform !== e.target.value)
      );
      setGithubLink('');
    }
  };
  const addDribbble = (e: any) => {
    if (e.target.checked) {
      setSocials([...socials, { platform: 'dribble', url: dribbbleLink }]);
    } else {
      setSocials(
        socials.filter((social) => social.platform !== e.target.value)
      );
      setDribbbleLink('');
    }
  };
  const addReddit = (e: any) => {
    if (e.target.checked) {
      setSocials([...socials, { platform: 'reddit', url: redditLink }]);
    } else {
      setSocials(
        socials.filter((social) => social.platform !== e.target.value)
      );
      setRedditLink('');
    }
  };
  useEffect(() => {
    if (socialList) {
      setSocials(socialList);
    }
  }, [socialList]);
  const submitSocials = async (e: any) => {
    e.preventDefault();
    const socialsLink = socials?.slice(0, 3);
    try {
      await hlbClient().put('/api/users/socials', {
        socials: socialsLink,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Opps Something went Wrong');
    }
    apiCall && apiCall();
    setShowSocials(false);
    setRedditLink('');
    setDribbbleLink('');
    setGithubLink('');
    setInstagramLink('');
    setBehanceLink('');
    setFacebookLink('');
    setLinkedInLink('');
    setTwitterLink('');
    setWebsiteLink('');
    setSocials([]);
  };
  const getMessage = (e: string) => {
    setErrorMessage(e);
  };
  useEffect(() => {
    if (websiteLink) {
      setErrorMessage('');
    }
    if (linkedInLink) {
      setErrorMessage('');
    }
    if (twitterLink) {
      setErrorMessage('');
    }
    if (facebookLink) {
      setErrorMessage('');
    }
    if (instagramLink) {
      setErrorMessage('');
    }
    if (behanceLink) {
      setErrorMessage('');
    }
    if (dribbbleLink) {
      setErrorMessage('');
    }
    if (githubLink) {
      setErrorMessage('');
    }
    if (redditLink) {
      setErrorMessage('');
    }
  }, [
    websiteLink,
    linkedInLink,
    twitterLink,
    facebookLink,
    instagramLink,
    behanceLink,
    dribbbleLink,
    redditLink,
  ]);

  return (
    <div>
      {componentType !== 'checkList' ? (
        <>
          {entityType !== 'editButton' ? (
            <>
              <img
                onClick={() => {
                  // setShowSocials(true);
                  setIsAddSocialsOpen(true);
                }}
                onKeyPress={() => {
                  // setShowSocials(true);
                  setIsAddSocialsOpen(true);
                }}
                role="presentation"
                src={greyTwitter}
                alt="add session"
                className="icon-img"
              />
              <img
                onClick={() => {
                  // setShowSocials(true);
                  setIsAddSocialsOpen(true);
                }}
                onKeyPress={() => {
                  // setShowSocials(true);
                  setIsAddSocialsOpen(true);
                }}
                role="presentation"
                src={greyLinkedIn}
                alt="add session"
                className="icon-img"
              />
              <img
                onClick={() => {
                  // setShowSocials(true);
                  setIsAddSocialsOpen(true);
                }}
                onKeyPress={() => {
                  // setShowSocials(true);
                  setIsAddSocialsOpen(true);
                }}
                role="presentation"
                src={greyGithub}
                alt="add session"
                className="icon-img"
              />
            </>
          ) : (
            <img
              onClick={() => {
                setIsAddSocialsOpen(true);
                if (apiCall) {
                  apiCall();
                }
              }}
              onKeyPress={() => {
                setIsAddSocialsOpen(true);
              }}
              role="presentation"
              src={edit}
              alt="add session"
              className="icon-img"
              style={{ width: '18px', margin: '10px' }}
            />
          )}
        </>
      ) : (
        <img
          onClick={() => {
            setIsAddSocialsOpen(true);
          }}
          onKeyPress={() => {
            setIsAddSocialsOpen(true);
          }}
          role="presentation"
          src={smallPlus}
          alt="add"
          className="checklist__card--plus-icon"
        />
      )}

      {renderAddSocials({ open: isAddSocialsOpen })}

      <Modal show={showSocials} size="lg" onHide={() => setShowSocials(false)}>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <button
                type="button"
                onClick={() => setShowSocials(false)}
                className="btn-close float-end"
              />
            </div>
            <div className="co-md-12">
              <p className="videoUpload__heading">Social Links</p>
              <p className="videoUpload__sub-heading">
                Add up to three social links to showcase your works on other
                platforms
              </p>
              <form onSubmit={submitSocials} style={{ marginLeft: '0px' }}>
                <div className="row g-0">
                  {/* website link */}
                  <label className="socials__form--label">Website</label>
                  <div className="col-1 mt-1 text-center checkbox-position">
                    {websiteLink ? (
                      <input
                        type="checkbox"
                        value="portfolio"
                        name="portfolio"
                        checked={Boolean(checkUrl?.portfolio)}
                        onChange={addWebsite}
                        disabled={
                          (!checkUrl.portfolio && socials.length > 2) ||
                          websiteLink === ''
                        }
                        className="socials--checkbox"
                      />
                    ) : (
                      <button
                        type="button"
                        className="socials--checkbox"
                        onClick={() => getMessage('Enter your Portfolio link')}
                        style={{
                          position: 'absolute',
                          padding: '4px',
                          top: '5px',
                          opacity: 0,
                        }}
                      />
                    )}
                    <label className="socials--checkbox-label" />
                  </div>
                  <div className="col-11">
                    <input
                      className="form-input"
                      value={websiteLink}
                      onChange={(e) => {
                        setWebsiteLink(e.target.value);
                      }}
                      type="text"
                      placeholder="Your website URL"
                      disabled={!checkUrl.portfolio && socials.length > 2}
                    />
                    {errorMessage === 'Enter your Portfolio link' && (
                      <label style={{ color: '#F71C1C' }}>
                        <img
                          src={redCautionIcon}
                          alt=".."
                          style={{ marginTop: '-2px' }}
                        />{' '}
                        {errorMessage}
                      </label>
                    )}
                  </div>
                  {/* LinkedIN Profile */}
                  <label className="socials__form--label mt-2">
                    LinkedIN Profile
                  </label>
                  <div className="col-1 mt-1 text-center checkbox-position">
                    {linkedInLink ? (
                      <input
                        type="checkbox"
                        value="linkedIn"
                        name="linkedIn"
                        checked={Boolean(checkUrl?.linkedIn)}
                        disabled={
                          (!checkUrl.linkedIn && socials.length > 2) ||
                          linkedInLink === ''
                        }
                        onClick={addLinkedin}
                        className="socials--checkbox"
                      />
                    ) : (
                      <button
                        type="button"
                        className="socials--checkbox"
                        onClick={() => getMessage('Enter your LinkedIn link')}
                        style={{
                          position: 'absolute',
                          padding: '4px',
                          top: '5px',
                          opacity: 0,
                        }}
                      />
                    )}
                    <label className="socials--checkbox-label" />
                  </div>
                  <div className="col-11">
                    <input
                      className="form-input"
                      value={linkedInLink}
                      onChange={(e) => {
                        setLinkedInLink(e.target.value);
                      }}
                      type="text"
                      placeholder="LinkedIN URL"
                      disabled={!checkUrl.linkedIn && socials.length > 2}
                    />
                    {errorMessage === 'Enter your LinkedIn link' && (
                      <label style={{ color: '#F71C1C' }}>
                        <img
                          src={redCautionIcon}
                          alt=".."
                          style={{ marginTop: '-2px' }}
                        />{' '}
                        {errorMessage}
                      </label>
                    )}
                  </div>
                  {/* Twitter Profile  */}
                  <label className="socials__form--label mt-2">
                    Twitter Profile
                  </label>
                  <div className="col-1 mt-1 text-center checkbox-position">
                    {twitterLink ? (
                      <input
                        type="checkbox"
                        value="twitter"
                        name="twitter"
                        checked={Boolean(checkUrl?.twitter)}
                        onClick={addTwitter}
                        disabled={
                          (!checkUrl.twitter && socials.length > 2) ||
                          twitterLink === ''
                        }
                        className="socials--checkbox"
                      />
                    ) : (
                      <button
                        type="button"
                        className="socials--checkbox"
                        onClick={() => getMessage('Enter your Twitter link')}
                        style={{
                          position: 'absolute',
                          padding: '4px',
                          top: '5px',
                          opacity: 0,
                        }}
                      />
                    )}
                    <label className="socials--checkbox-label" />
                  </div>
                  <div className="col-11">
                    <input
                      className="form-input"
                      value={twitterLink}
                      onChange={(e) => {
                        setTwitterLink(e.target.value);
                      }}
                      type="text"
                      placeholder="Twitter URL"
                      disabled={!checkUrl.twitter && socials.length > 2}
                    />
                    {errorMessage === 'Enter your Twitter link' && (
                      <label style={{ color: '#F71C1C' }}>
                        <img
                          src={redCautionIcon}
                          alt=".."
                          style={{ marginTop: '-2px' }}
                        />{' '}
                        {errorMessage}
                      </label>
                    )}
                  </div>
                  {/* Facebook Profile  */}
                  <label className="socials__form--label mt-2">
                    Facebook Profile{' '}
                  </label>
                  <div className="col-1 mt-1 text-center checkbox-position">
                    {facebookLink ? (
                      <input
                        type="checkbox"
                        value="facebook"
                        name="facebook"
                        checked={Boolean(checkUrl?.facebook)}
                        onClick={addFacebook}
                        disabled={
                          (!checkUrl.facebook && socials.length > 2) ||
                          facebookLink === ''
                        }
                        // onClick={() => setIsChecked(!isChecked)}
                        className="socials--checkbox"
                      />
                    ) : (
                      <button
                        type="button"
                        className="socials--checkbox"
                        onClick={() => getMessage('Enter your FaceBook link')}
                        style={{
                          position: 'absolute',
                          padding: '4px',
                          top: '5px',
                          opacity: 0,
                        }}
                      />
                    )}
                    <label className="socials--checkbox-label" />
                  </div>
                  <div className="col-11">
                    <input
                      className="form-input"
                      value={facebookLink}
                      onChange={(e) => {
                        setFacebookLink(e.target.value);
                      }}
                      type="text"
                      placeholder="Facebook URL"
                      disabled={!checkUrl.facebook && socials.length > 2}
                    />
                    {errorMessage === 'Enter your FaceBook link' && (
                      <label style={{ color: '#F71C1C' }}>
                        <img
                          src={redCautionIcon}
                          alt=".."
                          style={{ marginTop: '-2px' }}
                        />{' '}
                        {errorMessage}
                      </label>
                    )}
                  </div>
                  {/* Instagram Profile  */}
                  <label className="socials__form--label mt-2">
                    Instagram Profile{' '}
                  </label>
                  <div className="col-1 mt-1 text-center checkbox-position">
                    {instagramLink ? (
                      <input
                        type="checkbox"
                        value="instagram"
                        name="instagram"
                        checked={Boolean(checkUrl?.instagram)}
                        onClick={addInstagram}
                        disabled={
                          (!checkUrl.instagram && socials.length > 2) ||
                          instagramLink === ''
                        }
                        className="socials--checkbox"
                      />
                    ) : (
                      <button
                        type="button"
                        className="socials--checkbox"
                        onClick={() => getMessage('Enter your Instagram link')}
                        style={{
                          position: 'absolute',
                          padding: '4px',
                          top: '5px',
                          opacity: 0,
                        }}
                      />
                    )}
                    <label className="socials--checkbox-label" />
                  </div>
                  <div className="col-11">
                    <input
                      className="form-input"
                      value={instagramLink}
                      onChange={(e) => {
                        setInstagramLink(e.target.value);
                      }}
                      type="text"
                      placeholder="Instagram URL"
                      disabled={!checkUrl.instagram && socials.length > 2}
                    />
                    {errorMessage === 'Enter your Instagram link' && (
                      <label style={{ color: '#F71C1C' }}>
                        <img
                          src={redCautionIcon}
                          alt=".."
                          style={{ marginTop: '-2px' }}
                        />{' '}
                        {errorMessage}
                      </label>
                    )}
                  </div>
                  {/* Behance Profile  */}
                  <label className="socials__form--label mt-2">
                    Behance Profile{' '}
                  </label>
                  <div className="col-1 mt-1 text-center checkbox-position">
                    {behanceLink ? (
                      <input
                        type="checkbox"
                        value="behance"
                        name="behance"
                        checked={Boolean(checkUrl?.behance)}
                        onClick={addBehance}
                        disabled={
                          (!checkUrl.behance && socials.length > 2) ||
                          behanceLink === ''
                        }
                        className="socials--checkbox"
                      />
                    ) : (
                      <button
                        type="button"
                        className="socials--checkbox"
                        onClick={() => getMessage('Enter your Behance link')}
                        style={{
                          position: 'absolute',
                          padding: '4px',
                          top: '5px',
                          opacity: 0,
                        }}
                      />
                    )}
                    <label className="socials--checkbox-label" />
                  </div>
                  <div className="col-11">
                    <input
                      className="form-input"
                      value={behanceLink}
                      onChange={(e) => {
                        setBehanceLink(e.target.value);
                      }}
                      type="text"
                      placeholder="Behance URL"
                      disabled={!checkUrl.behance && socials.length > 2}
                    />
                    {errorMessage === 'Enter your Behance link' && (
                      <label style={{ color: '#F71C1C' }}>
                        <img
                          src={redCautionIcon}
                          alt=".."
                          style={{ marginTop: '-2px' }}
                        />{' '}
                        {errorMessage}
                      </label>
                    )}
                  </div>
                  {/* Dribble Profile  */}
                  <label className="socials__form--label mt-2">
                    Dribble Profile
                  </label>
                  <div className="col-1 mt-1 text-center checkbox-position">
                    {dribbbleLink ? (
                      <input
                        type="checkbox"
                        value="dribble"
                        name="dribble"
                        checked={Boolean(checkUrl?.dribble)}
                        onClick={addDribbble}
                        disabled={
                          (!checkUrl.dribble && socials.length > 2) ||
                          dribbbleLink === ''
                        }
                        className="socials--checkbox"
                      />
                    ) : (
                      <button
                        type="button"
                        className="socials--checkbox"
                        onClick={() => getMessage('Enter your Dribbble link')}
                        style={{
                          position: 'absolute',
                          padding: '4px',
                          top: '5px',
                          opacity: 0,
                        }}
                      />
                    )}
                    <label className="socials--checkbox-label" />
                  </div>
                  <div className="col-11">
                    <input
                      className="form-input"
                      value={dribbbleLink}
                      onChange={(e) => {
                        setDribbbleLink(e.target.value);
                      }}
                      type="text"
                      placeholder="Dribble URL"
                      disabled={!checkUrl.dribble && socials.length > 2}
                    />
                    {errorMessage === 'Enter your Dribbble link' && (
                      <label style={{ color: '#F71C1C' }}>
                        <img
                          src={redCautionIcon}
                          alt=".."
                          style={{ marginTop: '-2px' }}
                        />{' '}
                        {errorMessage}
                      </label>
                    )}
                  </div>
                  {/* Github Profile  */}
                  <label className="socials__form--label mt-2">
                    Github Profile{' '}
                  </label>
                  <div className="col-1 mt-1 text-center checkbox-position">
                    {githubLink ? (
                      <input
                        type="checkbox"
                        value="github"
                        name="github"
                        checked={Boolean(checkUrl?.github)}
                        onClick={addgithub}
                        disabled={
                          (!checkUrl.github && socials.length > 2) ||
                          githubLink === ''
                        }
                        className="socials--checkbox"
                      />
                    ) : (
                      <button
                        type="button"
                        className="socials--checkbox"
                        onClick={() => getMessage('Enter your GitHub link')}
                        style={{
                          position: 'absolute',
                          padding: '4px',
                          top: '5px',
                          opacity: 0,
                        }}
                      />
                    )}
                    <label className="socials--checkbox-label" />
                  </div>
                  <div className="col-11">
                    <input
                      className="form-input"
                      value={githubLink}
                      onChange={(e) => {
                        setGithubLink(e.target.value);
                      }}
                      type="text"
                      placeholder="Github URL"
                      disabled={!checkUrl.github && socials.length > 2}
                    />
                    {errorMessage === 'Enter your GitHub link' && (
                      <label style={{ color: '#F71C1C' }}>
                        <img
                          src={redCautionIcon}
                          alt=".."
                          style={{ marginTop: '-2px' }}
                        />{' '}
                        {errorMessage}
                      </label>
                    )}
                  </div>
                  {/* reddit */}
                  <label className="socials__form--label mt-2">
                    Reddit Profile{' '}
                  </label>
                  <div className="col-1 mt-1 text-center checkbox-position">
                    {redditLink ? (
                      <input
                        type="checkbox"
                        value="reddit"
                        name="reddit"
                        checked={Boolean(checkUrl?.reddit)}
                        onClick={addReddit}
                        disabled={
                          (!checkUrl.reddit && socials.length > 2) ||
                          redditLink === ''
                        }
                        className="socials--checkbox"
                      />
                    ) : (
                      <button
                        type="button"
                        className="socials--checkbox"
                        onClick={() => getMessage('Enter your Reddit link')}
                        style={{
                          position: 'absolute',
                          padding: '4px',
                          top: '5px',
                          opacity: 0,
                        }}
                      />
                    )}
                    <label className="socials--checkbox-label" />
                  </div>
                  <div className="col-11">
                    <input
                      className="form-input"
                      value={redditLink}
                      onChange={(e) => {
                        setRedditLink(e.target.value);
                      }}
                      type="text"
                      placeholder="Reddit URL"
                      disabled={!checkUrl.reddit && socials.length > 2}
                    />
                    {errorMessage === 'Enter your Reddit link' && (
                      <label style={{ color: '#F71C1C' }}>
                        <img
                          src={redCautionIcon}
                          alt=".."
                          style={{ marginTop: '-2px' }}
                        />{' '}
                        {errorMessage}
                      </label>
                    )}
                  </div>
                </div>
                <div className="text-center mt-3">
                  {socials.length !== 0 ? (
                    <button className="videoUpload__btn btn mt-3" type="submit">
                      Update
                    </button>
                  ) : (
                    <button
                      className="videoUpload__btn-grey btn mt-3"
                      type="submit"
                      disabled
                    >
                      Update
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Socials;
