import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from 'react-share';

type Props = {
  children: React.ReactNode,
  noteURL: string,
  name: string,
}

const ShareWithSocialMedia: React.FC<Props> = ({ noteURL, name, children }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <OverlayTrigger
    trigger="click"
    placement="top"
    containerPadding={20}
    rootClose
    overlay={(
      <Popover id="popover-contained">
        <Popover.Content>
          <FacebookShareButton
            url={noteURL}
            quote={name}
          >
            <FacebookIcon
              size={32}
              round
              className="mx-2"
            />
          </FacebookShareButton>
          <TwitterShareButton
            url={noteURL}
            title={name}
          >
            <TwitterIcon
              size={32}
              round
              className="mx-2"
            />
          </TwitterShareButton>
          <WhatsappShareButton
            url={noteURL}
            title={name}
          >
            <WhatsappIcon
              size={32}
              round
              className="mx-2"
            />
          </WhatsappShareButton>
          <LinkedinShareButton
            url={noteURL}
            title={name}
          >
            <LinkedinIcon
              size={32}
              round
              className="mx-2"
            />
          </LinkedinShareButton>
        </Popover.Content>
      </Popover>
)}
  >
    <div role="presentation">
      {children}
    </div>
  </OverlayTrigger>
);
ShareWithSocialMedia.propTypes = {
  children: PropTypes.string.isRequired,
  noteURL: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
export default ShareWithSocialMedia;
