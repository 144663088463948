import React, { useRef } from 'react'
import HtmlParser from '../../../BasicComponents/htmlParser';
import _ from 'lodash';
import { TypeAnswerCard } from '../../types';


const TypeAnswer: React.FC<TypeAnswerCard> = ({
    questionImg = null, questionText,
    verifyAnswer, id, reason, disableInteraction = false, done,
    answerText: answer,
}) => {
    const inputRef = useRef<any>(null);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        // Your form submission logic here
      };
    
      const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          // When Enter key is pressed, blur the input field to hide the keyboard
          if (inputRef.current) {
            inputRef.current.blur();
          }
        }
      };
      const getAnswer = (userAnswer: string, correctAnswer: string) => {
        const payload = {
            userAnswer, correctAnswer
        }
        const result = verifyAnswer('writeAnswer', payload);

        done('writeAnswer', result)
      }
    return (
                // eslint-disable-next-line react/jsx-filename-extension
                <>
                <style>
                    {`
            .form-control {
                display: block;
                width: 90%;
                padding: 0.375rem 0.75rem;
                margin-left: 15px;
            }
            `}
                </style>
                <form className="mobile__anki--card-container" onSubmit={handleSubmit}>
                    <div className="">
                        {_.get(questionImg, 'file', '') && (
                            <div className="mobile__anki--image-Container">
                                <img
                                    src={questionImg?.file}
                                    alt=""
                                    className={'mobile__anki--card-container-image'}
                                />
                            </div>
                        )}
                        <p className="mobile__anki--paragraph">
                            <HtmlParser text={questionText} />
                        </p>
                        <p className="mobile__anki--paragraph">Write your answer</p>
                        {disableInteraction ? (
                            <div className="form-control">{answer}</div>
                        ) : (
                            <input
                                ref={inputRef}
                                onKeyDown={handleKeyPress}
                                className="form-control" placeholder='Type your answer.' />
                        )}
                        <button disabled={disableInteraction} className="multiple-option--done-btn"
                                onClick={() => getAnswer(inputRef.current.value.trim(), answer)}
                                type="submit"
                            >Done</button>
                    </div>
                </form>
            </>
);
}

export default TypeAnswer;