import React from 'react';
import { Link } from 'react-router-dom';

function LogIn() {
  return (
    <Link
      to="/login"
      className="text-dark"
      style={{
        textDecoration: 'none', color: '#000', display: 'block', width: '100%', padding: '5px',
      }}
    >
      Login

    </Link>
  );
}

export default LogIn;
