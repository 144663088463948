import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import './floatingMessage.css';

const FloatingMessage: React.FC = () => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <>
      <div className="floating-message__container">
        <div className="floating-message__wrapper">
          <div className="floating-message__pulsating-circle"></div>
          <div className="floating-message__contents">
            <div className="floating-message__message">
              Want to know <i>&quot;How the notes work&quot;?</i>
            </div>
            <button
              className="floating-message__btn"
              type="button"
              onClick={() => {
                setShowVideo(!showVideo);
              }}
            >
              Click Here
            </button>
          </div>
        </div>
      </div>
      {showVideo && (
        <Modal
          show={showVideo}
          centered
          onHide={() => setShowVideo(false)}
          className="video-modal__container"
          backdrop="static"
        >
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <button
                  type="button"
                  onClick={() => setShowVideo(false)}
                  className="btn-close float-end"
                />
              </div>
            </div>
            <div className="col-12 video-modal__body">
              <p className="video-modal__title">Tutorial: How notes work?</p>
              <div className="video-modal__media">
                <iframe
                  className="video-modal__iframe"
                  width="100%"
                  height="400"
                  src="https://www.youtube.com/embed/07X5prbQP0s"
                  title="How notes work youtube"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default FloatingMessage;
