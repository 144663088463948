import React, { useState } from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { clock } from '../icons';
import './bookSessionCard.css';
import RequestDemo from '../requestDemo';

type dataObject = {
  image: string;
  heading: string;
  content: string;
  link: string;
};
type props = {
  data: dataObject[];
  isBookingAvailable: boolean;
  isMentorAcc: boolean;
  mentorId: string;
};

const BookSessionCard: React.FC<props> = ({
  // eslint-disable-next-line react/prop-types
  data,
  isBookingAvailable,
  isMentorAcc,
  mentorId,
}) => {
  const [showRequestDemo, setShowRequestDemo] = useState<boolean>(false);
  if (data) {
    return (
      <>
        <style>
          {`
        .modal-healing {
          fontWeight: '600';
           letterSpacing: '0.04em';
            color: '#9281fc';
             width: '90%';
        }
        `}
        </style>
        {/* eslint-disable-next-line react/prop-types */}
        {data &&
          data.map((bookingData, index) => (
            // eslint-disable-next-line react/jsx-key
            <div className="booking-card mb-3 shadow__v2">
              <div className="session-edit-card">
                <p className="Booking__session--sessiontype">
                  {_.get(bookingData, 'sessionType', '')}
                </p>
              </div>
              <p
                className="Booking__session--one-on-one"
                style={{ marginBottom: '16px' }}
              >
                {_.get(bookingData, 'name', '')}
              </p>
              <p style={{ marginBottom: '16px' }}>
                {_.get(bookingData, 'isFree', false) ? (
                  <span
                    className="price"
                    style={{
                      verticalAlign: 'bottom',
                      marginRight: '24px',
                      display: 'inline-block',
                    }}
                  >
                    Free
                  </span>
                ) : (
                  <>
                    {_.get(bookingData, 'discountPrice') &&
                    _.get(bookingData, 'price') ? (
                      <>
                        <span
                          className="price"
                          style={{
                            verticalAlign: 'bottom',
                            marginRight: '24px',
                            display: 'inline-block',
                          }}
                        >
                          ₹{' '}
                          {_.get(bookingData, 'discountPrice', 0)
                            ? _.get(bookingData, 'discountPrice', '')
                            : _.get(bookingData, 'price', 0)}
                        </span>
                        <span
                          className="off"
                          style={{
                            verticalAlign: 'text-bottom',
                            display: 'inline-block',
                          }}
                        >
                          {' '}
                          ₹ {_.get(bookingData, 'price', '')}
                        </span>
                      </>
                    ) : (
                      <span
                        className="price"
                        style={{
                          verticalAlign: 'bottom',
                          marginRight: '24px',
                          display: 'inline-block',
                        }}
                      >
                        {' '}
                        ₹ {_.get(bookingData, 'price', '')}
                      </span>
                    )}
                  </>
                )}
              </p>
              <span className="duration">
                <img
                  src={clock}
                  alt="duration"
                  className="img-fluid"
                  style={{ margin: '-5px 15px 0px 0px' }}
                />
                {_.get(bookingData, 'duration', '')} minutes
              </span>
              <p
                className="paragraph"
                style={{
                  fontSize: '14px',
                  fontWeight: 'normal',
                  marginTop: '16px',
                  marginBottom: '31px',
                }}
              >
                {_.get(bookingData, 'description', '')}
              </p>
              {isBookingAvailable && !isMentorAcc ? (
                <Link
                  to={`/sessionBooking/${_.get(bookingData, '_id', '')}`}
                  className="text-decoration-none"
                >
                  <div className="d-flex justiy-content-center mt-2">
                    <button
                      type="button"
                      className={`${
                        index === 0
                          ? 'btn purple-btn'
                          : 'btn purple-outline-btn'
                      }`}
                      style={{
                        width: '100%',
                        margin: '0 auto',
                        padding: '12px',
                      }}
                    >
                      Book Now
                    </button>
                  </div>
                </Link>
              ) : (
                <div className="d-flex justiy-content-center mt-2">
                  <button
                    type="button"
                    className={`${
                      index === 0
                        ? 'btn btn-secondary disabled'
                        : 'btn btn-outline-secondary disabled'
                    } ${isBookingAvailable ? '' : 'disabled'}`}
                    style={{
                      width: '100%',
                      margin: '0 auto',
                      padding: '12px',
                    }}
                    disabled
                  >
                    Book Now
                  </button>
                </div>
              )}
            </div>
          ))}
        <div className="booking-card mb-3 shadow__v2">
          <p className="one-on-one">Ask me a question</p>
          <p
            className="paragraph"
            style={{ fontSize: '14px', fontWeight: 'normal' }}
          >
            Not sure about something? Send me queries and I will try my best to
            resolve it
          </p>
          <div className="d-flex justiy-content-center mt-2">
            {isBookingAvailable && !isMentorAcc ? (
              <button
                type="button"
                className="btn purple-outline-btn"
                onClick={() => {
                  setShowRequestDemo(true);
                }}
                style={{ width: '100%', margin: '0 auto', padding: '12px' }}
              >
                Ask question
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-outline-secondary disabled"
                onClick={() => {
                  setShowRequestDemo(true);
                }}
                style={{ width: '100%', margin: '0 auto', padding: '12px' }}
                disabled
              >
                Ask question
              </button>
            )}
          </div>
        </div>

        <Modal
          show={showRequestDemo}
          onHide={() => setShowRequestDemo(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            style={{
              fontFamily: 'Poppins',
              padding: '0 20px',
            }}
          >
            <p className="mt-4 modal-healing">Let’s get in touch</p>
            <button
              type="button"
              onClick={() => setShowRequestDemo(false)}
              className="btn-close float-end"
            />
          </Modal.Header>
          <Modal.Body>
            <RequestDemo
              mentorId={mentorId}
              setShowRequestDemo={setShowRequestDemo}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  }

  return <></>;
};

export default BookSessionCard;
