import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import SocialMedia, { SocialType } from '../BasicComponents/socialMedia';
import { earth, tick } from '../BasicComponents/icons';
import HtmlParser from '../BasicComponents/htmlParser';

type props = {
  mentor: {
    profileDetails: {
      expInYears: number,
      socials: SocialType[],
      verified: boolean,
      shortBio: string,
      description: string,
      skills: string[],
      languages: string[],
      publicUrl: string,
      profilePic: { url: string },
     },
    minSessionPrice: number,
    name: string,
  }
}

const MentorSearchCard: React.FC<props> = ({ mentor }) => {
  const expYears = _.get(mentor, 'profileDetails.expInYears', null) || null;
  const sessionPrice = _.get(mentor, 'minSessionPrice', null) || null;
  return (
    <div className="search__mentor--card-container mt-5">
      <div className="search__mentor--profile-box">
        <div className="mentor-profile-box d-flex">
          <img src={_.get(mentor, 'profileDetails.profilePic.url', '')} alt="" className="search__mentor--proile-pic" />
        </div>
        <div className="icon-box">
          <SocialMedia socials={_.get(mentor, 'profileDetails.socials', [])} />
        </div>
      </div>
      <div className="search__mentor--detail-box">
        <p className="search__mentor--detail-box--user-name">
          {_.capitalize(mentor?.name)}
          {' '}
          { _.get(mentor, 'profileDetails.verified', '') === true && <img src={tick} alt="tic" style={{ paddingLeft: '5px' }} /> }
        </p>
        <p className="search__mentor--detail-box--tagLine">
          {_.get(mentor, 'profileDetails.shortBio', '')}
        </p>
        <div className="search__mentor--Rating">
          {/* <MentorRating rating={_.get(mentor, 'profileDetails.rating', '0.1')} />
          {' '}
          {parseFloat(_.get(mentor, 'profileDetails.rating', '0.1')).toFixed(1)} */}
        </div>
        <span className="search__mentor--detail-box--bio">
          <HtmlParser text={_.get(mentor, 'profileDetails.description', '').slice(0, 140)} />
        </span>
        {_.get(mentor, 'profileDetails.skills', []) && _.get(mentor, 'profileDetails.skills', []).slice(0, 3).map((skill: string, index: number) => (
          <span key={index.toString()} className="search__mentor--skills-button">{skill}</span>
        ))}
        {_.get(mentor, 'profileDetails.skills', '').length > 3 && (
        <span className="search__mentor--skills-button">
          +
          {_.get(mentor, 'profileDetails.skills', '').length - 3}
        </span>
        )}
        <div className="search__mentor--session-and-timeZone-Box">
          {/* <div style={{ display: 'block', width: '50%' }}>
            <p className="search__mentor--time-zone-and-session">
              {_.get(mentor, 'totalSessionsTaken', '0')}
              {' '}
              sessions taken
            </p>
          </div> */}
          <div style={{ display: 'block', width: '50%' }}>
            <p className="search__mentor--time-zone-and-session">
              <img src={earth} alt="earth" />
              {' '}
              <span style={{ marginLeft: '10px' }}>Indian Standard Time</span>
              {' '}
            </p>
          </div>
        </div>
      </div>
      <div className="search__mentor--session-box">
        <p className="search__mentor--session-box--exp-and-lang">Experience</p>
        {expYears ? (
          <p className="search__mentor--session-box--year">
            {expYears <= 10 ? expYears : '10+' }
            {' '}
            {expYears <= 1 ? 'year' : 'years'}
          </p>
        ) : (
          <p className="search__mentor--session-box--year">NA</p>
        )}
        <p className="search__mentor--session-box--exp-and-lang">Speaks</p>
        <p className="search__mentor--session-box--year">
          {_.get(mentor, 'profileDetails.languages', []) && _.get(mentor, 'profileDetails.languages', []).map((language: string, index: number) => (
            <span key={index.toString()}>
              { (index ? ', ' : '') + language }
            </span>
          ))}
        </p>
        <p className="search__mentor--session-box--exp-and-lang">Sessions starting at</p>
        {sessionPrice ? (
          <p className="search__mentor--session-box--price">
            ₹
            {_.get(mentor, 'minSessionPrice', 'Free')}
          </p>
        ) : (
          <p className="search__mentor--session-box--price">
            Free
          </p>
        ) }
        <Link
          to={{
            pathname: `/mentor/@${_.get(mentor, 'profileDetails.publicUrl', '')}`,
          }}
          className="btn search__mentor--session-box-btn"
        >
          View Profile
        </Link>
      </div>
    </div>
  );
};

export default MentorSearchCard;
