import React, { ReactNode } from 'react';
import { noop } from 'lodash';
import './NavButton.css';

export type NavButtonProps = {
  children: ReactNode,
  onClick: () => void,
  active: boolean,
  variant?: "primary" | "responsive",
  disabled?: boolean,
}

const NavButton = ({
  children, onClick = noop, active, variant = "primary", disabled = false, ...otherProps
}: NavButtonProps) => {
  const classNames = [
    variant === "primary" ? 'navButton' : "navButton--responsive",
    variant === "primary" && active && 'navButton--active',
    variant === "responsive" && active && "navButton--responsive--active"
  ].join(' ');

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <button
      className={classNames}
      type="button"
      onClick={onClick}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </button>
  );
};

export default NavButton;
