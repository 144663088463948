import React, { useEffect, useState } from 'react';
import {
  XCircle, Calendar, Clock, UsersThree, Coins, MonitorPlay, MagnifyingGlass, User, ArrowUpRight,
} from 'phosphor-react';
import { Link, useHistory } from 'react-router-dom';
import './AttendeeList.css';
import dayjs from 'dayjs';
import { formatFees, getTimeDifference } from '../../Utils';
import { hlbClient } from '../../../Clients/hlbClient';

export type AttendeeListProps = {
  webinar: any;
  onClose: () => void;
  fetchWebinars: () => Promise<void>;
}

const AttendeeList = ({ webinar, onClose, fetchWebinars }: AttendeeListProps) => {
  const [publishWebinar, setPublishWebinar] = useState(webinar?.isPublished || false);
  const [attendees, setAttendees] = useState<any>([]);
  const [attendeeOffset, setAttendeeOffset] = useState(0);
  const [userSearch, setUserSearch] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [showPublishMessage, setShowPublishMessage] = useState(false);
  const history = useHistory();

  const fetchAttendees = async () => {
    try {
      const resp = await hlbClient().get(`/api/webinar/${webinar?._id}/attendees?limit=10&offset=${attendeeOffset}`);
      if (resp.data.success && resp.data.result?.length > 0) {
        setAttendees(resp.data.result);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handlePublishWebinar = async () => {
    try {
      await hlbClient().put(`/api/webinar/${webinar?._id}/publish`);
      fetchWebinars();
      onClose();
      setShowPublishMessage(false);
      setPublishWebinar(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setShowPublishMessage(false);
    }
  };

  const handlePreviewRedirect = () => {
    if (!webinar?.isPublished) {
      history.push(`/webinar/${webinar?._id}`);
    } else {
      history.push(`/webinars/${webinar?.publicUrl}`);
    }
  };

  useEffect(() => {
    fetchAttendees();
  }, [attendeeOffset]);

  useEffect(() => {
    const fetchSearchedAttendees = async () => {
      try {
        const resp = await hlbClient().get(`/api/webinar/${webinar?._id}/attendees?search=${userSearch}&limit=10&offset=${attendeeOffset}`);
        if (resp.data.success && resp.data.result.length > 0) {
          setAttendees(resp.data.result);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    // eslint-disable-next-line no-unused-expressions
    userSearch ? fetchSearchedAttendees() : fetchAttendees();
  }, [userSearch]);

  return (
    <div className="attendeeList--wrapper">
      <div className="attendeeList__container">
        <div className="attendeeList__header">
          <div className="attendeeList__header--wrapper">
            <h2 className="attendeeList__header--heading">
              {webinar.title}
            </h2>
            <button type="button" className="attendeeList__header--preview" onClick={handlePreviewRedirect}>
              <ArrowUpRight size={20} />
            </button>
          </div>
          <XCircle size={24} color="#403F40" onClick={onClose} className="attendeeList__header--close" />
        </div>

        <div className="attendeeList__details">
          <div className="attendeeList__info attendeeList__info--date">
            <Calendar size={26} color="#403F40" className="attendeeList__info--icon" />
            <span className="attendeeList__info--text">
              {dayjs(webinar?.startTime).format('D MMM, YYYY')}
              {' '}
              at
              {' '}
              {dayjs(webinar?.startTime).format('hh:mmA(IST)')}
            </span>
          </div>
          <div className="attendeeList__info attendeeList__info--time">
            <Clock size={26} color="#403F40" className="attendeeList__info--icon" />
            <span className="attendeeList__info--text">{getTimeDifference(webinar?.startTime, webinar?.endTime, false)}</span>
          </div>
          <div className="attendeeList__info attendeeList__info--time">
            <UsersThree size={26} color="#403F40" className="attendeeList__info--icon" />
            <span className="attendeeList__info--text">
              {webinar?.totalAttendees}
              {' '}
              {webinar?.totalAttendees > 0 ? 'total attendees' : 'attendees'}
            </span>
          </div>
          <div className="attendeeList__info attendeeList__info--time">
            <Coins size={26} color="#403F40" className="attendeeList__info--icon" />
            <span className="attendeeList__info--text">
              {formatFees(webinar?.totalMoneyCollected)}
              {' '}
              fees collected
            </span>
          </div>
          <div className="attendeeList__info attendeeList__info--time">
            <MonitorPlay size={26} color="#403F40" className="attendeeList__info--icon" />
            <span className="attendeeList__info--text">
              Webinar platform:
              {' '}
              {webinar?.platform === 'gmeet' ? 'Google Meet' : 'Zoom'}
            </span>
          </div>
        </div>
        <div className={`attendeeList__publishWebinar ${publishWebinar ? 'published' : ''}`}>
          {
            webinar?.isPublished ? (
              <h2 className="publishWebinar__title published">
                &quot;
                {webinar?.title}
                &quot; is published and, now open
                for bookings
              </h2>
            ) : (
              <>
                {showPublishMessage ? (
                  <div className="publishWebinar__warning">
                    <ul className="warning__messages">
                      <li className="warning__message warning__message--1">- You cannot change Title and Date of a Webinar after publishing it!</li>
                      <li className="warning__message warning__message--2">- You cannot Unpublish a Published webinar</li>
                    </ul>
                    <div className="warning__action">
                      <button type="button" className="warning__action--cancel" onClick={() => setShowPublishMessage(false)}>Cancel</button>
                      <button type="button" className="warning__action--publish" onClick={handlePublishWebinar}>Publish</button>
                    </div>
                  </div>
                ) : (
                  <h2 className="publishWebinar__title">
                    &quot;
                    {webinar?.title}
                    &quot; is not published yet, publish it
                    to make it accessible
                  </h2>
                )}
              </>
            )
          }
          {
            !publishWebinar && !showPublishMessage && (
              <button className="publishWebinar__publish" type="button" onClick={() => setShowPublishMessage(true)}>Publish this webinar</button>
            )
          }
        </div>
        {
          publishWebinar ? (
            <>
              {attendees?.length > 0 ? (
                <div className="attendeeList">
                  <div className="attendeeList__head">
                    <h2 className="attendeeList__head--text">
                      Attendees
                      {' '}
                      {`${attendees?.length > 0 ? `(${attendees.length})` : ''}`}
                    </h2>
                    <div className="attendeeList__search">
                      <input
                        type="search"
                        className={`attendeeList__search--input ${isSearchOpen ? 'active' : ''}`}
                        value={userSearch}
                        onChange={(e) => setUserSearch(e.target.value)}
                        placeholder="search for an attendee"
                      />
                      <MagnifyingGlass
                        onClick={() => setIsSearchOpen(!isSearchOpen)}
                        className="attendeeList__search--button"
                        size={20}
                        color="#444444"
                      />
                    </div>
                  </div>
                  <div className="attendeeList__list">
                    {
                      attendees.map((attendee: any, index: number) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div className="attendeeList__attendee" key={index}>
                          <div className="attendee__profile">
                            <User className="attendee__profile--icon" size={26} />
                            <h2 className="attendee__profile--name">{attendee.name}</h2>
                          </div>
                          <h2 className="attendee__email">{attendee.email}</h2>
                          <Link to={attendee.profileLink}>
                            <ArrowUpRight color="#403F40" />
                          </Link>
                        </div>
                      ))
                    }
                    {
                      attendees.length > 0 ? (
                        <div className="attendeeList__navigation">
                          <button
                            onClick={() => setAttendeeOffset((prev) => prev - 10)}
                            type="button"
                            className="attendeeList__navigation--button"
                            disabled={attendeeOffset === 0}
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => setAttendeeOffset((prev) => prev + 10)}
                            type="button"
                            className="attendeeList__navigation--button"
                            disabled={attendees.length < 10}
                          >
                            Next
                          </button>
                        </div>
                      ) : null
                    }
                  </div>
                </div>
              ) : (
                <div className="attendeeList--blank">
                  <h5>No Attendees yet!</h5>
                </div>
              )}
            </>
          ) : null
        }
      </div>
    </div>
  );
};

export default AttendeeList;
