import React from 'react';
import './PromotionCard.css';
import { truncate } from 'lodash';

export type PromotionCardProps = {
    title: string;
    subtext: string;
    buttonTitle: string;
    onClick: () => void;
}

const PromotionCard = ({
  title, subtext, buttonTitle, onClick,
}: PromotionCardProps) => (
  <div className="promotedCard">
    <span className="promotedCard__heading">Promoted</span>
    <div className="promotedCard__info">
      <h3 className="info__title">
        {truncate(title, { length: 60, separator: '...' })}
      </h3>
      <span className="info__subtext">{truncate(subtext, { length: 30, separator: '...' })}</span>
    </div>
    <button onClick={onClick} className="promotedCard__link" type="button">{buttonTitle}</button>
  </div>
);

export default PromotionCard;
