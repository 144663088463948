/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { hlbClient } from '../../../Clients/hlbClient';
import { myConfig } from '../../../config';
import NavbarContainer from '../../BasicComponents/navbar';
import ConclusionMobile from './conclusionMobile';
import CardContainer from './Mobile/cardContainer';
import MobileLoadingCard from './Mobile/mobileLoadingCard';
import WatchTutorial from './watchTutorial';
import notebg from '../assets/notebg.jpg';

/* Function to open fullscreen mode */
function openFullscreen() {
  let elem = document.documentElement as any;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) { /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE/Edge */
    elem = window && window.top && window.top.document.body; // To break out of frame in IE
    elem.msRequestFullscreen();
  }
}

// Events
const output = document.getElementsByClassName('fullscreen') as any;
document.addEventListener('fullscreenchange', () => {
  output.innerHTML = 'fullscreenchange event fired!';
});
document.addEventListener('mozfullscreenchange', () => {
  output.innerHTML = 'mozfullscreenchange event fired!';
});
document.addEventListener('webkitfullscreenchange', () => {
  output.innerHTML = 'webkitfullscreenchange event fired!';
});
document.addEventListener('msfullscreenchange', () => {
  output.innerHTML = 'msfullscreenchange event fired!';
});
type dataProps = {
  cards: any;
  name: string,
  _id: string,
}
const LearningAnki: React.FC = () => {
  const parms: { id: string } = useParams();
  const { id } = parms;

  const newInnerWidth: number = window.innerWidth;
  const newInnerHeight: number = window.innerHeight;

  const [data, setData] = useState<dataProps>({ cards: [], name: '', _id: '' });
  // eslint-disable-next-line no-undef
  const [innWidth, setInnWidth] = useState(newInnerWidth);
  const [innHeight, setInnHeight] = useState(newInnerHeight);
  const [mobileView, setMobileView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isStart, setIsStart] = useState(false);
  // The state for our timer
  const [time, setTime] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [noteEvents, setNoteEvents] = useState<{ event: string; cardId: string; deckId: any; time: number; }[]>([]);
  const [cardIndex, getCardIndex] = useState(-1);
  const [isWatchTutorial, setIsWatchTutorial] = useState<boolean>(false);
  const [isConclusion, setIsConclusion] = useState(false);

  const currentEnviroment = myConfig.environment;

  useEffect(() => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }, []);

  const getTime = (event: string, cardId: string) => {
    const noteInfo = {
      event, cardId, deckId: data?._id, time,
    };
    setNoteEvents((prev) => [...prev, noteInfo]);
    if (currentEnviroment !== 'production') {
      // eslint-disable-next-line no-console
      console.log(noteInfo);
    }
  };

  const getDeckById = async () => {
    setIsLoading(true);
    try {
      const resp = await hlbClient().get(`/api/srs/deck/${id}`);
      if (resp?.data?.success) {
        setData(resp?.data?.result);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getDeckById();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const mql = window.matchMedia('(max-width: 614px)');
    const view = mql.matches;

    if (view) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, [innWidth]);


  const [percentageCount, setPercentageCount] = useState(0);

  const toBase64 = (file: any) => new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
  async function getFileFromUrl(url: string, name: string) {
    // eslint-disable-next-line no-undef
    const response = await fetch(url);
    if (response) {
      setPercentageCount((prev) => prev + 1);
    }
    const dataImage = await response.blob();
    const metadata = {
      type: 'image/jpeg',
    };
    // eslint-disable-next-line no-undef
    const file = new File([dataImage], name, metadata);
    const respImage = await toBase64(file);
    return respImage;
  }

  const [finalData, setFinalData] = useState<{ answerImage: { url: string; name: string; file: any }; questionImage: { url: string; name: string; file: any } }[]>([]);([]);
  // eslint-disable-next-line no-async-promise-executor
  useEffect(() => {
    const fetchData = async () => {
      const newData: { answerImage: { url: string, name: string, file: any }, questionImage: { url: string, name: string, file: any } }[] = data?.cards;
      if (newData) {
        for (const i of newData) {
          if (i?.answerImage?.url) {
            i.answerImage.file = await getFileFromUrl(i.answerImage.url, i.answerImage?.name);
          }
          if (i?.questionImage?.url) {
            i.questionImage.file = await getFileFromUrl(i.questionImage.url, i.questionImage?.name);
          }
        }
      }
      setFinalData(newData);
    };
  
    fetchData();
  }, [data]);

  useEffect(() => {
    if (finalData && finalData?.length !== 0) {
      setIsLoading(false);
    }
  }, [finalData]);
  const count = (percentageCount / 2) * 10;

  const [isActive, setIsActive] = useState(false);
  const startTimer = () => {
    setIsActive(true);
  };

  function reset() {
    setTime(0);
    setIsActive(false);
  }

  useEffect(() => {
    let interval: any = null;
    if (isActive) {
      interval = setInterval(() => {
        setTime((seconds) => seconds + 1);
      }, 1000);
    } else if (!isActive && time !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  useEffect(() => {
    if (cardIndex !== -1) {
      reset();
      startTimer();
      getTime('CARD_RENDERED', data?.cards[cardIndex]?._id);
    }
  }, [cardIndex]);

  const updateDimensions = () => {
    setInnWidth(window.innerWidth);
    setInnHeight(window.innerHeight);
}

useEffect(() => {
  window.addEventListener("resize", updateDimensions);
  return () => window.removeEventListener("resize", updateDimensions);
}, []);   

  return (
    <>
      <style>
        {`
          body {
            font-size: 16px;
            background-image: url(${notebg});
            background-repeat: no-repeapt;
            background-size: cover;
          }
          .mobile__anki--view-container {
            height: ${innHeight}px;
           }
          .desktop-note-container {
            display: flex;
            align-items: center;
            width: 390px; 
            height: 85vh;
            margin: 0 auto;
          }
          .desktop-note-container .mobile__anki--view-container {
            position: relative;
            height: 560px;
            border-radius: 8px;
          }
          .desktop-note-container .mobile__anki--card-container {
            height: 470px;
          }
          .desktop-note-container h3 {
            font-size: 16px;
          }
          .desktop-note-container button {
            font-size: 16px;
          }
          .desktop-note-container .mobile__anki--btn-hard {
            width: 130px;
            height: 38px;
          }
          .desktop-note-container .mobile__anki--btn-easy {
            width: 130px;
            height: 38px;
          }
          .desktop-note-container video {
            height: 428px;
          }
          .desktop-note-container .desktop-only {
            height: 520px;
          }
          .desktop-note-container h1 {
            font-size: 20px !important;
          }
          .desktop-note-container img {
            height: 160px !important;
          } 
          .desktop-note-container .mobile__anki--paragraph {
            font-size: .85em;
          }
          .desktop-note-container .mobile__anki--hint-container {
            bottom: 40px
          }
        `}
      </style>
      {mobileView ? (
        <div
          className="fullScreen"
        >
          {!isStart
            ? (
              !isWatchTutorial
                ? (
                  <MobileLoadingCard
                    openFullscreen={openFullscreen}
                    data={data}
                    percentageCount={count}
                    setIsStart={setIsStart}
                    isLoading={isLoading}
                    startTimer={startTimer}
                    getCardIndex={getCardIndex}
                    setIsWatchTutorial={setIsWatchTutorial}
                    mobileView={mobileView}
                  />
                )
                : <WatchTutorial {...{ setIsWatchTutorial, innHeight, mobileView }} />
            )
            : (
              !isConclusion
                ? (
                  <CardContainer
                    name={data?.name}
                    data={finalData}
                    innHeight={innHeight}
                    // startTimer={startTimer}
                    getTime={getTime}
                    getCardIndex={getCardIndex}
                    setIsConclusion={setIsConclusion}
                    noteId={data?._id}
                  />
                ) : (
                  <ConclusionMobile
                    {...{ innHeight, noteId: data?._id, data }}
                    name={data?.name}
                  />
                )
            )}
        </div>
      ) : (
        <>
          <div className="container-fluid mb-5">
            <div className="row">
              <NavbarContainer />
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="desktop-note-container mt-4">
                {!isStart
                  ? (
                    !isWatchTutorial
                      ? (
                        <MobileLoadingCard
                          openFullscreen={openFullscreen}
                          data={data}
                          percentageCount={count}
                          setIsStart={setIsStart}
                          isLoading={isLoading}
                          startTimer={startTimer}
                          getCardIndex={getCardIndex}
                          setIsWatchTutorial={setIsWatchTutorial}
                          mobileView={mobileView}
                        />
                      )
                      : <WatchTutorial {...{ setIsWatchTutorial, innHeight, mobileView }} />
                  )
                  : (
                    !isConclusion
                      ? (
                        <CardContainer
                          name={data?.name}
                          data={finalData}
                          innHeight={innHeight}
                          // startTimer={startTimer}
                          getTime={getTime}
                          getCardIndex={getCardIndex}
                          setIsConclusion={setIsConclusion}
                          noteId={data?._id}
                        />
                      ) : (
                        <ConclusionMobile
                          {...{ innHeight, noteId: data?._id, data }}
                          name={data?.name}
                        />
                      )
                  )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default LearningAnki;
