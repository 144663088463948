/* eslint-disable react/jsx-key */
import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import './faq.css';
import NavbarContainer from '../BasicComponents/navbar/index.ts';
import { mentor, student } from '../Constants';
import WhatsappPlugin from '../BasicComponents/WhatsappPlugin/index.ts';

function FAQ() {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <NavbarContainer />
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-12 mt-4 p-4">
            <h1 className="faq__heading">FAQ&apos;s</h1>
            <div className="faq__heading--bar" />
          </div>
          <div className="container__faq--box">
            <Accordion
              defaultActiveKey={1}
              className="faq__accordion--container"
            >
              {mentor
                && mentor.map((data) => (
                  <Card style={{ border: 'none' }}>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={data.id}
                      className="faq__accordion--header"
                    >
                      {data.que}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={data.id}>
                      <Card.Body>
                        <div className="row">
                          <div className="col-1">
                            <div className="faq__content--left-border float-end" />
                          </div>
                          <div className="col-11">
                            <p className="faq__content-text">{data.ans}</p>
                            {data.list && (
                              <ul>
                                {data.list.map((l) => (
                                  <li>{l}</li>
                                ))}
                              </ul>
                            )}
                            {data.extra && (
                              <p className="faq__content-text">{data.extra}</p>
                            )}
                            {data.endingPara && (
                              <p className="faq__content-text">
                                {data.endingPara}
                              </p>
                            )}
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              {student
                && student.map((data) => (
                  <Card style={{ border: 'none' }}>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={data.id}
                      className="faq__accordion--header"
                    >
                      {data.que}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={data.id}>
                      <Card.Body>
                        <div className="row">
                          <div className="col-1">
                            <div className="faq__content--left-border float-end" />
                          </div>
                          <div className="col-11">
                            <p className="faq__content-text">{data.ans}</p>
                            {data.list && (
                              <ul>
                                {data.list.map((l) => (
                                  <li>{l}</li>
                                ))}
                              </ul>
                            )}
                            {data.extra && (
                              <p className="faq__content-text">{data.extra}</p>
                            )}
                            {data.endingPara && (
                              <p className="faq__content-text">
                                {data.endingPara}
                              </p>
                            )}
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
            </Accordion>
          </div>
        </div>
      </div>
      <WhatsappPlugin />
    </>
  );
}

export default FAQ;
