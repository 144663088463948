import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export const chatReducer = createSlice({
  name: 'chats',
  initialState: {
    chatOrder: [],
    chatMessages: {
    },
    unreadCount: 0,
    unreadCountsInfo: {},
    courseCache: {},
    instructorCache: {},
    studentCache: {},
    enrollmentInfo: {},
  },
  reducers: {
    getInitialState: (state, { payload }) => {
      let newState = state;
      payload.chat.forEach((chat) => {
        const { id: courseId, ...courseDetail } = chat.courseDetails[0];
        const { id: instructorId, ...instrutorDetail } = chat.instructor;
        const { id: studentId, ...studentDetail } = chat.student;
        // eslint-disable-next-line no-unused-vars
        const { _id: enrollmentId, ...user } = chat;
        newState = {
          ...newState,
          chatOrder: [...newState.chatOrder, `${enrollmentId}`],
          chatMessages: {
            ...newState.chatMessages, [`${enrollmentId}`]: chat.message ? [chat.message] : [],
          },
          courseCache: {
            ...newState.courseCache, [`${courseId}`]: courseDetail,
          },
          instructorCache: {
            ...newState.instructorCache, [`${instructorId}`]: instrutorDetail,
          },
          studentCache: {
            ...newState.studentCache, [`${studentId}`]: studentDetail,
          },
          enrollmentInfo: {
            ...newState.enrollmentInfo,
            [`${enrollmentId}`]: { studentId, instructorId, courseId },
          },
          unreadCountsInfo: {
            ...newState.unreadCountsInfo,
            [`${enrollmentId}`]: _.get(user, 'unseenMessages', 0),
          },
          unreadCount: newState.unreadCount + (_.get(chat, 'message.senderId', payload.userId) === payload.userId ? 0 : _.get(user, 'unseenMessages', 0)),
        };
      });
      return newState;
    },
    onNewMsg: (state, { payload }) => {
      let newState = state;
      const index = newState.chatOrder.findIndex(
        (user) => user === payload.message.enrollmentId,
      );
      newState = {
        ...newState,
        chatOrder: [payload.message.enrollmentId, ...newState.chatOrder.slice(0, index),
          ...newState.chatOrder.slice(index + 1)],
        chatMessages: {
          ...newState.chatMessages,
          [`${payload.message.enrollmentId}`]:
          [payload.message, ...newState.chatMessages[`${payload.message.enrollmentId}`]],
        },
        unreadCountsInfo: {
          ...newState.unreadCountsInfo,
          [`${payload.message.enrollmentId}`]: newState.unreadCountsInfo[`${payload.message.enrollmentId}`] + (payload.message.enrollmentId === payload.curEnrollment ? 0 : 1),
        },
        unreadCount: newState.unreadCount
         + (payload.message.senderId === payload.userId
          || (payload.message.enrollmentId === payload.curEnrollment) ? 0 : 1),
      };
      return newState;
    },
    onGetChat: (state, { payload }) => {
      let newState = state;
      newState = {
        ...newState,
        chatMessages: {
          ...newState.chatMessages, [`${payload[0].enrollmentId}`]: payload,
        },
      };
      return newState;
    },
    onOpenChat: (state, { payload }) => {
      let newState = state;
      newState = {
        ...newState,
        unreadCount: newState.unreadCount - (newState.unreadCountsInfo[`${payload}`]),
        unreadCountsInfo: {
          ...newState.unreadCountsInfo,
          [`${payload}`]: 0,
        },
      };
      return newState;
    },
    onDeleteMsg: (state, { payload }) => {
      let newState = state;
      const index = newState.chatMessages[`${payload.enrollmentId}`].findIndex(
        (user) => user._id === payload._id,
      );
      if (index !== -1) {
        newState = {
          ...newState,
          chatMessages: {
            ...newState.chatMessages,
            [`${payload.enrollmentId}`]: [
              ...newState.chatMessages[`${payload.enrollmentId}`].slice(0, index), payload,
              ...newState.chatMessages[`${payload.enrollmentId}`].slice(index + 1),
            ],
          },
        };
      }
      return newState;
    },
    resetState: () => ({
      chatOrder: [],
      chatMessages: {
      },
      unreadCount: 0,
      unreadCountsInfo: {},
      courseCache: {},
      instructorCache: {},
      studentCache: {},
      enrollmentInfo: {},
    }),
  },
});

export const {
  getInitialState, onNewMsg, onDeleteMsg, onGetChat, onOpenChat, resetState,
} = chatReducer.actions;

export default chatReducer.reducer;
