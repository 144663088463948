import { useState, useEffect } from "react";

interface IsMobileViewResponse {
    isMobileView: boolean;
}

const useIsMobileView = () : IsMobileViewResponse => {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 414);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 414);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return { isMobileView };
}

export default useIsMobileView;
