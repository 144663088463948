import React, { useEffect, useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import NavbarContainer from '../BasicComponents/navbar';
import Privacy from './privacy';
import Refund from './refund';
import TermsAndConditions from './termsAndConditions';
import './policyContainer.css';

const PolicyContainer: React.FC = () => {
  const [currentNavBar, setCurrentNavBar] = useState<string>('');

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const currentPage: string = window.location.href;
    const currentPageSection: string | undefined = currentPage.split('/').pop();
    currentPageSection
      ? setCurrentNavBar(currentPageSection)
      : setCurrentNavBar('');
    // eslint-disable-next-line no-undef
  }, [window.location.href]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <NavbarContainer />
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-4 mt-4">
            <ul className="sideNav">
              <li>
                <Link
                  to="/policy"
                  className={currentNavBar === 'policy' ? 'policy__active' : ''}
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link
                  to="/policy/Privacy"
                  className={
                    currentNavBar === 'Privacy' ? 'policy__active' : ''
                  }
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/policy/Refund"
                  className={currentNavBar === 'Refund' ? 'policy__active' : ''}
                >
                  Refund Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-8 mt-4">
            <Switch>
              <Route exact path="/policy">
                <TermsAndConditions />
              </Route>
              <Route path="/policy/Privacy">
                <Privacy />
              </Route>
              <Route path="/policy/Refund">
                <Refund />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default PolicyContainer;
