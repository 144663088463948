import React, {
  useContext, useEffect, useState,
} from 'react';
import ReactDOM from 'react-dom';
import * as Yup from 'yup';
import Masonry from 'react-masonry-css';
import './Testimonials.css';
import {
  Image, Scroll, UploadSimple, X,
} from 'phosphor-react';
import {
  // eslint-disable-next-line no-unused-vars
  ErrorMessage, Form, Formik,
} from 'formik';
import FormData from 'form-data';

import { Spinner } from 'react-bootstrap';
import { AuthContext } from '../../../../provider/AuthContext';
import { hlbClient } from '../../../../Clients/hlbClient';
import ImagePreview from './ImagePreview';

const validationSchema = Yup.object().shape({
  files: Yup.array().nullable().max(10),
});

type TestimonialImageProps = {
  testimonial: any;
  showDelete: any;
  refetchWebinarDetails: () => void;
  webinarId: string;
}

type TestimonialsProps = {
  webinar: any;
  refetchWebinarDetails: () => void;
  isMobileView: boolean;
}

const TestimonialImage = ({
  testimonial, showDelete, refetchWebinarDetails, webinarId,
}: TestimonialImageProps) => {
  const [deletingTestimonial, setDeletingTestimonial] = useState(false);

  const handleDeleteTestimonial = async () => {
    // eslint-disable-next-line no-shadow
    const { fileName } = testimonial;
    try {
      const response = await hlbClient().put(`/api/webinar/deleteTestimonial/${webinarId}/${fileName}`);
      if (response.data.success) {
        refetchWebinarDetails();
        setDeletingTestimonial(false);
      }
    } catch (error) {
      setDeletingTestimonial(false);
      // eslint-disable-next-line no-console
      console.error('Something went wrong while deleting testimonial!');
    }
  };

  return (
    <div className="testimonial__card" key={Math.random() * 10000}>
      <div className="testimonial__image">
        {
          showDelete ? (
            <button
              type="button"
              onClick={() => {
                setDeletingTestimonial(true);
                handleDeleteTestimonial();
              }}
              className="testimonial__image--delete"
            >
              {
                deletingTestimonial ? <Spinner animation="border" variant="danger" size="sm" /> : <X size={18} color="red" />
              }
            </button>
          ) : null
        }
        <img src={testimonial?.url} alt={testimonial?._id} />
      </div>
    </div>
  );
};

const Testimonials = ({ webinar, refetchWebinarDetails, isMobileView }: TestimonialsProps) => {
  const { testimonials, createdBy, _id } = webinar;
  // eslint-disable-next-line no-undef
  const token = window.localStorage.getItem('token');
  const { authState, setStateUpdate, stateUpdate } = useContext(AuthContext);
  const id = authState?._id;
  const [openTestimonialForm, setOpenTestimonialForm] = useState(false);
  const [uploadingTestimonials, setUploadingTestimonials] = useState(false);
  const [userDetails, setUserDetails] = useState<any>({});
  const isCurrentUserCreatedWebinar = userDetails?.user === createdBy;
  // eslint-disable-next-line no-undef
  const portalRoot = document.getElementById('portal-root');
  const breakpoints = {
    default: 3,
    1100: 2,
    700: 1,
  };

  useEffect(() => {
    if (token) {
      setStateUpdate(!stateUpdate);
    }
  }, []);

  useEffect(() => {
    const fetchCurrentUserDetails = async () => {
      try {
        const response = await hlbClient().get(`/api/users/${id}/profile`);
        setUserDetails(response.data[0]);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    // eslint-disable-next-line no-unused-expressions
    id && fetchCurrentUserDetails();
  }, [id]);

  const uploadTestimonials = async (images: any) => {
    try {
      const formData = new FormData();
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < images.length; i++) {
        formData.append('image', images[i]);
      }
      const resp = await hlbClient('multipart/form-data').put(`/api/webinar/addTestimonials/${_id}`, formData);

      if (resp.data.success) {
        refetchWebinarDetails();
        setOpenTestimonialForm(false);
        setUploadingTestimonials(false);
      } else {
        setUploadingTestimonials(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Something went wrong while uploading testimonials!');
      setUploadingTestimonials(false);
    }
  };

  const RenderAddTestimonialForm = () => {
    if (portalRoot) {
      return ReactDOM.createPortal(
        <div className="webinar__addTestimonial">
          <div className="testimonial__form">
            <h2 className="addTestimonial__header">Upload Testimonial</h2>
            <Formik
              initialValues={{ files: [] }}
              onSubmit={(values) => {
                setUploadingTestimonials(true);
                uploadTestimonials(values.files);
              }}
              validationSchema={validationSchema}
            >
              {({ values, setFieldValue, errors }) => (
                <Form className="addTestimonial__form">
                  <div className="addTestimonial__form--upload">
                    <UploadSimple size={46} color="#8C52FF" />
                    <label htmlFor="file" className="uploadTestimonial--label">Browse files</label>
                    <input
                      type="file"
                      id="file"
                      onChange={(event) => setFieldValue('files', Array.from(event.target.files || []))}
                      accept="image/*"
                      multiple
                    />
                    {values.files?.length > 0 && (
                    <span>
                      {values.files.length}
                      {' '}
                      file(s) selected
                    </span>
                    )}
                    {
                      values.files?.length > 0 && values.files?.length === 1
                        ? <ImagePreview file={values.files[0]} />
                        : null
                    }
                    <span className={errors.files ? 'testimonial__upload--error' : ''}>You can upload upto 10 images at once!</span>
                  </div>
                  <div className="addTestimonial__action">
                    <button
                      type="button"
                      onClick={() => {
                        setOpenTestimonialForm(false);
                        setFieldValue('files', []);
                      }}
                      className="addTestimonial__action--cancel"
                      disabled={uploadingTestimonials}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="addTestimonial__action--submit"
                      disabled={Boolean(!values?.files?.length) || Boolean(uploadingTestimonials) || Boolean(errors.files)}
                    >
                      Submit
                      {' '}
                      {uploadingTestimonials && <Spinner animation="border" variant="primary" size="sm" />}
                    </button>
                    <ErrorMessage component="span" name="files" />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>,
        portalRoot,
      );
    }
    return null;
  };

  return (
    <section className={`webinar__testimonials ${!testimonials?.length ? 'testimonials__noData--mobile' : ''}`}>
      {
        testimonials?.length > 0 ? (
          <div className="testimonials--wrapper">
            {
              !isMobileView ? (
                <h4 className="testimonials__heading">
                  What people say about
                  <br />
                  my past webinar
                </h4>
              ) : (
                <h4 className="testimonials__heading">
                  Testimonials
                </h4>
              )
            }
            {
              isMobileView ? (
                <div className="testimonials__imageContainer">
                  {
                    testimonials?.map((testimonial: any) => (
                      <TestimonialImage
                        testimonial={testimonial}
                        webinarId={_id}
                        showDelete={isCurrentUserCreatedWebinar}
                        refetchWebinarDetails={refetchWebinarDetails}
                        key={testimonial?._id}
                      />
                    ))
                  }
                </div>
              ) : (
                <Masonry
                  breakpointCols={breakpoints}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {
                    testimonials?.map((testimonial: any) => (
                      <TestimonialImage
                        testimonial={testimonial}
                        webinarId={_id}
                        showDelete={isCurrentUserCreatedWebinar}
                        refetchWebinarDetails={refetchWebinarDetails}
                        key={testimonial?._id}
                      />
                    ))
                  }
                </Masonry>
              )
            }
            { openTestimonialForm && <RenderAddTestimonialForm /> }
            {
              isCurrentUserCreatedWebinar ? (
                <button
                  onClick={() => {
                    setOpenTestimonialForm(true);
                  }}
                  type="button"
                  className="testimonial__image--addMore"
                >
                  <Image size={22} />
                  Add more
                </button>
              ) : null
            }
          </div>
        ) : (
          <div className={`${isMobileView ? 'testimonials__noData testimonials__noData--mobile' : 'testimonials__noData'}`}>
            { openTestimonialForm && <RenderAddTestimonialForm /> }
            <div className="noData--iconWrapper">
              <Scroll size={isMobileView ? 30 : 52} color="#9C04C2" />
            </div>
            <h2 className="noData--message">
              No Testimonials to show for
              <br />
              {`'${webinar?.title}'`}
            </h2>
            {
              isCurrentUserCreatedWebinar ? (
                <button onClick={() => setOpenTestimonialForm(true)} type="button" className="noData--createButton">Add Testimonial</button>
              ) : null
            }
          </div>
        )
      }
    </section>
  );
};

export default Testimonials;
