import React, { useEffect, useState } from 'react';
import { TypeAnswerFeaturesType } from './FeaturesRenderer';

const TypeAnswerFeatures = ({
  features,
  setFeatures,
}: {
  features: TypeAnswerFeaturesType;
  setFeatures: React.Dispatch<React.SetStateAction<TypeAnswerFeaturesType>>;
}) => {
  const [isHint, setIsHint] = useState(Boolean(features?.hint) || false);
  const [hint, setHint] = useState(features?.hint || '');
  const [correctAnswer, setCorrectAnswer] = useState(
    features?.correctAnswer || ''
  );

  useEffect(() => {
    if (isHint) {
      setFeatures({
        hint,
        correctAnswer,
      });
    } else if (!isHint && features?.hint?.length !== 0) {
      setFeatures({
        hint: '',
        correctAnswer,
      });
    } else {
      setFeatures({
        correctAnswer,
      });
    }
  }, [isHint, hint, correctAnswer]);

  return (
    <div className="features__container">
      <div className="features__input-container">
        <div className="features__toggle-container">
          <div className="features__title">Write hint for your card</div>
          <label className="features__switch">
            <input
              type="checkbox"
              checked={isHint}
              onChange={(e) => setIsHint((prev) => !prev)}
            />
            <span className="slider"></span>
          </label>
        </div>
        <textarea
          className="features__input"
          placeholder="Enter your hint..."
          value={hint}
          onChange={(e) => setHint(e.target.value)}
          disabled={!isHint}
        />
      </div>
      <div className="features__input-container">
        <div className="features__title">Write answer for your card</div>
        <input
          className="features__input"
          placeholder="Enter your answer..."
          value={correctAnswer}
          onChange={(e) => setCorrectAnswer(e.target.value)}
        />
      </div>
    </div>
  );
};

export default TypeAnswerFeatures;
