import React, { useContext, useEffect, useRef, useState } from 'react';
import "./AuthModal.css";
import { AuthInput } from '../components/AuthInput';
import { AuthButton } from '../components/AuthButton';
import { noop } from 'lodash';
import { Separator } from '../components/Separator';
import { handleEmailOtpSubmission, handleEmailSubmission, handlePhoneOtpSubmission, handlePhoneSubmission, maskEmail, maskPhoneNumber } from '../utils';
import { ResendOtp } from '../components/ResendOtp';
import { AuthContext } from '../../../provider/AuthContext';
import { useHistory } from 'react-router';

interface LoginModalProps {
    changeAuthMode: () => void;
    closeModal: () => void;
}

const LoginModal = ({ changeAuthMode, closeModal } : LoginModalProps) => {
    const dialogRef = useRef<HTMLDivElement | null>(null);
    const [loginMode, setLoginMode] = useState<'Phone' | 'Mail'>('Phone');
    const [phoneNo, setPhoneNo] = useState({
        value: '',
        errorMessage: ''
    });
    const [emailId, setEmailId] = useState({
        value: '',
        errorMessage: '',
    });
    const [otp, setOtp] = useState({
        value: '',
        errorMessage: '',
    });
    const [phoneNoSubmitted, setPhoneNoSubmitted] = useState(false);
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [resendTimer, setResendTimer] = useState(0);
    const { authState, setStateUpdate, stateUpdate } = useContext(AuthContext);
    const { profile } = authState;
    const history = useHistory();

    const handleOutsideClick = (event: MouseEvent) => {
        if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
            closeModal();
        }
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (resendTimer > 0) {
                setResendTimer((prev) => prev - 1);
            }
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [resendTimer]);

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick, true);

        return () => {
            document.removeEventListener('click', handleOutsideClick, true);
        };
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, setValue: React.Dispatch<React.SetStateAction<{
        value: string;
        errorMessage: string;
    }>>) => {
        setValue({
            value: event.target.value,
            errorMessage: '',
        });
    };

    const handleLoginModeChange = () => {
        if (loginMode === 'Phone') {
            phoneNoSubmitted && setPhoneNoSubmitted(false);
            setPhoneNo({
                value: '',
                errorMessage: '',
            })
            setLoginMode('Mail');
        }

        if (loginMode === 'Mail') {
            emailSubmitted && setEmailSubmitted(false);
            setEmailId({
                value: '',
                errorMessage: '',
            })
            setLoginMode('Phone');
        }
    };

    const handleAuthModeChange = () => {
        phoneNoSubmitted && setPhoneNoSubmitted(false);
        setPhoneNo({
            value: '',
            errorMessage: '',
        });
        changeAuthMode();
    };

    const phoneSuccessCallback = () => {
        !phoneNoSubmitted && setPhoneNoSubmitted(true);
        setResendTimer(60);
    };

    const phoneErrorCallback = (errorMessage?: string) => {
        if (errorMessage) {
            setPhoneNo((prev) => {
                return {
                    ...prev,
                    errorMessage,
                }
            });
        }
    };

    const emailSuccessCallback = () => {
        !emailSubmitted && setEmailSubmitted(true);
        setResendTimer(60);
    }

    const emailErrorCallback = (errorMessage?: string) => {
        if (errorMessage) {
            setEmailId((prev) => {
                return {
                    ...prev,
                    errorMessage,
                }
            });
        }
    }

    const otpSuccessCallback = () => {
        const hlUserType = window.localStorage.getItem('hlUserType');
        if (hlUserType === "mentor" && !profile) {
            history.push('/onboarding/mentorDetailForm');
        } else {
            closeModal();
        }
    };

    const otpErrorCallback = (errorMessage?: string) => {
        if (errorMessage) {
            setOtp((prev) => {
                return {
                    ...prev,
                    errorMessage,
                }
            });
        }
    }

    return (
        <div className='authModal'>
            <div className="authModal__container" ref={dialogRef}>
                <div className="authModal__header">
                    <h2 className='authModal__header__title'>
                        {phoneNoSubmitted || emailSubmitted ? "Enter OTP" : "Login to continue!"}
                    </h2>
                    <p className="authModal__header__message">
                        { phoneNoSubmitted && `Enter the OTP that was sent to your registered phone no. ${maskPhoneNumber(phoneNo.value)} and be careful not to share the OTP with anyone!` }
                        { emailSubmitted && `Enter the OTP that was sent to your registered mail address ${maskEmail(emailId.value)} and be careful not to share the OTP with anyone!` }
                        { !phoneNoSubmitted && !emailSubmitted && "Please enter your details to continue"}
                    </p>
                </div>
                {/* Input field for Phone */}
                {
                    loginMode === "Phone" && !phoneNoSubmitted && (
                        <AuthInput
                            key="phoneNumber"
                            label='Phone no.'
                            type='tel'
                            placeholder='Enter your registered Phone no.'
                            value={phoneNo.value}
                            updateValue={(event) => handleInputChange(event, setPhoneNo)}
                            errorMessage={phoneNo.errorMessage}
                            showDialCode
                            maxLength={10}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    void handlePhoneSubmission(phoneNo.value, phoneSuccessCallback, phoneErrorCallback);
                                }
                            }}
                        />
                    )
                }
                {/* OTP field for Phone */}
                {
                    loginMode === 'Phone' && phoneNoSubmitted && (
                        <AuthInput
                            key="phoneOtp"
                            type='tel'
                            placeholder='Enter 6 digit OTP here'
                            value={otp.value}
                            updateValue={(event) => handleInputChange(event, setOtp)}
                            errorMessage={otp.errorMessage}
                            maxLength={6}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    handlePhoneOtpSubmission(phoneNo.value, otp.value, otpSuccessCallback, otpErrorCallback)
                                }
                            }}
                        />
                    )
                }
                {/* Input field for Email */}
                {
                    loginMode === 'Mail' && !emailSubmitted && (
                        <AuthInput
                            key="emailId"
                            label='Email'
                            type='email'
                            placeholder='Enter your mail address'
                            value={emailId.value}
                            updateValue={(event) => handleInputChange(event, setEmailId)}
                            errorMessage={emailId.errorMessage}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    void handleEmailSubmission(emailId.value, emailSuccessCallback, emailErrorCallback);
                                }
                            }}
                        />
                    )
                }
                {/* OTP field for Email */}
                {
                    loginMode === 'Mail' && emailSubmitted && (
                        <AuthInput
                            key="emailOtp"
                            type='tel'
                            placeholder='Enter 6 digit OTP here'
                            value={otp.value}
                            updateValue={(event) => handleInputChange(event, setOtp)}
                            errorMessage={otp.errorMessage}
                            maxLength={6}
                            onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                    void handleEmailOtpSubmission(emailId.value, otp.value, otpSuccessCallback, otpErrorCallback);
                                }
                            }}
                        />
                    )
                }
                {/* Phone Login Button */}
                {
                    loginMode === 'Phone' && (
                        <AuthButton
                            variant='primary'
                            onClick={() => phoneNoSubmitted ? void handlePhoneOtpSubmission(phoneNo.value, otp.value, otpSuccessCallback, otpErrorCallback) : void handlePhoneSubmission(phoneNo.value, phoneSuccessCallback, phoneErrorCallback)}
                            style={{ marginTop: "30px" }}
                            disabled={phoneNoSubmitted ? Boolean(!otp.value) : Boolean(!phoneNo.value)}
                        >
                            {phoneNoSubmitted ? 'Log in' : 'Continue'}
                        </AuthButton>
                    )
                }

                {/* Email Login Button */}
                {
                    loginMode === 'Mail' && (
                        <AuthButton
                            variant='primary'
                            onClick={() => emailSubmitted ? void handleEmailOtpSubmission(emailId.value, otp.value, otpSuccessCallback, otpErrorCallback) : void handleEmailSubmission(emailId.value, emailSuccessCallback, emailErrorCallback)}
                            style={{ marginTop: "30px" }}
                            disabled={emailSubmitted ? Boolean(!otp.value) : Boolean(!emailId.value)}
                        >
                            {emailSubmitted ? 'Log in' : 'Continue'}
                        </AuthButton>
                    )
                }

                {
                    phoneNoSubmitted || emailSubmitted ? (
                        <ResendOtp
                            handleResend={() => phoneNoSubmitted ? void handlePhoneSubmission(phoneNo.value, phoneSuccessCallback, phoneErrorCallback) : handleEmailSubmission(emailId.value, noop, noop)} 
                            timer={resendTimer}
                            style={{ marginTop: "40px" }}
                        />
                    ) : (
                        <>
                            <Separator text='Or' style={{ margin: "15px 0" }} />
                            <AuthButton
                                variant='secondary'
                                onClick={handleLoginModeChange}
                            >
                                Login with {loginMode === 'Mail' ? 'Phone' : 'Email'}
                            </AuthButton>
                            <div className='authAlternatives' style={{ marginTop: "20px", alignSelf: "center" }}>
                                <p className="authAlternatives-message">
                                    Don’t have an account?
                                    <button
                                        onClick={handleAuthModeChange}
                                        className="authAlternatives-action"
                                    >
                                        Sign up
                                    </button>
                                </p>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default LoginModal;