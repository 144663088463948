import { CheckCircle, XCircle } from 'phosphor-react';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

type Props = {
    finishResultFlow: () => void
    next: () => void,
    correctAnswerText: string,
    isCorrect: boolean
  }
  
  const ResultPopup: React.FC<Props> = ({
    next, correctAnswerText, isCorrect, finishResultFlow
  }) => {
  
    const [isClicked, setIsClicked] = useState<boolean>(false);
    const [timeoutId, setTimeoutId] = useState<any>(null);
  
    const nextPage = () => {
      const timeout: any = setTimeout(() => {
        next();
        finishResultFlow();
      }, 3000);
      setTimeoutId(timeout);
    };
  
    useEffect(() => {
      if(isCorrect) {
        if (isClicked) {
          next();
          finishResultFlow();
          setIsClicked(false);
        } else {
          nextPage()
        }
      }
  
      // Clear the timeout when the component unmounts or when the dependency values change
      return () => clearTimeout(timeoutId);
    }, [isClicked]);
  
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <>
        (
              <div className="confirmPopUp__container" id="open-close">
                { isCorrect ? 
                    <CorrectAnswer setIsClicked={setIsClicked} /> : <WrongAnswer next={next} correctAnswerText={correctAnswerText} finishResultFlow={finishResultFlow}/>
                }
              </div>
            )
      </>
    );
  }

type ca = { setIsClicked: Dispatch<SetStateAction<boolean>> }
const CorrectAnswer: React.FC<ca> = ({ setIsClicked }) => {
  return (
    <div className="confirmPopup__main--container">
      <CheckCircle className="confirmPopup__icon green" />
      <p className="green">Correct Answer</p>
      <div
        role="presentation"
        className="confirmPopup__bar--main-container mt-3"
        onClick={() => { setIsClicked(true);  }}
      >
        <div
          className="confirmPopup__bar--sub-container"
          style={{
            width: '100%', animationDelay: '600ms', zIndex: -1, height: '52px',
          }}
        />
        <p className="confirmPopup__btn-text">Continue</p>
      </div>
    </div>
  )
}

type wa = { correctAnswerText: string, next: () => void, finishResultFlow: () => void }
const WrongAnswer: React.FC<wa> = ({ correctAnswerText, next, finishResultFlow }) => {
  return (
    <div className="confirmPopup__main--container">
      <XCircle className="confirmPopup__icon red" />
      <p className="red">Wrong Answer</p>
      <p>
        Correct Answer:
        {' '}
        {/* eslint-disable-next-line max-len */}
          <span>
            {correctAnswerText}
          </span>
      </p>
      <button type="button" className="confirmPopup__gotIt-btn mt-3" onClick={() => { finishResultFlow();  next(); }}>Got it</button>
    </div>
  )
}

export default ResultPopup