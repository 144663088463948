import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';

type ImagePreviewProps = {
  // eslint-disable-next-line no-undef
  file: File;
}

const ImagePreview = ({ file }: ImagePreviewProps) => {
  const [preview, setPreview] = useState<string | null>(null);

  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const { result } = reader;
    if (typeof result === 'string') {
      setPreview(result);
    }
  };

  return (
    <>
      {preview ? (
        <img
          style={{ backgroundSize: 'cover', objectFit: 'cover', borderRadius: '4px' }}
          src={preview}
          alt="preview"
          height="80px"
          width="80px"
        />
      ) : (
        <Spinner animation="border" variant="primary" />
      )}
    </>
  );
};

export default ImagePreview;
