export const myConfig = {
  disqusShortName: `${process.env.REACT_APP_DISQUS}`,
  url: `${process.env.REACT_APP_URL}`,
  gaTracking: `${process.env.REACT_APP_GA}`,
  environment: `${process.env.REACT_APP_ENV || 'development'}`,
  mixPanelToken: `${process.env.REACT_APP_MIX_PANEL_TOKEN}`,
  razorpay: `${process.env.REACT_APP_RAZORPAY}`,
  skipWaitlistUrl: `${process.env.REACT_APP_SKIP_WAITLIST_URL}`,
  verifiedMentors: `${process.env.VERIFIED_MENTORS}`.split(' ') || [],
};
