// single chocie card

import React, { useState } from "react";
import HtmlParser from "../../../BasicComponents/htmlParser";
import _ from "lodash";
import { SCQCardV2, OptionTypeV2 } from "../../types";
import { handleBase64ToImg } from "../../../Utils/cardUtils";

const SingleChoiceV2Card: React.FC<SCQCardV2> = ({
  description, medias, options,
  verifyAnswer, id, reason, disableInteraction = false, done
}) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<null | number>(null);
  const [background, setBackground] = useState<{background: string, color: string }>({background: '', color: ''});

  const wrongAnswerColor = {
    background: 'red',
    color: '#fff',
  };

  const correctAnswerColor = {
    background: 'green',
    color: '#fff',
  }
  const selectOption = (index: number, selectedOption: OptionTypeV2, options: OptionTypeV2[]) => {
    const result = verifyAnswer('singlechoicev2', { selectedOption, options });
    setSelectedOptionIndex(index);
    if (result) {
      setBackground(correctAnswerColor);
    } else {
      setBackground(wrongAnswerColor);
    }
    done('scq', result); 
  };

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <div className="mobile__anki--card-container">
    <div className="">
      <p className="mobile__anki--paragraph">
      <HtmlParser text={handleBase64ToImg(description, medias)} />
      </p>
      {options &&
        options.map((item: OptionTypeV2, index: number) => (
              <div
                role="presentation"
                className={
                  selectedOptionIndex !== index
                    ? 'single-option-dev'
                    : 'single-option-dev-green'
                }
                style={selectedOptionIndex === index ? background : {}}
                key={item._id || index.toString()}
                onClick={() => {
                  if(!disableInteraction) {
                    selectOption(index, item, options);
                  }
                }}
              >
                <p className="single-option-dev--text">{item.text}</p>
              </div>
        ))}
    </div>
  </div>
  )
}

export default SingleChoiceV2Card;