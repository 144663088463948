export const exitMessage = [
  { heading: 'Aw! Don’t leave just yet.', content: 'Perhaps this note wasn’t the right fit for you, try reviewing a different note!' },
  { heading: 'Oh no! You are leaving?', content: 'Looks like you want to go back, how about trying a different note?' },
];

export const completionMessage = [
  { heading: 'Awesome! You’ve reviewed everything', content: 'Review again to practice with the same questions, or try another ‘note’' },
  { heading: 'Review Complete!', content: 'You can repeat this note again for a quick review, or try a different note.' },
  { heading: 'You’re doing great!', content: 'Keep reviewing this note for a better understanding, or go with a new note.' },
];
