import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type props = {
  initialValue: any;
  onTextChangeHandler: (k: any) => any;
  placeholder: string;
  id: string;
  textHeight?: number;
}

const Editor:React.FC<props> = ({
  initialValue, onTextChangeHandler, placeholder, id, textHeight = 150,
}) => {
  const [text, setText] = useState<any>(initialValue);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const element = document.querySelector('.ql-editor');
    if (element !== null) {
      element.setAttribute('data-placeholder', placeholder);
    }
  }, [placeholder]);
  useEffect(() => {
    setText(initialValue);
  }, [initialValue]);
  const modules = {
    syntax: true,
    toolbar: [
      [{ header: [2, 3, 4, 5, 6, false] }, { font: [] }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      ['bold',
        'italic',
        'underline',
        'strike',
        'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['code-block'],
      [
        {
          color: [
            '#000000',
            '#e60000',
            '#ff9900',
            '#ffff00',
            '#008a00',
            '#0066cc',
            '#9933ff',
            '#ffffff',
            '#facccc',
            '#ffebcc',
            '#ffffcc',
            '#cce8cc',
            '#cce0f5',
            '#ebd6ff',
            '#bbbbbb',
            '#f06666',
            '#ffc266',
            '#ffff66',
            '#66b966',
            '#66a3e0',
            '#c285ff',
            '#888888',
            '#a10000',
            '#b26b00',
            '#b2b200',
            '#006100',
            '#0047b2',
            '#6b24b2',
            '#444444',
            '#5c0000',
            '#663d00',
            '#666600',
            '#003700',
            '#002966',
            '#3d1466',
            '#2e4053',
            '#283747',
            '#212f3c',
            '#117a65',
            '#138d75',
            '#16a085',
            '#148f77',
            '#7cb342',
            '#388e3c',
            '#43a047',
            '#f4511e',
            '#f57c00',
            '#f9a825',
            'custom-color',
          ],
        },
      ],
    ],
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'code-block',
    'color',
    'indent',
    'align',
  ];
  return (
    <ReactQuill
      value={text}
      placeholder={placeholder}
      theme="snow"
      id={id}
      modules={modules}
      formats={formats}
      onChange={(value) => { setText(value); onTextChangeHandler(value); }}
      style={{ height: `${textHeight}px` }}
    />
  );
};

export default Editor;
