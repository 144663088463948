/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { createContext, useState, useEffect } from 'react';

export const CourseContext = createContext();

export default function CourseProvider({ children }) {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [media, setMedia] = useState('');
  const [type, setType] = useState('');
  const [moduleId, setModuleId] = useState('');
  const [moduleHeading, setModuleHeading] = useState('');
  const [moduleType, setModuleType] = useState('');
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const [isWatched, setIsWatched] = useState(false);
  const [allModules, setAllModules] = useState([]);
  const [index, setIndex] = useState(0);
  const [offset, setOffset] = useState(0);
  const [enrolledData, setEnrolledData] = useState([]);
  const [sortBy, setSortBy] = useState('pendingAssignments');
  const [sortOrder, setSortOrder] = useState('desc');
  const [status, setStatus] = useState('');
  useEffect(() => {
    if (moduleId !== '') {
      setIndex(allModules.findIndex((module) => module._id === moduleId));
    } else {
      setIndex(-1);
    }
  }, [moduleId]);
  return (
    <CourseContext.Provider value={{
      searchKeyword,
      setSearchKeyword,
      media,
      setMedia,
      type,
      setType,
      moduleId,
      setModuleId,
      moduleHeading,
      setModuleHeading,
      url,
      setUrl,
      moduleType,
      setModuleType,
      isWatched,
      setIsWatched,
      allModules,
      setAllModules,
      index,
      setIndex,
      offset,
      setOffset,
      description,
      setDescription,
      sortBy,
      setSortBy,
      sortOrder,
      setSortOrder,
      enrolledData,
      setEnrolledData,
      status,
      setStatus,
    }}
    >
      {children}
    </CourseContext.Provider>
  );
}
