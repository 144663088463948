import React, { HTMLAttributes } from 'react';
import './Separator.css';

interface SeparatorProps extends HTMLAttributes<HTMLDivElement> {
    text: string;
}

const Separator = ({ text, ...rest } : SeparatorProps) => {
    return (
        <div className='separator' {...rest}>
            <div className='separator-line' />
            <span className='separator-text'>{text}</span>
            <div className='separator-line' />
        </div>
    );
};

export default Separator;