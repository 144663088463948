import React, { useState } from 'react';
import _ from 'lodash';
import HtmlParser from '../../../BasicComponents/htmlParser';
import './singleChoiceCard.css';

type Props = {
  queImg: any;
  questionOption: any;
  questionText: any;
  getSingleChoiceData?: (k: any) => void;
  setOpen?: (k: boolean) => void;
  getCorrectAnswer?: (k: string) => void;
  cardType?: string;
  setConclusionType: any;
  setIsMultiFalse: any;
  isMultiCorrect?: boolean;
};

const MultipleChoiceCard: React.FC<Props> = ({
  questionOption,
  questionText,
  queImg,
  getSingleChoiceData,
  setOpen,
  getCorrectAnswer,
  cardType = '',
  setConclusionType,
  setIsMultiFalse,
}) => {
  const [answerOption, setAnswerOption] = useState<
    {
      _id: string;
      isCorrect: boolean;
      optionText: string;
    }[]
  >([]);

  const getSelectedOption = (list: any) => {
    setAnswerOption([...answerOption, list]);
  };

  const removeOption = (id: string) => {
    setAnswerOption(
      answerOption.filter((option: { _id: string }) => option._id !== id)
    );
  };

  const getCorrectAns = (option: any) => {
    const rightAnswer = option.find(
      (list: { isCorrect: boolean }) => list?.isCorrect === true
    );
    getCorrectAnswer?.(rightAnswer?.optionText);
  };

  const backgroundColor = {
    background: 'red',
    color: '#fff',
  };

  const [background, setBackground] = useState({});

  const optionMap = answerOption?.reduce(
    (acc: any, item: any) => ({ ...acc, [item._id]: item }),
    {}
  );
  const checkIsAllOptionsAreCorrect = () => {
    setConclusionType('multi');
    const questionCorrect = questionOption.filter(
      (x: { isCorrect: boolean }) => x && x.isCorrect
    );
    const answerCorrect = answerOption.filter(
      (x: { isCorrect: boolean }) => x && x.isCorrect
    );
    getSingleChoiceData?.(questionCorrect);

    setIsMultiFalse(
      answerOption.length === answerCorrect.length &&
        answerCorrect.length === questionCorrect.length
    );

    if (
      answerOption.length === answerCorrect.length &&
      answerCorrect.length === questionCorrect.length
    ) {
      setBackground({});
    } else {
      setBackground(backgroundColor);
    }
    setOpen?.(true);
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className="mobile__anki--card-container">
      <div className="">
        {_.get(queImg, 'file', '') && (
          <div className="mobile__anki--image-Container">
            <img
              src={queImg?.file}
              alt=""
              className={
                cardType !== 'preview'
                  ? 'mobile__anki--card-container-image'
                  : 'mobile__anki--card-container-image--preview'
              }
            />
          </div>
        )}
        <p className="mobile__anki--paragraph">
          <HtmlParser text={questionText} />
        </p>
        {questionOption &&
          questionOption.map((list: any, index: number) => (
            <>
              {cardType === 'preview' ? (
                <div
                  role="presentation"
                  className="single-option-dev"
                  key={index.toString()}
                >
                  <p className="single-option-dev--text">{list?.optionText}</p>
                </div>
              ) : (
                <>
                  {optionMap[list?._id]?._id !== list?._id ? (
                    <div
                      role="presentation"
                      className={
                        optionMap[list?._id]?._id !== list?._id
                          ? 'single-option-dev'
                          : 'single-option-dev-green'
                      }
                      style={
                        optionMap[list?._id]?._id === list?._id
                          ? background
                          : {}
                      }
                      key={index.toString()}
                      onClick={() => {
                        getCorrectAns(questionOption);
                        getSelectedOption(list);
                      }}
                    >
                      <p className="single-option-dev--text">
                        {list?.optionText}
                      </p>
                    </div>
                  ) : (
                    <div
                      role="presentation"
                      className={
                        optionMap[list?._id]?._id !== list?._id
                          ? 'single-option-dev'
                          : 'single-option-dev-green'
                      }
                      style={
                        optionMap[list?._id]?._id === list?._id
                          ? background
                          : {}
                      }
                      key={index.toString()}
                      onClick={() => {
                        removeOption(list?._id);
                      }}
                    >
                      <p className="single-option-dev--text">
                        {list?.optionText}
                      </p>
                    </div>
                  )}
                </>
              )}
            </>
          ))}
        {cardType !== 'preview' ? (
          <button
            className="multiple-option--done-btn"
            type="button"
            onClick={() => {
              checkIsAllOptionsAreCorrect();
            }}
          >
            Done
          </button>
        ) : (
          <button className="multiple-option--done-btn" type="button">
            Done
          </button>
        )}
      </div>
    </div>
  );
};

export default MultipleChoiceCard;
