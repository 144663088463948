import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import NavbarContainer from '../../BasicComponents/navbar';
import { studentLearning2, backLite, warningRed } from '../../BasicComponents/icons';
import { hlbClient } from '../../../Clients/hlbClient';
import '../loginSignup.css';

type Timeout = /*unresolved*/ any

const UserSignUp: React.FC = () => {
  const [phone, setPhone] = useState('');
  const [otp, setOpt] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isPhoneFilled, setIsPhoneFilled] = useState(false);
  const [isOtpEntered, setIsOtpEntered] = useState(false);
  const [phoneErrMsg, setPhoneErrMsg] = useState('');
  const [otpErrMsg, setOtpErrMsg] = useState('');
  const [emailErrMsg, setEmailErrMsg] = useState('');
  const [dialingCode, setDialingCode] = useState('+91');
  const [timer, setTimer] = useState(0);
  const history = useHistory();

  const setHlDetails = (token: any) => {
    const decoded: { user: { id: string, user_type: string } } = jwt_decode(token);
    /* eslint-disable no-undef */
    window.localStorage.setItem('hlUserId', decoded.user.id);
    window.localStorage.setItem('hlUserType', decoded.user.user_type);
  };

  const handlePhoneSubmission = async (e: any) => {
    e.preventDefault();
    if (phone && phone.match(/^[0-9]{10}$/g)) {
      try {
        const response = await hlbClient().post('/api/users/phone/otp?verify=true', {
          phone,
        });
        if (response.data.msg === 'OTP sent' || response.data.msg === 'OTP sent Again') {
          setIsPhoneFilled(true);
          setTimer(60);
        }
      } catch (err: any) {
        if (err.response.data.msg === 'User Already exists') {
          setPhoneErrMsg('User Already exists');
        } else {
          setPhoneErrMsg('Please enter a valid number');
        }
      }
    } else {
      setPhoneErrMsg('Please enter valid number');
    }
  };

  const handleOtpSubmission = async (e: any) => {
    e.preventDefault();
    const payload = {
      phone,
      otp,
    };
    try {
      const response = await hlbClient().post('/api/users/verify', JSON.stringify(payload));
      if (response.data) {
        setIsOtpEntered(true);
        setTimer(0);
      }
    }catch (err: unknown) {
      if (typeof err === 'object' && err !== null && 'response' in err) {
        const response = err.response;
    
        if (typeof response === 'object' && response !== null && 'data' in response) {
          const data = response.data;
    
          if (typeof data === 'object' && data !== null && 'msg' in data) {
            const msg = data.msg;
    
            if (msg === 'Enter Valid OTP' || msg === 'User Already exists') {
              setOtpErrMsg(msg);
            }
          }
        }
      }
    }
  };

  const handleNameEmailSubm = async (e: any) => {
    e.preventDefault();
    try {
      const response = await hlbClient().post('/api/users/studentSignup', {
        name, email, phone,
      });
      if (response.data) {
        /* eslint-disable no-undef */
        window.localStorage.setItem('token', response.data.token);
        setHlDetails(response.data.token);
        history.push('/searchMentor');
      }
    } catch (err: any) {
      if (err.response.data.msg === 'User Already exits') {
        setEmailErrMsg('User Already exits');
      } else {
        setEmailErrMsg('Please enter a valid Email');
      }
    }
  };

  const handleBackButton = () => {
    if (isPhoneFilled && isOtpEntered) {
      setIsPhoneFilled(false);
      setIsOtpEntered(false);
      setPhone('');
      setOpt('');
      setName('');
      setEmail('');
      setEmailErrMsg('');
    } else if (isPhoneFilled) {
      setIsPhoneFilled(false);
      setPhone('');
      setPhoneErrMsg('');
      setOpt('');
      setOtpErrMsg('');
      setTimer(0);
    } else {
      setIsOtpEntered(false);
      setOtpErrMsg('');
      setTimer(0);
      setOpt('');
    }
  };

  const renderFormHeadings = () => {
    if (isPhoneFilled && isOtpEntered) {
      return (
        <h2 className="auth__message marginHelper-bottom-small">
          Almost done!
        </h2>
      );
    // eslint-disable-next-line no-else-return
    } else if (isPhoneFilled) {
      return (
        <h2 className="auth__message marginHelper-bottom-small">
          Enter the OTP sent to your mobile
        </h2>
      );
    } else {
      return (
        <h2 className="auth__message marginHelper-bottom-med">
          Enter your mobile number to continue
        </h2>
      );
    }
  };

  const renderSubheadings = () => {
    if (isPhoneFilled && isOtpEntered) {
      return (
        <h2 className="auth__subheading auth__otp--subheading">
          We’ll be using this information for your application
        </h2>
      );
    // eslint-disable-next-line no-else-return
    } else if (isPhoneFilled) {
      return (
        <h2 className="auth__subheading auth__otp--subheading">
          {`We've sent it to +91 ${phone}`}
          <button
            type="button"
            className="auth__subheading--link"
            onClick={() => setIsPhoneFilled(false)}
          >
            Edit
          </button>
        </h2>
      );
    } else {
      return null;
    }
  };

  const displayComponents = () => {
    if (isPhoneFilled && isOtpEntered) {
      return (
        <form className="auth__form">
          <div className="auth__field-container">
            <label
              htmlFor="Name"
              className="auth__name--label"
            >
              Name
            </label>
            <div className="auth__name--field">
              <input
                type="text"
                id="Name"
                className="auth__name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete="off"
                required
              />
            </div>
          </div>
          <div className="auth__field-container">
            <label
              htmlFor="Email"
              className="auth__email--label"
            >
              Email
            </label>
            <div className={`auth__signupEmail--field ${emailErrMsg && 'warning'}`}>
              <input
                type="email"
                id="Email"
                className="auth__signupEmail"
                value={email}
                autoComplete="off"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailErrMsg('');
                }}
                required
              />
              {
                emailErrMsg && (
                  <p className="auth__error-msg">
                    <img src={warningRed} alt="warning" />
                    {emailErrMsg}
                  </p>
                )
              }
            </div>
          </div>
          <button
            className="auth__button"
            type="submit"
            disabled={!name.length && !email.length}
            onClick={handleNameEmailSubm}
          >
            Finish
          </button>
        </form>
      );
    // eslint-disable-next-line no-else-return
    } else if (isPhoneFilled) {
      return (
        <form className="auth__form">
          <div className={`auth__otp--field ${otpErrMsg && 'warning'}`}>
            <input
              type="tel"
              maxLength={6}
              value={otp}
              onChange={(e) => {
                setOpt(e.target.value);
                setOtpErrMsg('');
              }}
              placeholder="Enter OTP"
              className="auth__otp"
              autoComplete="off"
              required
            />
            {
              otpErrMsg && (
                <p className="auth__error-msg">
                  <img src={warningRed} alt="warning" />
                  {otpErrMsg}
                </p>
              )
            }
          </div>
          {
            (isPhoneFilled && !isOtpEntered) && (
              <div className="auth__otpInfo">
                <span className="auth__otpInfo--text">
                  Didn’t receive OTP?
                </span>
                {
                  timer > 0 ? (
                    <span className="auth__otpInfo--timer">
                      {timer < 10 ? `0${timer}` : timer}
                    </span>
                  ) : (
                    <button
                      type="button"
                      className="auth__otpInfo--resend"
                      onClick={handlePhoneSubmission}
                    >
                      Resend
                    </button>
                  )
                }
              </div>
            )
          }
          <button
            className="auth__button"
            type="submit"
            disabled={otp.length < 6}
            onClick={handleOtpSubmission}
          >
            Continue
          </button>
        </form>
      );
    } else {
      return (
        <form className="auth__form">
          <div className={`auth__contact--field ${phoneErrMsg && 'warning'}`}>
            <select
              className="auth__dialing-code"
              onChange={(e) => setDialingCode(e.target.value)}
              value={dialingCode}
            >
              <option value="+91">+91</option>
            </select>
            <input
              className="auth__contact-no"
              type="tel"
              maxLength={10}
              value={phone}
              onChange={(e) => {
                setPhoneErrMsg('');
                setPhone(e.target.value);
              }}
              placeholder="Mobile number"
              autoComplete="off"
              required
            />
            {
              phoneErrMsg && (
                <p className="auth__error-msg">
                  <img src={warningRed} alt="warning" />
                  {phoneErrMsg}
                </p>
              )
            }
          </div>
          <button
            className="auth__button"
            type="submit"
            disabled={phone.length < 10}
            onClick={handlePhoneSubmission}
          >
            Continue
          </button>
        </form>
      );
    }
  };

  useEffect(() => {
    const timerValue: string | number | Timeout | undefined = timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
    return () => clearInterval(timerValue);
  }, [timer]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <NavbarContainer />
        </div>
      </div>
      <div className="auth auth__container">
        <div className="auth__card">
          <div className="card__sec card__sec--1 auth__image">
            <img src={studentLearning2} alt="learning" />
          </div>
          <div className="card__sec card__sec--2 auth__signup">
            {
              (isPhoneFilled || isOtpEntered) && (
                <button
                  type="button"
                  className="auth__back-button"
                  onClick={handleBackButton}
                >
                  <img src={backLite} alt="back" />
                </button>
              )
            }
            <>
              { renderFormHeadings() }
            </>
            <>
              { renderSubheadings() }
            </>
            { displayComponents() }
            {
              (!isPhoneFilled && !isOtpEntered) && (
                <div className="auth__agreement .auth__agreement-details">
                  <p>
                    By continuing, I accept the
                    {' '}
                    <Link to="/policy" className="auth__agreement--link">Terms of Service</Link>
                    {' '}
                    , including the
                    {' '}
                    <Link to="/policy" className="auth__agreement--link">User Agreement</Link>
                    {' '}
                    and
                    {' '}
                    <Link to="/policy/Privacy" className="auth__agreement--link">Privacy Policy</Link>
                  </p>
                </div>
              )
            }
            {
              (!isPhoneFilled && !isOtpEntered) && (
                <div className="auth__alternatives">
                  <p className="auth__alternatives--text">
                    Already have an account?
                  </p>
                  <Link to="/login" className="auth__alternatives--link">
                    Log In
                  </Link>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSignUp;
