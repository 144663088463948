import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import _ from 'lodash';
import HtmlParser from '../../BasicComponents/htmlParser';
import {
  deleteIcon, dots, edit, shareIcon,
} from '../../BasicComponents/icons';

type props = {
  answer: string,
  question: string,
  queImg?: { file?: string; url?: any },
  ansImg?: { file?: string; url?: any },
  hint: any,
  type: string,
  NextArrow?: () => void,
  setDeckId?: (k: string) => void,
  toggleCreateCardModal?: () => void,
  setEditData?: Dispatch<SetStateAction<any>>,
  setEntityType?: (k: string) => unknown,
  id?: string,
  deckId?: string,
  setDeletingItemName?: Dispatch<SetStateAction<any>>,
  setCardId?: Dispatch<SetStateAction<string>>,
  setCompType?: Dispatch<SetStateAction<string>>,
  setShowDelete?: Dispatch<SetStateAction<boolean>>,
  isWrittingAnswer?: boolean,
  getTime?: (k: string, k1: string) => void,
  getCardIndex?: (k: unknown) => unknown,
  cardId?: string,
  PrevArrow?: () => void,
  currentCard?: number,
  totalCard?: number,
  viewType?: string,
  setIsConclusion?: Dispatch<SetStateAction<boolean>>,
  questionOption?: unknown[],
  cardType?: string,
};

const PreviewCard: React.FC<props> = ({
  answer, question, queImg = {}, ansImg = {}, hint, type, NextArrow = null, setDeckId = null,
  toggleCreateCardModal = null, setEditData = null, setEntityType = null, id = '', deckId = '',
  setDeletingItemName = null, setCardId = null, setCompType = null, setShowDelete = null, isWrittingAnswer = false,
  getTime = null, getCardIndex = null, cardId = '', PrevArrow = null, currentCard = 0, totalCard = 0, viewType = '',
  setIsConclusion = null, questionOption = [], cardType = '',
}) => {
  const updateEditData = () => {
    setEditData?.({
      answer, question, queImg, ansImg, hint, id, questionOption, cardType,
    });
    setEntityType?.('edit');
  };

  // eslint-disable-next-line no-shadow
  const callGetTimeMethod = (event: string, cardId: string) => {
    if (viewType === 'learning') {
      getTime?.(event, cardId);
    }
  };
  const [showAnswer, setShowAnswer] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const deleteCard = () => {
    setCardId?.(id);
    setDeletingItemName?.('');
    setCompType?.('card');
    setDeckId?.(deckId);
    setShowDelete?.(true);
  };
  const DropdownMenu = (
    <DropdownButton
      key="start"
      id="dropdown-button-drop"
      drop="down"
      title={<img src={dots} alt="..." />}
    >
      <Dropdown.Item eventKey="1" className="notes__menu-item" onClick={() => { toggleCreateCardModal?.(); updateEditData(); }}>
        <img className="mx-2" height="16px" width="16px" src={edit} alt="..." />
        {' '}
        <span className="notes__card-menu-style">Edit</span>
      </Dropdown.Item>
      <Dropdown.Item eventKey="2" className="notes__menu-item" onClick={() => deleteCard()}>
        <img className="mx-2" height="16px" width="16px" src={deleteIcon} alt="..." />
        <span className="notes__card-menu-style">Delete</span>
      </Dropdown.Item>
      <Dropdown.Item eventKey="3" className="notes__menu-item">
        <img className="mx-2" height="16px" width="16px" src={shareIcon} alt="..." />
        <span className="notes__card-menu-style">Share</span>
      </Dropdown.Item>
    </DropdownButton>
  );

  useEffect(() => {
    if (isWrittingAnswer) {
      setShowAnswer(true);
    } else {
      setShowAnswer(false);
    }
  }, [isWrittingAnswer]);

  const [getClassName, updateClassName] = useState('');
  useEffect(() => {
    if (!hint && !queImg?.url) {
      updateClassName('notes__text--over-flow--without-image-and-hint');
    }
    if (queImg?.url && hint) {
      updateClassName('notes__text--over-flow--with-image-and-hint');
    }
    if (queImg?.url && !hint) {
      updateClassName('notes__text--over-flow--with-image');
    }
    if (hint && !queImg?.url) {
      updateClassName('notes__text--over-flow--with-hint');
    }
  }, [hint, queImg?.url]);

  useEffect(() => {
    if (currentCard) {
      setShowAnswer(false);
      setShowHint(false);
    }
  }, [currentCard]);

  return (
    <>
      <style>
        {`
      .anki-hard-disabled-btn {
          background: #68686852 !important;
          color: #9b9b9b !important;
          border: 2px solid #9b9b9b !important;
      }
      `}
      </style>
      {!showAnswer ? (
        <div className="previewCard__card--container mt-3 mb-3" style={type === 'student view' ? { margin: '0 auto' } : {}}>
          {(_.get(queImg, 'file', '') || _.get(queImg, 'url', ''))
            && <img src={queImg?.file || queImg?.url} className="anki_preview--image-card" alt="" />}
          {type === 'can edit' && (
            <div className="notes_dropdown">
              {DropdownMenu}
            </div>
          )}
          <p className={`${getClassName} ans-que`}>
            <HtmlParser text={question} />
          </p>
          {hint && (
            <p role="presentation" className="hint" onClick={() => { setShowHint(true); callGetTimeMethod('SHOW_HINT_CLICKED', cardId); }}>
              {showHint ? hint : 'Click here to see hint!'}
            </p>
          )}
          <button disabled={cardType === 'mcq'} type="button" className="previewCard__btn" onClick={() => { setShowAnswer(!showAnswer); callGetTimeMethod('SHOW_ANSWER', cardId); }}>Show Answer</button>
        </div>
      ) : (
        <>
          <div className="previewCard__card--container mt-3 mb-3" style={type === 'student view' ? { margin: '0 auto' } : {}}>
            {type === 'can edit' && (
              <div className="notes_dropdown">
                {DropdownMenu}
              </div>
            )}
            {(_.get(ansImg, 'file', '') || _.get(ansImg, 'url', ''))
              && <img src={ansImg?.file || ansImg?.url} className="anki_preview--image-card" alt="" />}
            <p className={ansImg?.url ? 'notes__text--over-flow--with-image ans-que' : 'notes__text--over-flow--without-image ans-que'}>
              <HtmlParser text={answer} />
            </p>
            {type !== 'student view' && <button type="button" className="previewCard__btn" onClick={() => setShowAnswer(!showAnswer)}>Show Question</button>}
          </div>
          {type === 'student view' && (
            <div className="anki__previewCard--group-btn">
              <div className="anki__previewCard--btn-container">
                <button
                  type="button"
                  className={currentCard < 2 ? 'hard anki-hard-disabled-btn' : 'hard'}
                  disabled={currentCard < 2}
                  onClick={() => {
                    PrevArrow?.();
                    callGetTimeMethod('PREVIOUS_BUTTON_CLICKED', cardId);
                    getCardIndex?.((prev: number) => prev - 1);
                  }}
                >
                  Previous
                </button>
                {currentCard !== totalCard ? (
                  <button
                    type="button"
                    className="easy"
                    onClick={() => {
                      NextArrow?.();
                      getTime?.('', '');
                      callGetTimeMethod('NEXT_BUTTON_CLICKED', cardId);
                      getCardIndex?.((prev: number) => prev + 1);
                    }}
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    className="easy"
                    onClick={() => {
                      setIsConclusion?.(true);
                      getTime?.('', '');
                      callGetTimeMethod('DONE_BUTTON_CLICKED', cardId);
                    }}
                  >
                    Done
                  </button>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default PreviewCard;
