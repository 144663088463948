import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    favouriteNoteIds: [],
};

export const userFavouriteNotesSlice = createSlice({
    name: 'userFavourites',
    initialState,
    reducers: {
        setFavouriteNotes: (state, action) => {
            const { favouriteNoteIds } = action.payload;

            return {
                favouriteNoteIds,
            };
        },
    },
});

export const { setFavouriteNotes } = userFavouriteNotesSlice.actions;

export default userFavouriteNotesSlice.reducer;