import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Toast } from 'react-bootstrap';
import './toastMessage.css';
import { isEmpty } from 'lodash';
import { addToast } from '../../../reducers/toast.reducer';
import { notiSuccess, notiError, notiWarning } from '../icons';

type ToastboxTypes = {
  data: any;
}

function Toastbox({ data }: ToastboxTypes) {
  const [showToast, setShowToast] = useState(false);
  const [style, setStyle] = useState('');
  const [icon, setIcon] = useState('');

  useEffect(() => {
    if (data?.type === 'success') {
      setStyle('toast__Message--success');
      setIcon(notiSuccess);
    }
    if (data?.type === 'warning') {
      setStyle('toast__Message--warning');
      setIcon(notiWarning);
    }
    if (data?.type === 'error') {
      setStyle('toast__Message--error');
      setIcon(notiError);
    }
    if (!isEmpty(data)) {
      setShowToast(true);
    }
  }, [data]);
  const dispatch = useDispatch();
  if (!isEmpty(data)) {
    setTimeout(() => {
      dispatch(addToast(''));
    }, 3000);
  }
  return (
    <Toast
      onClose={() => setShowToast(false)}
      show={showToast}
      delay={3000}
      autohide
      className={`${style}`}
    >
      <Toast.Body>
        <img src={`${icon}`} alt="" style={{ width: '24px', height: '24px', marginRight: '10px' }} />
        {data?.message}
      </Toast.Body>
    </Toast>
  );
}

export default Toastbox;
