import React, { useState } from 'react';
import _ from 'lodash';
import HtmlParser from '../../../BasicComponents/htmlParser';
import './singleChoiceCard.css';

type Props = {
  queImg: any;
  questionOption: any;
  questionText: any;
  getSingleChoiceData?: (k: any) => void;
  setOpen?: (k: boolean) => void;
  getCorrectAnswer?: (k: string) => void;
  cardType?: string;
  isSingleChoice?: { isCorrect?: boolean };
  setConclusionType?: any;
};

const SingleChoiceCard: React.FC<Props> = ({
  questionOption,
  questionText,
  queImg,
  getSingleChoiceData,
  setOpen,
  getCorrectAnswer,
  cardType = '',
  setConclusionType,
}) => {
  const [color, setColor] = useState(5);
  const backgroundColor = {
    background: 'red',
    color: '#fff',
  };

  const [background, setBackground] = useState({});

  const getCorrectAns = (option: any) => {
    setConclusionType?.('single');
    const rightAnswer = option.find(
      (list: { isCorrect: boolean }) => list?.isCorrect === true
    );
    getCorrectAnswer?.(rightAnswer?.optionText);
  };

  const getOptionValue = (list: any) => {
    if (list?.isCorrect) {
      setBackground({});
    } else {
      setBackground(backgroundColor);
    }
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className="mobile__anki--card-container">
      <div className="">
        {_.get(queImg, 'file', '') && (
          <div className="mobile__anki--image-Container">
            <img
              src={queImg?.file}
              alt=""
              className={'mobile__anki--card-container-image'}
            />
          </div>
        )}
        <p className="mobile__anki--paragraph">
          <HtmlParser text={questionText} />
        </p>
        {questionOption &&
          questionOption.map((list: any, index: number) => (
            <>
              {cardType === 'preview' ? (
                <div
                  role="presentation"
                  className={
                    color !== index
                      ? 'single-option-dev'
                      : 'single-option-dev-green'
                  }
                  key={index.toString()}
                >
                  <p className="single-option-dev--text">{list?.optionText}</p>
                </div>
              ) : (
                <div
                  role="presentation"
                  className={
                    color !== index
                      ? 'single-option-dev'
                      : 'single-option-dev-green'
                  }
                  style={color === index ? background : {}}
                  key={index.toString()}
                  onClick={() => {
                    setColor(index);
                    getSingleChoiceData?.(list);
                    getCorrectAns(questionOption);
                    getOptionValue(list);
                    setOpen?.(true);
                  }}
                >
                  <p className="single-option-dev--text">{list?.optionText}</p>
                </div>
              )}
            </>
          ))}
      </div>
    </div>
  );
};

export default SingleChoiceCard;
