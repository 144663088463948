import React from 'react';
import { HeartStraightBreak } from 'phosphor-react';
import "./EmptyStates.css";

const EmptyFavourites = () => {
    return (
        <div className="emptyContainer emptyContainer__favourites">
            <div className="emptyContainer__icon--wrapper">
            <HeartStraightBreak size={32} weight="fill" color="#FF6F6F" className="emptyContainer__icon" />
            </div>
            <div className="emptyContainer__info">
            <h4 className="info__heading">No Favourites found!</h4>
            <p className="info__subtext">Your favourite Notes, Webinar and Sessions <br /> will appear here.</p>
            </div>
        </div>
    );
};

export default EmptyFavourites;