import React, { useState } from 'react';
import { motion } from 'framer-motion/dist/framer-motion';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import WaitlistPattern1 from '../AssetV2/waitlist-bg-pattern-1.png';
import WaitlistForm from '../AssetV2/waitlist-form.png';
import "./Waitlist.css";
import { ArrowRight, BookBookmark, CurrencyInr, Info, MagnifyingGlass, TrendUp, Users, WarningCircle } from 'phosphor-react';
import Tooltip from '../BasicComponents/Tooltip';
import SkipWaitlistDialog from './SkipWaitlistDialog';
import WaitlistSuccessDialog from './WaitlistSuccessDialog';
import { hlbClient } from '../../Clients/hlbClient';

const features = [
    {
        title: "Earn with Hyperlearn",
        description: "Monetize your expertise by sharing valuable insights, experiences, and skills. Create and share notes to earn extra income from your knowledge.",
        url: "https://github.com",
        primaryColor: "#00C54FCC",
        bgColor: "rgba(24, 189, 90, 0.10)",
        icon: <CurrencyInr size={90} color="#00C54FCC" />,
    },
    {
        title: "Granular access level",
        description: "Establish granular access levels to precisely tailor and control permissions, determining with precision which individuals can view specific information.",
        url: "https://github.com",
        primaryColor: "#00D1FF",
        bgColor: "rgba(32, 214, 255, 0.10)",
        icon: <MagnifyingGlass size={90} color="#00D1FF" />,
    },
    {
        title: "Learn & Earn with Notes",
        description: "Contribute to the global knowledge exchange by crafting thorough and informative notes, allowing you to share your expertise with a wide audience.",
        url: "https://github.com",
        primaryColor: "#935DFFCC",
        bgColor: "rgba(163, 117, 255, 0.10)",
        icon: <BookBookmark size={90} color="#935DFFCC" />,
    },
    {
        title: "Track Students learning",
        description: "Keep tabs on students' educational progress by closely observing their development and ensuring optimal learning experiences.",
        url: "https://github.com",
        primaryColor: "#04E292CC",
        bgColor: "rgba(36, 225, 157, 0.10)",
        icon: <TrendUp size={90} color="#04E292CC" />,
    },
    {
        title: "Powerful community",
        description: "Join a diverse community of learners, educators, and experts passionate about continuous learning and knowledge sharing.",
        url: "https://github.com",
        primaryColor: "#FFC121CC",
        bgColor: "rgba(249, 201, 78, 0.10)",
        icon: <Users size={90} color="#FFC121CC" />,
    }
];

const initialFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    socialUrl: "",
};

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required!"),
    lastName: Yup.string().required("Last name is required!"),
    email: Yup.string().email("Please enter a valid Email address").required("Email is required!"),
    socialUrl: Yup.string().url("Please enter a valid URL").required("Social Url is required!")
});

const Waitlist = () => {
    const [showSkipWaitlistDialog, setShowSkipWaitlistDialog] = useState(false);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);

    const closeSkipWaitlistDialog = () => setShowSkipWaitlistDialog(false);
    const closeSuccessDialog = () => setShowSuccessDialog(false);

    const submitDetails = async () => {
        try {
            const response = await hlbClient().post("/api/waitlist", {
                name: `${values.firstName} ${values.lastName}`,
                email: values.email,
                social: values.socialUrl,
            });
            if (response.data.success) {
                setShowSuccessDialog(true);
                resetForm();
            }
        } catch (error) {
            console.error("Something went wrong!", error);
        }
    };

    const formik = useFormik({
        initialValues: initialFormValues,
        validationSchema,
        onSubmit: async () => {
            await submitDetails();
        }
    });

    const { handleSubmit, handleChange, errors, values, submitCount, resetForm } = formik;

    return (
        <div className="waitlist">
            <section className="waitlist__hero">
                <img className="hero__bgPattern1" src={WaitlistPattern1} alt="Pattern 1" />
                <div className="waitlist__content">
                    <motion.div
                        initial={{ x: -100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <h2 className="content__brand">hyperlearn</h2>
                    </motion.div>
                    <motion.div
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: 50, opacity: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className="content__info">
                            <h1 className="info__heading">Join us as Creator and start your journey with hyperlearn.</h1>
                            <p className="info__description">Discover the ultimate community made for learning</p>
                        </div>
                        <div className="content__actions">
                            <button className="action action--primary">
                                Join waitlist
                                <ArrowRight weight="bold" color='white' size={22} />
                            </button>
                            <button onClick={() => setShowSkipWaitlistDialog(true)} className="action action--secondary">Skip waitlist</button>
                        </div>
                    </motion.div>
                </div>
            </section>
            <section className="waitlist__features">
                <div className="features__header">
                    <h2 className="header__heading">Features</h2>
                    <p className="header__subText">Hop on board as a Creator and kickstart your adventure with HyperLearn!</p>
                </div>
                <div className="features__cards">
                    {
                        features.map((item) => (
                            <div className="cards__card" key={item.title}>
                                <div className="card__image" style={{ backgroundColor: item.bgColor }}>
                                    {item.icon}
                                </div>
                                <div className="card__info">
                                    <div className="info__title">{item.title}</div>
                                    <div className="info__description">
                                        {item.description}
                                    </div>
                                </div>
                                <a href={item.url} className="card__link" style={{ color: item.primaryColor }} target='_blank' rel="noreferrer">
                                    Learn more
                                    <ArrowRight weight="bold" size={16} />
                                </a>
                            </div>
                        ))
                    }
                </div>
            </section>
            <section className="waitlist__form">
                <div className="form__image">
                    <img src={WaitlistForm} alt="waitlist-form" />
                </div>
                <div className="form__container">
                    <div className="form__header">
                        <h2 className='form__heading'>Join the Waitlist!</h2>
                        <p className='form__description'>Signup to the waitlist and you’ll be the first to know when the tool is launched!</p>
                    </div>
                    <form className='form' onSubmit={handleSubmit}>
                        <div style={{ display: "flex", gap: "25px" }}>
                            <div className="form__input">
                                <label className="form__label" htmlFor="firstName">
                                    First name
                                    <span className="form__label--required">*</span>
                                </label>
                                <input
                                    placeholder='John'
                                    className="form__input"
                                    type="text"
                                    id='firstName'
                                    name='firstName'
                                    value={values.firstName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form__input">
                                <label className="form__label" htmlFor="lastName">
                                    Last name
                                    <span className="form__label--required">*</span>
                                </label>
                                <input
                                    placeholder='Doe'
                                    className="form__input"
                                    type="text"
                                    id='lastName'
                                    name='lastName'
                                    value={values.lastName}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form__input">
                            <label className="form__label" htmlFor="email">
                                Email
                                <span className="form__label--required">*</span>
                            </label>
                            <input
                                placeholder='john.doe@gmail.com'
                                className="form__input"
                                type="email"
                                id='email'
                                name='email'
                                value={values.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form__input">
                            <label className="form__label" htmlFor="socialUrl">
                                Active social URL
                                <span className="form__label--required">*</span>
                                <Tooltip direction='top' content="Share your social platform link where you're most active.">
                                    <Info size={16} style={{ cursor: "pointer" }} />
                                </Tooltip>
                            </label>
                            <input
                                placeholder="https://www.instagram.com/beargrylls/?hl=en"
                                className="form__input"
                                type="text"
                                id='socialUrl'
                                name='socialUrl'
                                value={values.socialUrl}
                                onChange={handleChange}
                            />
                        </div>
                        {Object.keys(errors).length > 0 && submitCount > 0 && (
                            <div className="form__error">
                                <WarningCircle size={20} color="#F43333" weight='bold' />
                                <p className='error__message'>{errors.email || errors.firstName || errors.lastName || errors.socialUrl}</p>
                            </div>
                        )}
                        <button
                            className="form__button"
                            type='submit'
                        >
                            Join waitlist!
                        </button>
                        <span className="form__helper">Have the content ready? <span className='form__helper--highlight' onClick={() => setShowSkipWaitlistDialog(true)}>Skip waitlist!</span></span>
                    </form>
                </div>
            </section>
            <SkipWaitlistDialog open={showSkipWaitlistDialog} onClose={closeSkipWaitlistDialog} />
            <WaitlistSuccessDialog open={showSuccessDialog} onClose={closeSuccessDialog} />
        </div>
    );
};

export default Waitlist;