import { configureStore } from '@reduxjs/toolkit';
import chatReducer from './reducers/chat.reducer';
import toastReducer from './reducers/toast.reducer';
import showcaseConfigReducer from './reducers/showcaseConfig.reducer';
import userFavouriteNotesReducer from './reducers/userFavouriteNotes.reducer';

export default configureStore({
  reducer: {
    chats: chatReducer,
    toast: toastReducer,
    showcaseConfig: showcaseConfigReducer,
    favNoteIds: userFavouriteNotesReducer,
  },
});
