import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import _ from 'lodash';
import Spinner from 'react-bootstrap/Spinner';
import Creatable from 'react-select/creatable';
import { hlbClient } from '../../Clients/hlbClient';
import './firstSession.css';
import {
  redCautionIcon,
  tips,
  cross,
  progressBarStep2,
} from '../BasicComponents/icons';
import { sessionNames } from '../Constants';
import { addToast } from '../../reducers/toast.reducer';
import NavbarContainer from '../BasicComponents/navbar/navbarContainer';
import Tooltip from '../BasicComponents/Tooltip';
import WhatsappPlugin from '../BasicComponents/WhatsappPlugin';

type FirstSessionProps = {
  entityType?: string;
  compType?: string;
  sessionId?: string | null;
  sessionData?: {
    sessionName?: string;
    sessionType?: string;
    price?: number;
    markedPrice?: number;
    duration?: number;
    description?: string;
  } | null;
  apiCall?: VoidFunction;
  closeEditSession?: VoidFunction;
};

const FirstSession: React.FC<FirstSessionProps> = ({
  entityType = 'profileDetailForm',
  compType = 'noComp',
  sessionId = '',
  sessionData = {
    sessionName: '',
    sessionType: '',
    price: 0,
    markedPrice: 0,
    duration: 0,
    description: '',
  },
  apiCall = () => null,
  closeEditSession = () => null,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [name, setName] = useState<{ value: string; label: string }[]>([
    {
      value: _.get(sessionData, 'name', ''),
      label: _.get(sessionData, 'name', ''),
    },
  ]);
  const [duration, setDuration] = useState<number>(
    _.get(sessionData, 'duration', 0)
  );
  const [description, setDescription] = useState<string>(
    _.get(sessionData, 'description', '')
  );
  const [isFreeSession, setIsFreeSession] = useState<boolean>(
    _.get(sessionData, 'isFree', false)
  );
  const [discountPrice, setDiscountPrice] = useState<number>(
    _.get(sessionData, 'discountPrice', 0)
  );
  const [price, setPrice] = useState<number>(_.get(sessionData, 'price', 0));
  const [sessionType, setSessionType] = useState<string>(
    _.get(sessionData, 'sessionType', '')
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [nameError, setNameError] = useState<string>('');
  const [priceError, setPriceError] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<string>('');
  const [sessionTypeError, setSessionTypeError] = useState<string>('');
  const [durationError, setDurationError] = useState<string>('');
  const history = useHistory();

  const handleChange = (value: any) => {
    if (value) {
      setNameError('');
      setName(value.label);
    }
  };
  const filled =
    name.length !== 0 &&
    description !== '' &&
    sessionType !== '' &&
    duration !== 0 &&
    price !== 0;
  useEffect(() => {
    if (isFreeSession) {
      setPrice(0);
      setDiscountPrice(0);
    }
  }, [isFreeSession]);

  const customTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#9281fc',
      primary25: 'rgba(167, 118, 228, 0.08)',
      danger: 'rgba(239, 9, 9, 0.75)',
      neutral50: '#C4C4C4',
      neutral20: nameError ? '#de3b38' : '#cccccc',
    },
  });
  const checkValidation = () => {
    if (name[0].label === '') {
      setNameError('Enter session name');
    } else {
      setNameError('');
    }
    if (duration === 0) {
      setDurationError('Select Duration');
    } else {
      setDurationError('');
    }
    if (description === '') {
      setDescriptionError('Please write about your Session');
    } else {
      setDescriptionError('');
    }
    if (sessionType === '') {
      setSessionTypeError('Select SessionType');
    } else {
      setSessionTypeError('');
    }
    if (price === 0) {
      setPriceError('Enter price');
    } else {
      setPriceError('');
    }
  };
  const formValue: {
    name: string;
    duration: number;
    discountPrice: number;
    description: string;
    price: number;
    isFree: boolean;
    sessionType: string;
  } = {
    name: typeof name === 'object' ? name[0].label : name,
    duration,
    discountPrice,
    description,
    price,
    isFree: isFreeSession,
    sessionType,
  };
  const dispatch = useDispatch();
  const submitFirstSession = async (e: any) => {
    setIsLoading(true);
    // eslint-disable-next-line no-nested-ternary
    const method =
      entityType !== 'editPage'
        ? hlbClient().post('/api/onboarding/firstSession', formValue)
        : compType === 'sessionCard'
        ? hlbClient().put(`/api/scheduler/session/${sessionId}`, formValue)
        : hlbClient().post('/api/scheduler/session', formValue);
    e.preventDefault();
    try {
      await method;
      // eslint-disable-next-line no-unused-expressions
      entityType === 'editPage'
        ? ''
        : history.push('/onboarding/setAvailability');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');
    }
    if (entityType === 'editPage') {
      apiCall();
      dispatch(
        addToast({ type: 'success', message: 'Session Created Successfully' })
      );
    }
    if (compType === 'sessionCard') {
      dispatch(
        addToast({ type: 'success', message: 'session Edited Successfully' })
      );
      closeEditSession();
    }
    setIsLoading(false);
  };

  return (
    <>
      {entityType !== 'editPage' && (
        <div className="container-fluid">
          <div className="row">
            <NavbarContainer />
          </div>
        </div>
      )}
      <div
        className={
          entityType !== 'editPage'
            ? 'container-fluid background-form'
            : 'container-fluid'
        }
      >
        <div className="row d-flex justify-content-center">
          <div
            className={
              entityType !== 'editPage'
                ? 'first__session--sub-container  mb-5'
                : 'first__session--sub-container'
            }
          >
            <div
              className={
                entityType !== 'editPage'
                  ? 'mentor-form-box form-container'
                  : ''
              }
              style={
                entityType !== 'editPage'
                  ? { marginTop: '114px' }
                  : { marginTop: '' }
              }
            >
              {entityType !== 'editPage' && (
                <>
                  <a
                    href="/dashboardV2"
                    className="close-button"
                    data-toggle="tooltip"
                    title="Close"
                  >
                    <img src={cross} alt="close" />
                  </a>
                  <h1>Create your first mentoring session</h1>
                  <div className="mt-5 mb-5 text-center">
                    <img
                      src={progressBarStep2}
                      alt="..."
                      className="img-fluid"
                    />
                  </div>
                </>
              )}
              <form onSubmit={submitFirstSession} style={{ marginLeft: '0px' }}>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <label htmlFor="exampleDataList" className="form-label__v2">
                      Session Name
                    </label>
                    <Tooltip
                      content="Select a Session or type your own session name"
                      direction="bottom"
                    >
                      <img className="tooltip--img" src={tips} alt="Info" />
                    </Tooltip>
                    <Creatable
                      isClearable
                      onChange={(value) => handleChange(value)}
                      options={sessionNames}
                      defaultValue={entityType === 'editPage' ? name : null}
                      className="creatable--field"
                      // eslint-disable-next-line max-len
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      placeholder="Select or create a new session name"
                      theme={customTheme}
                    />
                    {nameError && (
                      <label style={{ color: '#F71C1C' }}>
                        <img
                          src={redCautionIcon}
                          alt=".."
                          style={{ marginTop: '-2px' }}
                        />{' '}
                        {nameError}
                      </label>
                    )}
                  </div>
                  <div className="col-md-12 mt-3">
                    <label htmlFor="exampleDataList" className="form-label__v2">
                      Session Type
                    </label>
                    <div className="row">
                      <div className="col-6">
                        <input
                          required
                          type="radio"
                          name="session-type"
                          value="one-on-one"
                          onChange={() => {
                            setSessionType('one-on-one');
                            setSessionTypeError('');
                          }}
                          checked={sessionType === 'one-on-one'}
                          onClick={(e: any) => {
                            setSessionType(e.target.value);
                          }}
                          style={{
                            border: `${
                              sessionTypeError && '1px solid #F71C1C'
                            }`,
                          }}
                        />{' '}
                        <label
                          htmlFor="radio"
                          className="form-label__v2 form-label-heading"
                        >
                          1x1 Session
                        </label>
                      </div>
                      <div className="col-6">
                        <input
                          type="radio"
                          name="session-type"
                          value="group"
                          onChange={() => {
                            setSessionType('group');
                            setSessionTypeError('');
                          }}
                          checked={sessionType === 'group'}
                          onClick={(e: any) => {
                            setSessionType(e.target.value);
                          }}
                          style={{
                            border: `${
                              sessionTypeError && '1px solid #F71C1C'
                            }`,
                          }}
                        />{' '}
                        <label
                          htmlFor="radio"
                          className="form-label__v2 form-label-heading"
                        >
                          Group Session
                        </label>
                      </div>
                      {sessionTypeError && (
                        <label style={{ color: '#F71C1C' }}>
                          <img
                            src={redCautionIcon}
                            alt=".."
                            style={{ marginTop: '-2px' }}
                          />{' '}
                          {sessionTypeError}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <label htmlFor="exampleDataList" className="form-label__v2">
                    Time Duration
                  </label>
                  <div className="row">
                    <div className="col-4">
                      <input
                        required
                        type="radio"
                        name="duration"
                        value="60"
                        checked={duration === 60}
                        onChange={() => {
                          setDuration(60);
                          setDurationError('');
                        }}
                        onClick={(e: any) => {
                          setDuration(e.target.value);
                        }}
                      />{' '}
                      <label
                        htmlFor="radio"
                        className="form-label__v2 form-label-heading"
                      >
                        60 Mins
                      </label>
                    </div>
                    <div className="col-4">
                      <input
                        type="radio"
                        name="duration"
                        value="30"
                        checked={duration === 30}
                        onChange={() => {
                          setDuration(30);
                          setDurationError('');
                        }}
                        onClick={(e: any) => {
                          setDuration(e.target.value);
                        }}
                      />{' '}
                      <label
                        htmlFor="radio"
                        className="form-label__v2 form-label-heading"
                      >
                        30 Mins
                      </label>
                    </div>
                    <div className="col-4">
                      <input
                        type="radio"
                        name="duration"
                        value="15"
                        checked={duration === 15}
                        onChange={() => {
                          setDuration(15);
                          setDurationError('');
                        }}
                        onClick={(e: any) => {
                          setDuration(e.target.value);
                        }}
                      />{' '}
                      <label
                        htmlFor="radio"
                        className="form-label__v2 form-label-heading"
                      >
                        15 Mins
                      </label>
                    </div>
                    {durationError && (
                      <label style={{ color: '#F71C1C' }}>
                        <img
                          src={redCautionIcon}
                          alt=".."
                          style={{ marginTop: '-2px' }}
                        />{' '}
                        {durationError}
                      </label>
                    )}
                  </div>
                </div>
                <div className="col-md-12 my-3">
                  <label htmlFor="exampleDataList" className="form-label__v2">
                    Free Session
                  </label>
                  <div className="row">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                      }}
                    >
                      <input
                        type="checkbox"
                        id="free-session"
                        name="freeSession"
                        checked={isFreeSession}
                        onChange={() => setIsFreeSession(!isFreeSession)}
                      />{' '}
                      <label
                        className="form-label__v2 form-label-heading"
                        htmlFor="free-session"
                      >
                        Make this session free
                      </label>
                    </div>
                  </div>
                </div>
                {!isFreeSession && (
                  <div className="col-md-12 my-3">
                    <div className="row">
                      <div className="col-6 col-md-6">
                        <label
                          htmlFor="exampleDataList"
                          className="form-label__v2"
                        >
                          Price
                        </label>
                        <div className="row g-1">
                          <div className="col-6 col-ms-6 col-md-6">
                            <input
                              type="number"
                              className="form-input"
                              value={price}
                              onChange={(e: any) => {
                                setPriceError('');
                                setPrice(parseInt(e.target.value, 10));
                              }}
                              style={{
                                border: `${priceError && '1px solid #F71C1C'}`,
                              }}
                            />
                          </div>
                          <div className="col-6">
                            <p
                              className="first__session-form-select form-select-sm mt-3"
                              aria-label=".form-select-sm example"
                            >
                              (₹) Rupee
                            </p>
                          </div>
                          {priceError && (
                            <label style={{ color: '#F71C1C' }}>
                              <img
                                src={redCautionIcon}
                                alt=".."
                                style={{ marginTop: '-2px' }}
                              />{' '}
                              {priceError}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="col-6 col-md-6">
                        <div className="row justify-content-end g-0">
                          <div className="col-12 col-ms-7 col-md-7">
                            <label
                              htmlFor="exampleDataList"
                              className="form-label__v2"
                            >
                              <span style={{ marginRight: '5px' }}>
                                Discounted Price
                              </span>
                              <Tooltip
                                content="Add a discounted price for the session here"
                                direction="bottom"
                              >
                                <img src={tips} alt="tips" />
                              </Tooltip>
                            </label>
                            <input
                              type="number"
                              className="form-input"
                              placeholder="Optional"
                              value={discountPrice}
                              // eslint-disable-next-line max-len
                              onChange={(e) => {
                                setDiscountPrice(parseInt(e.target.value, 10));
                              }}
                            />
                          </div>
                          <div className="col-2 mt-4" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  <label htmlFor="exampleDataList" className="form-label__v2">
                    Session details
                  </label>
                  <textarea
                    className="form-textarea p-2"
                    placeholder="What mentees should expect from this sessions?"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                      setDescriptionError('');
                    }}
                    style={{
                      border: `${descriptionError && '1px solid #F71C1C'}`,
                    }}
                  />
                  {descriptionError && (
                    <label style={{ color: '#F71C1C' }}>
                      <img
                        src={redCautionIcon}
                        alt=".."
                        style={{ marginTop: '-2px' }}
                      />{' '}
                      {descriptionError}
                    </label>
                  )}
                </div>
                <div className="col-md-12">
                  {filled ? (
                    <button type="submit" className="btn btn-primary__v2 mt-4">
                      {isLoading === false ? (
                        'Save & Continue'
                      ) : (
                        <Spinner animation="border" role="status" />
                      )}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-grey__v2 mt-4"
                      onClick={checkValidation}
                    >
                      Save & Continue
                    </button>
                  )}
                </div>
                <div className="col-12 text-center add-more-session__text mt-3 mb-2">
                  <p>You can add more sessions from your dashboard</p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <WhatsappPlugin />
    </>
  );
};
export default FirstSession;
