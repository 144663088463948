import React from 'react';
import { Link } from 'react-router-dom';
import { noop } from 'lodash';
import { bigCross } from '../icons';

export type button = {
  btnName: string;
  action: string;
  url: string | null;
  handler: () => void | null;
};

type FailureConfirmationProps = {
  heading: string;
  description: string;
  button1: button;
  button2: button;
};

const FailureConfirmation = ({
  heading,
  description,
  button1,
  button2,
}: FailureConfirmationProps) => (
  <div className="confirmation">
    <div className="confirmation__box">
      <div className="confirmation__head">
        <img src={bigCross} alt="Failed" />
        <h3 className="confirmation__head--title">{heading}</h3>
        <p className="confirmation__head--description">{description}</p>
      </div>
      {button1 || button2 ? (
        <div className="confirmation__links">
          {button1 && (
            <Link
              className="confirmation__link"
              to={button1.url ? button1.url : ''}
              onClick={button1.handler ? button1.handler : noop}
            >
              {button1.btnName}
            </Link>
          )}
          {button2 && (
            <Link
              className="confirmation__link"
              to={button2.url ? button2.url : ''}
              onClick={button2.handler ? button2.handler : noop}
            >
              {button2.btnName}
            </Link>
          )}
        </div>
      ) : null}
    </div>
  </div>
);

export default FailureConfirmation;
