import React from 'react';
import './empty.css';

type props = {
  type: string;
  msg: string;
  onClick?: () => void;
}

// eslint-disable-next-line react/prop-types
const Empty:React.FC<props> = ({ type, msg, onClick }) => {
  const entityType = type === 'normal' ? (
    <p className="empty__para--text">
      {msg}
    </p>
  ) : (
    <p className="empty__para--text">
      You haven’t added anything yet, click here to
      {' '}
      <span role="presentation" onClick={() => onClick?.()} style={{ color: '#FF6B4A' }}>
        “
        {msg}
        ”
      </span>
    </p>
  );

  return (
    <div className="empty__container">
      <svg width="40" height="40" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M42 82C64.0914 82 82 64.0914 82 42C82 19.9086 64.0914 2 42 2C19.9086 2 2 19.9086 2 42C2 64.0914 19.9086 82 42 82Z" stroke="#FF6B4A" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M58 58C58 58 52 50 42 50C32 50 26 58 26 58" stroke="#FF6B4A" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M30 30H30.04" stroke="#FF6B4A" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M54 30H54.04" stroke="#FF6B4A" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      {entityType}
    </div>
  );
};
export default Empty;
