import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';

type Props = {
  children: React.ReactNode,
  noteURL: string,
}

const CopyToClipBoard: React.FC<Props> = ({ noteURL, children }) => {
  const [isCopied, setIsCopied] = useState(false);
  async function copyTextToClipboard(text: string) {
    // eslint-disable-next-line no-undef
    if ('clipboard' in navigator) {
      // eslint-disable-next-line no-return-await, no-undef
      return await navigator.clipboard.writeText(text);
    }
    // eslint-disable-next-line no-undef
    return document.execCommand('copy', true, text);
  }

  const handleCopyClick = (copyText: string) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <OverlayTrigger
      trigger="click"
      key="top"
      placement="top"
      show={isCopied}
      overlay={(
        <Popover id="popover-positioned-top">
          <Popover.Content>
            Copied to clipboard!
          </Popover.Content>
        </Popover>
)}
    >
      <div
        role="presentation"
        onClick={() => handleCopyClick(noteURL)}
      >
        {children}
      </div>
    </OverlayTrigger>
  );
};

CopyToClipBoard.propTypes = {
  children: PropTypes.string.isRequired,
  noteURL: PropTypes.string.isRequired,
};

export default CopyToClipBoard;
