export const FEATURE_FLAGS = {
  flags: [
    {
      name: 'webinar',
      active: true,
    },
  ],
  // NOTE: UPDATE THIS KEY TO CURRENT DATE IF YOU'RE MAKING ANY CHANGES TO FLAGS.JS FILE
  flagsLastUpdatedOn: '10 Sep, 2022',
};
