import { ArrowCircleLeft } from 'phosphor-react';
import React, { Dispatch, SetStateAction } from 'react';

type props = {
  innHeight: number;
  setIsWatchTutorial: Dispatch<SetStateAction<boolean>>;
  mobileView: boolean;
}

const WatchTutorial: React.FC<props> = ({ innHeight, setIsWatchTutorial, mobileView }) => {
  const videoHeight = mobileView ? `${innHeight - 140}` : '450';
  return (
    <div className="mobile__anki--view-container">
      <style>
        {`
        .mobile__anki--card-container {
          height: ${innHeight - 77}px
        }
      `}
      </style>
      <div className="mobile__anki--header">
        <div>
          <span style={{ color: '#3b3b3b' }}>
            <ArrowCircleLeft size={28} onClick={() => setIsWatchTutorial(false)} />
          </span>
          <span className="mx-2">How to use note</span>
        </div>
        {/* <p style={{ color: '#8C52FF' }}>
          <span style={{ color: '#3b3b3b', marginLeft: '10px' }}>
            <ArrowCircleLeft size={28} onClick={() => setIsWatchTutorial(false)} />
          </span>
        </p> */}
      </div>
      <div className="mobile__anki--card-container">
        <iframe
          style={{ borderRadius: '8px' }}
          width="100%"
          height={videoHeight}
          src="https://youtube.com/embed/AN5dO65Mk6Y"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
        <div className="note__tutorial-video--btn-container">
          <button
            type="button"
            style={{ width: '48%', borderRadius: '8px' }}
            className="mobile__anki--btn-purple"
            onClick={() => setIsWatchTutorial(false)}
          >
            Back
          </button>
          <button
            type="button"
            style={{ width: '48%', borderRadius: '8px' }}
            className="mobile__anki--btn-purple"
            onClick={() => setIsWatchTutorial(false)}
          >
            Got it
          </button>
        </div>
      </div>
    </div>
  );
};
export default WatchTutorial;
