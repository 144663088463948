import React, { useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
  phone, downLite, upArrow, calendarLite,
} from '../../BasicComponents/icons';
import Tooltip from '../../BasicComponents/Tooltip';

export type LeadsAccordionType = {
  elData: any;
  index: number;
  correctTime: (dateObj: Date | string) => string;
  limitWordLength: (string: string, limit?: number) => string;
  capitalize: (words: string) => string;
};

const LeadsAccordion = ({
  elData, index, correctTime, limitWordLength, capitalize,
}: LeadsAccordionType) => {
  const [isActive, setIsActive] = useState(false);
  const processNo = (no: string) => no?.match(/.{1,5}/g)?.join('-');
  const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Octo', 'Nov', 'Dec'];
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const makeDate = (dateObj = new Date()) => {
    if (dateObj) {
      const newDate = dayjs(dateObj);
      const timeStr = correctTime(newDate.toDate());
      return `${newDate.get('date')} ${month[newDate.get('month')]} ${newDate.get('year')}, ${timeStr}`;
    }
    return dateObj;
  };

  return (
    <div className="sessions__leads">
      <div
        className={`leads__overview ${isActive ? 'leads__overview--expanded' : ''}`}
        onClick={() => setIsActive(!isActive)}
      >
        <div className="leads--wrapper">
          <div className="leads__overview--number">
            <span>{index + 1}</span>
            .
          </div>
          <div className="leads__overview--details">
            <p>{capitalize(_.get(elData, 'name', ''))}</p>
            <Tooltip content={_.get(elData, 'email', '')}>
              <p className="details--email">{limitWordLength(_.get(elData, 'email', ''))}</p>
            </Tooltip>
          </div>
        </div>
        <div className="leads__info">
          {
            elData.phone && (
            <p className="leads__info--contact">
              <img src={phone} alt="Phone" />
              {processNo(_.get(elData, 'phone', ''))}
            </p>
            )
          }
          {
            elData.createdAt && (
              <p className="leads__info--date">
                <img src={calendarLite} alt="Calendar" />
                { days[dayjs(elData.createdAt).get('day')]}
                , &nbsp;
                {dayjs(elData.createdAt).get('date')}
                {month[dayjs(elData.createdAt).get('month')] }
              </p>
            )
          }
        </div>
        <div className="leads__actions">
          <button type="button" onClick={() => setIsActive(!isActive)}>
            <img src={isActive ? upArrow : downLite} alt="arrow" />
          </button>
        </div>
      </div>
      <div className={`leads__details ${isActive ? 'show' : ''}`}>
        <div className="leads__details--description">
          {_.get(elData, 'description', '')}
        </div>
        <div className="leads__details--date">
          {makeDate(_.get(elData, 'createdAt', ''))}
        </div>
      </div>
    </div>
  );
};

export default LeadsAccordion;
