import React from 'react';
import HtmlParser from '../../../BasicComponents/htmlParser';
import { FactCard as FCT } from '../../types';
import { handleBase64ToImg } from '../../../Utils/cardUtils';

const FactCard: React.FC<FCT> = ({
  id,
  cardType,
  disableInteraction,
  description,
  medias,
  index,
  totalCards,
  reason = '',
  previous,
  next,
  done,
}) => {
  const allowPrevious = index === 0 || disableInteraction;
  const allowNext = index === totalCards || disableInteraction;

  return (
    <>
      <div className="card__body">
        <HtmlParser text={handleBase64ToImg(description, medias)} />
      </div>
      <div className="card__btn-grp">
        <button
          className="card__btn-secondary"
          disabled={allowPrevious}
          onClick={previous}
        >
          Previous
        </button>
        <button
          className="card__btn-primary"
          disabled={allowNext}
          onClick={next}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default FactCard;
