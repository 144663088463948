import {
  BrowserRouter, Route, Switch, useLocation,
} from 'react-router-dom';
import React, { useEffect } from 'react';
// import AuthContainer from './Comps/Auth/AuthContainer';
// import CoursePage from './Comps/Layout/CoursePage';
// import Home from './Comps/Layout/Home';
import { FEATURE_FLAGS } from './flags';
import AuthProdiver from './provider/AuthContext';
import SocketProvider from './provider/SocketContext';
// import CourseDetails from './Comps/Layout/CourseDetail/CourseDetails';
// import BecomeMentor from './Comps/Layout/CourseBuilder/BecomeMentor';
// import Profile from './Comps/Layout/Mentor/Profile';
// import CreateCourse from './Comps/Layout/CourseBuilder/Course';
import CourseProvider from './provider/CourseContext';
// import MyCourse from './Comps/Layout/MyCourse/MyCourse';
// import MyPurchases from './Comps/Layout/Purchases/MyPurchases';
// import DashBoardContainer from './Comps/DashBoard/DashBoardContainer';
// import Messages from './Comps/Chat/MyMessages';
// import ErrorBoundary from './Comps/BaseComps/ErrorBoundary';
// import MentorDetail from './Comps/Layout/Mentor/MentorDetail';
import MentorProfile, { mentorProfileEdit } from './CompV2/mentorProfile';
import MentorDetailForm from './CompV2/MentorDetailForm';
import CalendarContainer from './CompV2/Appointments/CalendarContainer';
import SetAvailability from './CompV2/Appointments/SetAvailability';
import FirstSession from './CompV2/firstSession';
import HomePage from './CompV2/homePage/index.ts';
import MentorDashboard from './CompV2/MentorDashboard/index.ts';
import UserLogin from './CompV2/LoginSignUp/userLogin/userLogin';
import UserSignUp from './CompV2/LoginSignUp/userSignUp/userSignUp';
import MentorSignUp from './CompV2/LoginSignUp/mentorSignUp/mentorSignUp';
import SearchMentor from './CompV2/searchMentor';
import UserProfile from './CompV2/UserProfile';
import Settings from './CompV2/settings';
import PolicyContainer from './CompV2/TermsAndConditions/index.ts';
import AboutAndContact from './CompV2/AboutAndContact/index.ts';
import ProtectedRoute from './protected.route';
import NotFound from './CompV2/BasicComponents/NotFound/index.ts';
import ErrorPage from './CompV2/errorPages/errorPage.tsx';
import FAQ from './CompV2/homePage/faq';
import CreateWebinar from './CompV2/Webinar/CreateWebinar/index.ts';

import StudentWebinar from './CompV2/Webinar/StudentWebinar/index.ts';
import LearningAnki from './CompV2/hyperNotes/learningFlow/learningAnki';
import StudentCardView from './CompV2/hyperNotes/learningFlow/desktop/studentCardView';
// import CardsListPage from './CompV2/hyperNotes/manage/cardsListPage';
import MainPage from './CompV2/hyperNotes/manage/mainPage';
import StudentDashboard from './CompV2/studentDashboard/StudentDashboard.tsx';

import analytics from './analytics';
// import FlowModeWithStyle from './CompV2/notesV2/modes/flowMode';
import FlowMode from './CompV2/notesV2/modes/flowMode';
import { CanvasControl } from './CompV2/notesV2/common';
import CardsListPageV2 from './CompV2/notesV2/pages/cardListPageV2';
import { Login } from './CompV2/Auth';
import { Signup } from './CompV2/Auth/Signup';
import Waitlist from './CompV2/waitlist';

const initFeatures = () => {
  // eslint-disable-next-line no-undef
  const flag = localStorage.getItem('featureFlags');
  const parsedFlag = flag && JSON.parse(flag);

  if (parsedFlag && parsedFlag[0].flagsLastUpdatedOn !== FEATURE_FLAGS.flagsLastUpdatedOn) {
    // eslint-disable-next-line no-undef
    localStorage.removeItem('featureFlags');
  }
  // eslint-disable-next-line no-undef
  if (localStorage.getItem('featureFlags') === null) {
    // eslint-disable-next-line no-undef
    localStorage.setItem('featureFlags', JSON.stringify([
      FEATURE_FLAGS,
    ]));
  }
};

function AppChild() {
  const location = useLocation();

  useEffect(() => {
    analytics.page();
  }, [location]);

  return (
    <AuthProdiver>
      <SocketProvider>
        <CourseProvider>
          <Switch>
            {/* <Route exact path="/" component={Home} />
            <Route path="/CoursePage" component={CoursePage} />
            <Route path="/MyCourse/:id" component={MyCourse} />
            <Route path="/CourseDetails/:id" component={CourseDetails} />
            <Route path="/BecomeMentor" component={BecomeMentor} />
            <Route path="/Profile" component={Profile} />
            <Route path="/Course/:id" component={CreateCourse} />
            <Route path="/Course" component={CreateCourse} />
            <Route path="/MyCourse/:id" component={MyCourse} />
            <Route path="/mentorDetail/:id" component={MentorDetail} />
            <Route path="/login" component={AuthContainer} />
            <Route path="/dashboard" component={DashBoardContainer} />
            <Route path="/Purchases" component={MyPurchases} /> */}
            {/* v2 */}
            <Route exact path="/" component={HomePage} />
            <ProtectedRoute mentorRoute path="/dashboardV2" component={MentorDashboard} />
            <Route path="/mentor/signUp" component={MentorSignUp} />
            <ProtectedRoute mentorRoute path="/mentor/editProfile" component={mentorProfileEdit} />
            <Route path="/mentor/:id" component={MentorProfile} />
            <ProtectedRoute path="/sessionBooking/:sessionId" component={CalendarContainer} />
            <ProtectedRoute mentorRoute path="/onboarding/setAvailability" component={SetAvailability} />
            <ProtectedRoute mentorRoute path="/onboarding/firstSession" component={FirstSession} />
            <ProtectedRoute mentorRoute path="/onboarding/mentorDetailForm" component={MentorDetailForm} />
            <Route path="/user/login" component={UserLogin} />
            <Route path="/user/signUp" component={UserSignUp} />
            <Route path="/searchMentor" component={SearchMentor} />
            <Route path="/login" component={Login} />
            <Route path="/signup/student" component={Signup} />
            <Route path="/signup/mentor" component={Signup} />
            <ProtectedRoute path="/user/profile" component={UserProfile} />
            <ProtectedRoute mentorRoute path="/settings" component={Settings} />
            <Route path="/policy" component={PolicyContainer} />
            <Route path="/aboutAndContact" component={AboutAndContact} />
            <Route path="/notes/flow-mode/:id" component={LearningAnki} />
            <Route path="/notes/flow-mode-v2/:id" component={CanvasControl(FlowMode)} />
            <Route path="/space-repitition-system/deck/:id" component={StudentCardView} />
            <Route path="/space-repitition-system/:id" component={CardsListPageV2} />
            <Route path="/space-repitition-system" component={MainPage} />
            <Route path="/faq" component={FAQ} />
            <Route path="/error" component={ErrorPage} />
            <ProtectedRoute mentorRoute path="/webinar/create/" component={CreateWebinar} />
            <ProtectedRoute mentorRoute path="/webinar/edit/:webinarId">
              <CreateWebinar isEdit />
            </ProtectedRoute>
            <ProtectedRoute mentorRoute path="/webinar/:webinarId" component={StudentWebinar} />
            <Route path="/webinars/:publicUrl" component={StudentWebinar} />
            <Route path="/myDashboard/showcase" component={StudentDashboard} />
            <Route path="/myDashboard" component={StudentDashboard} />
            <Route path="/waitlist" component={Waitlist} />
            <Route path="*">
              <NotFound />
            </Route>
            {/* <ErrorBoundary>
              <Route path="/Messages" component={Messages} />
            </ErrorBoundary> */}
          </Switch>
        </CourseProvider>
      </SocketProvider>
    </AuthProdiver>
  );
}
function App() {
  initFeatures();
  return (
    <BrowserRouter>
      <AppChild />
    </BrowserRouter>
  );
}

export default App;
