import React from 'react';
import ContentLoader from 'react-content-loader';

const DeckCardLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={350}
    height={150}
    viewBox="0 0 350 150"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <rect x="9" y="18" rx="5" ry="5" width="102" height="123" />
    <rect x="151" y="53" rx="5" ry="5" width="195" height="18" />
    <rect x="153" y="80" rx="5" ry="5" width="122" height="14" />
    <rect x="155" y="106" rx="5" ry="5" width="106" height="11" />
    <rect x="245" y="17" rx="5" ry="5" width="98" height="11" />
    <circle cx="132" cy="62" r="11" />
  </ContentLoader>
);

export default DeckCardLoader;
