/* eslint-disable func-names */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Modal, Spinner } from 'react-bootstrap';
import ReactCrop, { Crop } from 'react-image-crop';
import FormData from 'form-data';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import Editor from '../../../BasicComponents/Editor';
import {
  greenImage, PencilLine, redCautionIcon, Trash, uploadPurple,
} from '../../../BasicComponents/icons';
import './singleChoiceCardCreation.css';
import { hlbClient } from '../../../../Clients/hlbClient';
import { addToast } from '../../../../reducers/toast.reducer';
import SingleChoiceCard from '../../learningFlow/Mobile/singleChoiceCard';
import Toggle from '../../../BasicComponents/toggle';
import MultipleChoiceCard from '../../learningFlow/Mobile/multipleChoiceCard';

type Props = {
  toggleCreateCardModal: () => void,
  entityType: string,
  editdata: any,
  getMyDeck: () => void,
  toggleConfirmModal: () => void,
  }

const SingleChoiceCardModal: React.FC<Props> = ({
  toggleCreateCardModal, entityType, editdata,
  getMyDeck, toggleConfirmModal,
}) => {
  const [question, setQuestion] = useState('');
  const [questionOption, setQuestionOption] = useState([
    { optionText: '', isCorrect: false },
    { optionText: '', isCorrect: false },
  ]);
  // eslint-disable-next-line no-unused-vars
  const [correctAnswer, getCorrectAnswer] = useState('');
  const [imageSize, setImageSize] = useState(0);
  const [questionPhoto, setQuestionPhoto] = useState('');
  const [displayPic, setDisplayPic] = useState('');
  const [pic, setPic] = useState([]);
  const [crop, setCrop] = useState<Crop>({ unit: 'px', width: 102, aspect: 1 / 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [isUpload, setIsUpload] = useState(false);
  const [isMulti, setIsMulti] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [questionImageFlag, setQuestionImageFlag] = useState('');

  const { id } = useParams<any>();

  useEffect(() => {
    if (question === '<p><br></p>') {
      setQuestion('');
    }
  }, [question]);

  useEffect(() => {
    if (isUpload === false) {
      setPic([]);
      setDisplayPic('');
    }
  }, [isUpload]);

  useEffect(() => {
    if (entityType === 'edit') {
      if (editdata) {
        setQuestionOption(editdata?.questionOption || questionOption);
        setQuestion(editdata?.question);
      }
      if (editdata?.queImg?.url) {
        setIsUpload(true);
        setDisplayPic(editdata?.queImg?.url);
        setQuestionPhoto(editdata?.queImg?.fileName);
        setPic(editdata?.queImg?.url);
      }
      // eslint-disable-next-line no-unused-expressions
      editdata?.questionOption
      && editdata?.questionOption.filter((option: any) => option.isCorrect).length === 1
        ? setIsMulti(false)
        : setIsMulti(true);
    }
  }, [editdata, entityType]);

  const [cropModal, setCropModal] = useState(false);
  const addFields = () => {
    const newField = { optionText: '', isCorrect: false };

    setQuestionOption([...questionOption, newField]);
  };

  const handleFormChange = (index: number, e: any) => {
    const value = e?.target?.name === 'isCorrect' ? e.target.checked : e.target.value;
    const data: any[] = [...questionOption];
    data[index][e?.target?.name] = value;
    setQuestionOption(data);
    if (e?.target?.checked && isMulti === false) {
      setQuestionOption(data.map(({ isCorrect, ...item }, i) => ({
        ...item,
        isCorrect: index === i ? value : false,
      })));
    }
  };
  const removeFields = (index: number) => {
    const data = [...questionOption];
    data.splice(index, 1);
    setQuestionOption(data);
  };

  const uploadPicture = (e: any) => {
    setQuestionPhoto(e.target.files[0].name);
    setImageSize(e.target.files[0].size / 1024 / 1024);
  };
  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      // eslint-disable-next-line no-undef
      const reader: any = new window.FileReader();
      reader.addEventListener('load', () => setDisplayPic(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const uploadPicWithoutCrop = async (newdisplayPic: any) => {
    // eslint-disable-next-line no-undef
    const base64Response = await fetch(newdisplayPic);
    const blob = await base64Response.blob();
    // eslint-disable-next-line no-undef
    const imageName = questionPhoto;
    // eslint-disable-next-line no-undef
    const profilePic: any = new File([blob], imageName, { type: "'image/jpeg', 'image/jpg', 'image/png'", lastModified: new Date().getTime() });
    setPic(profilePic);
  };

  const onLoad = useCallback((img: any) => {
    imgRef.current = img;
  }, []);
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image: any = imgRef.current;
    const canvas: any = previewCanvasRef.current;
    // eslint-disable-next-line no-shadow
    const crop: any = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    // eslint-disable-next-line no-undef
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
  }, [completedCrop]);

  function saveProfilePic(canvas: any) {
    // If Size of Image is greater than 5 then it will show the error else processed to next step
    if (imageSize > 5) {
      // eslint-disable-next-line no-console
      console.log('image size too big');
    } else {
      canvas.toBlob(
        (blob: any) => {
          // eslint-disable-next-line no-undef
          const previewAnswerUrl = window.URL.createObjectURL(blob);
          setDisplayPic(previewAnswerUrl);
          // eslint-disable-next-line no-undef
          const answerPic: any = new File([blob], questionPhoto, { type: "'image/jpeg', 'image/peg', 'image/png'", lastModified: new Date().getTime() });
          setPic(answerPic);
        },
        questionPhoto,
        1,
      );
    }
  }

  const queImg = { file: displayPic };
  const ImagePreviousState = () => {
    setDisplayPic(editdata?.queImg?.url || '');
    setQuestionPhoto(editdata?.queImg?.fileName || '');
    setPic(editdata?.queImg?.url || []);
  };

  const dispatch = useDispatch();
  const onSubmit = async () => {
    const formData = new FormData();
    formData.append('questionImage', pic);
    formData.append('questionText', question);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < questionOption.length; i++) {
      formData.append(
        `questionOption[${i}][isCorrect]`,
        questionOption[i].isCorrect,
      );
      formData.append(
        `questionOption[${i}][optionText]`,
        questionOption[i].optionText,
      );
    }
    setIsLoading(true);
    try {
      if (entityType === 'edit') {
        formData.append('questionImageFlag', questionImageFlag);
        const resp = await hlbClient('multipart/form-data').put(`/api/srs/deck/${id}/interactiveCard/mcq/${editdata.id}/edit`, formData);
        setPic([]);
        if (resp?.data?.success) {
          dispatch(addToast({ type: 'success', message: resp?.data?.msg }));
          setIsLoading(false);
          toggleCreateCardModal();
          getMyDeck();
        } else { setIsLoading(false); }
      } else {
        const resp = await hlbClient('multipart/form-data').post(`/api/srs/deck/${id}/interactiveCard/mcq/create`, formData);
        setPic([]);
        if (resp?.data?.success) {
          dispatch(addToast({ type: 'success', message: resp?.data?.msg }));
          setIsLoading(false);
          toggleCreateCardModal();
          toggleConfirmModal();
          getMyDeck();
        } else { setIsLoading(false); }
      }
    } catch (err) {
      const error: any = err;
      const message = error?.response?.data?.result?.errors[0]?.msg;
      dispatch(addToast({ type: 'error', message }));
      setIsLoading(false);
    }
  };
  const optionCorrect = questionOption.filter((list) => list && list.isCorrect);
  const checkOptionText = questionOption.filter((list) => list && list.optionText);

  const imageUploaded = optionCorrect.length !== 0 && checkOptionText.length !== 0 && question !== '' && displayPic.length !== 0;
  const imageUploadedWithMultiChoice = optionCorrect.length > 1 && checkOptionText.length !== 0 && question !== '' && displayPic.length !== 0;
  const noImageUpload = optionCorrect.length !== 0 && checkOptionText.length !== 0 && question !== '';
  const noImageUploadWithMultiChoice = optionCorrect.length > 1 && checkOptionText.length !== 0 && question !== '';

  const ImageUploadedWithMultiChoiceValidation = isUpload
    ? imageUploadedWithMultiChoice : noImageUploadWithMultiChoice;
  const ImageUploadValidation = isUpload ? imageUploaded : noImageUpload;

  const isValid = isMulti
    ? ImageUploadedWithMultiChoiceValidation
    : ImageUploadValidation;

  useEffect(() => {
    if (isUpload) {
      if (editdata?.queImg?.url) {
        if (editdata?.queImg?.url !== displayPic) {
          setQuestionImageFlag('changed');
        } else {
          setQuestionImageFlag('');
        }
      } else {
        setQuestionImageFlag('changed');
      }
    } else {
      setQuestionImageFlag('removed');
    }
  }, [isUpload, editdata, displayPic]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      <style>
        {`
      input[type='radio'] {
        -webkit-appearance: none;
        width: 17px;
        height: 17px;
        border: 1.7px solid #7B45E7;
        border-radius: 50%;
        outline: none;
        cursor: pointer;
        transition: .2s ease;
        margin-top: 2px;
      }
      
      input[type='radio']:hover {
        box-shadow: 0 0 5px 0px #ad86fa inset;
      }
      
      input[type='radio']:before {
        content: '';
        display: block;
        width: 60%;
        height: 60%;
        margin: 19.8% auto;
        border-radius: 50%;
        transition: .2s ease;
      }
      
      input[type='radio']:checked:before {
        background: #7B45E7;
      }
      .form-check-input:checked {
        background-color: #8C52FF;
        border-color: #8C52FF;
      }
      `}
      </style>
      {/* eslint-disable-next-line react/jsx-filename-extension */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-8">
            <span className="create__card--heading mt-3 mx-3">
              <p>Question</p>
            </span>
            <p className="guide-text mx-3">
              The question will be displayed to the learners when they open a note.
            </p>
            <div className="mb-4 mx-3" style={{ height: '120px' }}>
              <Editor
                initialValue={question}
                placeholder="Write your question..."
                onTextChangeHandler={setQuestion}
                id="question"
                textHeight={90}
              />
            </div>

            <div className="mt-5 mx-3">
              <span className="d-flex align-item-center">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    checked={isMulti}
                    onChange={(e) => setIsMulti(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                    {isMulti ? 'Switch to single choice.' : 'Switch to multiple choice.'}
                  </label>
                </div>
                {' '}
              </span>
              <small>Options</small>
              {questionOption.map((input, index) => (
                <div key={index.toString()} className="mt-2 single-choice__input-box-container">
                  <input
                    name="optionText"
                    className="single-choice__input--field"
                    value={input.optionText}
                    onChange={(e) => handleFormChange(index, e)}
                    placeholder=""
                  />
                  {index > 1 && (
                    <button type="button" className="single-choice__remove" onClick={() => removeFields(index)}>
                      <Trash />
                    </button>
                  )}
                  {isMulti === false ? (
                    <input
                      type="radio"
                      name="isCorrect"
                      // value={input.isCorrect}
                      checked={input.isCorrect}
                      onChange={(e) => handleFormChange(index, e)}
                    />
                  ) : (
                    <Toggle nameValue="isCorrect" index={index} entityType="multiArgs" isChecked={input.isCorrect} onChangeHandler={handleFormChange} />
                  )}
                </div>
              ))}
            </div>
            {questionOption.length < 4 && <button type="button" className="single-choice__add--more mx-3" onClick={() => addFields()}>+ Add more</button>}
            <div className="anki__deck-creation mb-3 mx-3" style={{ display: 'flex', width: '100%' }}>
              <div className="mt-3 mb-3" style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                <div className="col-md-3 mt-2">
                  <span className="d-flex align-item-center">
                    <Toggle isChecked={isUpload} onChangeHandler={setIsUpload} />
                    {' '}
                    <span className="mx-1" style={{ fontSize: '14px' }}>Add an Image</span>
                  </span>
                </div>
                <div className="col-md-9">
                  {isUpload
                    && (
                      <div className="anki__card-creation--container-upload">
                        <span>
                          <img src={greenImage} alt="..." className="mx-2" />
                          {displayPic.length !== 0 ? (
                            <>
                              {questionPhoto?.slice(0, 15)}
                              {questionPhoto?.length > 15 && '...'}
                            </>
                          ) : ''}
                        </span>
                        <label htmlFor="file" className="mx-2">
                          {displayPic.length !== 0 ? (
                            <span className="cursor-pointer">
                              <PencilLine size={18} />
                              {' '}
                              <span className="purple">Change Image</span>
                            </span>
                          ) : (
                            <span className="cursor-pointer">
                              <img src={uploadPurple} alt="..." />
                              {' '}
                              <span className="purple">Upload Image</span>
                            </span>
                          )}
                        </label>
                        <input
                          type="file"
                          id="file"
                          onChange={(e: any) => {
                            uploadPicture(e); onSelectFile(e); setCropModal(true);
                          }}
                          onClick={(e: any) => {
                            // eslint-disable-next-line no-param-reassign
                            e.target.value = null;
                          }}
                          accept="image/*"
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="anki__deck-creation--footer-create-card">
              <div />
              <div>
                <button type="button" className="btn anki__deck-creation-secondary" onClick={() => toggleCreateCardModal()}>Cancel</button>
                <button disabled={!isValid} type="button" className={`btn mx-2 ${isValid ? 'anki__deck-creation-primary' : 'anki__deck-creation-primary-grey'}`} onClick={() => onSubmit()}>
                  {/* eslint-disable-next-line max-len */}
                  {isLoading && <Spinner animation="border" className="mx-2" role="status" size="sm" />}
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className="col-4">
            <p className="create-card--preview-text">Preview</p>
            <p className="create-card--preview-info-text mb-2">This is how your card will look!</p>
            <div className="single-choice__preview" style={{ width: '326px' }}>
              {isMulti === false ? (
                <SingleChoiceCard
                  // eslint-disable-next-line react/jsx-no-bind
                  getSingleChoiceData={function (): void {
                    throw new Error('Function not implemented.');
                  }}
                  // eslint-disable-next-line react/jsx-no-bind
                  setOpen={function (): void {
                    throw new Error('Function not implemented.');
                  }}
                  {...{
                    questionOption,
                    questionText: question,
                    queImg,
                    getCorrectAnswer,
                    cardType: 'preview',
                  }}
                />
              )
                : (
                  <MultipleChoiceCard
                    // eslint-disable-next-line react/jsx-no-bind
                    getSingleChoiceData={function (): void {
                      throw new Error('Function not implemented.');
                    }}
                    // eslint-disable-next-line react/jsx-no-bind
                    setOpen={function (): void {
                      throw new Error('Function not implemented.');
                    }}
                    setConclusionType={undefined}
                    setIsMultiFalse={undefined}
                    {...{
                      queImg,
                      questionOption,
                      questionText: question,
                      getCorrectAnswer,
                      cardType: 'preview',
                    }}
                    getCorrectAnswer={getCorrectAnswer}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
      {cropModal && (
        <Modal show={cropModal} centered backdrop="static">
          <style>
            {`
          .grey-btn {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            background: rgba(140, 82, 255, 0.26);
            border-radius: 4px;
            color: #8C52FF;
        }
          `}
          </style>
          <Modal.Body>
            <ReactCrop
              src={displayPic}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c: any) => setCrop(c)}
              onComplete={(c: any) => setCompletedCrop(c)}
            />
            <div>
              <p style={{ color: 'red', textAlign: 'center' }}>
                <img src={redCautionIcon} alt=".." style={{ marginTop: '-2px' }} />
                {' '}
                GIFs will not work if you crop it.
              </p>
              <canvas
                ref={previewCanvasRef}
                style={{
                  width: Math.round(completedCrop ? 102 : 0),
                  height: Math.round(completedCrop ? 102 : 0),
                  margin: '8% 40% 1% 40%',
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                className="btn grey-btn mx-2"
                type="button"
                // eslint-disable-next-line max-len
                onClick={() => { setCropModal(false); ImagePreviousState(); }}
              >
                Cancel
              </button>
              <button
                className="btn purple-btn mx-2"
                type="button"
                // eslint-disable-next-line max-len
                onClick={() => { setCropModal(false); uploadPicWithoutCrop(displayPic); }}
              >
                Save GIF
              </button>
              <button
                onClick={() => {
                  setCropModal(false);
                  saveProfilePic(previewCanvasRef.current);
                }}
                className="btn purple-btn"
                type="button"
              >
                {' '}
                Crop & Save

              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
SingleChoiceCardModal.propTypes = {
  toggleCreateCardModal: PropTypes.func.isRequired,
  getMyDeck: PropTypes.func.isRequired,
  entityType: PropTypes.string.isRequired,
  editdata: PropTypes.string.isRequired,
};
export default SingleChoiceCardModal;
