import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Modal, Spinner } from 'react-bootstrap';

import { hlbClient } from '../../../../Clients/hlbClient';
import { handleBase64ToImg, handleImgToBase64 } from '../../../Utils/cardUtils';
import {
  FactCard as FC,
  MCQCardV2,
  SCQCardV2,
  TypeAnswerV2Card as TCV2,
  ReviewCardV2 as RCV2,
  featuresProps,
} from '../../types';

import CardRenderer from '../../cards/cardRenderer/cardRenderer';
import Editor from '../editor/Editor';
import AddTopics from '../AddTopics';
import FeaturesRenderer from '../features/FeaturesRenderer';

import './manageCard.css';
import '../features/feature-styles.css';
import { Info } from 'phosphor-react';

type ManageCardProps = {
  noteId: string;
  openModal: boolean;
  setOpenModal: (k: boolean) => void;
  getDeckById: () => void;
  type?: string;
  data?: any;
};

type CardNameMapType = {
  [key: string]: string;
  factv2: string;
  multiplechoicev2: string;
  singlechoicev2: string;
  typeanswerv2: string;
  reviewv2: string;
};

const ManageCard: React.FC<ManageCardProps> = ({
  noteId,
  openModal,
  setOpenModal,
  getDeckById,
  type = '',
  data = {},
}) => {
  const [topicErrorMessage, setTopErrorMessage] = useState<string | null>(null);

  const cardCreationOptions = [
    { value: 'factv2', label: 'Facts card' },
    { value: 'multiplechoicev2', label: 'MCQ card' },
    { value: 'singlechoicev2', label: 'SCQ card' },
    { value: 'typeanswerv2', label: 'Type Answer card' },
    { value: 'reviewv2', label: 'Review Card' },
  ];

  const [cardType, setCardType] = useState<{ value: string; label: string }>({
    value: data?.cardType || 'factv2',
    label:
      cardCreationOptions.filter((obj) => obj.value === data?.cardType)[0]
        ?.label || 'Facts card',
  });

  const cardNameMap: CardNameMapType = {
    factv2: 'fact',
    multiplechoicev2: 'multipleChoice',
    singlechoicev2: 'singleChoice',
    typeanswerv2: 'typeAnswer',
    reviewv2: 'review',
  };

  const customTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#9281fc',
      primary25: 'rgba(167, 118, 228, 0.08)',
      dangerLight: 'transparent',
      danger: '#1a1a1a',
      neutral50: '#C4C4C4',
    },
  });

  const dataQuestionText = handleBase64ToImg(
    data?.description || '',
    data?.medias
  );
  const dataAnswerText = handleBase64ToImg(
    data?.answerDescription || '',
    data?.medias
  );

  useEffect(() => {
    if (type === 'edit') {
      setFeatures({
        hint: data.hint,
        correctAnswer: data.correctAnswer,
        options: data.options,
      });
    } else {
      setFeatures({});
    }
  }, [cardType]);

  const [loading, setLoading] = useState(false);
  const [editorText, setEditorText] = useState(dataQuestionText);
  const [questionText, setQuestionText] = useState(dataQuestionText);
  const [answerText, setAnswerText] = useState(dataAnswerText);
  const [topicValue, setTopicValue] = useState(data?.topics && data.topics[0]);
  const [isEditorAnswerMode, setIsEditorAnswerMode] = useState(false);
  const [features, setFeatures] = useState<any>(
    type === 'edit'
      ? {
          hint: data.hint,
          correctAnswer: data.correctAnswer,
          options: data.options,
        }
      : {}
  );

  useEffect(() => {
    if (data?.description) {
      setQuestionText(data?.description);
    }
  }, [`${data?.description}`])

  const onTextChangeHandler = (value: any) => {
    setEditorText(value);
  };
  useEffect(() => {
    if (isEditorAnswerMode) {
      setAnswerText(editorText);
    } else {
      setQuestionText(editorText);
    }
  }, [editorText]);
  useEffect(() => {
    if (isEditorAnswerMode) {
      setEditorText(answerText);
    } else {
      setEditorText(questionText);
    }
  }, [isEditorAnswerMode]);

  const getTopic = (value: string) => {
    setTopicValue(value);
  };

  let cardData: any;
  switch (cardNameMap[cardType.value]) {
    case 'typeAnswer': {
      cardData = {
        cardType: 'typeanswerv2',
        description: questionText,
        topics: [topicValue],
        ...features,
      } as TCV2 & { _id: string };
      break;
    }
    case 'multipleChoice': {
      cardData = {
        cardType: 'multipleChoice',
        description: questionText,
        topics: [topicValue],
        ...features,
      } as MCQCardV2 & { _id: string };
      break;
    }
    case 'singleChoice': {
      cardData = {
        cardType: 'singleChoice',
        description: questionText,
        topics: [topicValue],
        ...features,
      } as SCQCardV2 & { _id: string };
      break;
    }
    case 'review': {
      cardData = {
        cardType: 'reviewv2',
        description: questionText,
        topics: [topicValue],
        answerDescription: answerText,
        cardFace: isEditorAnswerMode ? 'answer_side' : 'question_side',
        ...features,
      } as RCV2 & { _id: string };
      break;
    }
    default: {
      cardData = {
        cardType: 'factv2',
        description: questionText,
        topics: [topicValue],
      } as FC & { _id: string };
    }
  }

  const onSubmit = async () => {
    setLoading(true);
    const finalQuestionText = handleImgToBase64(questionText);
    const finalAnswerText = handleImgToBase64(answerText);

    const finalMedia = {
      ...finalQuestionText.medias,
      ...finalAnswerText.medias,
    };

    let finalDataSentToApi = {
      ...finalQuestionText,
      topicKey: topicValue,
      ...features,
    };

    if (cardType.value === 'reviewv2') {
      finalDataSentToApi = {
        ...finalDataSentToApi,
        answerDescription: finalAnswerText.description,
        medias: finalMedia,
      };
    }

    // alert(JSON.stringify(finalDataSentToApi, null, 4)); // for debugging options object on Submit

    try {
      if (type === 'edit') {
        const resp = await hlbClient().put(
          `/api/notesv2/${noteId}/${cardNameMap[cardType.value]}/${
            data.cardId
          }/edit`,
          finalDataSentToApi
        );
        if (resp?.status === 200) {
          getDeckById();
          setLoading(false);
          setOpenModal(false);
        }
      } else {
        const resp = await hlbClient().post(
          `/api/notesv2/${noteId}/${cardNameMap[cardType.value]}/create`,
          finalDataSentToApi
        );
        if (resp?.status === 200) {
          getDeckById();
          setLoading(false);
          setOpenModal(false);
        }
      }
    } catch (err) {
      console.error('oops something went wrong');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      questionText === '<p><br></p>' ||
      questionText === '<head></head><body></body>'
    ) {
      setQuestionText('');
    }
    if (
      answerText === '<p><br></p>' ||
      answerText === '<head></head><body></body>'
    ) {
      setAnswerText('');
    }
  }, [questionText, answerText]);

  const isValid = () => {
    const optionCorrect =
      (features.options &&
        features.options.filter((list: any) => list && list.isCorrect)) ||
      [];
    const checkOptionText =
      (features.options &&
        features.options.filter((list: any) => list && list.text)) ||
      [];
    // eslint-disable-next-line no-prototype-builtins
    const hasHint = features['hint'] ? features.hint !== '' : true;

    const scqValidation =
      optionCorrect.length !== 0 &&
      checkOptionText.length !== 0 &&
      questionText !== '' &&
      topicValue !== '' &&
      hasHint;
    const mcqValidation =
      optionCorrect.length > 1 &&
      checkOptionText.length !== 0 &&
      questionText !== '' &&
      topicValue !== '' &&
      hasHint;
    const factsValidation = questionText !== '' && topicValue !== '' && hasHint;
    // eslint-disable-next-line no-prototype-builtins
    const typeAnswerValidation =
      questionText !== '' &&
      topicValue !== '' &&
      features?.correctAnswer !== '' &&
      features['correctAnswer'] &&
      hasHint;
    const reviewValidation =
      questionText !== '' && answerText !== '' && topicValue !== '' && hasHint;

    switch (cardNameMap[cardType.value]) {
      case 'singleChoice':
        return scqValidation;
      case 'multipleChoice':
        return mcqValidation;
      case 'fact':
        return factsValidation;
      case 'typeAnswer':
        return typeAnswerValidation;
      case 'review':
        return reviewValidation;
      default:
        return false;
    }
  };

  return (
    <>
      <Modal
        show={openModal}
        fullscreen="xl"
        centered
        onHide={() => setOpenModal(false)}
        backdrop="static"
        className="manage-card__modal"
      >
        <Modal.Body>
          <div className="row">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <div className="manage-card__header">
                <span>{`${type === 'edit' ? 'Edit' : 'Create'} card`}</span>
                <Select
                  options={cardCreationOptions}
                  theme={customTheme}
                  onChange={(values: any) => setCardType(values)}
                  value={cardType}
                  className="manage-card__select-card"
                  classNamePrefix="select"
                  isDisabled={type === 'edit'}
                />
              </div>
              <button
                type="button"
                onClick={() => setOpenModal(false)}
                className="btn-close float-end"
              />
            </div>
          </div>
          <div className="manage-card__body">
            <div className="manage-card__col manage-card__preview">
              <div className="manage-card__col-header">
                <div>
                  <div className="manage-card__title">Preview</div>
                  <div className="manage-card__text">
                    This is how your card will look!
                  </div>
                </div>
                <button className="manage-card__btn-primary">Reset</button>
              </div>
              <div
                className="manage-card__preview-wrapper"
                style={{ width: '320px', height: '500px' }}
              >
                <CardRenderer
                  card={cardData}
                  disableInteraction={true}
                  reason={''}
                  previous={() => null}
                  next={() => null}
                  verifyAnswer={() => true}
                  done={() => null}
                  closeCard={() => null}
                />
              </div>
            </div>
            <div className="manage-card__separator" />
            <div className="manage-card__col manage-card__editor">
              <div className="manage-card__col-header">
                <div>
                  <div className="manage-card__title">Card Editor</div>
                  <div className="manage-card__text">
                    This is where you will edit your card!
                  </div>
                </div>
              </div>
              <div
                className="manage-card__editor-wrapper"
                style={{ width: '320px', height: '500px' }}
              >
                <Editor
                  editorText={editorText}
                  onTextChangeHandler={onTextChangeHandler}
                />
              </div>
            </div>
            <div className="manage-card__col manage-card__options">
              <div className="manage-card__input-container">
                <div className="manage-card__title">Select card topic</div>
                <AddTopics {...{ getTopic, topicValue, setTopErrorMessage }} />
              </div>
              <div className="manage-card__option-container">
                <FeaturesRenderer
                  {...{ features, setFeatures, type: cardType.value }}
                />
                {cardType.value === 'reviewv2' && (
                  <div
                    className="features__toggle-container"
                    style={{ marginTop: '1rem' }}
                  >
                    <div className="features__title">
                      {!isEditorAnswerMode
                        ? 'Switch editor to write answer'
                        : 'Switch editor to question mode'}
                    </div>
                    <label className="features__switch">
                      <input
                        type="checkbox"
                        checked={isEditorAnswerMode}
                        onChange={(e) => setIsEditorAnswerMode((prev) => !prev)}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                )}
              </div>
              <div className="warning-div">
                {topicErrorMessage && (
                  <p>
                    <Info /> {topicErrorMessage}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="manage-card__btn-grp">
            <button
              className="manage-card__btn-secondary"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </button>
            <button
              disabled={loading || !isValid()}
              className="manage-card__btn-primary"
              onClick={onSubmit}
            >
              {loading && (
                <Spinner animation="border" role="status" size="sm" />
              )}
              {type === 'edit'
                ? loading
                  ? 'Saving...'
                  : 'Save'
                : loading
                ? 'Creating...'
                : 'Create'}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ManageCard;
