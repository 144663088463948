import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { hlbClient } from '../../../Clients/hlbClient';
import { AuthContext } from '../../../provider/AuthContext';
import {
  smallPlus, notiSuccess, notiError, cross,
} from '../icons';
import './customUrl.css';

function CustomUrl() {
  // eslint-disable-next-line no-undef
  const token = window.localStorage.getItem('token');
  const { authState, setStateUpdate, stateUpdate } = useContext(AuthContext);
  const [customUrlLink, setCustomUrlLink] = useState(_.get(authState, 'profile.publicUrl', ''));
  const [isProfileValid, setIsProfileValid] = useState(false);
  const [urlEdited, setUrlEdited] = useState(false);
  const [isMiniModalOpen, setIsMiniModalOpen] = useState(false);

  const handleUpdateUrl = async (e: any) => {
    e.preventDefault();
    const response = await hlbClient().put('/api/dashboardV2/setPublicUrl', {
      publicUrl: customUrlLink,
    });
    if (response.data.message) {
      setIsMiniModalOpen(false);
      setUrlEdited(false);
      // eslint-disable-next-line no-undef
      window.location.reload();
    }
  };

  const handleUrlChange = (e: any) => {
    setCustomUrlLink(e.target.value);
    setUrlEdited(true);
  };

  useEffect(() => {
    if (token) {
      setStateUpdate(!stateUpdate);
    }
  }, []);

  useEffect(() => {
    const checkIsValid = async () => {
      const response = await hlbClient().post('/api/dashboardV2/checkUrl', {
        publicUrl: customUrlLink,
      });
      if (response.data.message === 'URL is available' && customUrlLink.length > 3) {
        setIsProfileValid(true);
      } else {
        setIsProfileValid(false);
      }
    };
    checkIsValid();
  }, [customUrlLink]);

  const MiniModal = ({ open }: { open: boolean }) => {
    const modalRoot = document.getElementById('portal-root') as Element | DocumentFragment;
    if (!open || !modalRoot) return null;
    return ReactDOM.createPortal(
      <div className="modal--wrapper">
        <div className="custom__modal">
          <div className="modal__header">
            <div className="header__info">
              <h2 className="header--heading">Create Custom URL</h2>
              <p className="header--desc">Personalize the URL for your profile</p>
            </div>
            <button
              type="button"
              className="modal__close--btn"
              onClick={() => setIsMiniModalOpen(false)}
            >
              <img src={cross} alt="close" />
            </button>
          </div>
          <div className="modal__input">
            <div className="input__prefix">
              <span className="prefix--link">www.hyperlearn.in/</span>
            </div>
            <div className="input--url">
              <input
                key="url-input"
                type="text"
                className="input__custom-url"
                value={customUrlLink}
                onChange={handleUrlChange}
              />
              {
                urlEdited ? (
                  <img
                    src={isProfileValid ? notiSuccess : notiError}
                    alt="icon"
                    className="valid--icon"
                  />
                ) : null
              }
            </div>
          </div>
          <div className="modal__footer">
            <button
              type="button"
              onClick={handleUpdateUrl}
              disabled={!isProfileValid}
            >
              Update
            </button>
          </div>
        </div>
      </div>,
      // eslint-disable-next-line no-undef
      modalRoot,
    );
  };

  return (
    <div>
      <img
        onClick={() => {
          setIsMiniModalOpen(true);
        }}
        role="presentation"
        src={smallPlus}
        alt="add session"
        className="checklist__card--plus-icon"
      />

      {MiniModal({ open: isMiniModalOpen })}
    </div>
  );
}

export default CustomUrl;
