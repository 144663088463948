const loadScript = (src: string): Promise<boolean> => new Promise((resolve) => {
  // eslint-disable-next-line no-undef
  const script = document.createElement('script');
  script.src = src;
  script.onload = () => {
    resolve(true);
  };
  script.onerror = () => {
    resolve(false);
  };
  // eslint-disable-next-line no-undef
  document.body.appendChild(script);
});

export default loadScript;
