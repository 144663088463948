import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../manage/style.css';
import _, { truncate } from 'lodash';
import { leftArrow, rightArrow, XCircle } from '../../../BasicComponents/icons';
import NavbarContainer from '../../../BasicComponents/navbar';
import PreviewCard from '../../common/previewCard';
import WatchTutorialDesktop from '../watchTutorialDesktop';
import ConclusionDesktop from '../conclusionDesktop';
import ExitModal from '../../common/exitModal';

type arrowProps = {
  className: any;
  style: any;
  onClick: any;
}
// eslint-disable-next-line react/prop-types
const PrevArrow = ({ className, style, onClick }: arrowProps): JSX.Element => (
  <div
    role="presentation"
    className={className}
    style={{ ...style }}
    onClick={onClick}
  >
    <img src={leftArrow} alt="left arrow" className="slick-arrow slick-prev" />
  </div>
);
// eslint-disable-next-line react/prop-types
const NextArrow = ({ className, style, onClick }: arrowProps): JSX.Element => (
  <div
    role="presentation"
    className={className}
    style={{ ...style }}
    onClick={onClick}
  >
    <img src={rightArrow} alt="left arrow" className="slick-arrow slick-next" />
  </div>
);

type props = {
  data: any[],
  isLoading: boolean,
  setIsStart: (k: boolean) => boolean,
  startTimer: () => void,
  getCardIndex: (k: any) => any,
  oldData: { name: string; image: { url: string } },
  isStart: boolean,
  percentageCount: number,
  getTime: () => any,
}

// eslint-disable-next-line react/prop-types
const StudentCardView: React.FC<props> = ({
  data, isLoading, setIsStart, startTimer, getCardIndex, oldData, isStart,
  percentageCount, getTime,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isWatchTutorial, setIsWatchTutorial] = useState(false);
  const [isConclusion, setIsConclusion] = useState(false);
  const [exitModal, toggleExitModal] = useState(false);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    nextArrow: <NextArrow className={undefined} style={undefined} onClick={undefined} />,
    prevArrow: <PrevArrow className={undefined} style={undefined} onClick={undefined} />,
  };
  const slider = useRef<any>(null);

  const next = () => {
      slider.current?.slickNext();
      // eslint-disable-next-line no-unused-expressions, react/prop-types
      (currentPage < data?.length) && setCurrentPage(currentPage + 1);
  };

  const prev = () => {
    slider.current.slickPrev();
    // eslint-disable-next-line no-unused-expressions, react/prop-types
    setCurrentPage(currentPage - 1);
  };

  const exitFlow = () => {
    toggleExitModal(!exitModal);
  };

  return (
    <>
      <style>
        {`
      .progress {
        display: flex;
        height: 1rem;
        overflow: hidden;
        font-size: .75rem;
        background-color: #bbbcbd;
        border-radius: 0.25rem;
      }
      .anki__deck--name-onloading {
        padding: 10px;
        width: 100%;
        text-align: center;
      }
      .note__watch--tutorials-link{
        position: absolute;
        bottom: 50px;
        left: 60px;
      }
      `}
      </style>
      <div className="container-fluid mb-5">
        <div className="row">
          <NavbarContainer />
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="anki__card--slider-container">
            <div className="d-flex" style={{ justifyContent: 'space-between' }}>
              <span className="d-flex">
                <p style={{ color: '#8C52FF' }}>
                  {currentPage}
                  /
                  {/* eslint-disable-next-line react/prop-types */}
                  {data?.length}
                </p>
                <p className="mx-3">{truncate(oldData?.name, { length: 45 })}</p>
              </span>
              <XCircle size={28} onClick={() => exitFlow()} className="cursor-pointer" style={{ color: '#3b3b3b' }} />
            </div>
            {/* eslint-disable-next-line no-nested-ternary */}
            {isStart
              ? (
                !isConclusion
                  ? (
                    <Slider
                      // eslint-disable-next-line no-return-assign
                      ref={(c: any) => (slider.current = c)}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...settings}
                    >
                      {/* eslint-disable-next-line react/prop-types */}
                      {data && data?.map((card) => (
                        // eslint-disable-next-line react/jsx-key
                        <PreviewCard
                          answer={card?.answerText}
                          question={card?.questionText}
                          queImg={card?.questionImage}
                          ansImg={card?.answerImage}
                          hint={card?.hintText}
                          type="student view"
                          NextArrow={next}
                          PrevArrow={prev}
                          getTime={getTime}
                          getCardIndex={getCardIndex}
                          cardId={card?._id}
                          currentCard={currentPage}
                          totalCard={data?.length}
                          viewType="learing"
                          setIsConclusion={setIsConclusion}
                        />
                      ))}
                    </Slider>
                  ) : <ConclusionDesktop />
              ) : (
                !isWatchTutorial
                  ? (
                    <div className="previewCard__card--container mt-3 mb-3" style={{ margin: '0 auto' }}>
                      {_.get(oldData, 'image.url', '')
                        && <img src={_.get(oldData, 'image.url', '')} className="anki_preview--image-card" alt="" />}
                      <div className="anki__deck--name-onloading mt-5 mb-5">
                        <h6>{_.get(oldData, 'name', '')}</h6>
                      </div>
                      {isLoading && (
                        <div className="anki__deck--name-onloading">
                          <div className="progress" style={{ height: '5px', width: '90%' }}>
                            {/* eslint-disable-next-line react/self-closing-comp */}
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: `${percentageCount}%` }}
                              aria-valuenow={percentageCount}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            >
                            </div>
                          </div>
                        </div>
                      )}
                      {isLoading ? (
                        <p className="text-center">
                          Loading your note.
                        </p>
                      )
                        : (
                          <>
                            <p
                              role="presentation"
                              onClick={() => setIsWatchTutorial(true)}
                              className="note__watch--tutorials-link"
                            >
                              Watch Tutorial
                            </p>
                            <button
                              type="button"
                              className="previewCard__btn"
                              onClick={() => {
                                setIsStart(true);
                                startTimer();
                                // eslint-disable-next-line no-shadow
                                getCardIndex((prev: number) => prev + 1);
                              }}
                            >
                              Start
                            </button>
                          </>
                        )}
                    </div>
                  ) : <WatchTutorialDesktop {...{ setIsWatchTutorial }} />
              )}
          </div>
        </div>
      </div>
      {exitModal && <ExitModal {...{ exitModal, toggleExitModal }} />}
    </>
  );
};

export default StudentCardView;
