import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import './style.css';
import ReactCrop from 'react-image-crop';
import { useDispatch } from 'react-redux';
import FormData from 'form-data';
import { Field, Form, Formik } from 'formik';

import Select from 'react-select';
import Editor from '../../../BasicComponents/Editor';
import {
  back, PencilLine,
  greenImage, redCautionIcon, uploadPurple,
} from '../../../BasicComponents/icons';
import { hlbClient } from '../../../../Clients/hlbClient';
import { addToast } from '../../../../reducers/toast.reducer';
import SingleChoiceCardModal from './singleChoiceCardModal';
import Toggle from '../../../BasicComponents/toggle';
import TypeAnswerCardModal from './typeAnswerCardModal';
import Card from '../../learningFlow/Mobile/card';

type props = {
  toggleCreateCardModal: () => void,
  createCardModal: boolean,
  id: string,
  setPreviewData: (k: any) => any,
  editdata?: {
    hint?: string,
    answer?: any,
    question?: any,
    queImg?: { url: string, fileName: string },
    ansImg?: { url: string, fileName: string },
    cardType?: string,
    id?: string,
  },
  entityType: string,
  getMyDeck: () => void,
  toggleConfirmModal: () => void,
}

const CreateCardModal: React.FC<props> = ({
  toggleCreateCardModal, createCardModal,
  id, setPreviewData, editdata = {}, entityType, getMyDeck, toggleConfirmModal,
}) => {
  const [isWrittingAnswer, setIsWrittingAnswer] = useState<boolean>(false);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [hint, setHint] = useState<any>('');
  const [imageSize, setImageSize] = useState(0);
  const [imageSizeTwo, setImageSizeTwo] = useState(0);
  const [questionPhoto, setQuestionPhoto] = useState('');
  const [answerPhoto, setAnswerPhoto] = useState('');
  const [displayPic, setDisplayPic] = useState<any>('');
  const [displayPicTwo, setDisplayPicTwo] = useState<any>('');
  const [pic, setPic] = useState<string | []>([]);
  const [picTwo, setPicTwo] = useState<string | []>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [crop, setCrop] = useState<any>({ unit: 'px', width: 102, aspect: 1 / 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [isHint, setIsHint] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isUploadAnswer, setIsUploadAnswer] = useState(false);
  const [questionImageFlag, setQuestionImageFlag] = useState('');
  const [answerImageFlag, setAnswerImageFlag] = useState('');

  const [cardOption, setCardOption] = useState<{ value: string; label: string; }>({ value: 'review', label: 'Review card' });

  const [cropModal, setCropModal] = useState(false);

  useEffect(() => {
    if (isHint === false) {
      setHint('');
    }
    if (isUpload === false) {
      setPic([]);
    }
    if (isUploadAnswer === false) {
      setPicTwo([]);
    }
  }, [isHint, isUpload, isUploadAnswer]);

  useEffect(() => {
    setPreviewData({
      answer,
      question,
      hint,
      displayPic,
      displayPicTwo,
    });
  }, [answer, question, hint, displayPic, displayPicTwo]);

  useEffect(() => {
    if (entityType === 'edit') {
      if (editdata?.hint) {
        setIsHint(true);
        setHint(editdata?.hint);
      }
      if (editdata) {
        setAnswer(editdata?.answer);
        setQuestion(editdata?.question);
        setHint(editdata?.hint);
      }
      if (editdata?.queImg?.url) {
        setIsUpload(true);
        setDisplayPic(editdata?.queImg?.url);
        setQuestionPhoto(editdata?.queImg?.fileName);
        setPic(editdata?.queImg?.url);
      }
      if (editdata?.ansImg?.url) {
        setIsUploadAnswer(true);
        setDisplayPicTwo(editdata?.ansImg?.url);
        setAnswerPhoto(editdata?.ansImg?.fileName);
        setPicTwo(editdata?.ansImg?.url);
      }
    }
  }, [editdata, entityType]);

  useEffect(() => {
    if (entityType === 'add') {
      setQuestion('');
      setAnswer('');
      setHint('');
      setIsUploadAnswer(false);
      setDisplayPicTwo('');
      setAnswerPhoto('');
      setIsUpload(false);
      setDisplayPic('');
      setQuestionPhoto('');
      setPic([]);
      setPicTwo([]);
    }
  }, [entityType]);

  const uploadPicture = (e: any) => {
    if (isWrittingAnswer) {
      setAnswerPhoto(e.target.files[0].name);
      setImageSizeTwo(e.target.files[0].size / 1024 / 1024);
    } else {
      setQuestionPhoto(e.target.files[0].name);
      setImageSize(e.target.files[0].size / 1024 / 1024);
    }
  };
  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      // eslint-disable-next-line no-undef
      const reader: any = new window.FileReader();
      if (isWrittingAnswer) {
        reader.addEventListener('load', () => setDisplayPicTwo(reader.result));
        reader.readAsDataURL(e.target.files[0]);
      } else {
        reader.addEventListener('load', () => setDisplayPic(reader.result));
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  const uploadPicWithoutCrop = async (newdisplayPic: any) => {
    // eslint-disable-next-line no-undef
    const base64Response = await fetch(newdisplayPic);
    const blob = await base64Response.blob();
    // eslint-disable-next-line no-undef
    const imageName = isWrittingAnswer ? answerPhoto : questionPhoto;
    // eslint-disable-next-line no-undef
    const profilePic: any = new File([blob], imageName, { type: "'image/jpeg', 'image/peg', 'image/png'", lastModified: new Date().getTime() });
    if (isWrittingAnswer) {
      setPicTwo(profilePic);
    } else {
      setPic(profilePic);
    }
  };

  const onLoad = useCallback((img: any) => {
    imgRef.current = img;
  }, []);
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image: any = imgRef.current;
    const canvas: any = previewCanvasRef.current;
    // eslint-disable-next-line no-shadow
    const crop: any = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    // eslint-disable-next-line no-undef
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
  }, [completedCrop]);

  function saveProfilePic(canvas: any) {
    // If Size of Image is greater than 5 then it will show the error else processed to next step
    if (imageSize > 5 || imageSizeTwo > 5) {
      // eslint-disable-next-line no-console
      console.log('image size too big');
    } else {
      canvas.toBlob(
        (blob: any) => {
          if (isWrittingAnswer) {
            // eslint-disable-next-line no-undef
            const previewAnswerUrl = window.URL.createObjectURL(blob);
            setDisplayPicTwo(previewAnswerUrl);
            // eslint-disable-next-line no-undef
            const answerPic: any = new File([blob], answerPhoto, { type: "'image/jpeg', 'image/jpg', 'image/png'", lastModified: new Date().getTime() });
            setPicTwo(answerPic);
          } else {
            // eslint-disable-next-line no-undef
            const previewQuestionUrl = window.URL.createObjectURL(blob);
            setDisplayPic(previewQuestionUrl);
            // eslint-disable-next-line no-undef
            const questionPic: any = new File([blob], questionPhoto, { type: "'image/jpeg', 'image/jpg', 'image/png'", lastModified: new Date().getTime() });
            setPic(questionPic);
          }
        },
        answerPhoto,
        questionPhoto,
        1,
      );
    }
  }

  const [answerError, setAnswerError] = useState('');
  const [questionError, setQuestionError] = useState('');
  const [hintError, setHintError] = useState('');
  const [answerImageError, setAnswerImageError] = useState('');
  const [questionImageError, setQuestionImageError] = useState('');

  useEffect(() => {
    if (answer === '<p><br></p>') {
      setAnswer('');
    }
    if (question === '<p><br></p>') {
      setQuestion('');
    }
  }, [answer, question]);

  useEffect(() => {
    if (question !== '') {
      setQuestionError('');
    }
    if (answer !== '') {
      setAnswerError('');
    }
    if (hint !== '') {
      setHintError('');
    }
    if (displayPic?.length !== 0) {
      setQuestionImageError('');
    }
    if (displayPicTwo?.length !== 0) {
      setAnswerImageError('');
    }
  }, [answer, question, hint, displayPic?.length,
    displayPicTwo?.length, isHint, isUpload, isUploadAnswer]);

  const dispatch = useDispatch();
  const sumbitHandler = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('questionText', question);
    formData.append('answerText', answer);
    formData.append('hintText', hint);
    formData.append('questionImage', pic);
    formData.append('answerImage', picTwo);
    try {
      if (entityType === 'edit') {
        formData.append('questionImageFlag', questionImageFlag);
        formData.append('answerImageFlag', answerImageFlag);
        const resp = await hlbClient('multipart/form-data').put(`/api/srs/deck/${id}/card/${editdata.id}`, formData);
        setPic('');
        setQuestion('');
        setAnswer('');
        setHint('');
        if (resp?.data?.success) {
          dispatch(addToast({ type: 'success', message: resp?.data?.msg }));
          toggleCreateCardModal();
          getMyDeck();
        }
      } else {
        const resp = await hlbClient('multipart/form-data').post(`api/srs/deck/${id}/card/create`, formData);
        setPic('');
        setQuestion('');
        setAnswer('');
        setHint('');
        if (resp?.data?.success) {
          dispatch(addToast({ type: 'success', message: resp?.data?.msg }));
          getMyDeck();
          toggleCreateCardModal();
          toggleConfirmModal();
        }
      }
    } catch (err: any) {
      const message = err?.response?.data?.result?.msg;
      dispatch(addToast({ type: 'error', message }));
    }
    setIsLoading(false);
  };

  const [goNext, setGoNext] = useState(false);
  const [submitCard, setSubmitCard] = useState(false);

  useEffect(() => {
    if (!questionError && !hintError && !questionImageError) {
      setGoNext(true);
    } else {
      setGoNext(false);
    }
    if (!answerError && !answerImageError) {
      setSubmitCard(true);
    } else {
      setSubmitCard(false);
    }
  }, [questionError, hintError, questionImageError, answerImageError, answerError]);

  const checkQuestionValidation = () => {
    if (!questionError && !hintError && !questionImageError) {
      setIsWrittingAnswer(!isWrittingAnswer);
    }
  };

  const checkAnswerValidation = async () => {
    if (!answerError && !answerImageError) {
      sumbitHandler();
    }
  };

  useEffect(() => {
    if (question === '') {
      setQuestionError('Please enter your question*');
    } else {
      setQuestionError('');
    }
    if (isHint) {
      if (hint === '') {
        setHintError('Adding a hint is mandatory if the field is checked on.');
      } else {
        setHintError('');
      }
    } else {
      setHintError('');
    }
    if (isUpload) {
      if (displayPic.length === 0) {
        setQuestionImageError('Adding an image is mandatory if the field is checked on.');
      } else {
        setQuestionImageError('');
      }
    } else {
      setQuestionImageError('');
      setPic([]);
      setDisplayPic('');
    }
    if (answer === '') {
      setAnswerError('Please enter your answer*');
    } else {
      setAnswerError('');
    }
    if (isUploadAnswer) {
      if (displayPicTwo.length === 0) {
        setAnswerImageError('Adding an image is mandatory if the field is checked on.');
      } else {
        setAnswerImageError('');
      }
    } else {
      setAnswerImageError('');
      setPicTwo([]);
      setDisplayPicTwo('');
    }
  }, [question, isHint, hint, isUpload, displayPic?.length,
    answer, isUploadAnswer, displayPicTwo?.length]);
  const queImg = {
    url: displayPic || [],
  };
  const ansImg = {
    url: displayPicTwo || [],
  };
  const queImgFile = {
    file: displayPic || [],
  };
  const ansImgFile = {
    file: displayPicTwo || [],
  };
  useEffect(() => {
    if (isUpload) {
      setPic(editdata?.queImg?.url || pic);
      setDisplayPic(editdata?.queImg?.url || displayPic);
    }
    if (isUploadAnswer) {
      setPicTwo(editdata?.ansImg?.url || picTwo);
      setDisplayPicTwo(editdata?.ansImg?.url || displayPicTwo);
    }
  }, [isUpload, isUploadAnswer, editdata]);

  useEffect(() => {
    if (isUploadAnswer) {
      if (editdata?.ansImg?.url) {
        if (editdata?.ansImg?.url !== displayPicTwo) {
          setAnswerImageFlag('changed');
        } else {
          setAnswerImageFlag('');
        }
      } else {
        setAnswerImageFlag('changed');
      }
    } else {
      setAnswerImageFlag('removed');
    }
    if (isUpload) {
      if (editdata?.queImg?.url) {
        if (editdata?.queImg?.url !== displayPic) {
          setQuestionImageFlag('changed');
        } else {
          setQuestionImageFlag('');
        }
      } else {
        setQuestionImageFlag('changed');
      }
    } else {
      setQuestionImageFlag('removed');
    }
  }, [isUpload, isUploadAnswer, editdata, displayPicTwo, displayPic]);

  const initialValues = {
    questionText: question,
    answerText: answer,
    hintText: hint || '',
    questionImage: pic,
    answerImage: picTwo,
  };
  const handleSubmit = () => {
    sumbitHandler();
  };

  const ImagePreviousState = () => {
    if (!isWrittingAnswer) {
      setDisplayPic(editdata?.queImg?.url || '');
      setQuestionPhoto(editdata?.queImg?.fileName || '');
      setPic(editdata?.queImg?.url || []);
    } else {
      setDisplayPicTwo(editdata?.ansImg?.url || '');
      setAnswerPhoto(editdata?.ansImg?.fileName || '');
      setPicTwo(editdata?.ansImg?.url || []);
    }
  };
  const cardCreationOptions = [
    { value: 'review', label: 'Review card' },
    { value: 'mcq', label: 'MCQ card' },
    { value: 'writeAnswer', label: 'Write Answer card' },
  ];
  const customTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#9281fc',
      primary25: 'rgba(167, 118, 228, 0.08)',
      dangerLight: 'transparent',
      danger: '#1a1a1a',
      neutral50: '#C4C4C4',
    },
  });

  useEffect(() => {
    if (editdata?.cardType === 'mcq') {
      setCardOption({ value: 'mcq', label: 'MCQ card' });
    }
    if (editdata?.cardType === 'review') {
      setCardOption({ value: 'review', label: 'Review card' });
    }
    if (editdata?.cardType === 'writeAnswer') {
      setCardOption({ value: 'writeAnswer', label: 'Write Answer card' });
    }
  }, [editdata?.cardType]);

  return (
    <>
      <style>
        {`
      .create-card--preview-text {
        margin: 0px !important;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #444444;
      }
      .create-card--preview-info-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #828282;
      }
      .single-choice__preview .mobile__anki--btn-hard {
        width: 130px;
        height: 38px;
      }
      .single-choice__preview .mobile__anki--btn-easy {
        width: 130px;
        height: 38px;
      }
      .single-choice__preview button {
        font-size: 16px;
    }
    .single-choice__preview .mobile__anki--paragraph {
      font-size: .85em;
  }
      `}
      </style>
      <Modal show={createCardModal} fullscreen="sm" className="anki__modal--width-change-edit" centered onHide={() => toggleCreateCardModal()}>
        <Modal.Body>
          <div className="row">
            <div className="col-12 d-flex justify-content-space-between align-items-center">
              {!isWrittingAnswer && (
                <div style={{ width: '50%' }} className="mx-3">
                  <Select
                    options={cardCreationOptions}
                    theme={customTheme}
                    onChange={(values: any) => setCardOption(values)}
                    value={cardOption}
                  />
                </div>
              )}
              {isWrittingAnswer && (
                <span className="go-back cursor-pointer" role="presentation" onClick={() => setIsWrittingAnswer(!isWrittingAnswer)}>
                  <img src={back} alt="..." style={{ marginTop: '-5px' }} />
                  {' '}
                  Go back
                </span>
              )}
              <button
                type="button"
                onClick={() => toggleCreateCardModal()}
                className="btn-close float-end"
                style={{ width: '4px', height: '4px' }}
              />
            </div>
            {cardOption.value === 'review' ? (
              <>
                <div className="col-8 mt-3">
                  <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    enableReinitialize
                  >
                    <Form style={{ margin: '0px 20px' }}>
                      <div className="row">
                        <div className="col-12 mt-3">
                          <span className="create__card--heading">
                            <p>{isWrittingAnswer ? 'Answer' : 'Question'}</p>
                          </span>
                        </div>
                        <div className="col-md-9">
                          <p className="guide-text">
                            {isWrittingAnswer ? 'The answer shows up to the learners when they flip the question card.'
                              : 'The question will be displayed to the learners when they open a note.'}
                          </p>
                        </div>

                      </div>
                      {!isWrittingAnswer ? (
                        <div className="anki__form-box--deck-creaton">
                          {!isWrittingAnswer && (
                            <Editor
                              initialValue={question}
                              placeholder="Write your question..."
                              onTextChangeHandler={setQuestion}
                              id="question"
                            />
                          )}
                          <div className="col-12" style={{ marginTop: '4.5rem' }}>
                            {questionError && (
                              <label style={{ color: '#F71C1C', fontSize: '13px' }}>
                                <img src={redCautionIcon} alt=".." style={{ marginTop: '-2px' }} />
                                {' '}
                                {questionError}
                              </label>
                            )}
                          </div>
                          <div className="col-12" />
                          <div className="anki__deck-creation" style={{ display: 'flex', width: '100%' }}>
                            <div className="mt-2 mb-3" style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                              {isHint && (
                                <div className="col-md-12 d-flex justify-content-end">
                                  <small>
                                    {hint.length}
                                    /30
                                  </small>
                                </div>
                              )}
                              <div className="col-md-3 mb-2 d-flex align-item-center align-item-center">
                                <span className="d-flex align-item-center mt-2">
                                  <Toggle isChecked={isHint} onChangeHandler={setIsHint} />
                                  {' '}
                                  <span className="mx-1" style={{ fontSize: '14px' }}>Add your Hint here</span>
                                </span>
                              </div>
                              <div className="col-md-9 mb-2">
                                {isHint
                                  && (
                                    <Field
                                      name="hintText"
                                      type="text"
                                      value={hint}
                                      placeholder="You can add your hint here."
                                      className="anki__deck-creation--input"
                                      onChange={(e: any) => setHint(e.target.value)}
                                    />
                                  )}
                                {hintError && (
                                  <label style={{ color: '#F71C1C', fontSize: '13px' }}>
                                    <img src={redCautionIcon} alt=".." style={{ marginTop: '-2px' }} />
                                    {' '}
                                    {hintError}
                                  </label>
                                )}
                              </div>
                              <div className="col-md-3">
                                <span className="d-flex align-item-center mt-2">
                                  <Toggle isChecked={isUpload} onChangeHandler={setIsUpload} />
                                  {' '}
                                  <span className="mx-1" style={{ fontSize: '14px' }}>Add an Image</span>
                                </span>
                              </div>
                              <div className="col-md-9">
                                {isUpload
                                  && (
                                    <div className="anki__card-creation--container-upload">
                                      <span>
                                        <img src={greenImage} alt="..." className="mx-2" />
                                        {displayPic.length !== 0 ? (
                                          <>
                                            {questionPhoto?.slice(0, 15)}
                                            {questionPhoto?.length > 15 && '...'}
                                          </>
                                        ) : ''}
                                      </span>
                                      <label htmlFor="file" className="mx-2">
                                        {displayPic.length !== 0 ? (
                                          <span className="cursor-pointer">
                                            <PencilLine size={18} />
                                            {' '}
                                            <span className="purple">Change Image</span>
                                          </span>
                                        ) : (
                                          <span className="cursor-pointer">
                                            <img src={uploadPurple} alt="..." />
                                            {' '}
                                            <span className="purple">Upload Image</span>
                                          </span>
                                        )}
                                      </label>
                                      <input
                                        type="file"
                                        id="file"
                                        required
                                        onChange={(e) => {
                                          uploadPicture(e); onSelectFile(e); setCropModal(true);
                                        }}
                                        onClick={(event: any) => {
                                          // eslint-disable-next-line no-param-reassign
                                          event.target.value = null;
                                        }}
                                        accept="image/*"
                                      />
                                    </div>
                                  )}
                                {questionImageError && (
                                  <label style={{ color: '#F71C1C', fontSize: '13px' }}>
                                    <img src={redCautionIcon} alt=".." style={{ marginTop: '-2px' }} />
                                    {' '}
                                    {questionImageError}
                                  </label>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                        : (
                          <div className="anki__form-box--deck-creaton">
                            {isWrittingAnswer && (
                              <Editor
                                initialValue={answer}
                                placeholder="Write your Answer..."
                                onTextChangeHandler={setAnswer}
                                id="answer"
                              />
                            )}
                            <div className="col-12" style={{ marginTop: '4.5rem' }}>
                              {answerError && (
                                <label style={{ color: '#F71C1C', fontSize: '13px' }}>
                                  <img src={redCautionIcon} alt=".." style={{ marginTop: '-2px' }} />
                                  {' '}
                                  {answerError}
                                </label>
                              )}
                            </div>
                            <div className="anki__deck-creation mb-3" style={{ display: 'flex', width: '100%' }}>
                              <div className="mt-3 mb-3" style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                                <div className="col-md-3 mt-2">
                                  <span className="d-flex align-item-center">
                                    {/* eslint-disable-next-line max-len */}
                                    <Toggle isChecked={isUploadAnswer} onChangeHandler={setIsUploadAnswer} />
                                    {' '}
                                    <span className="mx-1" style={{ fontSize: '14px' }}>Add an Image</span>
                                  </span>
                                </div>
                                <div className="col-md-9">
                                  {isUploadAnswer
                                    && (
                                      <div className="anki__card-creation--container-upload">
                                        <span>
                                          <img src={greenImage} alt="..." className="mx-2" />
                                          {displayPicTwo.length !== 0 ? (
                                            <>
                                              {answerPhoto?.slice(0, 15)}
                                              {answerPhoto?.length > 15 && '...'}
                                            </>
                                          ) : ''}
                                        </span>
                                        <label htmlFor="file" className="mx-2">
                                          {displayPicTwo.length !== 0 ? (
                                            <span className="cursor-pointer">
                                              <PencilLine size={18} />
                                              {' '}
                                              <span className="purple">Change Image</span>
                                            </span>
                                          ) : (
                                            <span className="cursor-pointer">
                                              <img src={uploadPurple} alt="..." />
                                              {' '}
                                              <span className="purple">Upload Image</span>
                                            </span>
                                          )}
                                        </label>
                                        <input
                                          type="file"
                                          id="file"
                                          onChange={(e) => {
                                            uploadPicture(e); onSelectFile(e); setCropModal(true);
                                          }}
                                          onClick={(event: any) => {
                                            // eslint-disable-next-line no-param-reassign
                                            event.target.value = null;
                                          }}
                                          accept="image/*"
                                        />
                                      </div>
                                    )}
                                  {answerImageError && (
                                    <label style={{ color: '#F71C1C', fontSize: '13px' }}>
                                      <img src={redCautionIcon} alt=".." style={{ marginTop: '-2px' }} />
                                      {' '}
                                      {answerImageError}
                                    </label>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      <div className="anki__deck-creation--footer-create-card">
                        <div />
                        <div>
                          <button disabled={isLoading} type="button" className="btn anki__deck-creation-secondary" onClick={() => toggleCreateCardModal()}>Cancel</button>
                          {!isWrittingAnswer ? (
                            <span role="presentation" onClick={() => checkQuestionValidation()} className={goNext ? 'btn anki__deck-creation-primary mx-2' : 'btn anki__deck-creation-primary-grey mx-2'}>
                              {entityType === 'edit' ? 'Edit Answer' : 'Write Answer'}
                            </span>
                          ) : (
                            <button type="button" onClick={() => checkAnswerValidation()} className={submitCard ? 'btn anki__deck-creation-primary mx-2' : 'btn anki__deck-creation-primary-grey mx-2'}>
                              {isLoading && <Spinner animation="border" className="mt-1 mx-2" role="status" size="sm" />}
                              {entityType === 'edit' ? 'Save' : 'Create'}
                            </button>
                          )}

                        </div>
                      </div>
                    </Form>
                  </Formik>
                </div>
                <div className="col-4 mt-4">
                  <p className="create-card--preview-text">Preview</p>
                  <p className="create-card--preview-info-text mb-2">This is how your  card will look!</p>
                  <div className="single-choice__preview" style={{ width: '326px' }}>
                  <Card
                    answer={answer}
                    question={question}
                    queImg={queImgFile}
                    ansImg={ansImgFile}
                    hint={hint}
                    isWrittingAnswer={isWrittingAnswer}
                    type="preview only"
                  />
                  </div>
                </div>
              </>
              // eslint-disable-next-line max-len
            ) : (
              cardOption.value === 'mcq' ? (
                <SingleChoiceCardModal
                  entityType={entityType}
                  editdata={editdata}
                  toggleCreateCardModal={toggleCreateCardModal}
                  // setCardOption={setCardOption}
                  getMyDeck={getMyDeck}
                  toggleConfirmModal={toggleConfirmModal}
                />
              ) : (
                <TypeAnswerCardModal
                  entityType={entityType}
                  editdata={editdata}
                  toggleCreateCardModal={toggleCreateCardModal}
                  // setCardOption={setCardOption}
                  getMyDeck={getMyDeck}
                  toggleConfirmModal={toggleConfirmModal}
                />
              )
            )}
          </div>
        </Modal.Body>

      </Modal>

      {cropModal && (
        <Modal show={cropModal} centered backdrop="static">
          <style>
            {`
          .grey-btn {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            background: rgba(140, 82, 255, 0.26);
            border-radius: 4px;
            color: #8C52FF;
        }
          `}
          </style>
          <Modal.Body>
            <ReactCrop
              src={isWrittingAnswer ? displayPicTwo : displayPic}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c: any) => setCrop(c)}
              onComplete={(c: any) => setCompletedCrop(c)}
            />
            <div>
              <p style={{ color: 'red', textAlign: 'center' }}>
                <img src={redCautionIcon} alt=".." style={{ marginTop: '-2px' }} />
                {' '}
                GIFs will not work if you crop it.
              </p>
              <canvas
                ref={previewCanvasRef}
                style={{
                  width: Math.round(completedCrop ? 102 : 0),
                  height: Math.round(completedCrop ? 102 : 0),
                  margin: '8% 40% 1% 40%',
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                className="btn grey-btn mx-2"
                type="button"
                // eslint-disable-next-line max-len
                onClick={() => { setCropModal(false); ImagePreviousState(); }}
              >
                Cancel
              </button>
              <button
                className="btn purple-btn mx-2"
                type="button"
                // eslint-disable-next-line max-len
                onClick={() => { setCropModal(false); uploadPicWithoutCrop(isWrittingAnswer ? displayPicTwo : displayPic); }}
              >
                Save GIF
              </button>
              <button
                onClick={() => {
                  setCropModal(false);
                  saveProfilePic(previewCanvasRef.current);
                }}
                className="btn purple-btn"
                type="button"
              >
                {' '}
                Crop & Save

              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default CreateCardModal;
