import React, { useState } from 'react';
import {
  nextPurple,
  prevPurple,
  rightArrow,
  leftArrow,
} from '../../BasicComponents/icons';

export type PaginationProps = {
  // eslint-disable-next-line react/require-default-props
  recordsPerPage?: number;
  data: any;
  // eslint-disable-next-line react/require-default-props
  currentPage?: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const Pagination = ({
  recordsPerPage = 6, data, currentPage = 1, setCurrentPage,
}: PaginationProps) => {
  const [pageNumberLimit] = useState(3);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(3);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const pageNumbers = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= Math.ceil(data.length / recordsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pageNumbers.length > maxPageNumberLimit) {
    pageIncrementBtn = <span> &hellip; </span>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <span> &hellip; </span>;
  }

  return (
    <div className="sessions__pagination">
      <button type="button" className="pagination--button" onClick={handlePrevbtn} disabled={currentPage === pageNumbers[0]}>
        <img src={currentPage === pageNumbers[0] ? leftArrow : prevPurple} alt="previous" />
      </button>
      {pageDecrementBtn}
      {
            pageNumbers.map((page) => {
              if (page < maxPageNumberLimit + 1 && page > minPageNumberLimit) {
                return (
                  <button
                    type="button"
                    className={`sessions__pagination--page ${currentPage === page ? 'sessions__pagination--page-active' : ''}`}
                    onClick={() => setCurrentPage(page)}
                    key={page}
                  >
                    {page}
                  </button>
                );
              }
              return null;
            })
        }
      {pageIncrementBtn}
      <button type="button" className="pagination--button" onClick={handleNextbtn} disabled={currentPage === pageNumbers[pageNumbers.length - 1]}>
        <img src={currentPage === pageNumbers[pageNumbers.length - 1] ? rightArrow : nextPurple} alt="previous" />
      </button>
    </div>
  );
};

export default Pagination;
