import React from 'react';
import TypeAnswerFeatures from './TypeAnswerFeatures';
import ScqFeatures from './ScqFeatures';
import McqFeatures from './McqFeatures';
import ReviewFeatures from './ReviewFeatures';
import { featuresProps } from '../../types';

import './feature-styles.css';

export type TypeAnswerFeaturesType = Pick<
  featuresProps,
  'hint' | 'correctAnswer'
>;
export type ScqFeaturesType = Pick<featuresProps, 'hint' | 'options'>;
export type McqFeaturesType = Pick<featuresProps, 'hint' | 'options'>;
export type ReviewFeaturesType = Pick<featuresProps, 'hint'>;

const FeaturesRenderer = ({
  type,
  features,
  setFeatures,
}: {
  type: string;
  features: featuresProps;
  setFeatures: React.Dispatch<React.SetStateAction<featuresProps>>;
}) => {
  switch (type) {
    case 'typeanswerv2':
      return (
        <TypeAnswerFeatures
          features={features as TypeAnswerFeaturesType}
          setFeatures={
            setFeatures as React.Dispatch<
              React.SetStateAction<TypeAnswerFeaturesType>
            >
          }
        />
      );
    case 'singlechoicev2':
      return (
        <ScqFeatures
          features={features as ScqFeaturesType}
          setFeatures={
            setFeatures as React.Dispatch<React.SetStateAction<ScqFeaturesType>>
          }
        />
      );
    case 'multiplechoicev2':
      return (
        <McqFeatures
          features={features as McqFeaturesType}
          setFeatures={
            setFeatures as React.Dispatch<React.SetStateAction<McqFeaturesType>>
          }
        />
      );
    case 'reviewv2':
      return (
        <ReviewFeatures
          features={features as ReviewFeaturesType}
          setFeatures={
            setFeatures as React.Dispatch<
              React.SetStateAction<ReviewFeaturesType>
            >
          }
        />
      );
    default:
      return null;
  }
};

export default FeaturesRenderer;
