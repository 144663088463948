import React from 'react';
import { Link } from 'react-router-dom';
import { tickGreen } from '../icons';
import { button } from '../failureConfirmation/failureConfirmation';

type SuccessConfirmationProps = {
  heading: string;
  description: string;
  button1: button;
  button2: button;
  message: string;
}

const SuccessConfirmation = ({
  heading, description, button1, button2, message,
}: SuccessConfirmationProps) => (
  <div className="confirmation">
    <div className="confirmation__box">
      <div className="confirmation__head">
        <img src={tickGreen} alt="Successful" />
        <h3 className="confirmation__head--title">
          { heading }
        </h3>
        <p className="confirmation__head--description">
          { description }
          {' '}
          <span className="confirmation__head--message">
            {message}
          </span>
        </p>
      </div>
      {
        button1 || button2 ? (
          <div className="confirmation__links">
            { button1 && (
              <Link
                className="confirmation__link"
                to={button1?.url || ''}
                onClick={button1.handler}
              >
                {button1.btnName}
              </Link>
            )}
            { button2 && (
              <Link
                className="confirmation__link"
                to={button2?.url || ''}
                onClick={button2.handler}
              >
                {button2.btnName}
              </Link>
            )}
          </div>
        ) : null
      }
    </div>
  </div>
);

export default SuccessConfirmation;
