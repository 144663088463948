import React, { useEffect } from 'react';

const Privacy: React.FC = () => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="row">
      <div className="col-md-12">
        <h1 className="tearmandcondition--heading">
          Hyperlearn Privacy Policy
        </h1>
        <p className="tearmandcondition--text">
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit or make a purchase from
          <a href="https://www.hyperlearn.in">https://www.hyperlearn.in</a> (the
          “Site”).
        </p>
        <h3 className="tearmandcondition--sub-heading">
          PERSONAL INFORMATION WE COLLECT
        </h3>
        <p className="tearmandcondition--text">
          When you visit the Site, we automatically collect certain information
          about your device, including information about your web browser, IP
          address, time zone, and some of the cookies that are installed on your
          device. Additionally, as you browse the Site, we collect information
          about the individual web pages or products that you view, what
          websites or search terms referred you to the Site, and information
          about how you interact with the Site. We refer to this
          automatically-collected information as “Device Information.” We
          collect Device Information using the following technologies:
          <ul>
            <li>
              “Cookies” are data files that are placed on your device or
              computer and often include an anonymous unique identifier. For
              more information about cookies, and how to disable cookies, visit
              http://www.allaboutcookies.org.
            </li>
            <li>
              “Log files” track actions occurring on the Site, and collect data
              including your IP address, browser type, Internet service
              provider, referring/exit pages, and date/time stamps.
            </li>
            <li>
              “Web beacons,” “tags,” and “pixels” are electronic files used to
              record information about how you browse the Site.
            </li>
          </ul>
          Additionally when you make a purchase or attempt to make a purchase
          through the Site, we collect certain information from you, including
          your name, payment information (including credit card numbers), email
          address, and phone number. We refer to this information as “Order
          Information.” When we talk about “Personal Information” in this
          Privacy Policy, we are talking both about Device Information and Order
          Information.
        </p>

        <h3 className="tearmandcondition--sub-heading">
          HOW DO WE USE YOUR PERSONAL INFORMATION?
        </h3>
        <p className="tearmandcondition--text">
          We use the Order Information that we collect generally to fulfil any
          orders placed through the Site (including processing your payment
          information and providing you with invoices and/or order
          confirmations). Additionally, we use this Order Information to:
          Communicate with you;Screen our orders for potential risk or fraud;
          and When in line with the preferences you have shared with us, provide
          you with information or advertising relating to our products or
          services. We use the Device Information that we collect to help us
          screen for potential risk and fraud (in particular, your IP address),
          and more generally to improve and optimize our Site (for example, by
          generating analytics about how our customers browse and interact with
          the Site, and to assess the success of our marketing and advertising
          campaigns).
        </p>
        <h3 className="tearmandcondition--sub-heading">
          SHARING YOUR PERSONAL INFORMATION
        </h3>
        <p className="tearmandcondition--text">
          We share your Personal Information with third parties to help us use
          your Personal Information, as described above. We use Google Analytics
          to help us understand how our customers use the Site--you can read
          more about how Google uses your Personal Information here:{' '}
          <a href="https://www.google.com/intl/en/policies/privacy/">
            https://www.google.com/intl/en/policies/privacy/
          </a>
          . You can also opt-out of Google Analytics here:
          <a href="https://tools.google.com/dlpage/gaoptout">
            https://tools.google.com/dlpage/gaoptout
          </a>
          . Finally, we may also share your Personal Information to comply with
          applicable laws and regulations, to respond to a subpoena, search
          warrant or other lawful request for information we receive, or to
          otherwise protect our rights. You can opt out of targeted advertising
          <hr />
          by: FACEBOOK -{' '}
          <a href="https://www.facebook.com/settings/?tab=ads">
            https://www.facebook.com/settings/?tab=ads
          </a>{' '}
          <br />
          GOOGLE -{' '}
          <a href="https://www.google.com/settings/ads/anonymous">
            https://www.google.com/settings/ads/anonymous
          </a>{' '}
          <br />
          BING -
          <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">
            https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
          </a>
          Additionally, you can opt out of some of these services by visiting
          the Digital Advertising Alliance’s opt-out portal at:
          <a href="http://optout.aboutads.info/">
            http://optout.aboutads.info/
          </a>
          .
          <hr />
        </p>

        <h3 className="tearmandcondition--sub-heading">DO NOT TRACK</h3>

        <p className="tearmandcondition--text">
          Please note that we do not alter our Site’s data collection and use
          practices when we see a Do Not Track signal from your browser.
        </p>
        <h3 className="tearmandcondition--sub-heading">YOUR RIGHTS</h3>

        <p className="tearmandcondition--text">
          We value user privacy, if you want your personal information removed
          from our database, you can reach out to support@hyperlearn.in.
        </p>
        <h3 className="tearmandcondition--sub-heading">DATA RETENTION</h3>
        <p className="tearmandcondition--text">
          When you place an order through the Site, we will maintain your Order
          Information for our records unless and until you ask us to delete this
          information.
        </p>
        <h3 className="tearmandcondition--sub-heading">GOOGLE CALENDAR API</h3>
        <p className="tearmandcondition--text">
          If you wish to take advantage of seamless integration with Google
          Calendar, you may authorise Hyperlearn to access your Google Calendar.
          Google Calendar will request for following permission.
          <ul>
            <li>Read Calendar Events</li>
            <li>Add Calendar Events</li>
            <li>Edit Calendar Events</li>
            <li>Delete Calendar Events</li>
          </ul>
          Apart from above usage, we shall not be using Google Calendar APIs for
          any other purpose.
        </p>
        <h3 className="tearmandcondition--sub-heading">Log Files</h3>

        <p className="tearmandcondition--text">
          hyperlearn follows a standard procedure of using log files. These
          files log visitors when they visit websites. All hosting companies do
          this and a part of hosting services&apos; analytics. The information
          collected by log files include internet protocol (IP) addresses,
          browser type, Internet Service Provider (ISP), date and time stamp,
          referring/exit pages, and possibly the number of clicks. These are not
          linked to any information that is personally identifiable. The purpose
          of the information is for analyzing trends, administering the site,
          tracking users&apos; movement on the website, and gathering
          demographic information.
        </p>

        <h3 className="tearmandcondition--sub-heading">MINORS</h3>

        <p className="tearmandcondition--text">
          The Site is not intended for individuals under the age of 18.
        </p>

        <h3 className="tearmandcondition--sub-heading">CHANGES</h3>

        <p className="tearmandcondition--text">
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </p>

        <h3 className="tearmandcondition--sub-heading">CONTACT US</h3>

        <p className="tearmandcondition--text">
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e-mail at <a href="support@hyperlearn.in">support@hyperlearn.in</a>
        </p>

        <p className="tearmandcondition--text">
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers&apos;
          respective websites.
        </p>

        <h3 className="tearmandcondition--sub-heading">
          CCPA Privacy Rights (Do Not Sell My Personal Information)
        </h3>

        <p className="tearmandcondition--text">
          Under the CCPA, among other rights, California consumers have the
          right to:
        </p>
        <p className="tearmandcondition--text">
          Request that a business that collects a consumer&apos;s personal data
          disclose the categories and specific pieces of personal data that a
          business has collected about consumers.
        </p>
        <p className="tearmandcondition--text">
          Request that a business delete any personal data about the consumer
          that a business has collected.
        </p>
        <p className="tearmandcondition--text">
          Request that a business that sells a consumer&apos;s personal data,
          not sell the consumer&apos;s personal data.
        </p>
        <p className="tearmandcondition--text">
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>

        <h3 className="tearmandcondition--sub-heading">
          GDPR Data Protection Rights
        </h3>

        <p className="tearmandcondition--text">
          We would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following:
        </p>
        <p className="tearmandcondition--text">
          The right to access – You have the right to request copies of your
          personal data. We may charge you a small fee for this service.
        </p>
        <p className="tearmandcondition--text">
          The right to rectification – You have the right to request that we
          correct any information you believe is inaccurate. You also have the
          right to request that we complete the information you believe is
          incomplete.
        </p>
        <p className="tearmandcondition--text">
          The right to erasure – You have the right to request that we erase
          your personal data, under certain conditions.
        </p>
        <p className="tearmandcondition--text">
          The right to restrict processing – You have the right to request that
          we restrict the processing of your personal data, under certain
          conditions.
        </p>
        <p className="tearmandcondition--text">
          The right to object to processing – You have the right to object to
          our processing of your personal data, under certain conditions.
        </p>
        <p className="tearmandcondition--text">
          The right to data portability – You have the right to request that we
          transfer the data that we have collected to another organization, or
          directly to you, under certain conditions.
        </p>
        <p className="tearmandcondition--text">
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>

        <h3 className="tearmandcondition--sub-heading">
          Children&apos;s Information
        </h3>

        <p className="tearmandcondition--text">
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>

        <p className="tearmandcondition--text">
          hyperlearn does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
