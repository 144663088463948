import { SmileySad } from 'phosphor-react';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { exitMessage } from './textConstant';

    type props = {
        exitModal: boolean,
        toggleExitModal: Dispatch<SetStateAction<boolean>>;
        setIsConclusion?: Dispatch<SetStateAction<boolean>>;
      
      };
      
      const ExitPopup: React.FC<props> = ({ exitModal, toggleExitModal, setIsConclusion }) => {
        const exitFlow = () => {
          setIsConclusion?.(true);
        };
        const [textValue, setTextValue] = useState<{ content: string; heading: string } | null >(null);
      
        useEffect(() => {
          const data: { content: string; heading: string }[] = exitMessage;
          const len = data.length;
          const randomIndex: number = Math.floor(Math.random() * len);
          const randomContent = data[randomIndex];
          setTextValue(randomContent);
        }, []);
      
        return (
          <>
            <style>
              {`
            .anki__modal--width-change-edit>.modal-dialog {
              max-width: 360px !important
          }
          .note__exit--heading {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #4A4A4A;
          }
          .note__exit--content {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #444444;
          }
          .note__exit--cancel-btn {
            width: 140.2px;
            height: 35px;
            left: 58.85px;
            top: 429px;
            border: none;
            background: #D9D9D9;
            border-radius: 8px;
            transition: .2s ease;
          }
          .note__exit--cancel-btn:hover {
            transform:translateY(-2px);
          }
          .note__exit--close-btn {
            width: 137.22px;
            height: 35px;
            left: 215.95px;
            top: 429px;
            border: none;
            background: #8C52FF;
            border-radius: 8px;
            color: #fff;
            transition: .2s ease;
          }
          .note__exit--close-btn:hover {
            transform:translateY(-2px);
          }
          .btn-container {
            display: flex;
            justify-content: space-around;
            align-item: center;
          }
          .modal.show .modal-dialog {
            margin: 0 auto;
          }
          `}
      
            </style>
            <Modal show={exitModal} fullscreen="sm" className="anki__modal--width-change-edit" centered onHide={() => toggleExitModal(false)}>
              <Modal.Body>
                <div className="text-center">
                  <SmileySad style={{ color: '#F8981D' }} size={55} />
                  <h4 className="note__exit--heading mt-2">{textValue?.heading}</h4>
                  <p className="note__exit--content mt-2">{textValue?.content}</p>
                </div>
                <div className="btn-container">
                  <button
                    type="button"
                    onClick={() => toggleExitModal(false)}
                    className="note__exit--cancel-btn mt-3"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="note__exit--close-btn mt-3"
                    onClick={() => exitFlow()}
                  >
                    Exit
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          </>
        );
}

export default ExitPopup;