import React from 'react';
import { Link } from 'react-router-dom';

import MentorImage from './mentorCardImage/mentor1.svg';
import './becomeAMentor.css';

const BecomeAMentor: React.FC = () => {
  return (
    <>
      <div className="become-a-mentor__container">
        <div className="become-a-mentor__wrapper">
          <img
            src={MentorImage}
            alt="mentor image"
            className="become-a-mentor__mentor-image"
          />
          <div className="become-a-mentor__contents">
            <div className="become-a-mentor__primary-text">
              Teach with Hyperlearn
            </div>
            <div className="become-a-mentor__secondary-text">
              Join the global community of mentors on Hyperlearn and unlock the
              tools and skills to share your passion.
            </div>
            <Link to="/" className="btn become-a-mentor__btn">
              Get started
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default BecomeAMentor;
