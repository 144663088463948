import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { searchIcon } from '../BasicComponents/icons';
import { CourseContext } from '../../provider/CourseContext';
import { updateShowcaseConfig } from '../../reducers/showcaseConfig.reducer';

import './landingInputField.css';

const LandingInputField: React.FC = () => {
  const [key, setKey] = useState('');
  const { setSearchKeyword } = useContext(CourseContext);
  const history = useHistory();
  const dispatch = useDispatch();

  const submitHandler = async (e: any) => {
    e.preventDefault();
    if (key) {
      setSearchKeyword(key);
      history.push(`/myDashboard/showcase`);
      dispatch(
        updateShowcaseConfig({
          title: `${key.charAt(0).toUpperCase() + key.slice(1)} Notes`,
          entityType: 'note',
          search: [key],
        })
      );
    }
  };

  return (
    <>
      <div className="landing__input--container">
        <img
          src={searchIcon}
          alt="search icon"
          className="landing__input--search-icon-img"
        />
        <input
          type="text"
          className="landing__input-field"
          placeholder="Search for anything..."
          value={key}
          onChange={(e) => setKey(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') submitHandler(e);
          }}
        />
      </div>
    </>
  );
};

export default LandingInputField;
