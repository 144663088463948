/* eslint-disable no-unused-expressions */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react';
import { useDispatch } from 'react-redux';
import './MentorDetailForm.css';
import FormData from 'form-data';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {
  userImage,
  redCautionIcon,
  cross,
  progressBarStep1,
} from '../BasicComponents/icons';
import { hlbClient } from '../../Clients/hlbClient';
import { spokenLanguages } from '../Constants';
import TaggedInput from './taggedInput';
import { addToast } from '../../reducers/toast.reducer';
import NavbarContainer from '../BasicComponents/navbar/navbarContainer';
import Editor from '../BasicComponents/Editor';
import WhatsappPlugin from '../BasicComponents/WhatsappPlugin';
import { AuthContext } from '../../provider/AuthContext';
import { GroupBase } from 'react-select';

type MentorDetailFormProps = {
  entityType: string;
  data: {
    sessionName: string;
    sessionType: string;
    price: number;
    markedPrice: number;
    duration: number;
    description: string;
  };
  apiCall: VoidFunction;
};

const MentorDetailForm: React.FC<MentorDetailFormProps> = ({
  entityType,
  data,
  apiCall,
}) => {
  const [currentCompany, setCurrentCompany] = useState<any[]>([]);
  const [searchCompany, setSearchCompany] = useState<string>('');
  const [skills, setSkills] = useState<string[]>(_.get(data, 'skills', []));
  const [organizations, setOrganizations] = useState<any[]>(
    _.get(data, 'organisations', [])
  );
  const [experience, setExperience] = useState<any>(
    _.get(data, 'expInYears', 'Select Experience')
  );
  const [experienceError, setExperienceError] = useState<string>('');
  const [shortBio, setShortBio] = useState<string>(_.get(data, 'shortBio', ''));
  const [shortBioError, setShortBioError] = useState<string>('');
  const [userPhoto, setUserPhoto] = useState<string>('');
  const [displayPic, setDisplayPic] = useState<string>(
    _.get(data, 'profilePic.url', '')
  );
  const [pic, setPic] = useState<any>(_.get(data, 'profilePic.url', ''));
  const [crop, setCrop] = useState<{
    unit?: 'px' | '%' | undefined;
    width?: number | undefined;
    aspect?: number | undefined;
  }>({ unit: 'px', width: 102, aspect: 1 / 1 });
  const [completedCrop, setCompletedCrop] = useState<any>(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [languages, setLanguages] = useState<string[]>(
    _.get(data, 'languages', [])
  );
  const [languagesError, setLanguagesError] = useState<string>('');
  const [description, setDescripton] = useState<string>(
    _.get(data, 'description', '')
  );
  const [cropModal, setCropModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageSize, setImageSize] = useState<number>(0);

  const history = useHistory();

  // to get proper values of coming languages
  let found: any = [];
  if (languages) {
    // eslint-disable-next-line no-restricted-syntax
    for (const lang of languages) {
      found = [...found, spokenLanguages.find((el) => el.label === lang)];
    }
  }
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [defaultLang, setDefaultLang] = useState(found);
  const [characterCounts, setCharacterCounts] = useState<number>(50);
  const [name, setName] = useState<string>(_.get(data, 'userName', ''));
  const [email, setEmail] = useState<string>(_.get(data, 'email', ''));
  const [nameError, setNameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [imgErrorModal, setImgErrorModal] = useState<boolean>(false);
  const emailValidate: boolean =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/.test(
      email
    );

  let filled = false;
  // eslint-disable-next-line no-nested-ternary
  entityType === 'editPage'
    ? name !== '' &&
      experience !== 'Select Experience' &&
      languages.length !== 0 &&
      pic &&
      shortBio !== ''
      ? (filled = true)
      : (filled = false)
    : name !== '' &&
      experience !== 'Select Experience' &&
      languages.length !== 0 &&
      pic &&
      shortBio !== '' &&
      emailValidate
    ? (filled = true)
    : (filled = false);

  const checkValidation = () => {
    if (name === '' || name.length <= 2) {
      setNameError('Enter Name*');
    } else {
      setNameError('');
    }
    if (experience === 'Select Experience') {
      setExperienceError('Enter Exprience');
    } else {
      setExperienceError('');
    }
    if (languages.length === 0) {
      setLanguagesError('Enter atleat one language');
    } else {
      setLanguagesError('');
    }
    if (shortBio === '') {
      setShortBioError('Enter Tagline');
    } else {
      setShortBioError('');
    }
    if (!emailValidate) {
      setEmailError('Enter Valid Email');
    } else {
      setEmailError('');
    }
  };
  useEffect(() => {
    if (name.length >= 3) {
      setNameError('');
    }
    if (email !== '') {
      setEmailError('');
    }
    if (shortBio !== '') {
      setShortBioError('');
    }
    if (experience !== 'Select Experience') {
      setExperienceError('');
    }
  }, [name, email, shortBio, experience]);
  useEffect(() => {
    setCharacterCounts(50 - shortBio.length);
  }, [shortBio]);
  useEffect(() => {
    async function fetchData() {
      try {
        const resp = await hlbClient().get(
          `/api/utils/companySearch?name=${searchCompany}`
        );
        setCurrentCompany(resp.data.companies);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('oops something went wrong');
      }
    }
    fetchData();
  }, [`${searchCompany}`]);

  const addSkills = (event: any) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      setSkills([...skills, event.target.value]);
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  const removeSkills = (index: number) => {
    setSkills([...skills.filter((skill) => skills.indexOf(skill) !== index)]);
  };

  const addOrganization = (event: any) => {
    if (event !== '') {
      setOrganizations([
        ...organizations,
        { name: event.name, url: event.logo, domain: event.domain },
      ]);
      setCurrentCompany([]);
      setSearchCompany('');
    }
  };

  const removeOrganization = (index: number) => {
    setOrganizations([
      ...organizations.filter(
        (organization) => organizations.indexOf(organization) !== index
      ),
    ]);
  };
  const uploadFile = async (e: any) => {
    setUserPhoto(e.target.files[0].name);
    setImageSize(e.target.files[0].size / 1024 / 1024);
  };

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      // eslint-disable-next-line no-undef
      const reader = new window.FileReader();
      reader.addEventListener('load', () =>
        setDisplayPic(reader.result ? reader.result.toString() : '')
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const onLoad = useCallback((img: any) => {
    imgRef.current = img;
  }, []);
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image: any = imgRef.current;
    const canvas: any = previewCanvasRef.current;
    // eslint-disable-next-line no-shadow
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    // eslint-disable-next-line no-undef
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);
  function saveProfilePic(canvas: any) {
    // If Size of Image is greater than 5 then it will show the error else processed to next step
    if (imageSize > 5) {
      setImgErrorModal(true);
    } else {
      canvas.toBlob(
        (blob: any) => {
          // eslint-disable-next-line no-undef
          const previewUrl = window.URL.createObjectURL(blob);
          setDisplayPic(previewUrl);
          // eslint-disable-next-line no-undef
          const profilePic = new File([blob], userPhoto, {
            // type: ['image/jpeg', 'image/peg', 'image/png'],
            // lastModified: new Date(),
          });
          setPic(profilePic);
        },
        userPhoto,
        1
      );
    }
  }

  const formHandler = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append('expInYears', experience);
    formData.append('name', name);
    formData.append('email', email);
    formData.append('image', pic);
    formData.append('description', description);
    formData.append('shortBio', shortBio);
    formData.append('languages', JSON.stringify(languages));
    formData.append('skills', JSON.stringify(skills));
    formData.append('organisations', JSON.stringify(organizations));
    // eslint-disable-next-line no-undef
    const method =
      entityType === 'editPage'
        ? hlbClient('multipart/form-data').put('/api/users/profile', formData)
        : hlbClient('multipart/form-data').post(
            '/api/onboarding/profile',
            formData
          );
    try {
      await method;
      setExperience('');
      setLanguages([]);
      setOrganizations([]);
      setSkills([]);
      setPic('');
      setDescripton('');
      entityType === 'editPage' ? '' : history.push('/onboarding/firstSession');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');
    }
    if (entityType === 'editPage') {
      apiCall();
      dispatch(
        addToast({ type: 'success', message: 'Profile Edited Successfully' })
      );
    }
    setIsLoading(false);
  };

  const { authState, setStateUpdate, stateUpdate } = useContext(AuthContext);

  // eslint-disable-next-line no-undef
  const token = window.localStorage.getItem('token');
  useEffect(() => {
    if (token) {
      setStateUpdate(!stateUpdate);
    }
  }, [token]);
  // eslint-disable-next-line camelcase
  const { profile, user_type } = authState;
  // eslint-disable-next-line camelcase
  if (user_type === 'mentor' && profile) {
    if (entityType !== 'editPage') {
      history.push('/dashboardV2');
    }
  }
  return (
    <>
      {entityType !== 'editPage' && (
        <div className="container-fluid">
          <div className="row">
            <NavbarContainer />
          </div>
        </div>
      )}
      <div
        className={
          entityType !== 'editPage'
            ? 'container-fluid background-form'
            : 'container-fluid'
        }
      >
        <div className="row d-flex justify-content-center">
          <div
            className={
              entityType !== 'editPage'
                ? 'Mentor__detail__form--sub-container Mentor__detail--top-margin mb-5'
                : 'Mentor__detail__form--sub-container mt-4'
            }
          >
            <div
              className={
                entityType !== 'editPage'
                  ? 'mentor-form-box form-container'
                  : ''
              }
            >
              {entityType !== 'editPage' && (
                <>
                  <a
                    href="/dashboardV2"
                    className="close-button"
                    data-toggle="tooltip"
                    title="Close"
                  >
                    <img src={cross} alt="close" />
                  </a>
                  <h1>Complete your mentor profile</h1>
                  <div className="mt-5 mb-5 text-center">
                    <img
                      src={progressBarStep1}
                      alt="..."
                      className="img-fluid"
                    />
                  </div>
                </>
              )}
              {/* <button type="button" className="btn signUp-with-linkedin text-white mt-3 mb-3">
                <span style={{ marginRight: '10px' }}>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                    d="M0 2.292C0 1.026 1.052 0 2.35 0H29.65C30.948 0 32 1.026 32 2.292V29.708C32
                     30.974 30.948 32 29.65 32H2.35C1.052 32 0
                     30.974 0 29.708V2.292ZM9.886 26.788V12.338H5.084V26.788H9.886ZM7.486
                     10.364C9.16 10.364 10.202 9.256 10.202 7.868C10.172 6.45 9.162 5.372
                     7.518 5.372C5.874 5.372 4.8 6.452 4.8 7.868C4.8 9.256 5.842 10.364
                     7.454 10.364H7.486ZM17.302 26.788V18.718C17.302 18.286 17.334 17.854
                     17.462 17.546C17.808 16.684 18.598 15.79 19.926 15.79C21.664 15.79 22.358
                     17.114 22.358 19.058V26.788H27.16V18.5C27.16 14.06 24.792 11.996 21.632
                     11.996C19.084 11.996 17.942 13.396 17.302 14.382V14.432H17.27C17.2806 14.4153
                     17.2913 14.3986 17.302 14.382V12.338H12.502C12.562 13.694 12.502 26.788 12.502
                     26.788H17.302Z" fill="white" fillOpacity="0.85" />
                  </svg>
                </span>
                {'  '}
                AutoFill with LinkedIn
                {' '}
              </button>
              <p>Or fill in the below form manually</p> */}
              <form
                onSubmit={formHandler}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault();
                }}
                style={{ marginLeft: '0' }}
              >
                <label
                  htmlFor="upload Image"
                  style={{ display: 'block' }}
                  className="form-label__v2"
                >
                  Profile Picutre
                </label>
                <img
                  src={displayPic || userImage}
                  alt=".."
                  className="image-round"
                />
                <div className="" style={{ display: 'inline-block' }}>
                  <label htmlFor="file">
                    <span className="btn upload-button">Upload Photo</span>
                  </label>
                  <input
                    type="file"
                    id="file"
                    onChange={(e) => {
                      uploadFile(e);
                      onSelectFile(e);
                      setCropModal(true);
                    }}
                    accept="image/*"
                  />
                </div>
                <p
                  style={{
                    margin: '10px',
                    fontSize: '13px',
                    color: '#8c52ff',
                  }}
                >
                  Upto 5mb. PNG and <br />
                  JPEG images supported.
                </p>
                <div className="row mt-4">
                  <div className="col-md-12 mb-2">
                    <label htmlFor="exampleDataList" className="form-label__v2">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-input"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      placeholder="Enter your name"
                      style={{ border: `${nameError && '1px solid #F71C1C'}` }}
                    />
                    {nameError && (
                      <label style={{ color: '#F71C1C' }}>
                        <img
                          src={redCautionIcon}
                          alt=".."
                          style={{ marginTop: '-2px' }}
                        />{' '}
                        {nameError}
                      </label>
                    )}
                  </div>
                  <div className="col-md-12 mb-2">
                    <label htmlFor="exampleDataList" className="form-label__v2">
                      Email
                    </label>
                    <input
                      type="Email"
                      className="form-input"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      placeholder="Enter your email"
                      style={{ border: `${emailError && '1px solid #F71C1C'}` }}
                      disabled={entityType === 'editPage'}
                    />
                    {emailError && (
                      <label style={{ color: '#F71C1C' }}>
                        <img
                          src={redCautionIcon}
                          alt=".."
                          style={{ marginTop: '-2px' }}
                        />{' '}
                        {emailError}
                      </label>
                    )}
                  </div>
                  <div className="col-md-12 mb-2">
                    <label
                      htmlFor="exampleDataList"
                      className="form-label__v2 mb-1"
                    >
                      Languages
                    </label>
                    <TaggedInput
                      // eslint-disable-next-line no-unneeded-ternary
                      previousValue={defaultLang ? defaultLang : null}
                      setLanguages={setLanguages}
                      options={
                        spokenLanguages as unknown as GroupBase<string>[]
                      }
                      placeholder="Enter Languages"
                      // style={{
                      //   border: `${languagesError && '1px solid #F71C1C'}`,
                      // }}
                    />
                    {/* <div className="tags-input">
                    <ul id="tags">
                      {languages && languages.map((language, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                        <li key={index} className="tag">
                          <span>{language}</span>
                          <i
                            role="button"
                            tabIndex={0}
                            className="fa fa-cross"
                            onClick={() => removeLanguages(index)}
                          >
                            <img src={cross} alt="delete" className="img-fluid mx-2" />
                          </i>
                        </li>
                      ))}
                    </ul>
                    {languages.length !== 3 && (
                    <input
                      type="text"
                      onKeyPress={(event) => {
                        // eslint-disable-next-line no-unused-expressions
                        event.target.keyCode === 13 && event.preventDefault();
                        addLanguages(event);
                      }}
                      placeholder={languages.length === 0 ? 'Enter Languages' : ''}
                    />
                    )}
                  </div> */}
                  </div>
                  <div className="col-md-12 mb-2">
                    <label htmlFor="exampleDataList" className="form-label__v2">
                      Experience
                    </label>
                    <select
                      className="form-select form-input"
                      style={{
                        border: `${experienceError && '1px solid #F71C1C'}`,
                      }}
                      aria-label="Default select example"
                      onChange={(e) => {
                        setExperience(e.target.value);
                      }}
                    >
                      <option
                        defaultValue={experience}
                        style={{ color: '#C4C4C4' }}
                      >
                        {experience > 10 ? '10+ Years' : experience}
                      </option>
                      <option value="1">1 Year</option>
                      <option value="2">2 Years</option>
                      <option value="3">3 Years</option>
                      <option value="4">4 Years</option>
                      <option value="5">5 Years</option>
                      <option value="6">6 Years</option>
                      <option value="7">7 Years</option>
                      <option value="8">8 Years</option>
                      <option value="9">9 Years</option>
                      <option value="10">10 Years</option>
                      <option value="11">10+ Years</option>
                    </select>
                    {experienceError && (
                      <label style={{ color: '#F71C1C' }}>
                        <img
                          src={redCautionIcon}
                          alt=".."
                          style={{ marginTop: '-2.5px' }}
                        />{' '}
                        {experienceError}
                      </label>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label
                      htmlFor="exampleDataList"
                      className="form-label__v2 mb-1"
                    >
                      Organization
                    </label>
                    <div className="tags-input">
                      <ul id="tags">
                        {organizations &&
                          organizations.map((organization, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <li key={index} className="tag">
                              <img
                                src={organization.url}
                                alt="logo"
                                className="img-fluid mx-2"
                                style={{ width: '20px' }}
                              />
                              <span>{organization.name}</span>
                              <i
                                role="button"
                                tabIndex={0}
                                className="fa fa-cross"
                                onClick={() => removeOrganization(index)}
                              >
                                <img
                                  src={cross}
                                  alt="delete"
                                  className="img-fluid mx-2"
                                  style={{ marginTop: '-2px' }}
                                />
                              </i>
                            </li>
                          ))}
                      </ul>
                      {organizations.length !== 10 && (
                        <input
                          type="text"
                          value={searchCompany}
                          onChange={(e) => {
                            setSearchCompany(e.target.value);
                          }}
                          placeholder={
                            organizations.length === 0
                              ? 'Enter Organization'
                              : ''
                          }
                        />
                      )}
                    </div>
                    {searchCompany !== '' && currentCompany && (
                      <div className="company-name-container">
                        {currentCompany.map((company, index) => (
                          <a
                            key={index}
                            type="button"
                            role="button"
                            tabIndex={0}
                            onClick={() => addOrganization(company)}
                            onKeyDown={() => addOrganization(company)}
                          >
                            <div className="row">
                              <div className="col-2">
                                <img
                                  src={company.logo}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="col-10">
                                <p>{company.name}</p>
                              </div>
                            </div>
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label
                      htmlFor="exampleDataList"
                      className="form-label__v2 mb-1"
                    >
                      Skills
                    </label>
                    <div className="tags-input">
                      <ul id="tags">
                        {skills &&
                          skills.map((skill, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <li key={index} className="tag">
                              <span>{skill}</span>
                              <i
                                role="button"
                                tabIndex={0}
                                className="fa fa-cross"
                                onClick={() => removeSkills(index)}
                              >
                                <img
                                  src={cross}
                                  alt="delete"
                                  className="img-fluid mx-2"
                                  style={{ marginTop: '-2px' }}
                                />
                              </i>
                            </li>
                          ))}
                      </ul>
                      {skills.length !== 10 && (
                        <input
                          type="text"
                          onKeyPress={(event) => {
                            // eslint-disable-next-line no-unused-expressions
                            event.key === 'Enter' && event.preventDefault();
                            addSkills(event);
                          }}
                          placeholder={
                            skills.length === 0 ? 'Add upto 10 skills' : ''
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 mb-2">
                    <label htmlFor="exampleDataList" className="form-label__v2">
                      Tagline
                    </label>
                    <label
                      htmlFor="exampleDataList"
                      className="counter-label__v2 float-end mt-2"
                    >
                      {characterCounts}
                      /50
                    </label>
                    <input
                      type="text"
                      className="form-input"
                      maxLength={50}
                      value={shortBio}
                      onChange={(e) => {
                        setShortBio(e.target.value);
                      }}
                      placeholder="Enter your tagline"
                      style={{
                        border: `${shortBioError && '1px solid #F71C1C'}`,
                      }}
                    />
                    {shortBioError && (
                      <label style={{ color: '#F71C1C' }}>
                        <img
                          src={redCautionIcon}
                          alt=".."
                          style={{ marginTop: '-2px' }}
                        />{' '}
                        {shortBioError}
                      </label>
                    )}
                  </div>
                  <div className="col-md-12 mb-5">
                    <label
                      htmlFor="exampleDataList"
                      className="form-label__v2 mb-1"
                    >
                      Bio
                    </label>
                    <Editor
                      initialValue={description}
                      placeholder="Tell us about yourself"
                      onTextChangeHandler={setDescripton}
                      id={email + 'detailedInfo'}
                    />
                  </div>
                  <div className="col-md-12 mt-2">
                    {filled ? (
                      <button
                        type="submit"
                        className="btn btn-primary__v2 mt-4"
                      >
                        {isLoading === false ? (
                          'Save & Continue'
                        ) : (
                          <Spinner animation="border" role="status" />
                        )}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-grey__v2 mt-4"
                        onClick={checkValidation}
                      >
                        Save & Continue
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal show={cropModal} backdrop="static" onHide={() => null}>
        <Modal.Body>
          <ReactCrop
            src={displayPic}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
          />
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                width: Math.round(completedCrop ? 102 : 0),
                height: Math.round(completedCrop ? 102 : 0),
                margin: '8% 40% 1% 40%',
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              setCropModal(false);
              saveProfilePic(previewCanvasRef.current);
            }}
            className="btn purple-btn"
            type="button"
          >
            {' '}
            Save
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={imgErrorModal} backdrop="static" onHide={() => null}>
        <Modal.Body>
          <div className="image-warning-container">
            <h1 className="image-heading-error">Image size too big</h1>
            <p className="image-description-error">
              Select a file less than 5MB
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              setImgErrorModal(false);
            }}
            className="btn purple-btn"
            type="button"
          >
            {' '}
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <WhatsappPlugin />
    </>
  );
};

export default MentorDetailForm;
