/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

function timeCopier({
  weekDays, weekday, index, days, setDays, setGetCopyBox,
}) {
  const [getTo, setGetTo] = useState([]);

  const CopyTo = (e, copyTo) => {
    if (e.target.checked) {
      setGetTo([...getTo, copyTo]);
    }
    if (!e.target.checked) {
      setGetTo(getTo.filter((copy) => copy !== copyTo));
    }
  };

  const copyAllTime = () => {
    const newDays = { ...days };
    getTo.forEach(
      (copyTimeTo) => {
        newDays[(copyTimeTo + 1) % 7].slots = [...newDays[(index + 1) % 7].slots];
        setDays(newDays);
        newDays[(copyTimeTo + 1) % 7].isAvailable = true;
      },
    );
    setGetCopyBox('');
  };

  const dayMap = {
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday',
  };

  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // eslint-disable-next-line max-len
      if (ref.current && !ref.current.contains(e.target)) {
        setGetCopyBox('');
      }
    };

    window.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      window.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, []);
  return (
    <ul
      className="dropdown-menu text-center mt-2"
      aria-labelledby="dropdownMenuButton1"
      ref={ref}
      style={{ display: 'block' }}
    >
      {weekDays.map((day, ind) => (
        <>
          {day === weekday
            ? (
              <li className="timeCopier-checkbox">
                <div className="col-10">{dayMap[day]}</div>
                <div className="col-2">
                  <input type="checkbox" checked className="timeCopier__checkbox-btn" disabled onChange={(e) => CopyTo(e, ind < index ? ind : ind + 1)} />
                </div>
              </li>
            )
            : (
              <li className="timeCopier-checkbox">
                <div className="col-10">{dayMap[day]}</div>
                <div className="col-2">
                  <input type="checkbox" className="timeCopier__checkbox-btn" onChange={(e) => CopyTo(e, ind)} />
                </div>
              </li>
            )}
        </>
      ))}
      <li><button className="btn btn-apply-timing" type="button" onClick={() => copyAllTime()}>Apply</button></li>
    </ul>
  );
}

timeCopier.propTypes = {
  weekDays: PropTypes.string.isRequired,
  days: PropTypes.string.isRequired,
  weekday: PropTypes.string.isRequired,
  setDays: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  setGetCopyBox: PropTypes.number.isRequired,
};

export default timeCopier;
