import React, { useEffect, useRef, useState } from 'react';
import "./AuthModal.css";
import "../Login/Login.css";
import { AuthInput } from '../components/AuthInput';
import { AuthButton } from '../components/AuthButton';
import { noop } from 'lodash';
import { EMAIL_REGEX, handleEmailSubmission, handlePhoneOtpSubmission, handlePhoneSubmission, handleSignup, maskPhoneNumber } from '../utils';
import { ResendOtp } from '../components/ResendOtp';
import { Link } from 'react-router-dom';

interface SignupModalProps {
    changeAuthMode: () => void;
    closeModal: () => void;
}

const SignupModal = ({ changeAuthMode, closeModal } : SignupModalProps) => {
    const dialogRef = useRef<HTMLDivElement | null>(null);
    const [name, setName] = useState({
        value: '',
        errorMessage: '',
    })
    const [phoneNo, setPhoneNo] = useState({
        value: '',
        errorMessage: ''
    });
    const [emailId, setEmailId] = useState({
        value: '',
        errorMessage: '',
    });
    const [otp, setOtp] = useState({
        value: '',
        errorMessage: '',
    })
    const [phoneNoSubmitted, setPhoneNoSubmitted] = useState(false);
    const [isAgreementChecked, setIsAgreementChecked] = useState(false);
    const [resendTimer, setResendTimer] = useState(0);
    const [areAllFieldsFilled, setAreAllFieldsFilled] = useState(false);

    const handleOutsideClick = (event: MouseEvent) => {
        if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
            closeModal();
        }
    };

    useEffect(() => {
        if (phoneNo.value && name.value && emailId.value) {
            setAreAllFieldsFilled(true);
        } else {
            setAreAllFieldsFilled(false);
        }
    }, [phoneNo, emailId, name]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (resendTimer > 0) {
                setResendTimer((prev) => prev - 1);
            }
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [resendTimer]);

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick, true);

        return () => {
            document.removeEventListener('click', handleOutsideClick, true);
        };
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, setValue: React.Dispatch<React.SetStateAction<{
        value: string;
        errorMessage: string;
    }>>) => {
        setValue({
            value: event.target.value,
            errorMessage: '',
        });
    };

    const handleAuthModeChange = () => {
        phoneNoSubmitted && setPhoneNoSubmitted(false);
        setPhoneNo({
            value: '',
            errorMessage: '',
        });
        changeAuthMode();
    };

    const phoneSuccessCallback = () => {
        !phoneNoSubmitted && setPhoneNoSubmitted(true);
        setResendTimer(60);
    }

    const phoneErrorCallback = (errorMessage?: string) => {
        if (errorMessage) {
            setPhoneNo((prev) => {
                return {
                    ...prev,
                    errorMessage,
                }
            });
        }
    };

    const signupErrorCallback = (errorMessage?: string) => {
        if (errorMessage) {
            setOtp((prev) => {
                return {
                    ...prev,
                    errorMessage,
                }
            });
        }
    };


    const otpSuccessCallback = () => {
        handleSignup("student", phoneNo.value, signupErrorCallback, closeModal, name.value, emailId.value);
    };

    const otpErrorCallback = (errorMessage?: string) => {
        if (errorMessage) {
            setOtp((prev) => {
                return {
                    ...prev,
                    errorMessage,
                }
            });
        }
    }

    const handleSignupSubmission = async () => {
        if (!name.value || name.value.trim() === "") {
            setName((prev) => {
                return {
                    ...prev,
                    errorMessage: "Name cannot be blank",
                }
            });
            return;
        }
        if (!emailId.value || !emailId.value.match(EMAIL_REGEX)) {
            setEmailId((prev) => {
                return {
                    ...prev,
                    errorMessage: "Please enter a valid email address",
                }
            });
            return;
        }
        // Phone no gets validated in handlePhoneSubmission func
        await handlePhoneSubmission(
            phoneNo.value,
            phoneSuccessCallback,
            phoneErrorCallback,
            'userSignup'
        );
    };

    return (
        <div className='authModal'>
            <div className="authModal__container" ref={dialogRef}>
                <div className="authModal__header">
                    <h2 className='authModal__header__title'>{phoneNoSubmitted ? 'Enter OTP' : 'Create your account'}</h2>
                    <p className="authModal__header__message">
                        {
                            phoneNoSubmitted && `Enter the OTP that was sent to your registered phone no. ${maskPhoneNumber(phoneNo.value)} and be careful not to share the OTP with anyone!`
                        }
                        {
                            !phoneNoSubmitted && 'Enter following details to create account'
                        }
                    </p>
                </div>

                {
                    phoneNoSubmitted ? (
                        <>
                            <AuthInput
                                key="phoneOtp"
                                type='tel'
                                placeholder='Enter 6 digit OTP here'
                                value={otp.value}
                                updateValue={(event) => handleInputChange(event, setOtp)}
                                errorMessage={otp.errorMessage}
                                maxLength={6}
                            />
                            <div className="authAgreement">
                                <input
                                    type="checkbox"
                                    className='authAgreement-checkbox'
                                    checked={isAgreementChecked}
                                    onChange={() => setIsAgreementChecked(!isAgreementChecked)}
                                />
                                <span className='authAgreement-message' style={{ fontSize: "12px" }}>By continuing, I agree to the <Link to="/policy" className='authAgreement-link'>Terms of Services</Link>, including the <Link to="/policy" className='authAgreement-link'>User Agreement</Link> & <Link to="/policy/Privacy" className='authAgreement-link'>Privacy Policy</Link></span>
                            </div>
                        </>
                    ) : (
                        <>
                            <AuthInput
                                key="userName"
                                label='Name'
                                type='text'
                                placeholder='John Doe'
                                value={name.value}
                                updateValue={(event) => handleInputChange(event, setName)}
                                errorMessage={name.errorMessage}
                                style={{ marginBottom: "15px" }}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        handleSignupSubmission();
                                    }
                                }}
                            />
                            <AuthInput
                                key="emailId"
                                label='Email'
                                type='email'
                                placeholder='Enter your mail address'
                                value={emailId.value}
                                updateValue={(event) => handleInputChange(event, setEmailId)}
                                errorMessage={emailId.errorMessage}
                                style={{ marginBottom: "15px" }}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        handleSignupSubmission();
                                    }
                                }}
                            />
                            <AuthInput
                                key="phoneNumber"
                                label='Phone no.'
                                type='tel'
                                placeholder='Enter your registered Phone no.'
                                value={phoneNo.value}
                                updateValue={(event) => handleInputChange(event, setPhoneNo)}
                                errorMessage={phoneNo.errorMessage}
                                showDialCode
                                maxLength={10}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        handleSignupSubmission();
                                    }
                                }}
                            />
                        </>
                    )
                }

                {
                    phoneNoSubmitted ? (
                        <AuthButton
                            variant='primary'
                            onClick={() => void handlePhoneOtpSubmission(phoneNo.value, otp.value, otpSuccessCallback, otpErrorCallback, 'userSignup')}
                            style={{ marginTop: "30px" }}
                            disabled={!otp.value || !isAgreementChecked}
                        >
                            Create account
                        </AuthButton>
                    ) : (
                        <AuthButton
                            variant='primary'
                            onClick={handleSignupSubmission}
                            style={{ marginTop: "30px" }}
                            disabled={!areAllFieldsFilled}
                        >
                            Continue
                        </AuthButton>
                    )
                }

                {
                    phoneNoSubmitted ? (
                        <ResendOtp
                            handleResend={() => phoneNoSubmitted ? void handlePhoneSubmission(phoneNo.value, noop, noop) : handleEmailSubmission(emailId.value, noop, noop)} 
                            timer={resendTimer}
                            style={{ marginTop: "40px" }}
                        />
                    ) : (
                        <>
                            <div className='authAlternatives' style={{ marginTop: "20px", alignSelf: "center" }}>
                                <p className="authAlternatives-message">
                                    Already have an account?
                                    <button
                                        onClick={handleAuthModeChange}
                                        className="authAlternatives-action"
                                    >
                                        Login
                                    </button>
                                </p>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default SignupModal;