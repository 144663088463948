import React, { useEffect, useState } from 'react';
import { ReviewFeaturesType } from './FeaturesRenderer';

const ReviewFeatures = ({
  features,
  setFeatures,
}: {
  features: ReviewFeaturesType;
  setFeatures: React.Dispatch<React.SetStateAction<ReviewFeaturesType>>;
}) => {
  const [isHint, setIsHint] = useState(Boolean(features?.hint) || false);
  const [hint, setHint] = useState(features?.hint || '');

  useEffect(() => {
    if (isHint) {
      setFeatures({
        hint,
      });
    } else if (!isHint && features?.hint?.length !== 0) {
      setFeatures({
        hint: '',
      });
    } else {
      setFeatures({});
    }
  }, [isHint, hint]);

  return (
    <div className="features__container">
      <div className="features__input-container">
        <div className="features__toggle-container">
          <div className="features__title">Write hint for your card</div>
          <label className="features__switch">
            <input
              type="checkbox"
              checked={isHint}
              onChange={(e) => setIsHint((prev) => !prev)}
            />
            <span className="slider"></span>
          </label>
        </div>
        <textarea
          className="features__input"
          placeholder="Enter your hint..."
          value={hint}
          onChange={(e) => setHint(e.target.value)}
          disabled={!isHint}
        />
      </div>
    </div>
  );
};

export default ReviewFeatures;
