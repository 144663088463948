import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { myConfig } from '../../config';
import { updateShowcaseConfig } from '../../reducers/showcaseConfig.reducer';

import LandingPageMenu from './landingPageMenu';
import LandingScreen from './landingScreen';
import {
  EntityContainer,
  NoteCard,
  WebinarCard,
  SessionCard,
} from '@abhijeet_hyperlearn/hlf_lib';
import Testimonials from '../BasicComponents/testimonials';
import { testimonialsConstant } from '../Constants';
import PromotionSection from './promotionSection';
import BecomeAMentor from './becomeAMentor';
import Footer from '../BasicComponents/footer';
import FloatingMessage from './floatingMessage';

import {
homePageNotes
} from '../Constants/homepageNotes';
import upcomingWebinarData from '../Constants/upcomingWebinars';
import popularSessionData from '../Constants/popularSessions';

type noteProps = {
  _id: string;
  image: {
    url: string;
  };
  cardCount: number;
  subscriberCount: number;
  tags: string[];
  name: string;
  description: string;
  mentorDetails: {
    name: string;
  };
  mentorProfileDetails: {
    profilePic: {
      url: string;
    };
    shortBio: string;
  };
  previewCards: cardProps[];
};

type cardProps = {
  _id: string;
  cardType: string;
  questionImage: {
    url: string;
  };
  questionText: string;
  questionOption: {
    isCorrect: boolean;
    optionText: string;
  };
};

const HomePage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const hlUserType: string | null = window.localStorage.getItem('hlUserType');
  const token: string | null = window.localStorage.getItem('token');

  const exploreNotesRef = useRef<HTMLDivElement>(null);
  const handleScrollToNotes = () => {
    if (exploreNotesRef.current) {
      exploreNotesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLoadMore = async (noteData: { category: string; body: any }) => {
    history.push(`/myDashboard/showcase`);
    dispatch(
      updateShowcaseConfig({
        title: noteData.category,
        entityType: 'note',
        search: noteData.body.search || '',
        filter: noteData.body.filter || [],
        ids: noteData.body.ids,
        showcaseItems: [],
      })
    );
  };

  useEffect(() => {
    if (hlUserType === 'student' && token) {
      history.push('/searchMentor');
    } else if (hlUserType === 'mentor' && token) {
      history.push('/dashboardV2');
    }
  }, []);

  const notesData = async (noteFetchBody: any) => {
    try {
      const data = await fetch(`${myConfig.url}/api/entities/getEntities`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(noteFetchBody),
      })
        .then((res) => res.json())
        .then((data) => data.data);

      const decks = data.entities.map((deck: noteProps) => {
        return {
          id: deck._id,
          image: deck.image.url,
          isTrending: false,
          title: deck.name,
          subscriberCount: deck.subscriberCount,
          cardCount: deck.cardCount,
          tags: deck.tags,
          buttonText: 'Explore',
          profileImage: deck.mentorProfileDetails.profilePic.url,
          description: deck.description,
          creatorName: deck.mentorDetails.name,
          creatorIntro: deck.mentorProfileDetails.shortBio,
          cards: [],
          handleClicked: () => {
            history.push(`notes/flow-mode-v2/${deck._id}`);
          },
        };
      });

      return {
        results: decks,
        totalResults: data.total,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const NotesEntity = ({
    exploreNotesRef,
    noteData,
  }: {
    exploreNotesRef?: React.RefObject<HTMLDivElement>;
    noteData: { category: string; body: any };
  }) => (
    <div
      ref={exploreNotesRef}
      style={{ maxWidth: '1400px', margin: 'auto', padding: '1rem' }}
    >
      <EntityContainer
        title={noteData.category}
        entityDetails={{
          component: NoteCard,
          loadEntityData: async () => notesData(noteData.body),
        }}
        handleLoadMore={() => handleLoadMore(noteData)}
      />
    </div>
  );

  const featuredNotes = homePageNotes[0]
  const remainingNotes = homePageNotes.slice(1)      

  return (
    <>
      <LandingPageMenu handleScrollToNotes={handleScrollToNotes} />

      <div className="container-fluid">
        <div className="row d-flex justify-content-center">
          <LandingScreen />

          <NotesEntity
            noteData={featuredNotes}
          />

          <FloatingMessage />

          {remainingNotes.map((n) => <NotesEntity key={n.category} noteData={n}/> )}

          {/* <Testimonials testimonials={testimonialsConstant} /> */}

          {/* <PromotionSection /> */}

          {/* <div style={{ maxWidth: '1400px', margin: 'auto', padding: '1rem' }}>
            <EntityContainer
              title="Upcoming Webinars"
              entityDetails={{
                component: WebinarCard,
                loadEntityData: async () => upcomingWebinarData,
              }}
            />
          </div> */}

          {/* <div style={{ maxWidth: '1400px', margin: 'auto', padding: '1rem' }}>
            <EntityContainer
              title="Popular Sessions"
              entityDetails={{
                component: SessionCard,
                loadEntityData: async () => popularSessionData,
              }}
            />
          </div> */}

          <BecomeAMentor />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default HomePage;
