import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { CourseContext } from '../../provider/CourseContext';
import { searchIcon } from '../BasicComponents/icons';

type props = {
  callApi: (k: string) => any,
  setSkills: (k: string) => void,
}

const SearchMentorInput: React.FC<props> = ({ callApi, setSkills }) => {
  const [key, setKey] = useState('');
  const [showCategory, setShowCategory] = useState(false);
  const { searchKeyword, setSearchKeyword } = useContext(CourseContext);
  const history = useHistory();
  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchKeyword(key);
    callApi(key);
    setSkills(key);
    history.push('/searchMentor');
  };
  const onChange = (e: any) => {
    setKey(e.target.value);
  };
  useEffect(() => {
    setKey(searchKeyword);
  }, [searchKeyword]);

  return (
    <>
      <form className="searchMentor__form" onSubmit={submitHandler} style={{ marginLeft: '0px' }}>
        <input
          type="text"
          className="searchMentor__form--input"
          placeholder="Try searching “React”"
          value={key}
          onChange={onChange}
        />
        <button type="submit" className="searchMentor__form--button">
          <img src={searchIcon} alt="search" />
        </button>
      </form>
      <div
        className="searchMentor__select-container"
        role="button"
        style={{ marginLeft: '0px' }}
        onClick={() => setShowCategory(!showCategory)}
      >
        { /*       <div name="recommended" id="recommended" className="searchMentor__hide">
          <div
            role="button"
            className="searchMentor__select--option"
            style={{ marginLeft: '0px' }}
            onClick={() => setShowCategory(!showCategory)}
          >
            {recommended !== '' ? recommended : 'Sort by recommended' }
          </div>
          <img src={filterArrow} alt="arrow" className="searchMentor__filterArrow" />
          <div className="searchMentor__show-box"
          style={showCategory === true ? { display: 'block' } : { display: 'none' }}>
            <button
              type="button"
              value="price"
              onClick={(e) => selectSortingValue(e)}
              className="btn searchMentor__btn-option-styling"
            >
              Price
            </button>
            <button
              type="button"
              value="rating"
              onClick={(e) => selectSortingValue(e)}
              className="btn searchMentor__btn-option-styling"
            >
              Rating
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
};
export default SearchMentorInput;
