import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import _, { noop } from 'lodash';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import Calendar from '../Calendso';
import TimeSlot from '../TimeSlot';
import {
  back, clock, earth, calendarImage, cross, session,
} from '../../BasicComponents/icons';
import Confirmation from './Confirmation';
import PaymentDialog from '../../PaymentDialog/index.ts';
import { hlbClient } from '../../../Clients/hlbClient';

function CalendarContainerMobile(props) {
  const {
    data, setDate, selectedDate, availability,
    timeZone, disabledDays, expertId, timeSlots, setTime, setShowForm,
    innWidth, showForm, selectedTime, duration, name, setName, email,
    setEmail, description, setDescription,
    successBooking, failedBooking, capitalize,
  } = props;

  const [emailValue, setEmailValue] = useState('');
  const [hostDetails, setHostDetails] = useState({});
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }, [showForm, selectedDate]);

  useEffect(() => {
    const fetchHostDetails = async () => {
      try {
        const response = await hlbClient().get(`/api/users/${data?.mentorId}/profile`);
        setHostDetails(response?.data[0]);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    // eslint-disable-next-line no-unused-expressions
    data?.mentorId && fetchHostDetails();
  }, [data?.mentorId]);

  const entityDetails = {
    title: data?.name,
    description: data?.description,
    price: data?.price,
    discountPrice: data?.discountPrice,
    isFree: data?.isFree,
    mentorName: hostDetails?.userName,
    mentorId: data?.mentorId,
    sessionDuration: duration,
    sessionType: data?.sessionType,
    selectedSessionDate: selectedDate,
    attendeeEmail: email,
    entityId: data?._id,
  };

  function navigationHandler() {
    return showForm ? setShowForm(false) : setDate(null);
  }

  const checkPattern = (enteredEmail) => {
    const pattern = /\S+@\S+\.\S+/;
    return pattern.test(enteredEmail);
  };

  const handleNewEmailValue = (e) => {
    if (e.key === 'Enter') {
      if (checkPattern(emailValue)) {
        if (!email.includes(emailValue)) {
          setEmail((prev) => [...prev, emailValue]);
        }
      }
      setEmailValue('');
    }
  };

  const handleDeleteEmail = (index) => {
    setEmail(
      email.filter((el, i) => i !== index),
    );
  };

  if (successBooking || failedBooking) {
    return <Confirmation isSuccessful={successBooking} />;
  // eslint-disable-next-line no-else-return
  } else {
    return (
      <div className="session__container--mobile">
        <nav className="session__header">
          {
            (selectedDate || showForm) ? (
              <button className="btn back__button" type="button" onClick={navigationHandler}>
                <img src={back} alt="Back Button" />
              </button>
            ) : (
              <Link className="btn back__button" to={`/mentor/${expertId}`}>
                <img src={back} alt="Back Button" />
              </Link>
            )
          }
        </nav>
        <div className={`session__booking session__booking--mobile ${selectedDate ? 'hidden' : ''} ${showForm ? 'visible' : ''}`}>
          <p className="session__text">Session details</p>
          <div className="session__head--wrapper">
            <p className="session__type">{data.sessionType && capitalize(data.sessionType)}</p>
            <h3 className="session__heading">
              {data.name && capitalize(data.name)}
            </h3>
          </div>
          <div className="session__info">
            <div className="session__price">
              {
                _.get(data, 'isFree', false) ? (
                  <span className="session__cost">
                    Free
                  </span>
                ) : (
                  <>
                    {
                    _.get(data, 'discountPrice') && _.get(data, 'price') ? (
                      <>
                        <span className="session__cost">
                          ₹
                          {' '}
                          {data.discountPrice}
                        </span>
                        <span className="session__cost--markthrough">
                          ₹
                          {' '}
                          {data.price}
                        </span>
                      </>
                    ) : (
                      <span className="session__cost">
                        ₹
                        {' '}
                        {data.price}
                      </span>
                    )
                  }
                  </>
                )
              }
            </div>
            <p className="session__duration">
              <img src={clock} alt="Clock" />
              {data.duration}
              {' '}
              minutes
            </p>
          </div>
          <div className="session__description">
            <p>
              {data.description && capitalize(data.description)}
            </p>
          </div>
          {
            showForm && (
            <div className="form__data form__data--mobile">
              <p className="form--schedule">
                <img src={calendarImage} alt="calendar" />
                {' '}
                {dayjs(selectedTime).tz(timeZone).format('hh:mm A ')}
                -
                {dayjs(selectedTime).tz(timeZone).add(duration, 'minute').format('hh:mm A dddd,MMMM D YYYY')}
              </p>
              <Dropdown className="text-black dropdown form__data--dropdown" style={{ transform: 'translateX(-10px)' }}>
                <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                  <img src={earth} alt="Earth Icon" />
                  {timeZone}
                  {/* <img src={down} alt="Down arrow" /> */}
                </Dropdown.Toggle>
                {/* <Dropdown.Menu className="dropdown--menu">
                  { timeZones.map(
                    (zone) => (
                      <Dropdown.Item onClick={() => { setZone(zone); changeAvailability(zone); }}>
                        {zone}
                      </Dropdown.Item>
                    ),
                  )}
                </Dropdown.Menu> */}
              </Dropdown>
            </div>
            )
          }
        </div>
        <div className={`session__calendar session__calendar--mobile ${selectedDate ? 'hidden' : ''}`}>
          <h3 className="calendar__heading">Select a Date</h3>
          <Calendar
            className="calendar"
            setDate={setDate}
            selectedDate={selectedDate}
            daysAllowed={(_.get(availability[0], 'bookingAllowedTill', 30) / 24) - 1}
            startDay={_.get(availability[0], 'bookingAllowedBefore', 0) / 24}
            disabledDays={disabledDays}
          />
          <div className="dropdown__container dropdown__container--mobile">
            <Dropdown className="text-black dropdown" style={{ transform: 'translateX(-10px)' }}>
              <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                <img src={earth} alt="Earth Icon" />
                {timeZone}
                {/* <img src={down} alt="Down arrow" /> */}
              </Dropdown.Toggle>
              {/* <Dropdown.Menu className="dropdown--menu">
                { timeZones.map(
                  (zone) => (
                    <Dropdown.Item onClick={() => setZone(zone)}>
                      {zone}
                    </Dropdown.Item>
                  ),
                )}
              </Dropdown.Menu> */}
            </Dropdown>
          </div>
        </div>
        {
          selectedDate && (
          <div className={`session__slots session__slots--mobile ${showForm ? 'hidden' : ''}`}>
            <div className="slots__information">
              <div className="slots__information--header">
                <h3 className="slots__heading">Select a Time</h3>
              </div>
              <div className="slots__information--time">
                <h3 className="session__date">
                  {dayjs(selectedDate).format('dddd,MMMM D')}
                </h3>
                <Dropdown className="text-black dropdown" style={{ transform: 'translateX(-10px)' }}>
                  <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                    <img src={earth} alt="Earth Icon" />
                    {timeZone}
                    {/* <img src={down} alt="Down arrow" /> */}
                  </Dropdown.Toggle>
                  {/* <Dropdown.Menu className="dropdown--menu">
                    { timeZones.map(
                      (zone) => (
                        <Dropdown.Item onClick={() => { setZone(zone); changeAvailability(zone); }}>
                          {zone}
                        </Dropdown.Item>
                      ),
                    )}
                  </Dropdown.Menu> */}
                </Dropdown>
              </div>
            </div>
            <div className="slots__container slots__container--mobile">
              <div className="slots slots--mobile">
                {(timeSlots.length > 0 ? (timeSlots.map((slot) => (
                  // eslint-disable-next-line react/jsx-key
                  <div className="text-center mt-2">
                    <TimeSlot
                      time={slot.time}
                      setTime={setTime}
                      setShow={setShowForm}
                      booked={slot.booked}
                      timeZone={timeZone}
                      innWidth={innWidth}
                    />
                  </div>
                ))) : <div style={{ textAlign: 'center' }}>No Slots Available </div>)}
              </div>
            </div>
          </div>
          )
        }
        {
          showForm && (
          <div className="session__form session__form--mobile">
            <h3 className="form--header">Enter details</h3>
            <form className="form__container">
              <div className="form__field">
                <div>
                  <label className="form__field--label" htmlFor="name">
                    Name
                    <sup> *</sup>
                  </label>
                  <input
                    type="text"
                    className="input form__field--input"
                    value={name}
                    id="name"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    required
                  />
                </div>
                <div>
                  <label className="form__field--label" htmlFor="email">
                    Email
                    <sup> *</sup>
                  </label>
                  <div className={`form__tagged--input ${email.length >= 2 ? 'wrapNeeded' : ''}`}>
                    {
                      _.compact(email).map((el, i) => (
                        <span className="tagged__input--filled" key={Math.random()}>
                          { el }
                          <button
                            type="button"
                            className="tagged__input--icon"
                            onClick={() => handleDeleteEmail(i)}
                            disabled={i === 0}
                          >
                            <img src={cross} alt="remove" />
                          </button>
                        </span>
                      ))
                    }
                    <input
                      type="email"
                      className="input form__field--input"
                      value={emailValue}
                      id="email"
                      onChange={(e) => {
                        setEmailValue(e.target.value);
                      }}
                      onKeyDown={handleNewEmailValue}
                      required
                      autoComplete="off"
                      disabled={_.get(data, 'sessionType', 'one-on-one') === 'one-on-one'}
                    />
                  </div>
                </div>
                <div>
                  <label className="form__field--label" htmlFor="desc">What do you need help with?</label>
                  <textarea
                    id="desc"
                    value={description}
                    rows="4"
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    className="form__field--textarea"
                    required
                  />
                </div>
              </div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  setIsPaymentModalOpen(true);
                }}
                role="button"
                onKeyDown={() => {
                  setIsPaymentModalOpen(true);
                }}
                tabIndex="-1"
                className="d-grid text-decoration-none"
              >
                <input
                  type="button"
                  className="btn form--submit"
                  value={`${data.isFree
                    ? 'Book Session'
                    : `Pay ₹${data.price && data.discountPrice ? data.discountPrice : data.price}`}`}
                />
              </a>
            </form>
          </div>
          )
        }
        <PaymentDialog
          open={isPaymentModalOpen}
          onClose={() => setIsPaymentModalOpen(false)}
          onContinue={noop}
          entityType="session"
          entityDetails={entityDetails}
          thumbnail={session}
        />
      </div>
    );
  }
}

CalendarContainerMobile.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  selectedDate: PropTypes.oneOfType(PropTypes.string,
    PropTypes.objectOf(PropTypes.object)).isRequired,
  setDate: PropTypes.oneOfType(PropTypes.string, PropTypes.objectOf(PropTypes.object)).isRequired,
  availability: PropTypes.arrayOf(PropTypes.oneOfType(
    [PropTypes.number, PropTypes.string],
  )).isRequired,
  setZone: PropTypes.func.isRequired,
  timeZone: PropTypes.string.isRequired,
  disabledDays: PropTypes.arrayOf(PropTypes.oneOfType(
    [PropTypes.number, PropTypes.string],
  )).isRequired,
  setShowForm: PropTypes.bool.isRequired,
  expertId: PropTypes.oneOfType(PropTypes.bool, PropTypes.string, PropTypes.number).isRequired,
  timeSlots: PropTypes.arrayOf(PropTypes.oneOfType(
    [PropTypes.number, PropTypes.string],
  )).isRequired,
  setTime: PropTypes.string.isRequired,
  innWidth: PropTypes.number.isRequired,
  showForm: PropTypes.bool.isRequired,
  selectedTime: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  setName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  setDescription: PropTypes.string.isRequired,
  changeAvailability: PropTypes.func.isRequired,
  successBooking: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  setSuccessBooking: PropTypes.bool.isRequired,
  failedBooking: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  setFailedBooking: PropTypes.bool.isRequired,
  capitalize: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.bool.isRequired,
};

export default CalendarContainerMobile;
