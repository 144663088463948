/* eslint-disable no-nested-ternary */
import React from 'react';
import './VideoCard.css';
import { videoPlayerLayout } from '../icons';

/* eslint-disable react/require-default-props */
type VideoCardProps = {
  data: string;
  type?: string;
  entityType?: string;
}

const VideoCard = ({ data, type = 'link', entityType = '' }: VideoCardProps) => {
  const extention = data.split('.').pop();
  let vimeoExtension = '';
  let youtubeExtension = '';

  if (data.includes('youtube')) {
    youtubeExtension = data.split('=').pop() || '';
  }
  if (data.includes('vimeo')) {
    vimeoExtension = data.split('/').pop() || '';
  }

  const sourcedata = type === 'link' && data ? (
    extention === 'video/mp4' || extention === 'video/amv'
      ? (
        <video src={data} className={entityType !== 'videoUploader' ? 'media-responsive__v2' : 'media-responsive__v2--video-uploader'} controls />
      ) : (
        youtubeExtension !== ''
          ? <iframe src={`https://www.youtube.com/embed/${youtubeExtension}`} allowFullScreen title="Preview" className={entityType !== 'videoUploader' ? 'media-responsive__v2' : 'media-responsive__v2--video-uploader'} />
          : <iframe src={`https://player.vimeo.com/video/${vimeoExtension}`} allow="fullscreen; picture-in-picture" allowFullScreen className={entityType !== 'videoUploader' ? 'media-responsive__v2' : 'media-responsive__v2--video-uploader'} title="Preview" />
      )
  ) : (
    <img src={videoPlayerLayout} alt="" className="media-responsive__v2--videoLayout" />
  );
  return (
    <>
      {sourcedata}
    </>
  );
};

export default VideoCard;
