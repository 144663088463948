/* eslint-disable react/jsx-key */
import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Dropdown, Toast } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import _ from 'lodash';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { AuthContext } from '../../provider/AuthContext';
import TimeRange from './TimeRange';
import { hlbClient } from '../../Clients/hlbClient';
import {
  // eslint-disable-next-line no-unused-vars
  progressBarWorkTime,
  progressBarStep3,
  earth,
  // eslint-disable-next-line no-unused-vars
  down,
  cross,
  plus,
  copy,
  googleCalendar,
} from '../BasicComponents/icons';
import './SetAvailibility.css';
import SuccessConfirmation from '../BasicComponents/successConfirmation/index.ts';
import Tooltip from '../BasicComponents/Tooltip';
import NavbarContainer from '../BasicComponents/navbar/navbarContainer';
import TimeCopier from './timeCopier';
import WhatsappPlugin from '../BasicComponents/WhatsappPlugin';

const DEFAULT_DURATION = 30;
dayjs.extend(utc);
dayjs.extend(timezone);
function SetAvailability() {
  const { authState } = useContext(AuthContext);
  const initialDefaultSlots = {
    startTime: dayjs('1970-01-01T00:00:00.000Z').startOf('date').toISOString(),
    endTime: dayjs('1970-01-01T00:00:00.000Z')
      .startOf('date')
      .add(DEFAULT_DURATION, 'minute')
      .toISOString(),
  };
  const [days, setDays] = useState({});
  const [bookingAllowedFrom, setFrom] = useState('');
  const [bookingAllowedTo, setTo] = useState('');
  const [authLink, setAuthLink] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [timeZone, setZone] = useState(dayjs.tz.guess());
  const [weekDays] = useState([
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
    'Sun',
  ]);
  const [availability, setAvailability] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [slotOverlapped, setSlotOverlapped] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [getCopyBox, setGetCopyBox] = useState('');

  async function getLink() {
    try {
      const resp = await hlbClient().get('/api/scheduler/connectgoogle');
      setAuthLink(resp.data);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        // eslint-disable-next-line no-unused-vars
        const resp = await hlbClient().get(
          `/api/scheduler/mentor/${authState._id}`
        );
        setAuthLink(false);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('oops something went wrong');
        getLink();
      }
    }
    if (authState._id) {
      fetchData();
    }
  }, [authState]);

  useEffect(() => {
    async function fetchData() {
      try {
        const resp = await hlbClient().get(
          `/api/scheduler/availability/mentor/${authState._id}`
        );
        setAvailability(resp.data);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('oops something went wrong');
      }
    }
    if (authState._id) {
      fetchData();
    }
  }, [authState]);

  useEffect(() => {
    if (availability[0]) {
      // eslint-disable-next-line max-len
      if (
        availability &&
        availability[0].bookingAllowedBefore &&
        availability[0].bookingAllowedTill
      ) {
        setFrom(availability[0].bookingAllowedBefore);
        setTo(availability[0].bookingAllowedTill / 24);
      } else {
        setFrom(10);
        setTo(15);
      }
      setDays(availability[0].days);
    } else if (!availability) {
      setDays({
        0: { slots: [initialDefaultSlots], isAvailable: true },
        1: { slots: [initialDefaultSlots], isAvailable: true },
        2: { slots: [initialDefaultSlots], isAvailable: true },
        3: { slots: [initialDefaultSlots], isAvailable: true },
        4: { slots: [initialDefaultSlots], isAvailable: true },
        5: { slots: [initialDefaultSlots], isAvailable: true },
        6: { slots: [initialDefaultSlots], isAvailable: true },
      });
      setFrom(10);
      setTo(15);
    }
  }, [availability]);

  const AddSlot = (index) => {
    // index+1 to convert 7 to 0 (Sunday)
    const newDays = { ...days };
    newDays[(index + 1) % 7].slots = [
      ...newDays[(index + 1) % 7].slots,
      {
        startTime: dayjs(
          _.get(
            days[(index + 1) % 7].slots[days[(index + 1) % 7].slots.length - 1],
            'endTime',
            dayjs('1970-01-01T00:00:00.000Z')
              .startOf('date')
              .subtract(DEFAULT_DURATION, 'minute')
          )
        )
          .add(DEFAULT_DURATION, 'minute')
          .toISOString(),
        endTime: dayjs(
          _.get(
            days[(index + 1) % 7].slots[days[(index + 1) % 7].slots.length - 1],
            'endTime',
            dayjs('1970-01-01T00:00:00.000Z')
              .startOf('date')
              .subtract(DEFAULT_DURATION, 'minute')
          )
        )
          .add(DEFAULT_DURATION * 2, 'minute')
          .toISOString(),
      },
    ];
    setDays(newDays);
  };

  const SetIsAvailable = (available, index) => {
    const newDays = { ...days };
    newDays[index].isAvailable = available;
    setDays(newDays);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await hlbClient().post('api/scheduler/availability', {
        userId: authState._id,
        isAvailable: true,
        bookingAllowedBefore: parseInt(bookingAllowedFrom, 10),
        bookingAllowedTill: 24 * bookingAllowedTo,
        days,
        timeZone,
      });
      setSubmitted(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');
    }
    setIsLoading(false);
  };

  // ----- TO DISCONNECT GOOGLE CALENDAR
  const handleGoogleDisconnect = async () => {
    const response = await hlbClient().post('api/scheduler/disconnectgoogle', {
      // eslint-disable-next-line quote-props
      userId: authState._id,
    });
    if (response.data.msg === 'Successfully disconnected') {
      getLink();
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <NavbarContainer />
        </div>
      </div>
      {submitted ? (
        <SuccessConfirmation
          heading="Profile Submitted Successfully"
          description="Add more information to your account to make it easier for students to connect with you."
          button1={{
            btnName: 'Dashboard',
            action: 'redirect',
            url: '/dashboardV2',
            handler: null,
          }}
          button2={{
            btnName: 'Your Profile',
            action: 'redirect',
            url: `/mentor/${_.get(authState, '_id', '')}`,
            handler: null,
          }}
        />
      ) : (
        <div className="availibility">
          <div className="availibility__container">
            <Link to="/dashboardV2" className="close-button">
              <Tooltip content="Close">
                <img src={cross} alt="close" />
              </Tooltip>
            </Link>
            <div className="availibility__head">
              <h3 className="availibility__head--heading">
                Lets setup your work timing
              </h3>
              <div className="availibility__image">
                <img src={progressBarStep3} alt="Step-3" />
              </div>
              <p className="availibility__head--label">
                Please select your preferred working days and timings
              </p>
              <div className="sync__container">
                <div className="sync__info">
                  <h3 className="sync__header">Sync your calendar</h3>
                  <p className="sync__desc">
                    So that we book your session only when you have free
                    timeslot in your calendar
                  </p>
                </div>
                <div className="sync__button">
                  <div className="sync__service">
                    <img src={googleCalendar} alt="calendar" />
                    <h2 className="sync__text">Google Calendar</h2>
                  </div>
                  {authLink ? (
                    <a href={authLink} className="sync__link">
                      Connect
                    </a>
                  ) : (
                    <button type="button" onClick={handleGoogleDisconnect}>
                      Disconnect
                    </button>
                  )}
                </div>
              </div>
              <Dropdown className="text-black dropdown form__data--dropdown">
                <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                  <img src={earth} alt="Earth Icon" />
                  {timeZone}
                  {/* <img src={down} alt="Down arrow" /> */}
                </Dropdown.Toggle>
                {/* <Dropdown.Menu className="dropdown--menu">
                  { timeZones.slice(0, 10).map(
                    (zone) => (
                      <Dropdown.Item
                        style={{ textAlign: 'left' }}
                        onClick={() => setZone(zone)}
                        disabled
                      >
                        {zone}
                      </Dropdown.Item>
                    ),
                  )}
                </Dropdown.Menu> */}
              </Dropdown>
            </div>
            <div className="availibility__slots">
              <form onSubmit={submitHandler}>
                {weekDays.map((weekday, index) => (
                  <div className="availibility__slot">
                    <div className="availibility__start">
                      <input
                        type="checkbox"
                        className="availibility__start--checkbox"
                        id={weekday}
                        checked={_.get(days, [(index + 1) % 7, 'isAvailable'])}
                        onChange={(e) => {
                          SetIsAvailable(e.target.checked, (index + 1) % 7);
                        }}
                      />
                      <label
                        htmlFor={weekday}
                        className="availibility__start--label"
                      >
                        {weekday}
                      </label>
                    </div>
                    <div className="availibility__second">
                      {days[0] &&
                        days[(index + 1) % 7].slots
                          .sort(
                            (a, b) => (a.startTime < b.startTime ? -1 : 1)
                            // eslint-disable-next-line arrow-body-style
                          )
                          .map((day, i) => {
                            if (days[(index + 1) % 7].isAvailable) {
                              return (
                                <div className="availibility__timeslot">
                                  <TimeRange
                                    day={(index + 1) % 7}
                                    days={days}
                                    setDays={setDays}
                                    slotNum={i}
                                    startTime={dayjs(_.get(day, 'startTime'))}
                                    endTime={dayjs(
                                      _.get(day, 'endTime', dayjs())
                                    )}
                                    setSlotOverlapped={setSlotOverlapped}
                                  />
                                </div>
                              );
                              // eslint-disable-next-line no-else-return
                            } else {
                              // remove the present slots when a day is unchecked
                              days[(index + 1) % 7].slots = _.remove(
                                days[(index + 1) % 7].slots
                              );
                              return (
                                <span className="availibility__not-available">
                                  Unavailable
                                </span>
                              );
                            }
                          })}
                    </div>
                    <div className="availibility__third">
                      {days[0] && days[(index + 1) % 7].isAvailable ? (
                        <>
                          <Tooltip content="Add slot">
                            <button
                              type="button"
                              onClick={() => AddSlot(index)}
                              className="add--button"
                            >
                              <img src={plus} alt="Add" />
                            </button>
                          </Tooltip>
                          <Tooltip content="Copy this slot">
                            <button
                              onClick={() => setGetCopyBox(index)}
                              type="button"
                              className="copy--button"
                            >
                              <img src={copy} alt="copy" />
                            </button>
                            {getCopyBox === index && (
                              <TimeCopier
                                {...{
                                  weekDays,
                                  setDays,
                                  weekday,
                                  index,
                                  days,
                                  setGetCopyBox,
                                }}
                              />
                            )}
                          </Tooltip>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ))}
                <div className="d-grid submit--button">
                  <button type="submit">
                    {isLoading === false ? (
                      'Submit'
                    ) : (
                      <Spinner animation="border" role="status" />
                    )}
                  </button>
                </div>
              </form>
              {slotOverlapped && (
                <Toast
                  onClose={() => setSlotOverlapped(false)}
                  className="availibility__overlapped"
                  show={slotOverlapped}
                  delay={6000}
                  autohide
                >
                  <Toast.Header closeButton={false}>
                    <strong className="me-auto text-danger">
                      Slots overlapped!
                    </strong>
                  </Toast.Header>
                  <Toast.Body className="text-danger">
                    <strong>Highlighted slots are overlapping</strong>, please
                    select proper slots!
                  </Toast.Body>
                </Toast>
              )}
            </div>
          </div>
        </div>
      )}
      <WhatsappPlugin />
    </>
  );
}

export default SetAvailability;
