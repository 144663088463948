import React from 'react';
import { useField, useFormikContext } from 'formik';
import dayjs from 'dayjs';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

type DatePickerFieldProps = Omit<ReactDatePickerProps<never, undefined>, 'onChange'> & {
  disabled: boolean;
  selected: Date | null;
  onChange: (date: Date | null) => void;
};

export const DatePickerField = ({ selected, onChange, ...props }: DatePickerFieldProps) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField<Date>(props.name as string);

  const handleChange = (val: Date) => {
    const formattedDate = dayjs(val).format('YYYY-MM-DD');
    setFieldValue(field.name, formattedDate);
    onChange(val);
  };

  return (
    <DatePicker
      selected={selected}
      onChange={handleChange}
      {...props}
    />
  );
};

export default DatePickerField;
