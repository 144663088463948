import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './announcementPopUp.css';
import { deleteIcon, plus } from '../icons';
import { hlbClient } from '../../../Clients/hlbClient';

type props = {
  apiCall?: () => void;
  isValue?: boolean;
};

// eslint-disable-next-line react/prop-types
const AnnouncementPopUp: React.FC<props> = ({
  apiCall = () => {
    // empty function
  },
  isValue = false,
}) => {
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [announcement, setAnnouncement] = useState<string>('');
  const [announcementCTA, setAnnouncementCTA] = useState<string>('');

  // eslint-disable-next-line no-undef
  const submitAnnouncement = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await hlbClient().put('/api/users/announcement', {
        announcement,
        announcementCTA,
        announcementEnabled: true,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');
    }
    apiCall();
    setShowVideo(false);
  };
  const DeleteAnnouncement = async () => {
    try {
      await hlbClient().put('/api/users/announcement', {
        announcement: '',
        announcementCTA: '',
        announcementEnabled: false,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');
    }
    apiCall();
  };

  return (
    <div>
      {isValue === false ? (
        <img
          onClick={() => {
            setShowVideo(true);
          }}
          onKeyPress={() => {
            setShowVideo(true);
          }}
          role="presentation"
          src={plus}
          alt="add session"
          className="profile__edit-addsession--edit"
        />
      ) : (
        <img
          onClick={() => {
            DeleteAnnouncement();
          }}
          onKeyPress={() => {
            DeleteAnnouncement();
          }}
          role="presentation"
          src={deleteIcon}
          alt="add session"
          className="profile__edit-addsession--edit"
        />
      )}

      <Modal
        show={showVideo}
        fullscreen="md"
        centered
        onHide={() => setShowVideo(false)}
      >
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <button
                type="button"
                onClick={() => setShowVideo(false)}
                className="btn-close float-end"
                style={{ width: '12px', height: '12px' }}
              />
            </div>
            <div className="co-md-12">
              <p className="videoUpload__heading">Announcement</p>
              <p className="videoUpload__sub-heading">
                Have something to share with people visiting your profile? Share
                it here for everyone to see
              </p>
              <form onSubmit={submitAnnouncement} style={{ marginLeft: '0px' }}>
                <textarea
                  required
                  className="form-input"
                  value={announcement}
                  onChange={(e) => setAnnouncement(e.target.value)}
                  placeholder="Post an announcement on your public profile"
                  style={{ height: '100px' }}
                />
                <input
                  className="form-input"
                  value={announcementCTA}
                  onChange={(e) => setAnnouncementCTA(e.target.value)}
                  type="text"
                  placeholder="Add a link for your announcement here (A survey maybe?)"
                />
                <div className="text-center mt-1">
                  <button className="announcement__btn btn " type="submit">
                    Post
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AnnouncementPopUp;
