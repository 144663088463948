import React, { useRef, useState } from 'react';
import HtmlParser from '../../../BasicComponents/htmlParser';
import { TypeAnswerV2Card as TC } from '../../types';
import { handleBase64ToImg } from '../../../Utils/cardUtils';

const TypeAnswerV2Card: React.FC<TC> = ({
  id,
  cardType,
  disableInteraction,
  description,
  medias,
  correctAnswer,
  reason = '',
  verifyAnswer,
  done,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [answer, setAnswer] = useState('');

  const checkAnswer = () => {
    const payload = {
      userAnswer: answer.trim(),
      correctAnswer,
    };
    const result = verifyAnswer('typeanswerv2', payload);
    done('typeanswerv2', result);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  return (
    <>
      <div className="card__body">
        <HtmlParser text={handleBase64ToImg(description, medias)} />
      </div>
      <div className="card__extras">
        <div className="card__input-container">
          <div className="card__input-label">Type your answer</div>
          <input
            ref={inputRef}
            className="card__input-field"
            placeholder="Your answer..."
            disabled={disableInteraction}
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            onKeyDown={handleKeyPress}
          />
        </div>

        <div className="card__btn-grp">
          <button
            className="card__btn-primary"
            disabled={disableInteraction || answer.trim().length === 0}
            onClick={checkAnswer}
          >
            Done
          </button>
        </div>
      </div>
    </>
  );
};

export default TypeAnswerV2Card;
