/* eslint-disable no-undef */
import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../../provider/AuthContext';
import NavbarDesktop from './navbarDesktop';
import NavbarMobile from './navbarMobile';

function NavbarContainer() {
  const [innWidth, setInnWidth] = useState<number>(window.innerWidth);
  const [mobileView, setMobileView] = useState<boolean>(false);
  const { authState, setStateUpdate, stateUpdate } = useContext(AuthContext);

  // eslint-disable-next-line no-undef
  const token: string | null = localStorage.getItem('token');

  useEffect(() => {
    if (token) {
      setStateUpdate(!stateUpdate);
    }
  }, []);

  const id: string = authState._id;

  function setWidth() {
    // eslint-disable-next-line no-undef
    setInnWidth(window.innerWidth);
  }
  useEffect(() => {
    // eslint-disable-next-line no-undef
    const mql: MediaQueryList = window.matchMedia('(max-width: 614px)');
    const view: boolean = mql.matches;

    if (view) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, [innWidth]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', setWidth);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', setWidth);
  }, []);
  const updateDimensions = () => {
    setWidth();
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const redirectLink = () => {
    // eslint-disable-next-line no-undef
    if (
      window.localStorage.getItem('hlUserType') === 'mentor' &&
      window.localStorage.getItem('token')
    ) {
      return '/dashboardV2';
      // eslint-disable-next-line no-else-return
    } else if (
      window.localStorage.getItem('hlUserType') === 'student' &&
      window.localStorage.getItem('token')
    ) {
      return '/searchMentor';
    }
    return '/';
  };

  return (
    <>
      {mobileView === false ? (
        <NavbarDesktop
          id={id}
          data={authState.profile}
          redirectLink={redirectLink}
        />
      ) : (
        <NavbarMobile
          id={id}
          data={authState.profile}
          redirectLink={redirectLink}
        />
      )}
    </>
  );
}

export default NavbarContainer;
