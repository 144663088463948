import React from 'react';
import { isEmpty } from 'lodash';
import Tooltip from '../Tooltip';
import { question } from '../icons';

type LabelProps = {
  label: string;
  tooltip?: string;
  isRequired?: boolean;
  HTMLFor?: string;
  limitPoints?: {
    currentCount: number;
    limit: number;
  };
};

const Label: React.FC<LabelProps> = (props) => {
/* eslint-disable react/prop-types */
  const {
    label, tooltip = '', isRequired = false, HTMLFor = '', limitPoints,
  } = props;

  return (
    <div className="inputLabel--wrapper">
      <div className="inputLabel">
        <label className="inputLabel--label" htmlFor={HTMLFor || label}>
          {label}
          {isRequired && <span className="inputLabel--required">*</span>}
        </label>
        {tooltip && (
          <Tooltip content={tooltip} direction="top">
            <img src={question} alt="tooltip" />
          </Tooltip>
        )}
      </div>
      {!isEmpty(limitPoints) && (
        <div className="inputLabel__limit">
          <span className="inputLabel__limit--currentCount">
            {limitPoints?.currentCount}
          </span>
          /
          <span className="inputLabel__limit--maxLimit">
            {limitPoints?.limit}
          </span>
        </div>
      )}
    </div>
  );
};

export default Label;
