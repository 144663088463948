import React, { useEffect, useState, useContext } from 'react';
import dayjs from 'dayjs';
import { Armchair, CircleWavyCheck } from 'phosphor-react';
import './HeroSection.css';
import { useHistory } from 'react-router';
import { noop } from 'lodash';
import { hlbClient } from '../../../../Clients/hlbClient';
import { AuthContext } from '../../../../provider/AuthContext';
import PaymentDialog from '../../../PaymentDialog';
import { webinar } from '../../../BasicComponents/icons';

type HeroSectionProps = {
  webinar: any;
  isMobileView: boolean;
}

const HeroSection = ({
  webinar: webinarData, isMobileView,
}: HeroSectionProps) => {
  const [hostDetails, setHostDetails] = useState<any>({});
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const { authState } = useContext(AuthContext);
  const history = useHistory();
  const {
    title, date, shortSummary, startTime, endTime, price,
    certificateOnBooking, totalSeats, createdBy, discountedPrice, _id,
  } = webinarData;
  const entityDetails = {
    title,
    description: shortSummary,
    mentorName: hostDetails?.userName,
    price,
    discountPrice: discountedPrice,
    startTime,
    endTime,
    date,
    entityId: _id,
  };

  useEffect(() => {
    const fetchHostDetails = async () => {
      try {
        const response = await hlbClient().get(`/api/users/${createdBy}/profile`);
        setHostDetails(response?.data[0]);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    // eslint-disable-next-line no-unused-expressions
    createdBy && fetchHostDetails();
  }, [createdBy]);

  return (
    <>
      <section className="webinar__hero">
        <div className="webinar__hero--wrapper">
          <div className="hero__webinarDetails">
            <div className="webinarDetails__header">
              <h4 className="header--date">
                {dayjs(date).format('MMM D, YYYY')}
                {' '}
                |
                {' '}
                {dayjs(startTime).format('hh:mmA')}
              </h4>
              <h2 className="header--title">{title}</h2>
            </div>
            <div className="webinarDetails__info">
              <p className="info--shortSummary">
                {shortSummary}
              </p>
              <h4 className="info--timeDuration">
                {dayjs(startTime).format('h:mmA')}
                {' '}
                -
                {' '}
                {dayjs(endTime).format('h:mmA')}
                {' '}
                (IST)
              </h4>
              {
              isMobileView ? (
                <h4 className="info--host-mobile">
                  Host:
                  {' '}
                  {hostDetails?.userName}
                  {' '}
                  (
                  {hostDetails?.organisations?.length > 0 ? hostDetails?.organisations[0]?.name : 'User'}
                  )
                </h4>
              ) : null
            }
            </div>
            {
            isMobileView ? (
              <div className="webinarDetails__perks-mobile">
                {
                certificateOnBooking ? (
                  <div className="perk--wrapper">
                    <CircleWavyCheck size={22} color="#000000" />
                    <h2 className="perk--name">Certificate on completion</h2>
                  </div>
                ) : null
              }
                {
                totalSeats < 200 ? (
                  <div className="perk--wrapper">
                    <Armchair size={22} color="#000000" />
                    <h2 className="perk--name">Limited bookings</h2>
                  </div>
                ) : (
                  <div className="perk--wrapper">
                    <Armchair size={22} color="#000000" />
                    <h2 className="perk--name">Unlimited seats</h2>
                  </div>
                )
              }
              </div>
            ) : null
          }
            <button
              disabled={authState?._id === createdBy}
              onClick={() => {
              // eslint-disable-next-line no-unused-expressions
                authState?._id ? setIsPaymentModalOpen(true) : history.push('/login');
              }}
              type="button"
              className="webinarDetails__action webinarDetails__action--register"
            >
              Register for
              {' '}
              {
              discountedPrice ? (
                <span className="webinar__price">
                  <span className="webinar__price--stricked">
                    ₹
                    {price || 0}
                  </span>
                  <span className="webinar__price--normal">
                    ₹
                    {discountedPrice}
                  </span>
                </span>
              ) : (
                <span className="webinar__price">
                  <span className="webinar__price--normal">
                    ₹
                    {price || 0}
                  </span>
                </span>
              )
            }
            </button>
          </div>
          <div className="hero__hostDetails">
            <div className="hostDetails__info">
              <img
                src={hostDetails?.profilePic?.url}
                alt="avatar"
                className="info__profile"
              />
              <h2 className="info__name">
                {hostDetails?.userName}
                {' '}
                <span className="info__isHost">(HOST)</span>
              </h2>
              <h4 className="info__workingAt">
                {hostDetails?.organisations?.length > 0 ? hostDetails?.organisations[0]?.name : 'User'}
              </h4>
            </div>
            <div className="hostDetails__perks">
              {
                certificateOnBooking ? (
                  <div className="perk--wrapper">
                    <CircleWavyCheck size={32} color="#B189FF" />
                    <h2 className="perk--name">Certificate on completion</h2>
                  </div>
                ) : null
              }
              {
                totalSeats < 200 ? (
                  <div className="perk--wrapper">
                    <Armchair size={32} color="#B189FF" />
                    <h2 className="perk--name">Limited bookings</h2>
                  </div>
                ) : (
                  <div className="perk--wrapper">
                    <Armchair size={32} color="#B189FF" />
                    <h2 className="perk--name">Unlimited seats</h2>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </section>
      <PaymentDialog
        open={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
        onContinue={noop}
        entityType="webinar"
        entityDetails={entityDetails}
        thumbnail={webinar}
      />
    </>
  );
};

export default HeroSection;
