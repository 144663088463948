import React, {
  useState, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { hlbClient } from '../../../Clients/hlbClient';
import { AuthContext } from '../../../provider/AuthContext';
import { options } from '../../Constants';
import {
  cross, facebook, twitter, linkedIn, behance, reddit, dribbble, instagram, gitHub, discord, medium,
} from '../icons';
import './AddSocials.css';

type Props = {
  apiCall?: () => void,
  handleClose: () => void,
}

const AddSocials: React.FC<Props> = ({ handleClose, apiCall }) => {
  const [selectedSocial, setSelectedSocial] = useState(options[0]);
  const [socialRegEx, setSocialRegEx] = useState<any>(null);
  const [enteredUrl, setEnteredUrl] = useState('');
  const [isUrlCorrect, setIsUrlCorrect] = useState<boolean | null>(null);
  const [errorMsg, setErrorMsg] = useState('');
  // eslint-disable-next-line max-len
  const [verifiedSocialProfiles, setVerifiedSocialProfiles] = useState<{ platform: string; url: string; }[]>([]);
  const [isListSaved, setIsListSaved] = useState(true);
  const { authState, setStateUpdate, stateUpdate } = useContext(AuthContext);
  const id = authState._id;
  // eslint-disable-next-line no-undef
  const token = window.localStorage.getItem('token');

  useEffect(() => {
    if (token) {
      setStateUpdate(!stateUpdate);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await hlbClient().get(`/api/users/${id}/profile`);
        if (response) {
          setVerifiedSocialProfiles(response.data[0].socials);
          apiCall && apiCall();
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Something went wrong! 🤖');
      }
    };
    fetchData();
  }, [id]);

  // ------ COMPARES ENTERED URL WITH CORRESPONDING REGEX
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    enteredUrl.match(socialRegEx) ? setIsUrlCorrect(true) : setIsUrlCorrect(null);
    if (errorMsg) setErrorMsg('');
    if (isUrlCorrect === false) setIsUrlCorrect(null);
    if (isListSaved && enteredUrl !== '') setIsListSaved(false);
  }, [enteredUrl]);

  // ------ CUSTOM STYLES FOR REACT SELECT
  const colorStyles = {
    control: (styles: any) => ({ ...styles, backgroundColor: '#EFEFEF', border: 'none' }),
    dropdownIndicator: (styles: any) => ({ ...styles, color: '#676767' }),
  };

  const handleSocialChange = (e: { value: string; image: string; } | any) => {
    setSelectedSocial(e);
    setEnteredUrl('');
    setErrorMsg('');
    setIsUrlCorrect(null);
  };

  const closeSocials = () => {
    if (isListSaved) {
      handleClose();
    } else {
      /* eslint-disable no-alert */
      // eslint-disable-next-line no-undef
      const confirmation = window.confirm('Changes are not updated! Are you sure you want to exit?');
      if (confirmation) {
        handleClose();
      }
    }
  };

  const addVerifiedSocial = () => {
    const verifiedSocial = {
      platform: selectedSocial.value,
      url: enteredUrl,
    };

    const alreadyExists = verifiedSocialProfiles.length && Boolean(
      verifiedSocialProfiles
        .filter((profile: { platform: string }) => profile.platform === verifiedSocial.platform)
        .length,
    );

    if (!alreadyExists) {
      setVerifiedSocialProfiles((prev) => [
        ...prev,
        verifiedSocial,
      ]);
    } else {
      setErrorMsg(`Profile for ${selectedSocial.value} already exists!`);
    }
  };

  // ------ ADDS ENTERED PROFILE URL INTO PROFILE LIST
  const handleConfirmSocial = () => {
    const verifyUrl = enteredUrl.match(socialRegEx);
    // eslint-disable-next-line no-unused-expressions
    if (verifyUrl) {
      setIsUrlCorrect(true);
      addVerifiedSocial();
      setEnteredUrl('');
      setIsUrlCorrect(null);
    } else {
      setIsUrlCorrect(false);
      setErrorMsg(`Please enter a valid ${selectedSocial.value} URL `);
    }
  };

  // ------ REMOVES CLICKED SOCIAL FROM PROFILE LIST
  const updateSocials = async (url: { platform: string; url: string; }) => {
    // eslint-disable-next-line max-len
    const updatedProfiles = verifiedSocialProfiles.filter((profile) => profile.platform !== url.platform);
    setVerifiedSocialProfiles(updatedProfiles);
  };

  // ----- REQUEST TO ADD SOCIALS
  const addSocials = async () => {
    try {
      const response: any = hlbClient().put('/api/users/socials', {
        socials: verifiedSocialProfiles,
      });
      if (response) {
        handleClose();
        apiCall && apiCall();
        setIsListSaved(true);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Something went wrong');
    }
  };

  // ------ CHECKS ENTERED SOCIAL URL WITH REGEX
  useEffect(() => {
    /* eslint-disable no-useless-escape */
    // eslint-disable-next-line consistent-return
    // eslint-disable-next-line default-case
    switch (selectedSocial.value) {
      case 'facebook':
        setSocialRegEx('(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?');
        break;
      case 'instagram':
        setSocialRegEx('(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am)\/([A-Za-z0-9-_]+)');
        break;
      case 'linkedIn':
        setSocialRegEx('(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([A-Za-z0-9-_]+)');
        break;
      case 'twitter':
        setSocialRegEx('(https:\/\/twitter.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+))');
        break;
      case 'behance':
        setSocialRegEx('(http(s?):\/\/)?(www\.)?behance\.([a-z])+\/([A-Za-z0-9]{1,})');
        break;
      case 'reddit':
        setSocialRegEx('(http(s?):\/\/)?(www\.)?reddit\.com\/r\/([A-Za-z0-9-_]+)?');
        break;
      case 'dribbble':
        setSocialRegEx('(http(s?):\/\/)?(www\.)?dribbble\.([a-z])+\/([A-Za-z0-9]{1,})');
        break;
      case 'github':
        setSocialRegEx('(http(s?):\/\/)?(www\.)?github\.([a-z])+\/([A-Za-z0-9]{1,})+\/?');
        break;
      case 'medium':
        setSocialRegEx('(https:\/\/medium.com\/@[a-zA-z0-9])');
        break;
      case 'discord':
        setSocialRegEx('(https?:\/\/)?(www\.)?(discord\.(gg|io|me|li)|discordapp\.com\/invite)\/.+[a-z]');
        break;
      case 'portfolio':
        setSocialRegEx('(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})');
        break;
    }
  }, [selectedSocial]);

  return (
    <div className="socials--wrapper">
      <div className="socials__box">
        <div className="socials__header">
          <h4 className="header__heading">
            Add
            <span className="header__heading--highlighted">
              &nbsp;Upto Three Social Links&nbsp;
            </span>
            to showcase
            <br />
            your works on other platforms
          </h4>
          <button
            type="button"
            className="header__closeBtn"
            onClick={closeSocials}
          >
            <img src={cross} alt="Close" />
          </button>
        </div>
        <div className="socials__block">
          {
            verifiedSocialProfiles.length > 0 && (
              <div className="socials__verifiedList">
                {
                  verifiedSocialProfiles.map((profile) => (
                    <div className="verified__profile" key={profile.platform}>
                      <div className="profile__info">
                        <img
                          src={options.find((opt) => opt.value === profile.platform)?.image}
                          alt={profile.platform}
                          className="profile__info--platform"
                        />
                        <a href={profile.url} className="profile__info--url">{profile.url}</a>
                      </div>
                      <div className="profile__action">
                        <button
                          type="button"
                          onClick={() => {
                            updateSocials(profile);
                            setIsListSaved(false);
                          }}
                        >
                          <img src={cross} alt="close" />
                        </button>
                      </div>
                    </div>
                  ))
                }
              </div>
            )
          }
          {
            (verifiedSocialProfiles.length < 3) ? (
              <div className="social__input--block">
                <div
                  className={`block--wrapper ${isUrlCorrect && 'success'} ${isUrlCorrect === false && 'warning'}`}
                >
                  <Select
                    value={selectedSocial}
                    onChange={handleSocialChange}
                    options={options}
                    className="socials__dropdown"
                    formatOptionLabel={(option) => (
                      <div className="socials__option">
                        <img className="socials__option--icon" src={option.image} alt={option.value} />
                      </div>
                    )}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    styles={colorStyles}
                    isSearchable={false}
                    maxMenuHeight={230}
                  />
                  <input
                    type="text"
                    value={enteredUrl}
                    onChange={(e) => setEnteredUrl(e.target.value)}
                    className="socials__input"
                    placeholder={`Enter profile url for ${selectedSocial.value}`}
                  />
                  <button
                    type="button"
                    className={`socials__confirmButton ${isUrlCorrect ? 'green' : ''}`}
                    onClick={handleConfirmSocial}
                  >
                    +
                  </button>
                </div>
                {
                  errorMsg && (
                    <span className="socials__input--invalid">
                      {errorMsg}
                    </span>
                  )
                }
              </div>
            ) : null
          }
        </div>
        <button
          type="button"
          className="socials__addButton"
          onClick={addSocials}
        >
          Update
        </button>
      </div>
    </div>
  );
};

AddSocials.propTypes = {
  handleClose: PropTypes.func.isRequired,
  apiCall: PropTypes.func.isRequired,
};

export default AddSocials;
