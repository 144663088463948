import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import WhatsappPlugin from '../../BasicComponents/WhatsappPlugin';
import NavbarContainer from '../../BasicComponents/navbar';
import {
  rocket,
  assistant,
  stats,
  authentication,
  backLite,
  warningRed,
} from '../../BasicComponents/icons';
import { hlbClient } from '../../../Clients/hlbClient';
import '../loginSignup.css';

type Timeout = /*unresolved*/ any

const MentorSignUp: React.FC = () => {
  const [phone, setPhone] = useState('');
  const [otp, setOpt] = useState('');
  const [isPhoneFilled, setIsPhoneFilled] = useState(false);
  const [isOtpEntered, setIsOtpEntered] = useState(false);
  const [phoneErrMsg, setPhoneErrMsg] = useState('');
  const [otpErrMsg, setOtpErrMsg] = useState('');
  const [dialingCode, setDialingCode] = useState('+91');
  const [timer, setTimer] = useState(0);
  const history = useHistory();

  const setHlDetails = (token: any) => {
    const decoded: any = jwt_decode(token);
    /* eslint-disable no-undef */
    window.localStorage.setItem('hlUserId', decoded.user.id);
    window.localStorage.setItem('hlUserType', decoded.user.user_type);
  };

  const handlePhoneSubmission = async (e: any) => {
    e.preventDefault();
    if (phone && phone.match(/^[0-9]{10}$/g)) {
      try {
        const response = await hlbClient().post(
          '/api/users/phone/otp?verify=true',
          {
            phone,
          },
        );
        if (
          response.data.msg === 'OTP sent'
          || response.data.msg === 'OTP sent Again'
        ) {
          setIsPhoneFilled(true);
          setTimer(60);
        }
      } catch (err) {
        if (typeof err === 'object' && err !== null && 'response' in err) {
          const response = err as { response: { data: { msg: string } } };
          if (response.response.data.msg === 'User Already exists') {
            setPhoneErrMsg('User Already exists');
          } else {
            setPhoneErrMsg('Please enter a valid number');
          }
        }
      }
    } else {
      setPhoneErrMsg('Please enter valid number');
    }
  };

  const makeMentorSignup = async () => {
    try {
      /* eslint-disable no-undef */
      const response = await hlbClient().post('/api/users/mentorSignup', {
        phone,
      });
      if (response.data) {
        window.localStorage.setItem('token', response.data.token);
        setHlDetails(response.data.token);
        history.push('/onboarding/mentorDetailForm');
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      // window.localStorage.setItem('hlUserId', null);
      // window.localStorage.setItem('hlUserType', '');
    }
  };

  const handleOtpSubmission = async (e: any): Promise<void> => {
    e.preventDefault();
    const payload = {
      phone,
      otp,
    };
    try {
      const response = await hlbClient().post(
        '/api/users/verify',
        JSON.stringify(payload),
      );
      if (response.data) {
        setIsOtpEntered(true);
        makeMentorSignup();
      }
    } catch (err) {
      if (typeof err === 'object' && err !== null) {
        const errorResponse = err as {response?: { data?: { msg?: string; };};};
        if (
          errorResponse.response?.data?.msg === 'Enter Valid OTP' ||
          errorResponse.response?.data?.msg === 'User Already exists'
        ) {
          setOtpErrMsg(errorResponse.response.data.msg);
        }
      }
    }
  };

  const handleBackButton = () => {
    if (isPhoneFilled) {
      setIsPhoneFilled(false);
      setPhone('');
      setPhoneErrMsg('');
      setOpt('');
      setOtpErrMsg('');
      setIsOtpEntered(false);
      setTimer(0);
    }
  };

  const renderFormHeadings = () => {
    if (isPhoneFilled) {
      return (
        <h2 className="auth__message marginHelper-bottom-small">
          Enter the OTP sent to your mobile
        </h2>
      );
      // eslint-disable-next-line no-else-return
    } else {
      return (
        <h2 className="auth__message marginHelper-bottom-med">
          Signup to become a Mentor
        </h2>
      );
    }
  };

  const renderSubheadings = () => {
    if (isPhoneFilled) {
      return (
        <h2 className="auth__subheading auth__otp--subheading">
          {`We've sent it to +91 ${phone}`}
          <button
            type="button"
            className="auth__subheading--link"
            onClick={() => {
              setOpt('');
              setIsPhoneFilled(false);
            }}
          >
            Edit
          </button>
        </h2>
      );
      // eslint-disable-next-line no-else-return
    } else {
      return null;
    }
  };

  const displayComponents = () => {
    if (isPhoneFilled) {
      return (
        <form className="auth__form">
          <div className={`auth__otp--field ${otpErrMsg && 'warning'}`}>
            <input
              type="tel"
              maxLength={6}
              value={otp}
              onChange={(e) => {
                setOpt(e.target.value);
                setOtpErrMsg('');
              }}
              placeholder="Enter OTP"
              className="auth__otp"
              autoComplete="off"
              required
            />
            {otpErrMsg && (
              <p className="auth__error-msg">
                <img src={warningRed} alt="warning" />
                {otpErrMsg}
              </p>
            )}
          </div>
          {isPhoneFilled && (
            <div className="auth__otpInfo">
              <span className="auth__otpInfo--text">Didn’t receive OTP?</span>
              {timer > 0 ? (
                <span className="auth__otpInfo--timer">
                  {timer < 10 ? `0${timer}` : timer}
                </span>
              ) : (
                <button
                  type="button"
                  className="auth__otpInfo--resend"
                  onClick={handlePhoneSubmission}
                >
                  Resend
                </button>
              )}
            </div>
          )}
          <button
            className="auth__button"
            type="submit"
            disabled={otp.length < 6}
            onClick={handleOtpSubmission}
          >
            Continue
          </button>
        </form>
      );
      // eslint-disable-next-line no-else-return
    } else {
      return (
        <form className="auth__form">
          <div className={`auth__contact--field ${phoneErrMsg && 'warning'}`}>
            <select
              className="auth__dialing-code"
              onChange={(e) => setDialingCode(e.target.value)}
              value={dialingCode}
            >
              <option value="+91">+91</option>
            </select>
            <input
              className="auth__contact-no"
              type="tel"
              pattern="\d{10}"
              maxLength={10}
              value={phone}
              onChange={(e) => {
                setPhoneErrMsg('');
                setPhone(e.target.value);
              }}
              placeholder="Mobile number"
              autoComplete="off"
              required
            />
            {phoneErrMsg && (
              <p className="auth__error-msg">
                <img src={warningRed} alt="warning" />
                {phoneErrMsg}
              </p>
            )}
          </div>
          <button
            className="auth__button"
            type="submit"
            disabled={phone.length < 10}
            onClick={handlePhoneSubmission}
          >
            Continue
          </button>
        </form>
      );
    }
  };

  useEffect(() => {
    const timerValue: string | number | Timeout | undefined = timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
    return () => clearInterval(timerValue);
  }, [timer]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <NavbarContainer />
        </div>
      </div>
      <div className="auth auth__container">
        <div className="auth__card">
          <div className="card__sec card__sec--1 auth__mentor-signup">
            <img
              src={authentication}
              alt="teaching"
              className="auth__mentor-icon"
            />
            <div className="auth__mentor-benefits">
              <h2 className="mentor__benefits--header">
                Share your expertise with the world and
                <br />
                earn money while also giving back
                <br />
                to the community.
              </h2>
              <div className="mentor__benefits mentor__benefit--box">
                <img
                  src={rocket}
                  alt="rocket"
                  className="mentor__benefit--img"
                />
                <p className="mentor__benefit--text">
                  <span className="mentor__benefit--highlighted">
                    Create an amazing Hyperlearn profile
                  </span>
                  {' '}
                  and increase your online presence by at least 10×.
                </p>
              </div>
              <div className="mentor__benefits mentor__benefit--box">
                <img src={stats} alt="stats" className="mentor__benefit--img" />
                <p className="mentor__benefit--text">
                  <span className="mentor__benefit--highlighted">
                    Track down all of your sessions
                  </span>
                  {' '}
                  and earnings in one place.
                </p>
              </div>
              <div className="mentor__benefits mentor__benefit--box">
                <img
                  src={assistant}
                  alt="assistant"
                  className="mentor__benefit--img"
                />
                <p className="mentor__benefit--text">
                  <span className="mentor__benefit--highlighted">
                    Get 24×7 support
                  </span>
                  {' '}
                  for all your mentoring needs.
                </p>
              </div>
            </div>
          </div>
          <div className="card__sec card__sec--2 auth__signup">
            {(isPhoneFilled || isOtpEntered) && (
              <button
                type="button"
                className="auth__back-button"
                onClick={handleBackButton}
              >
                <img src={backLite} alt="back" />
              </button>
            )}
            <>{renderFormHeadings()}</>
            <>{renderSubheadings()}</>
            {displayComponents()}
            {!isPhoneFilled && !isOtpEntered && (
              <div className="auth__agreement .auth__agreement-details">
                <p>
                  By continuing, I accept the
                  {' '}
                  <Link to="/" className="auth__agreement--link">
                    Terms of Service
                  </Link>
                  {' '}
                  , including the
                  {' '}
                  <Link to="/" className="auth__agreement--link">
                    User Agreement
                  </Link>
                  {' '}
                  and
                  {' '}
                  <Link to="/policy/Privacy" className="auth__agreement--link">
                    Privacy Policy
                  </Link>
                </p>
              </div>
            )}
            {!isPhoneFilled && !isOtpEntered && (
              <div className="auth__alternatives">
                <p className="auth__alternatives--text">
                  Already have an account?
                </p>
                <Link to="/login" className="auth__alternatives--link">
                  Log In
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <WhatsappPlugin />
    </>
  );
};

export default MentorSignUp;
