import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import "./Dialog.css";
import { Confetti } from 'phosphor-react';

interface WaitlistSuccessDialogProps {
    open: boolean;
    onClose: () => void;
}

const WaitlistSuccessDialog = ({ open, onClose }: WaitlistSuccessDialogProps) => {
    const dialogRef = useRef<HTMLDivElement | null>(null);
    const portalRoot = document.querySelector("#portal-root");

    const handleOutsideClick = (event: MouseEvent) => {
        if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick, true);

        return () => {
            document.removeEventListener("click", handleOutsideClick, true);
        }
    }, []);

    const Dialog = () => {
        return (
            <>
            {open ? (
                <div className="dialog--wrapper">
                    <div className="dialog__container container__success" ref={dialogRef}>
                        <div className="dialog__successIcon">
                            <Confetti size={52} color='#DB00FF' />
                        </div>
                        <h2 className="container__heading" style={{ marginBottom: "20px" }}>Thanks for Joining the waitlist!</h2>
                        <p className="container__description"><span className='container__description--highlight'>Stay tuned!</span> You&apos;ll be the first to know when our tool is launched. Keep a close eye on your inbox for timely updates on the launch date!</p>
                    </div>
                </div>
            ) : undefined}
            </>
        )
    };

    return ReactDOM.createPortal(<Dialog />, portalRoot as Element);
};

export default WaitlistSuccessDialog;