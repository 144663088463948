import React, { useRef } from 'react';
import './style.css';
import { NoteCard } from '@abhijeet_hyperlearn/hlf_lib';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';

import {
  info,
  leftArrow,
  rightArrow,
} from '../../BasicComponents/icons';
import Tooltip from '../../BasicComponents/Tooltip';

type props = {
  data: {
    _id: string,
    image: { url: string },
    name: string,
    subscriberCount: number,
    cardCount: number,
    mentorImage: { url: string },
    tags: any,
    description: string;
    creatorName: string;
    shortBio: string;
  }[],
  entityType?: string,
  headerName?: string,
};

const MyDeck: React.FC<props> = ({
  data, entityType = '', headerName = '',
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const buttonRight = (scrollOffset: number) => {
    if (ref.current && ref.current.scrollLeft) {
      ref.current.scrollLeft += scrollOffset;
    }
  };
  const buttonLeft = (scrollOffset: number) => {
    if (ref.current && ref.current.scrollLeft) {
      ref.current.scrollLeft += scrollOffset;
    }
  };

  const history = useHistory();

  const toolTipText = headerName === 'My Notes'
    ? ('All the notes you have created will be found here.')
    : ('All the notes you have subscribed will be found here.');

  const getHeader = headerName ? (
    <p>
      {headerName}
      {' '}
      <Tooltip content={toolTipText} direction="top">
        <img src={info} alt="info" />
      </Tooltip>
    </p>
  ) : (
    <p>
      {(entityType !== 'dashboard' && entityType !== 'mentorProfile' && entityType !== 'redirect') && entityType}
    </p>
  );

  const btnText = entityType === 'dashboard' || entityType === 'mentorProfile' || entityType === 'redirect' ? 'EXPLORE' : 'View Cards';

  return (
    <>
      <style>
        {
          `
            .notecard__wrapper-desktop {
              display: inline-flex;
            }
          `
        }
      </style>
      <div className="my__notes__header">
        {getHeader}
        <div className="d-flex align-items-center">
          <p className="notes-count">
            {/* eslint-disable-next-line react/prop-types */}
            {data?.length}
            {' - '}
            {/* eslint-disable-next-line react/prop-types */}
            {data?.length > 1 ? 'Notes' : 'Note'}
          </p>
          <img
            src={leftArrow}
            onKeyPress={() => buttonLeft(-200)}
            role="presentation"
            alt="..."
            onClick={() => buttonRight(-200)}
            id="slideLeft"
            className="btn profile__edit-addsession--edit"
          />
          {' '}
          <img
            src={rightArrow}
            onKeyPress={() => buttonLeft(200)}
            role="presentation"
            alt="..."
            onClick={() => buttonLeft(200)}
            id="slideRight"
            className="btn profile__edit-addsession--edit"
          />
        </div>
      </div>
      <div className="slider mb-4" ref={ref} style={{ scrollBehavior: 'smooth', display: 'flex' }}>
        {/* eslint-disable-next-line react/prop-types */}
        {data && data.map((d) => (
          // eslint-disable-next-line react/jsx-key
          <NoteCard
            id={d._id}
            image={d.image.url}
            isTrending={false}
            title={d.name}
            subscriberCount={d.subscriberCount}
            cardCount={d.cardCount}
            tags={d.tags || []}
            buttonText={btnText}
            profileImage={get(d, 'mentorImage.url', '') || ''}
            description={d.description || ''}
            creatorName={d.creatorName || ''}
            creatorIntro={d.shortBio || ''}
            handleClicked={() => 
              history.push(
                entityType === 'redirect' 
                ? `/notes/flow-mode-v2/${d._id}` 
                : `/space-repitition-system/${d._id}`
              )
            }
          />
        ))}
      </div>
    </>
  );
}
export default MyDeck;
