import React from 'react';
import {
  Formik, Form, Field, FieldArray, ErrorMessage,
} from 'formik';
import { noop } from 'lodash';
import * as Yup from 'yup';
import { minusCircle, plusCircle } from '../../BasicComponents/icons';
import Label from '../../BasicComponents/InputLabel';

type StepTwoProps = {
  next: (newData: any, isFinalStep?: boolean) => void;
  data: any;
  prev: (values: any) => void;
};

const validationSchema = Yup.object().shape({
  description: Yup.string()
    .min(50, 'Description is too short!')
    .max(400, 'You have reached the limit for description!')
    .required('Cannot be left blank!'),
  keyPoints: Yup.array()
    .of(Yup.string())
    .min(3, 'Minimum 3 keypoints are required!')
    .max(8, '8 keypoints are entered!'),
  keyPoint: Yup.string()
    .matches(/^(?!\s).*$/, 'Please enter valid text!')
    .min(5, 'Keypoint is too short')
    .max(30, 'You have reached the limit for Keypoint'),
  videoLink: Yup.string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gm,
      'Please enter a valid video URL',
    ),
});

const StepTwo = ({ next, data, prev }: StepTwoProps) => {
  const handleSubmit = (values: any) => {
    next(values);
  };

  return (
    <Formik
      initialValues={data}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, setFieldValue, errors }) => (
        <Form className="formContainer__form">
          <div className="form__field form__description">
            <Label HTMLFor="webinar--description" isRequired label="Detailed Description" tooltip="Describe your webinar in detail" limitPoints={{ currentCount: values.description.length, limit: 400 }} />
            <Field
              as="textarea"
              id="webinar--description"
              name="description"
              placeholder="Here you can describe what all you cover in the webinar."
              className="form__field--textarea"
              rows="5"
            />
            <ErrorMessage component="span" name="description" className="form__field--error" />
          </div>
          <div className="form__field form__keypoints">
            <Label HTMLFor="webinar--keypoint" isRequired label="Key Points" tooltip="All the key concepts will be shown to your students in webinar." limitPoints={{ currentCount: values.keyPoints.length, limit: 8 }} />
            <FieldArray
              name="keyPoints"
              render={(arrayHelpers) => (
                <>
                  {
                    values.keyPoints && values.keyPoints.length > 0 && (
                      <div className="keypoints__list">
                        {
                          values.keyPoints.map((_point: any, index: number) => (
                            <>
                              <div key={Math.random() * 10000} className="keypoints__listItem">
                                <Field name={`keyPoints.${index}`} disabled className="form__field--inputFull keyPoints__listItem--point" />
                                <button className="keyPoints__listItem--remove" type="button" onClick={() => arrayHelpers.remove(index)}>
                                  <img src={minusCircle} alt="remove" />
                                </button>
                              </div>
                            </>
                          ))
                        }
                      </div>
                    )
                  }
                  {
                    values.keyPoints.length < 8 && (
                      <>
                        <div className="keypoints__input">
                          <Field
                            type="text"
                            id="webinar--keypoint"
                            name="keyPoint"
                            placeholder="Add Points/Concepts that students will be learning from Webinar"
                            className="form__field--inputFull"
                            autoComplete="off"
                          />
                          <button
                            type="button"
                            className="keyPoints__listItem--add"
                            onClick={!values.keyPoint || errors.keyPoint ? noop : () => {
                              arrayHelpers.push(values.keyPoint);
                              setFieldValue('keyPoint', '');
                            }}
                          >
                            <img src={plusCircle} alt="remove" />
                          </button>
                        </div>
                        <ErrorMessage component="span" name="keyPoint" className="form__field--error" />
                      </>
                    )
                  }
                </>
              )}
            />
            <ErrorMessage component="span" name="keyPoints" className="form__field--error" />
          </div>
          <div className="form__field form__videoLink">
            <Label HTMLFor="webinar--videoLink" label="Video Link" tooltip="Add a video, to help  students understand your webinar a bit better" />
            <Field
              name="videoLink"
              id="webinar--videoLink"
              type="text"
              className="form__field--inputFull"
              placeholder="https://www.youtube.com/watch?v=oluY633rkgI"
            />
            <ErrorMessage component="span" name="videoLink" className="form__field--error" />
          </div>
          <div className="form__navigationButtons">
            <button onClick={() => prev(values)} type="button" className="form__navigationButton form__navigationButton--prev">Back</button>
            <button className="form__navigationButton form__navigationButton--next" type="submit">Next</button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default StepTwo;
