import React from 'react';

/* eslint-disable react/require-default-props */
export type StatCardProps = {
  source: string;
  title1?: string;
  title2?: string;
  value1?: number | null;
  value2?: number | null;
}

const StatCard = ({
  source, title1 = '', title2 = '', value1 = null , value2 = null,
}: StatCardProps) => (
  <div className="mdashboard__card-rounded stats__card">
    <div className="card__sec--1">
      <img src={source} alt="img" className="card__img" />
    </div>
    <div className="card__sec--2">
      <p className="card__title card__title--1">{title1}</p>
      <p className="card__title card__title--2">{title2}</p>
    </div>
    <div className="card__sec--3">
      <p className="card__value card__value--1">{value1}</p>
      <p className="card__value card__value--2">{value2}</p>
    </div>
  </div>
);

export default StatCard;
