type CuratedNotesType = {
    type: "note" | "webinar" | "session",
    containerLabel: string,
    search: string,
    filter: any[],
    ids: string[],
}[];

import { myConfig } from "../../config";


export const prodConfig: CuratedNotesType = [
    {
        type: "note",
        containerLabel: "Featured Notes",
        search: "",
        ids: [
            "64ae8bceed29ffb6423883b3",
            "643a5e3b6c6e526a0dcfa544",
            "63a48e0fd0119b64e6e54226",
            "64671045557c4ca2fdb3ddf5"
        ],
        filter: []
    },
    {
        type: "note",
        containerLabel: "Current affairs",
        search: "Current Affairs",
        ids: [],
        filter: []
    }   
]

export const stagingConfig: CuratedNotesType = [
    {
        type: "note",
        containerLabel: "Premium Notes",
        search: "",
        ids: [],
        filter: [
            {
                key: "price",
                value: {
                    gte: 100,
                },
            },
        ],
    },
    {
        type: "note",
        containerLabel: "Popular Notes",
        search: "",
        ids: [],
        filter: [
            {
                key: "subscriberCount",
                value: {
                    gte: 10,
                },
            },
        ],
    },
    {
        type: "note",
        containerLabel: "React Notes",
        search: "react",
        ids: [],
        filter: [],
    },
];

export const CuratedNotes =  myConfig.environment === 'production' ? prodConfig : stagingConfig;