// single chocie card

import React, { useState } from "react";
import HtmlParser from "../../../BasicComponents/htmlParser";
import _ from "lodash";
import { SCQCard, OptionType } from "../../types";

const SingleChoiceCard: React.FC<SCQCard> = ({
  questionImg = null, questionOption, questionText,
  verifyAnswer, id, reason, disableInteraction = false, done
}) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<null | number>(null);
  const [background, setBackground] = useState<{background: string, color: string }>({background: '', color: ''});

  const wrongAnswerColor = {
    background: 'red',
    color: '#fff',
  };

  const correctAnswerColor = {
    background: 'green',
    color: '#fff',
  }
  const selectOption = (index: number, selectedOption: OptionType, questionOption: OptionType[]) => {
    setSelectedOptionIndex(index);
    const result = verifyAnswer('scq', { selectedOption, questionOption });
    if (result) {
      setBackground(correctAnswerColor);
    } else {
      setBackground(wrongAnswerColor);
    }
    done('scq', result); 
  };

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <div className="mobile__anki--card-container">
    <div className="">
      {_.get(questionImg, 'file', '') && (
        <div className="mobile__anki--image-Container">
          <img
            src={questionImg?.file}
            alt=""
            className={'mobile__anki--card-container-image'}
          />
        </div>
      )}
      <p className="mobile__anki--paragraph">
        <HtmlParser text={questionText} />
      </p>
      {questionOption &&
        questionOption.map((item: OptionType, index: number) => (
              <div
                role="presentation"
                className={
                  selectedOptionIndex !== index
                    ? 'single-option-dev'
                    : 'single-option-dev-green'
                }
                style={selectedOptionIndex === index ? background : {}}
                key={item._id || index.toString()}
                onClick={() => {
                  if(!disableInteraction) {
                    selectOption(index, item, questionOption);
                  }
                }}
              >
                <p className="single-option-dev--text">{item.optionText}</p>
              </div>
        ))}
    </div>
  </div>
  )
}

export default SingleChoiceCard;