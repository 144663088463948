// multiple chocie card

import React, { useState } from "react";
import HtmlParser from "../../../BasicComponents/htmlParser";
import _ from "lodash";
import { MCQCard, OptionType } from "../../types";

const MultipleChoiceCard: React.FC<MCQCard> = ({
  questionImg = null, questionOption, questionText,
  verifyAnswer, id, reason, disableInteraction = false, done,
}) => {

  const [background, setBackground] =  useState<{background: string, color: string }>({background: '', color: ''});

  const [selectedOption, setSelectedOption] = useState<OptionType[]>([]);

  const wrongAnswerColor = {
    background: 'red',
    color: '#fff',
  };

  const correctAnswerColor = {
    background: 'green',
    color: '#fff',
  }

  const selectedOptionMap = selectedOption?.reduce(
    (acc: {[key: string]: OptionType }, item: OptionType) => ({ ...acc, [item._id]: item }),
    {}
  );

  const removeOption = (id: string) => {
    setSelectedOption(
      selectedOption.filter((option: { _id: string }) => option._id !== id)
    );
  };

  const selectOption = (option: OptionType) => {
    setSelectedOption([...selectedOption, option])
  }

  const checkIfAllOptionsAreCorrect = () => {
    // const questionCorrect = questionOption.filter(
    //   (x: OptionType) => x && x.isCorrect
    // );
    // const answerCorrect = SelectedOption.filter(
    //   (x: OptionType) => x && x.isCorrect
    // );

    const result= verifyAnswer('mcq', { selectedOption, questionOption });

    if (result) {
      setBackground(correctAnswerColor);
    } else {
      setBackground(wrongAnswerColor);
    }

    done('mcq', result);
  };

  return  (   // eslint-disable-next-line react/jsx-filename-extension
  <div className="mobile__anki--card-container">
    <div className="">
      {_.get(questionImg, 'file', '') && (
        <div className="mobile__anki--image-Container">
          <img
            src={questionImg?.file}
            alt=""
            className={
              disableInteraction
                ? 'mobile__anki--card-container-image'
                : 'mobile__anki--card-container-image--preview'
            }
          />
        </div>
      )}
      <p className="mobile__anki--paragraph">
        <HtmlParser text={questionText} />
      </p>
      {questionOption &&
        questionOption.map((item: any, index: number) => (
                  <div
                    role="presentation"
                    className={
                      selectedOptionMap[item?._id]?._id !== item?._id
                        ? 'single-option-dev'
                        : 'single-option-dev-green'
                    }
                    style={
                      selectedOptionMap[item?._id]?._id === item?._id
                        ? background
                        : {}
                    }
                    key={index.toString()}
                    onClick={() => {
                      if(!disableInteraction) {
                        if (!(item._id in selectedOptionMap)) {
                          selectOption(item);
                        } else {
                          removeOption(item._id);
                        }
                      }

                    }}
                  >
                    <p className="single-option-dev--text">
                      {item?.optionText}
                    </p>
                  </div>
            ))}
        <button
          className="multiple-option--done-btn"
          type="button"
          onClick={() => {
            if (!disableInteraction){
              checkIfAllOptionsAreCorrect();
            }
          }}
        >
          Done
        </button>
    </div>
  </div>
);
}

export default MultipleChoiceCard;