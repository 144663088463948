import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import ReactDOM from 'react-dom';
import _, { noop } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch } from 'react-redux';
import isToday from 'dayjs/plugin/isToday';
import weekday from 'dayjs/plugin/weekday';
import utc from 'dayjs/plugin/utc';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { hlbClient } from '../../Clients/hlbClient';
import { AuthContext } from '../../provider/AuthContext';
import StatCard from './StatCard';
import { Accordion, EnlistedAccordion, LeadsAccordion } from './accordion';
import ProfileProgress from './profileProgressBar';
import './MentorDashboard.css';
import {
  assistance, moneyBag, downLite, rightArrow, previous,
  purplePlus, searchLite, clock, usersThree, coins,
  settingPurple,
} from '../BasicComponents/icons';
import 'react-datepicker/dist/react-datepicker.css';
import NavbarContainer from '../BasicComponents/navbar/navbarContainer';

import FirstSession from '../firstSession/firstSession';
import { addToast } from '../../reducers/toast.reducer';
import ToastMessage from '../BasicComponents/toastMessage';
import Pagination from './Pagination';
import AttendeeList from './AttendeeList/AttendeeList';
import Empty from '../BasicComponents/Empty';
import { DeckCardLoader } from '../BasicComponents/ContentLoader';
import FeatureFlag from '../FeatureFlag';
import { formatFees, getTimeDifference } from '../Utils';
import MyDeck from '../hyperNotes/manage/myDeck';
import WhatsappPlugin from '../BasicComponents/WhatsappPlugin';

dayjs.extend(isToday);
dayjs.extend(utc);
dayjs.extend(weekday);

const MentorDashboard = () => {
  const [availableResults, setAvailableResults] = useState(5);
  const [availEnlistedResults, setAvailEnlistedResults] = useState(3);
  const { authState, setStateUpdate, stateUpdate } = useContext(AuthContext);
  const id = authState._id;
  // eslint-disable-next-line no-undef
  const token = window.localStorage.getItem('token');
  const [data, setData] = useState([]);
  const [mentorId, setMentorId] = useState('');
  const [statsData, setStatsData] = useState([]);
  const [enlistedData, setEnlistedData] = useState([]);
  const [leadsData, setLeadsData] = useState([]);
  const [bookingsList, setBookingsList] = useState([]);
  const [webinarList, setWebinarList] = useState([]);
  const [selectedWebinar, setSelectedWebinar] = useState({});
  const [userName, setUserName] = useState('');
  const [profilePercentage, setProfilePercentage] = useState<number | undefined>();
  const [profileChecklist, setProfileChecklist] = useState([]);
  const [checked, setChecked] = useState<boolean | undefined>();
  const [addShow, setAddShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [selectedEditSession, setSelectedEditSession] = useState(null);
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [modalType, setModalType] = useState('');
  const [popUpHeading, setPopUpHeading] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [leadsPerPage] = useState(6);
  const [showAttendeeList, setShowAttendeeList] = useState(false);
  const dispatch = useDispatch();

  const [selectedTimeframe, setSelectedTimeframe] = useState('todays');

  const enlistedShowMore = () => {
    setAvailEnlistedResults((prevState) => prevState + 3);
  };

  const handleShowMore = () => {
    setAvailableResults((prevState) => prevState + 3);
  };

  const handleBookings = async () => {
    setChecked(!checked);
    await hlbClient().put('/api/scheduler/availability', { isAvailable: !checked });
  };

  const limitWordLength = (string: string, limit = 14) => {
    if (string.length > limit) {
      return `${string.substring(0, limit)}...`;
    }
    return string;
  };
  const getSessionType = (sessionType: string) => {
    switch (sessionType) {
      case 'one-on-one':
        return '(1x1)';
      default:
        return `(${sessionType})`;
    }
  };
  const capitalize = (words: string) => {
    const first = words.charAt(0).toUpperCase();
    const rest = words.slice(1);
    return first + rest;
  };

  const processString = (str: string) => str
    .split(/(?=[A-Z])/)
    .map((p) => p[0].toUpperCase() + p.slice(1))
    .join(' ');

  const correctTime = (dateObj: Date | string): string => {
    const helperDate = new Date(dateObj);
    let hours = helperDate.getHours();
    let minutes = helperDate.getMinutes();
    const AmOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = (hours % 12) || hours;
    minutes = Number(minutes < 10 ? `0${minutes}` : minutes);
    const timeString = `${hours}:${minutes} ${AmOrPm}`;
    return timeString;
  };

  const closeEditSession = () => setEditShow(false);
  const ref = useRef<HTMLDivElement>(null);

  const fetchTimeframeBookings = (timeframe: string): { from: dayjs.Dayjs; to: dayjs.Dayjs } => {
    const timeRange: { from: dayjs.Dayjs; to: dayjs.Dayjs } = {
      from: dayjs(),
      to: dayjs(),
    };

    switch (timeframe) {
      case 'todays':
        timeRange.from = dayjs().startOf('day');
        timeRange.to = dayjs().endOf('day');
        break;
      case 'nextSevenDays':
        timeRange.from = dayjs().add(1, 'day').startOf('day');
        timeRange.to = dayjs().add(1, 'week').endOf('day');
        break;
      case 'upcoming':
        timeRange.from = dayjs().add(1, 'day').startOf('day');
        timeRange.to = dayjs().add(1, 'month').endOf('day');
        break;
      default:
        timeRange.from = dayjs().year(2020).startOf('day');
        timeRange.to = dayjs().subtract(1, 'days').endOf('day');
        break;
    }
    return timeRange;
  };

  const fetchEnlistedSessions = async (): Promise<void> => {
    try {
      const response = await hlbClient().get(`/api/scheduler/session?mentorId=${mentorId}`);
      setEnlistedData(response.data);
      setAddShow(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Something went wrong! 🤖', err);
    }
  };

  const deleteSession = async () => {
    try {
      await hlbClient().delete(`/api/scheduler/session/${selectedSessionId}`);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Something went wrong!');
    }
    dispatch(addToast({ type: 'success', message: 'Session Deleted Successfully' }));
    fetchEnlistedSessions();
    setDeleteShow(false);
  };

  // ------- CALCULATE PAGINATION PAGES
  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = leadsData && leadsData.slice(indexOfFirstLead, indexOfLastLead);

  useEffect(() => {
    if (token) {
      setStateUpdate(!stateUpdate);
    }
  }, []);

  // ------------ TO FETCH PROFILE PROGRESS DATA
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await hlbClient().get(`/api/users/${id}/profile`);
        setData(response.data[0]);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Something went wrong! 🤖');
      }
    };
    fetchData();
  }, [id]);

  // ------------ TO FETCH STATS DATA
  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await hlbClient().get('/api/dashboardV2/stats');
        setStatsData(response.data);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Something went wrong! 🤖');
      }
    };
    fetchStats();
  }, []);

  // --------------- TO FETCH ENLISTED SESSIONS DATA
  useEffect(() => {
    fetchEnlistedSessions();
  }, [mentorId]);

  // --------------- TO FETCH Q&A LEADS DATA
  useEffect(() => {
    const fetchQALeads = async () => {
      try {
        const response = await hlbClient().get('/api/mentor/leads');
        setLeadsData(response.data);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Something went wrong! 🤖');
      }
    };
    fetchQALeads();
  }, []);

  // -------------- TO FETCH BOOKINGS OF SELECTED TIME FRAME
  useEffect(() => {
    const fetchBookings = async () => {
      const timerange = fetchTimeframeBookings(selectedTimeframe);
      const response = await hlbClient().post('/api/dashboardV2/bookings', {
        ...timerange,
      });
      setBookingsList(response.data);
    };
    fetchBookings();
  }, [selectedTimeframe]);

  // -------------- TO FETCH MENTORS WEBINARS
  const fetchWebinars = async () => {
    try {
      const response = await hlbClient().get('/api/webinar/getMyWebinars');
      setWebinarList(response.data.result);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    mentorId && fetchWebinars();
  }, [mentorId]);

  useEffect(() => {
    if (data) {
      setUserName(_.get(data, 'userName', 'There'));
      setProfilePercentage(Math.floor(_.get(data, 'percentage', 0) * 100));
      setProfileChecklist(_.get(data, 'checkList', []));
      setChecked(_.get(data, 'availability'));
      setMentorId(_.get(data, 'user', ''));
    }
  }, [data]);

  useEffect(() => {
    if (token) {
      setStateUpdate(!stateUpdate);
    }
  }, [token]);
  const history = useHistory();
  // eslint-disable-next-line camelcase
  const { profile } = authState;
  const [myDeck, setMyDeck] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getMyDeck = async () => {
    try {
      const resp = await hlbClient().get('/api/srs/mydecks');
      if (resp.data.success) {
        setMyDeck(resp.data.result);
        setIsLoading(false);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getMyDeck();
  }, []);

  useEffect(() => {
    if (!profile) {
      history.push('/onboarding/mentorDetailForm');
    }
  }, [profile]);

  const renderAttendeeList = (open: boolean) => {
    if (!open) return null;
    const portalRoot = document.querySelector('#portal-root');
    if (portalRoot) {
      return ReactDOM.createPortal(
        <AttendeeList webinar={selectedWebinar} onClose={() => setShowAttendeeList(false)} fetchWebinars={fetchWebinars} />,
        portalRoot
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <NavbarContainer />
        </div>
      </div>
      <div className="mdashboard--wrapper">
        <div className="mdashboard">
          <ProfileProgress
            percentages={profilePercentage || 0}
            checklistData={profileChecklist}
            processString={processString}
          />
          <div className="mdashboard__header">
            <h3 className="header--heading">
              Hi
              {' '}
              {userName}
              !
            </h3>
          </div>
          <section className="mdashboard__stats">
            <StatCard
              source={assistance}
              title1="Sessions for today"
              title2="Sessions this week"
              value1={_.get(statsData, 'bookingsToday', 0)}
              value2={_.get(statsData, 'bookingslastWeek', 0)}
            />
            <StatCard
              source={moneyBag}
              title1="Earnings today"
              title2="Earnings this week"
              value1={_.get(statsData, 'earningsToday', 0)}
              value2={_.get(statsData, 'earningsLastSevenDays', 0)}
            />
            {/* <StatCard
              source={emailNotification}
              title1="New enquiries"
              title2="Total enquiries"
              value1="12"
              value2="25"
            /> */}
          </section>
          <FeatureFlag name="webinar">
            <section className="mdashboard__webinar">
              <div className="webinar__head">
                <h3 className="mdashboard__webinar--title">
                  Your webinars
                  {' '}
                  {
                    webinarList.length > 0 && (`(${webinarList.length})`)
                  }
                </h3>
                <div className="navigation__controls">
                  {webinarList?.length > 0 && (
                    <button
                      type="button"
                      className="navigation__add--webinar"
                      onClick={() => history.push('/webinar/create')}
                    >
                      Create
                    </button>
                  )}
                  {
                    webinarList?.length > 0 ? (
                      <>
                        <button
                          type="button"
                          className="navigation__controls--button"
                          onClick={() => {
                            if (ref.current) {
                              ref.current.scrollLeft += -620;
                            }
                          }}
                          disabled={webinarList?.length === 0}
                        >
                          <img src={previous} alt="prev" />
                        </button>
                        <button
                          type="button"
                          className="navigation__controls--button"
                          onClick={() => {
                            if (ref.current) {
                              ref.current.scrollLeft += 620; 
                            }
                          }}
                          disabled={webinarList?.length === 0}
                        >
                          <img src={rightArrow} alt="next" />
                        </button>
                      </>
                    ) : null
                  }
                </div>
              </div>
              {
                webinarList.length ? (
                  <div className="webinar__events" ref={ref}>
                    {
                      webinarList.map((webinar: any) => (
                        <div
                          className="event__card"
                          key={webinar._id}
                        >
                          <div className="event__head">
                            <h2 className="webinar--name">{limitWordLength(webinar?.title, 20)}</h2>
                            <div className="event__options">
                              <button
                                type="button"
                                className="event__option event__option--view"
                                onClick={() => {
                                  setSelectedWebinar(webinar);
                                  setShowAttendeeList(true);
                                }}
                              >
                                View
                              </button>
                              <button
                                type="button"
                                className="event__option event__option--edit"
                                onClick={() => history.push(`/webinar/edit/${webinar?._id}`)}
                              >
                                Edit
                              </button>
                              <button type="button" className="event__option event__option--delete">Delete</button>
                            </div>
                          </div>
                          <h2 className="event__date">
                            On
                            {' '}
                            {dayjs(webinar?.startTime).format('D MMM, YYYY')}
                            {' '}
                            at
                            {' '}
                            {dayjs(webinar?.startTime).format('hh:mmA')}
                          </h2>
                          <div className="event__info">
                            <div className="event__info--chip event__duration">
                              <img src={clock} alt="clock" />
                              <span>{getTimeDifference(webinar?.startTime, webinar?.endTime)}</span>
                            </div>
                            <div className="event__info--chip event__attendees">
                              <img src={usersThree} alt="clock" />
                              <span>
                                {webinar?.totalAttendees}
                                {' '}
                                Attendees
                              </span>
                            </div>
                            <div className="event__info--chip event__feesCollected">
                              <img src={coins} alt="clock" />
                              <span>
                                {formatFees(webinar?.totalMoneyCollected)}
                                {' '}
                                Collected
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                ) : (
                  <div className="webinar__noEvents">
                    <h2 className="webinar__noEvents--title">No upcoming webinars!</h2>
                    <button type="button" className="webinar__noEvents--createButton" onClick={() => history.push('/webinar/create')}>+ Create Webinar</button>
                  </div>
                )
              }
            </section>
          </FeatureFlag>
          <section className="mdashboard__sessions">
            <div className="mdashboard__card-rounded sessions__container sessions__bookings">
              <div className="sessions__header">
                <h3 className="sessions__heading--main">Your Sessions</h3>
                <p className="sessions__heading--sub">Manage your sessions from here</p>
              </div>
              <div className="sessions__section">
                <div className="sessions__timeframe">
                  <div className="timeframe__tabs">
                    <div
                      className={`timeframe__tab ${selectedTimeframe === 'completed' ? 'timeframe__tab--active' : ''}`}
                      onClick={() => setSelectedTimeframe('completed')}
                    >
                      Completed
                    </div>
                    <div
                      className={`timeframe__tab ${selectedTimeframe === 'todays' ? 'timeframe__tab--active' : ''}`}
                      onClick={() => setSelectedTimeframe('todays')}
                    >
                      Today&apos;s
                    </div>
                    <div
                      className={`timeframe__tab ${selectedTimeframe === 'nextSevenDays' ? 'timeframe__tab--active' : ''}`}
                      onClick={() => setSelectedTimeframe('nextSevenDays')}
                    >
                      Next 7 days
                    </div>
                    <div
                      className={`timeframe__tab ${selectedTimeframe === 'upcoming' ? 'timeframe__tab--active' : ''}`}
                      onClick={() => setSelectedTimeframe('upcoming')}
                    >
                      Upcoming
                    </div>
                  </div>
                </div>
                <div className="sessions__toggle">
                  <label htmlFor="toggle">Accept new booking</label>
                  <label className="sessions__switch">
                    <input
                      id="toggle"
                      type="checkbox"
                      defaultChecked={_.get(data, 'availability', true)}
                      onClick={handleBookings}
                      checked={checked}
                    />
                    <span className="sessions__switch--slider" />
                  </label>
                </div>
              </div>
              {/*  eslint-disable max-len */ }
              {/* <div className="sessions__calendar">
                <div className="sessions__calendar--wrapper">
                  <button
                    type="button"
                    onClick={() => navigate(-60)}
                    className="calendar__navigate calendar__navigate--left"
                  >
                    <img src={leftArrow} alt="prev" />
                  </button>
                  <div className="calendar__dates" ref={ref}>
                    {
                      dates.map((date, i) => (
                        <button
                          type="button"
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          className={`calendar__dates--date ${selectedDate === date ? 'selected' : ''}`}
                          onClick={() => loadBookings(date)}
                        >
                          <span className="calendar__dates--day">
                            { days[dayjs(date).get('day')].toUpperCase()}
                          </span>
                          <span className="calendar__dates--no">
                            { dayjs(date).get('date')}
                          </span>
                        </button>
                      ))
                    }
                  </div>
                  <button
                    type="button"
                    onClick={() => navigate(60)}
                    className="calendar__navigate calendar__navigate--right"
                  >
                    <img src={rightArrow} alt="next" />
                  </button>
                </div>
                <div className="sessions__calendar--wrapper">
                  <div className={`sessions__datepicker ${startDate && endDate ? 'react-datepicker--highlighted' : ''}`}>
                    <DatePicker
                      onChange={handleDatepicker}
                      startDate={startDate}
                      dateFormat="dd/MM/yyyy"
                      endDate={endDate}
                      minDate={dateToday}
                      selectsRange
                      placeholderText="Select date range"
                    />
                    <img src={calendarLite} alt="calendar" />
                  </div>
                </div>
              </div> */}
              <div className="upcoming__sessions--wrapper">
                <div className="upcoming__label">
                  <p className="upcoming__label--mentee">Mentee</p>
                  <p className="upcoming__label--session-type">Session type</p>
                  <p className="upcoming__label--duration">Duration</p>
                  <p className="upcoming__label--booking-day">Session on </p>
                  <p className="upcoming__label--starts-at">Starts at</p>
                </div>
                {
                  bookingsList.length ? (
                    <>
                      <div className="upcoming__sessions--container">
                        {
                            bookingsList.slice(0, availableResults).map((el, i) => (
                              <Accordion
                                elData={el}
                                correctTime={correctTime}
                                getSessionType={getSessionType}
                                capitalize={capitalize}
                                limitWordLength={limitWordLength}
                                // eslint-disable-next-line react/no-array-index-key
                                key={i}
                              />
                            ))
                          }
                      </div>
                      <div className="button__showmore">
                        <button
                          type="button"
                          onClick={handleShowMore}
                          // eslint-disable-next-line max-len
                          disabled={availableResults === bookingsList.length || bookingsList.length < availableResults}
                        >
                          {availableResults === bookingsList.length || bookingsList.length < availableResults ? 'No more bookings found!' : 'Show more'}
                          {availableResults === bookingsList.length || bookingsList.length < availableResults ? null : <img src={downLite} alt="down" />}
                        </button>
                      </div>
                    </>
                  ) : (
                    <p className="upcoming__sessions--message">
                      <img src={searchLite} alt="Search" />
                      No bookings found!
                    </p>
                  )
                }
              </div>
            </div>
          </section>
          <section className="mdashboard__enlisted">
            <div className="enlisted__header mb-3">
              <h3 className="enlisted__header--heading">
                My Notes
              </h3>
              <button
                type="button"
                className="enlisted__header--button"
                onClick={() => {
                  history.push('space-repitition-system');
                }}
                onKeyPress={() => {
                  setAddShow(true);
                }}
              >
                <img src={settingPurple} alt="Add" />
                Manage
              </button>
            </div>
            <div className="mdashboard__card-rounded enlisted__sessions--container">
              {!isLoading ? (
                <>
                  {myDeck.length !== 0 ? <MyDeck data={myDeck} entityType="dashboard" /> : <Empty onClick={noop} type="normal" msg="No notes found!" />}
                </>
              ) : (
                <div className="col-md-12">
                  <div className="row">
                    {[...Array(3)].map(() => (
                      // eslint-disable-next-line react/jsx-key
                      <div className="col-12 col-sm-6 col-md-4">
                        <DeckCardLoader />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </section>
          <div className="mdashboard__mini-sections">
            <section className="mdashboard__leads">
              <div className="leads__header">
                <h3 className="leads__header--heading">
                  Q&A leads
                </h3>
              </div>
              <div className="mdashboard__card-rounded leads__sessions--container">
                {
                  leadsData.length ? (
                    <>
                      <div className="leads__sessions--wrapper">
                        {
                          currentLeads.map((el, index) => (
                            <LeadsAccordion
                              elData={el}
                              key={Math.random()}
                              index={index}
                              correctTime={correctTime}
                              limitWordLength={limitWordLength}
                              capitalize={capitalize}
                            />
                          ))
                        }
                      </div>
                      <Pagination
                        recordsPerPage={leadsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        data={leadsData}
                      />
                    </>
                  ) : (
                    <p className="upcoming__sessions--message">
                      <img src={searchLite} alt="Search" />
                      No Q&A bookings found!
                    </p>
                  )
                }
              </div>
            </section>
            <section className="mdashboard__enlisted">
              <div className="enlisted__header">
                <h3 className="enlisted__header--heading">
                  Enlisted sessions
                </h3>
                <button
                  type="button"
                  className="enlisted__header--button"
                  onClick={() => {
                    setAddShow(true);
                    setModalType('addSession');
                    setPopUpHeading('Create your mentoring session');
                  }}
                  onKeyPress={() => {
                    setAddShow(true);
                  }}
                >
                  <img src={purplePlus} alt="Add" />
                  Create session
                </button>
              </div>
              <div className="mdashboard__card-rounded enlisted__sessions--container">
                {
                  enlistedData.length ? (
                    <>
                      <div className="enlisted__sessions--wrapper">
                        {
                          enlistedData.slice(0, availEnlistedResults).map((el, index) => (
                            <EnlistedAccordion
                              elData={el}
                              key={Math.random()}
                              index={index}
                              limitWordLength={limitWordLength}
                              getSessionType={getSessionType}
                              capitalize={capitalize}
                              setSelectedEditSession={setSelectedEditSession}
                              setEditShow={setEditShow}
                              setDeleteShow={setDeleteShow}
                              setSelectedSessionId={setSelectedSessionId}
                            />
                          ))
                        }
                      </div>
                      <div className="button__showmore">
                        <button
                          type="button"
                          onClick={enlistedShowMore}
                          // eslint-disable-next-line max-len
                          disabled={availEnlistedResults === enlistedData.length || enlistedData.length < availEnlistedResults}
                        >
                          { availEnlistedResults === enlistedData.length || enlistedData.length < availEnlistedResults ? 'No more sessions found!' : 'Show more' }
                          { availEnlistedResults === enlistedData.length || enlistedData.length < availEnlistedResults ? null : <img src={downLite} alt="down" /> }
                        </button>
                      </div>
                    </>
                  ) : (
                    <p className="upcoming__sessions--message">
                      <img src={searchLite} alt="Search" />
                      No Enlisted Sessions found!
                    </p>
                  )
                }
              </div>
            </section>
          </div>
        </div>
      </div>
      {/* ------ ADD SESSION MODAL */}
      <Modal show={addShow} size={modalType === 'editProfile' ? 'lg' : 'sm'} centered onHide={() => setAddShow(false)}>
        <Modal.Header style={{ paddingBottom: '0px' }}>
          <Modal.Title style={{ fontWeight: '500', fontSize: '28px' }}>
            {popUpHeading}
          </Modal.Title>
          <button
            type="button"
            onClick={() => setAddShow(false)}
            className="btn-close float-end"
          />
        </Modal.Header>
        <Modal.Body style={{ paddingTop: '0px' }}>
          {modalType === 'addSession' && (
            <>
              <FirstSession entityType="editPage" apiCall={fetchEnlistedSessions} />
            </>
          )}
        </Modal.Body>
      </Modal>
      {/* ------ EDIT SESSION MODAL */}
      <Modal show={editShow} centered onHide={() => setEditShow(false)}>
        <Modal.Header style={{ paddingBottom: '0px' }}>
          <Modal.Title>Edit session</Modal.Title>
          <button
            type="button"
            onClick={() => setEditShow(false)}
            className="btn-close float-end"
          />
        </Modal.Header>
        <Modal.Body style={{ paddingTop: '0px' }}>
          <FirstSession
            entityType="editPage"
            compType="sessionCard"
            sessionData={selectedEditSession}
            sessionId={selectedSessionId}
            apiCall={fetchEnlistedSessions}
            closeEditSession={closeEditSession}
          />
        </Modal.Body>
      </Modal>
      {/* ------ DELETE SESSION MODAL */}
      <Modal
        show={deleteShow}
        centered
        onHide={() => setDeleteShow(false)}
        className="custom-modal-style"
      >
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <button
                type="button"
                onClick={() => setDeleteShow(false)}
                className="btn-close float-end"
              />
            </div>
          </div>
          <div className="col-12 text-center">
            <p className="sessionCard__deleteMessage">Are you sure you want to delete?</p>
            <p className="sessionCard__deleteMessage-sub-text">After deleting the session you will not be able to get it back.</p>
            <button type="button" className=" btn sessionCard__cancel-btn" onClick={() => setDeleteShow(false)}>Cancel</button>
            <button type="button" className="btn sessionCard__delete-btn" onClick={() => deleteSession()}>Delete</button>
          </div>
        </Modal.Body>
      </Modal>
      {/* -------- ATTENDEE LIST MODAL -------- */}
      {renderAttendeeList(showAttendeeList)}
      <ToastMessage />
      <WhatsappPlugin />
    </>
  );
};

export default MentorDashboard;
