import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import "./Dialog.css";
import { myConfig } from '../../config';

interface SkipWaitlistDialogProps {
    open: boolean;
    onClose: () => void;
}

const SkipWaitlistDialog = ({ open, onClose }: SkipWaitlistDialogProps) => {
    const dialogRef = useRef<HTMLDivElement | null>(null);
    const portalRoot = document.querySelector("#portal-root");

    const handleOutsideClick = (event: MouseEvent) => {
        if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
            onClose();
        }
    };
    const skipWaitlistUrl = myConfig.skipWaitlistUrl;

    const handleRedirectUser = () => {
        window.open(skipWaitlistUrl, "_blank");
    }


    useEffect(() => {
        document.addEventListener("click", handleOutsideClick, true);

        return () => {
            document.removeEventListener("click", handleOutsideClick, true);
        }
    }, []);

    const Dialog = () => {
        return (
            <>
            {open ? (
                <div className='dialog--wrapper'>
                    <div className="dialog__container" ref={dialogRef}>
                        <h2 className="container__heading">Want to <span className="container__heading--highlight">Skip the Waitlist</span> and get started right away?</h2>
                        <p className="container__description" style={{ marginBottom: "20px" }}>
                            If you have your content ready and want to kickstart the publishing process, we&apos;re here to help at an <span className='container__description--highlight'>exclusive rate of ₹4500.</span> Our services include expert formatting and optimisation to ensure your content shines. Let&apos;s bring your ideas to life!
                        </p>
                        <button className="container__action container__action--primary" style={{ marginBottom: "12px" }} onClick={handleRedirectUser}>
                            Let&apos;s Go!
                        </button>
                        <button onClick={onClose} className="container__action container__action--secondary">No, will join the waitlist</button>
                    </div>
                </div>
            ) : undefined}
            </>
        );
    };

    return ReactDOM.createPortal(<Dialog />, portalRoot as Element);
};

export default SkipWaitlistDialog;