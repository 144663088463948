import React, { HTMLAttributes } from 'react';
import './ResendOtp.css';

interface ResendOtpProps extends HTMLAttributes<HTMLDivElement> {
    handleResend: () => void;
    timer: number;
}

const ResendOtp = ({ handleResend, timer, ...rest } : ResendOtpProps) => {
    return (
        <div className="resendOtp" {...rest}>
            <button
                type='button'
                className='resendOtp-action'
                onClick={handleResend}
                disabled={timer > 0}
            >
                Resend OTP
            </button>
            {
                timer !== 0 && (
                    <span className="resendOtp-message">
                        in {timer}s
                    </span>
                )
            }
        </div>
    );
};

export default ResendOtp;