import { Smiley, XCircle } from 'phosphor-react';
import React, {
  useState, useEffect, useContext,
} from 'react';
import { useHistory } from 'react-router';
import { truncate } from 'lodash';
import { completionMessage } from '../noteConstant';
import { hlbClient } from '../../../Clients/hlbClient';
import PaymentDialog from '../../PaymentDialog';
import { CopySimple, ShareNetwork, ArrowsClockwise } from '../../BasicComponents/icons';
import { AuthContext } from '../../../provider/AuthContext';
import CopyToClipBoard from '../../BasicComponents/copyToClipboard/copyToClipBoard';
import ShareWithSocialMedia from '../../BasicComponents/shareWithSocialMedia/shareWithSocialMedia';
import { AuthModal } from '../../Auth/Modal';

type props = {
  innHeight: number,
  name: string,
  noteId: string,
  data?: {
    cards: any[];
    discountedPrice: number,
    price: number,
    name: string,
    creatorName: string,
    _id: string,
    subscriberCount: number,
    difficulty: string,
    image: { url: string },
  } | any,
}

const ConclusionMobile: React.FC<props> = ({
  innHeight, name, noteId,
  data = {},
}) => {
  const history = useHistory();
  const [textValue, setTextValue] = useState<{ heading: string, content: string } | null>(null);
  const [isSubs, setIsSubs] = useState(true);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  // eslint-disable-next-line no-undef
  const token = window.localStorage.getItem('token');
  const { authState, setStateUpdate, stateUpdate } = useContext(AuthContext);
  const userId = authState?._id;
  // eslint-disable-next-line no-undef
  const noteURL = window.location.href;

  useEffect(() => {
    if (token) {
      setStateUpdate(!stateUpdate);
    }
  }, []);

  useEffect(() => {
    const len = completionMessage.length;
    const randomIndex: number = Math.floor(Math.random() * len);
    const randomContent: { heading: string, content: string } = completionMessage[randomIndex];
    setTextValue(randomContent);
  }, []);

  const reloadPage = () => {
    // eslint-disable-next-line no-undef
    window.location.reload();
  };

  const isSubscribedDeck = async () => {
    try {
      const resp = await hlbClient().get(`/api/srs/deck/isDeckSubscribed/${noteId}`);
      if (resp.data.success) {
        setIsSubs(resp.data.result);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  useEffect(() => {
    if (noteId) {
      isSubscribedDeck();
    }
  }, [noteId, isPaymentModalOpen]);

  const isFree = data?.discountedPrice === 0 && data?.price === 0;
  const entityDetails = {
    title: data?.name,
    description: '',
    price: data?.price,
    discountPrice: data?.discountedPrice,
    mentorName: data?.creatorName,
    entityId: data?._id,
    subscribers: data?.subscriberCount,
    cards: data?.cards?.length,
    difficulty: data?.difficulty || 'Medium',
  };

  const closeAuthModal = () => {
    setShowAuthModal(false);
  };

  return (
    <>
      <div className="mobile__anki--view-container">
        <style>
          {`
        .mobile__anki--card-container {
          height: ${innHeight - 77}px;
          padding: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .conclusion-note--copy--container {
          border: 1px solid #8C52FF;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
          border-radius: 4px;
          cursor: pointer;
          height: 35px;
        }
        .conclusion-note--copy-link {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1; 
          -webkit-box-orient: vertical;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #8C8C8C;
        }
        .copy--main-container {
          color: #8C52FF;
          display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        }
      `}
        </style>
        <div className="mobile__anki--header">
          <div>
            {truncate(name, { length: 30 })}
          </div>
          <p style={{ color: '#8C52FF' }}>
            <span className="cursor-pointer" style={{ color: '#3b3b3b', marginLeft: '10px' }}>
              <ArrowsClockwise size={28} onClick={() => reloadPage()} />
            </span>
            <span className="cursor-pointer" style={{ color: '#3b3b3b', marginLeft: '10px' }}>
              <XCircle size={28} onClick={() => history.push('/space-repitition-system')} />
            </span>
          </p>
        </div>
        <div className="mobile__anki--card-container">
          <Smiley style={{ color: '#24AA42' }} size={55} />
          <h1 className="text-center mt-3">
            {textValue?.heading}
          </h1>
          <p className="text-center mt-3">
            {textValue?.content}
          </p>
          <div className="copy--main-container mt-3">
            <CopyToClipBoard {...{ noteURL }}>
              <div
                role="presentation"
                className="conclusion-note--copy--container"
              >
                <p style={{ overflowWrap: "anywhere" }} className="conclusion-note--copy-link">{truncate(noteURL, { length: 40 })}</p>
                {' '}
                <CopySimple size={28} className="mx-1" color="#24AA42" />
              </div>
            </CopyToClipBoard>
            <ShareWithSocialMedia {...{ noteURL, name }}>
              <ShareNetwork size={28} className="mx-2 cursor-pointer" />
            </ShareWithSocialMedia>
          </div>
          {userId ? (
            <>
              {!isSubs && (
                <button type="button" className="mobile__anki--btn-purple mt-3" style={{ borderRadius: '8px' }} onClick={() => setIsPaymentModalOpen(true)}>
                  {`${isFree
                    ? 'Subscribe'
                    : `Subscribe at ₹${data.price && data.discountedPrice ? data.discountedPrice : data.price}`}`}
                </button>
              )}
            </>
          ) : (
            <button
              type="button"
              className="mobile__anki--btn-purple mt-3"
              style={{ borderRadius: '8px' }}
              onClick={() => setShowAuthModal(true)}
            >
              Login to Subscribe
            </button>
          )}

          <button
            type="button"
            style={{ borderRadius: '8px' }}
            className="mobile__anki--btn-purple mt-3"
            onClick={() => history.push('/space-repitition-system')}
          >
            Try another note
          </button>
        </div>
        <PaymentDialog
          open={isPaymentModalOpen}
          onClose={() => setIsPaymentModalOpen(false)}
          entityType="notes"
          entityDetails={entityDetails}
          thumbnail={data?.image?.url}
        />
      </div>
      <AuthModal open={showAuthModal} closeModal={closeAuthModal} />
    </>
  );
}

export default ConclusionMobile;
