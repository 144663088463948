import React, { useState, useEffect } from 'react';
import { useIsMobile } from '../Utils';

import LandingInputField from './landingInputField';

import { slide1, slide2, slide3 } from './assets';
import './landingScreen.css';

const slides: {
  id: number;
  primaryText: string;
  secondaryText: string;
  backgroundImage: string;
}[] = [
  {
    id: 1,
    primaryText: 'Ace exams and Interviews!',
    secondaryText: 'Gamify what you learn and make boring topics fun.',
    backgroundImage: slide1,
  },
  {
    id: 2,
    primaryText: 'Track Your Knowledge',
    secondaryText:
      'Recognize knowledge gaps and get better understanding of concepts.',
    backgroundImage: slide2,
  },
  {
    id: 3,
    primaryText: 'Learn Anytime and Anywhere',
    secondaryText:
      'With knowledge at your fingertips, you can learn any skill with Hyperlearn.',
    backgroundImage: slide3,
  },
];

const LandingScreen: React.FC = () => {
  const { isMobile } = useIsMobile();
  const [slideId, setSlideId] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideId((id) => (id % 3) + 1);
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className="landing__screen--landing--page">
        <div className="landing__screen-container">
          <div className="landing__screen--slide">
            <div className="landing__screen--box">
              <div
                className={`landing__screen--text ${
                  isMobile ? 'landing__screen--animation' : ''
                }`}
              >
                <h1 className="landing__screen--box-primary-heading">
                  {slides[slideId - 1].primaryText}
                </h1>
                <p className="landing__screen--box-secondary-heading">
                  {slides[slideId - 1].secondaryText}
                </p>
              </div>
              <LandingInputField />
            </div>
            {!isMobile && (
              <div className="landing__screen--media landing__screen--animation">
                <img
                  src={slides[slideId - 1].backgroundImage}
                  alt={slides[slideId - 1].primaryText}
                />
              </div>
            )}
          </div>
          <div className="landing__screen--pagination">
            {slides.map((slide, index) => (
              <div
                key={`slide-${index}`}
                className={`landing__screen--bullets ${
                  index === slideId - 1 ? 'active' : ''
                }`}
                id={`slide-${index}`}
                onClick={() => setSlideId(index + 1)}
              ></div>
            ))}
          </div>
        </div>
        <div className="landing__page--wave" />
      </div>
    </>
  );
};

export default LandingScreen;
