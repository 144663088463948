import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import Label from '../../BasicComponents/InputLabel';
import { hlbClient } from '../../../Clients/hlbClient';
import { formatDate, getISOString, getWebinarSeats } from '../../Utils';

type StepThreeProps = {
  next: (newData: any, isFinalStep?: boolean) => void;
  data: any;
  prev: (values: any) => void;
  // eslint-disable-next-line react/require-default-props
  editMode?: boolean;
  setNewWebinarId: React.Dispatch<React.SetStateAction<string>>;
}

export interface RouteParams {
  webinarId: string;
}

export const seatOptions = [
  { name: '1-50', value: '1-50' },
  { name: '51-100', value: '51-100' },
  { name: '101-200', value: '101-200' },
  { name: 'No limits', value: 'noLimits' },
];

export const webinarPlatformOptions = [
  { name: 'Google Meet', value: 'gmeet' },
  { name: 'Zoom', value: 'zoom' },
];

const yesNoOptions = [
  { name: 'Yes', value: 'yes' },
  { name: 'No', value: 'no' },
];

const validationSchema = Yup.object().shape({
  seats: Yup.string().required('Please select an option!'),
  certificate: Yup.string().required('Please select an option!'),
  platform: Yup.string().required('Please select an option!'),
  platformLink: Yup.string().required('Cannot be left blank!'),
});

const StepThree = ({
  next, data, prev, editMode = false, setNewWebinarId,
}: StepThreeProps) => {
  const [requestError, setRequestError] = useState('');
  const { webinarId = '' } = useParams<RouteParams>();
  const handleCreateWebinar = async (values: any) => {
    try {
      const response = await hlbClient().post('/api/webinar/createWebinar', {
        title: values.title,
        shortSummary: values.summary,
        detailedDescription: values.description,
        date: dayjs(values.date).toISOString(),
        startTime: getISOString(formatDate(values.date), values.startTime),
        endTime: getISOString(formatDate(values.date), values.endTime),
        price: values.originalPrice,
        discountedPrice: values.discountPrice,
        keyPoints: values.keyPoints,
        platform: values.platform,
        joiningLink: values.platformLink,
        introVideoLink: values.videoLink,
        isPublished: false,
        certificateOnBooking: values.certificate === 'yes',
        totalSeats: getWebinarSeats(values.seats),
      });
      if (response?.data?.success) {
        setNewWebinarId(response.data.result._id);
        next(values, true);
        setRequestError('');
      } else {
        setRequestError(response.data.msg);
      }
    } catch (error: any) {
      setRequestError(error.response.data.msg || error.message);
    }
  };

  const handleEditWebinar = async (values: any) => {
    try {
      const response = await hlbClient().put(`/api/webinar/editWebinars?_id=${webinarId}`, {
        title: values.title,
        shortSummary: values.summary,
        detailedDescription: values.description,
        date: dayjs(values.date).toISOString(),
        startTime: getISOString(formatDate(values.date), values.startTime),
        endTime: getISOString(formatDate(values.date), values.endTime),
        price: values.originalPrice,
        discountedPrice: values.discountPrice,
        keyPoints: values.keyPoints,
        platform: values.platform,
        joiningLink: values.platformLink,
        introVideoLink: values.videoLink,
        isPublished: false,
        certificateOnBooking: values.certificate === 'yes',
        totalSeats: getWebinarSeats(values.seats),
      });
      if (response?.data?.success) {
        next(values, true);
        setRequestError('');
      } else {
        setRequestError(response.data.msg);
      }
    } catch (error: any) {
      setRequestError(error.response.data.msg || error.message);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    requestError && setRequestError('');
  }, []);

  return (
    <Formik
      initialValues={data}
      onSubmit={editMode ? handleEditWebinar : handleCreateWebinar}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values }) => (
        <Form className="formContainer__form">
          <div className="form__field form__seats">
            <Label HTMLFor="webinar--seats" isRequired label="Total Seats" tooltip="Select, how many seats your are providing for your webinar" />
            <div className="form__field--options">
              {
                seatOptions.map((option) => (
                  <label key={option.value} className="form__field--label">
                    <Field type="radio" name="seats" value={option.value} className="form__field--radio" />
                    {option.name}
                  </label>
                ))
              }
            </div>
            <ErrorMessage component="span" name="seats" className="form__field--error" />
          </div>
          <div className="form__field form__certification">
            <Label HTMLFor="webinar--certification" isRequired label="Certificate on Completion" tooltip="Select, if you are providing any certificate for your webinar." />
            <div className="form__field--options">
              {
                yesNoOptions.map((option) => (
                  <label key={option.value} className="form__field--label">
                    <Field type="radio" name="certificate" value={option.value} className="form__field--radio" />
                    {option.name}
                  </label>
                ))
              }
            </div>
            <ErrorMessage component="span" name="certificate" className="form__field--error" />
          </div>
          <div className="form__field form__platform">
            <Label HTMLFor="webinar--platform" isRequired label="Webinar Platform" tooltip="Select, where you want your webinar to occur." />
            <div className="form__field--options">
              {
                webinarPlatformOptions.map((option) => (
                  <label key={option.value} className="form__field--label">
                    <Field type="radio" name="platform" value={option.value} className="form__field--radio" />
                    {option.name}
                  </label>
                ))
              }
            </div>
            {
              values.platform && (
                <div className="form__field form__platformLink">
                  <Field
                    name="platformLink"
                    type="text"
                    className="form__field--inputFull"
                    placeholder="Please enter your meeting link here"
                  />
                  <ErrorMessage component="span" name="platformLink" className="form__field--error" />
                </div>
              )
            }
            <ErrorMessage component="span" name="platform" className="form__field--error" />
          </div>
          {requestError && <span className="form__request--error">{requestError}</span>}
          <div className="form__navigationButtons">
            <button onClick={() => prev(values)} type="button" className="form__navigationButton form__navigationButton--prev">Back</button>
            <button className="form__navigationButton form__navigationButton--next" type="submit">Next</button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default StepThree;
