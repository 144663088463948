import React from 'react';
import { leftArrow, rightArrow } from '../icons';
import './Pagination.css';

type PaginationProps = {
  offset?: number;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
  limit?: number;
  totalCount: number;
};

const Pagination: React.FC<PaginationProps> = ({
  offset = 0,
  setOffset,
  limit = 10,
  totalCount,
}) => {
  const leftPaginate = () => {
    setOffset((offset) => Math.max(0, offset - limit));
  };

  const rightPaginate = () => {
    setOffset((offset) =>
      Math.min(
        offset + limit,
        totalCount % limit === 0
          ? totalCount - limit
          : totalCount - (totalCount % limit)
      )
    );
  };

  return (
    <div className="pagination__container">
      <div className="pagination__content">
        <div className="pagination__current-page">
          {`${offset + 1}-${Math.min(offset + limit, totalCount)}`}
        </div>
        &nbsp; of &nbsp;
        <div className="pagination__total-page">{totalCount}</div>
      </div>
      <button
        className={`pagination__buttons ${offset === 0 ? 'disabled' : ''}`}
        onClick={leftPaginate}
      >
        <img
          className="mx-2"
          height="12px"
          width="12px"
          src={leftArrow}
          alt="left-arrow"
        />
      </button>
      <button
        className={`pagination__buttons ${
          offset === totalCount - (totalCount % limit) ||
          offset === totalCount - limit
            ? 'disabled'
            : ''
        }`}
        onClick={rightPaginate}
      >
        <img
          className="mx-2"
          height="12px"
          width="12px"
          src={rightArrow}
          alt="right-arrow"
        />
      </button>
    </div>
  );
};

export default Pagination;
