import React, { useContext, useEffect, useState } from 'react';
import AuthImage from "../../AssetV2/hyperlearn-login.png";
import '../Login/Login.css';
import { AuthInput } from '../components/AuthInput';
import { AuthButton } from '../components/AuthButton';
import { Link, useHistory } from 'react-router-dom';
import { ResendOtp } from '../components/ResendOtp';
import { AuthContext } from '../../../provider/AuthContext';
import { EMAIL_REGEX, handlePhoneOtpSubmission, handlePhoneSubmission, handleSignup, maskPhoneNumber } from '../utils';
import { noop } from 'lodash';

const Signup = () => {
    const [name, setName] = useState({
        value: '',
        errorMessage: '',
    })
    const [phoneNo, setPhoneNo] = useState({
        value: '',
        errorMessage: ''
    });
    const [emailId, setEmailId] = useState({
        value: '',
        errorMessage: '',
    });
    const [otp, setOtp] = useState({
        value: '',
        errorMessage: '',
    })
    const [phoneNoSubmitted, setPhoneNoSubmitted] = useState(false);
    const [isAgreementChecked, setIsAgreementChecked] = useState(false);
    const [resendTimer, setResendTimer] = useState(0);
    const [areAllFieldsFilled, setAreAllFieldsFilled] = useState(false);

    const { setStateUpdate, stateUpdate } = useContext(AuthContext);
    const token = window.localStorage.getItem('token');
    const history = useHistory();
    const isStudentSignup = history.location.pathname === '/signup/student';    

    useEffect(() => {
        if (phoneNo.value && name.value && emailId.value) {
            setAreAllFieldsFilled(true);
        } else {
            setAreAllFieldsFilled(false);
        }
    }, [phoneNo, emailId, name]);

    useEffect(() => {
        if (token) {
        setStateUpdate(!stateUpdate);
        }
    }, [token]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, setValue: React.Dispatch<React.SetStateAction<{
        value: string;
        errorMessage: string;
    }>>) => {
        setValue({
            value: event.target.value,
            errorMessage: '',
        });
    };

    const phoneSuccessCallback = () => {
        !phoneNoSubmitted && setPhoneNoSubmitted(true);
        setResendTimer(60);
    }

    const phoneErrorCallback = (errorMessage?: string) => {
        if (errorMessage) {
            setPhoneNo((prev) => {
                return {
                    ...prev,
                    errorMessage,
                }
            });
        }
    };

    const signupSuccessCallback = () => {
        isStudentSignup ? history.push('/searchMentor') : history.push('/onboarding/mentorDetailForm');
    };

    const otpSuccessCallback = () => {
        isStudentSignup && handleSignup("student", phoneNo.value, noop, signupSuccessCallback, name.value, emailId.value);
        !isStudentSignup && handleSignup("mentor", phoneNo.value, noop, signupSuccessCallback);
    };

    const otpErrorCallback = (errorMessage?: string) => {
        if (errorMessage) {
            setOtp((prev) => {
                return {
                    ...prev,
                    errorMessage,
                }
            });
        }
    }

    const handleSignupSubmission = async () => {
        if (!name.value || name.value.trim() === "") {
            setName((prev) => {
                return {
                    ...prev,
                    errorMessage: "Name cannot be blank",
                }
            });
            return;
        }
        if (!emailId.value || !emailId.value.match(EMAIL_REGEX)) {
            setEmailId((prev) => {
                return {
                    ...prev,
                    errorMessage: "Please enter a valid email address",
                }
            });
            return;
        }
        // Phone no gets validated in handlePhoneSubmission func
        await handlePhoneSubmission(
            phoneNo.value,
            phoneSuccessCallback,
            phoneErrorCallback,
            'userSignup'
        );
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (resendTimer > 0) {
                setResendTimer((prev) => prev - 1);
            }
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [resendTimer]);

    return (
        <div className='authWrapper'>
            <div className="authContainer">
                <Link to="/" style={{ textDecoration: "none" }}>
                    <h1 className="brandName">
                        hyper
                        <span className="brandName--highlighted">learn</span>
                    </h1>
                </Link>
                <div className="authForm">
                    <div className="header">
                        <h4 className="header__title">
                            {phoneNoSubmitted ? 'Enter OTP' : 'Create your account'}
                        </h4>
                        <p className="header__message">
                            {
                                phoneNoSubmitted && `Enter the OTP that was sent to your registered phone no. ${maskPhoneNumber(phoneNo.value)} and be careful not to share the OTP with anyone!`
                            }
                            {
                                !phoneNoSubmitted && 'Please enter following details to create account'
                            }
                        </p>
                    </div>
                    {
                        phoneNoSubmitted ? (
                            <>
                                <AuthInput
                                    key="phoneOtp"
                                    type='tel'
                                    placeholder='Enter 6 digit OTP here'
                                    value={otp.value}
                                    updateValue={(event) => handleInputChange(event, setOtp)}
                                    errorMessage={otp.errorMessage}
                                    maxLength={6}
                                />
                                <div className="authAgreement">
                                    <input
                                        type="checkbox"
                                        className='authAgreement-checkbox'
                                        checked={isAgreementChecked}
                                        onChange={() => setIsAgreementChecked(!isAgreementChecked)}
                                    />
                                    <span className='authAgreement-message'>By continuing, I agree to the <Link to="/policy" className='authAgreement-link'>Terms of Services</Link>, including the <Link to="/policy" className='authAgreement-link'>User Agreement</Link> & <Link to="/policy/Privacy" className='authAgreement-link'>Privacy Policy</Link></span>
                                </div>
                            </>
                        ) : (
                            <>
                                <AuthInput
                                    key="userName"
                                    label='Name'
                                    type='text'
                                    placeholder='John Doe'
                                    value={name.value}
                                    updateValue={(event) => handleInputChange(event, setName)}
                                    errorMessage={name.errorMessage}
                                    style={{ marginBottom: "15px" }}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            handleSignupSubmission();
                                        }
                                    }}
                                />
                                <AuthInput
                                    key="emailId"
                                    label='Email'
                                    type='email'
                                    placeholder='Enter your mail address'
                                    value={emailId.value}
                                    updateValue={(event) => handleInputChange(event, setEmailId)}
                                    errorMessage={emailId.errorMessage}
                                    style={{ marginBottom: "15px" }}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            handleSignupSubmission();
                                        }
                                    }}
                                />
                                <AuthInput
                                    key="phoneNumber"
                                    label='Phone no.'
                                    type='tel'
                                    placeholder='Enter your registered Phone no.'
                                    value={phoneNo.value}
                                    updateValue={(event) => handleInputChange(event, setPhoneNo)}
                                    errorMessage={phoneNo.errorMessage}
                                    showDialCode
                                    maxLength={10}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            handleSignupSubmission();
                                        }
                                    }}
                                />
                            </>
                        )
                    }
                    {
                        phoneNoSubmitted ? (
                            <AuthButton
                                variant='primary'
                                onClick={() => void handlePhoneOtpSubmission(phoneNo.value, otp.value, otpSuccessCallback, otpErrorCallback, 'userSignup')}
                                style={{ marginTop: "30px" }}
                                disabled={!otp.value || !isAgreementChecked}
                            >
                                {/* phoneOtpSubmission is not working as token is generated when name & email gets submitted */}
                                Create account
                            </AuthButton>
                        ) : (
                            <AuthButton
                                variant='primary'
                                onClick={handleSignupSubmission}
                                style={{ marginTop: "30px" }}
                                disabled={!areAllFieldsFilled}
                            >
                                Continue
                            </AuthButton>
                        )
                    }

                    {
                        phoneNoSubmitted ? (
                            <ResendOtp
                                handleResend={noop} 
                                timer={resendTimer}
                                style={{ marginTop: "40px" }}
                            />
                        ) : (
                            <div className='authAlternatives' style={{ marginTop: "40px" }}>
                                <p className="authAlternatives-message">
                                    Already have an account?
                                    <Link to={"/login"} className='authAlternatives-link'>Log in</Link>
                                </p>
                            </div>
                        )
                    }

                </div>
            </div>
            <div className="authImage">
                <img src={AuthImage} alt="oeighn" />
            </div>
        </div>
    );
};

export default Signup;