import React, { useEffect, useState } from 'react';
import { Check, PencilSimple, Trash } from 'phosphor-react';
import { OptionTypeV2 } from '../../types';
import { ScqFeaturesType } from './FeaturesRenderer';

const ScqFeatures = ({
  features,
  setFeatures,
}: {
  features: ScqFeaturesType
  setFeatures: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const [hint, setHint] = useState('');
  const [options, setQuestionOption] = useState<OptionTypeV2[]>(features.options || []);
  const [isHint, setIsHint] = useState(Boolean(features.hint) || false);
  const [question, setQuestion] = useState('');
  const [toggleAddOption, setToggleAddOption] = useState(false);
  const [isEdit, setIsEdit] = useState<number | null>(null);
  const [hoveringOption, setHoveringOption] = useState<number | null>(null);

  // add more option fields
  const addFields = () => {
    const newField = { text: question, isCorrect: false };
    setQuestionOption((prev) => [...prev, newField]);
    setQuestion('');
  };

  const handleFormChange = (index: number, e: any) => {
    const value =
      e?.target?.name === 'isCorrect' ? e.target.checked : e.target.value;
    const data: any[] = [...options];
    data[index][e?.target?.name] = value;
    setQuestionOption(
      data.map(({ isCorrect, ...item }, i) => ({
        ...item,
        isCorrect: index === i ? value : false,
      }))
    );
  };

  const removeFields = (index: number) => {
    const data = [...options];
    data.splice(index, 1);
    setQuestionOption(data);
  };

  useEffect(() => {
    if (isHint) {
      setFeatures({
        hint,
        options,
      });
    } else if (!isHint && features?.hint?.length !== 0) {
      setFeatures({
        hint: '',
        options,
      });
    } else {
      setFeatures({
        options,
      });
    }
  }, [isHint, hint, options]);

  return (
    <div className="features__container">
      <div className="features__input-container">
        <div className="features__toggle-container">
          <div className="features__title">Write hint for your card</div>
          <label className="features__switch">
            <input
              type="checkbox"
              checked={isHint}
              onChange={(e) => setIsHint((prev) => !prev)}
            />
            <span className="slider"></span>
          </label>
        </div>
        <textarea
          className="features__input"
          placeholder="Enter your hint..."
          onChange={(e) => setHint(e.target.value)}
          disabled={!isHint}
        />
      </div>

      <div className="features__input-container">
        <div className="features__title">Options</div>

        <div className="features__option-wrapper" style={{ gap: '0.5rem' }}>
          {options.map((input: any, index: number) => (
            <div
              key={index.toString()}
              className="features__option-wrapper"
              onMouseEnter={() => setHoveringOption(index)}
              onMouseLeave={() => setHoveringOption(null)}
            >
              <div
                className={`features__option-btn-grp ${
                  hoveringOption === index ? 'option-visible' : 'option-hidden'
                }`}
              >
                {isEdit === index ? (
                  <button
                    className="features__option-btn add__btn"
                    onClick={() => setIsEdit(null)}
                  >
                    <Check />
                  </button>
                ) : (
                  <button
                    className="features__option-btn add__btn"
                    onClick={() => setIsEdit(index)}
                  >
                    <PencilSimple />
                  </button>
                )}
                <button
                  className="features__option-btn delete__btn"
                  onClick={() => removeFields(index)}
                >
                  <Trash />
                </button>
              </div>
              {isEdit === index ? (
                <textarea
                  name="text"
                  className="features__input"
                  value={input.text}
                  onChange={(e) => handleFormChange(index, e)}
                  placeholder={`Enter option ${index + 1}`}
                />
              ) : (
                <div className="features__option-field-container">
                  <input
                    type="radio"
                    name="isCorrect"
                    checked={input.isCorrect}
                    onChange={(e) => handleFormChange(index, e)}
                    className="features__option-field-input"
                  />
                  <div className="features__option-field-box">{input.text}</div>
                </div>
              )}
            </div>
          ))}

          {toggleAddOption && (
            <div className="features__option-wrapper">
              <div
                className="features__option-btn-grp"
                style={{ visibility: 'visible' }}
              >
                <button
                  className="features__option-btn add__btn"
                  onClick={() => {
                    addFields();
                    setToggleAddOption(false);
                  }}
                >
                  <Check />
                </button>
                <button
                  className="features__option-btn delete__btn"
                  onClick={() => setQuestion('')}
                >
                  <Trash />
                </button>
              </div>
              <textarea
                name="text"
                className="features__input"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder="Enter your option..."
              />
            </div>
          )}
        </div>

        {!toggleAddOption && options.length < 4 && (
          <button
            className="features__btn-addMore"
            onClick={() => setToggleAddOption(true)}
          >
            + Add Option
          </button>
        )}
      </div>
    </div>
  );
};

export default ScqFeatures;
