import axios from 'axios';
import { myConfig } from '../config';

export const hlbClient = (contentType = 'application/json') => axios.create({
  baseURL: myConfig.url,
  headers: {
    // eslint-disable-next-line no-undef
    'x-auth-token': localStorage.getItem('token'),
    Accept: 'application/json',
    'Content-Type': contentType,
  },
});
