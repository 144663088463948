import React, { useContext, useEffect, useState } from 'react';
import AuthImage from "../../AssetV2/hyperlearn-login.png";
import './Login.css';
import { AuthInput } from '../components/AuthInput';
import { AuthButton } from '../components/AuthButton';
import { Separator } from '../components/Separator';
import { Link, useHistory } from 'react-router-dom';
import { ResendOtp } from '../components/ResendOtp';
import { AuthContext } from '../../../provider/AuthContext';
import { handleEmailOtpSubmission, handleEmailSubmission, handlePhoneOtpSubmission, handlePhoneSubmission, maskEmail, maskPhoneNumber } from '../utils';

const Login = () => {
    const [authMode, setAuthMode] = useState<'Phone' | 'Mail'>('Phone');
    const [phoneNo, setPhoneNo] = useState({
        value: '',
        errorMessage: ''
    });
    const [emailId, setEmailId] = useState({
        value: '',
        errorMessage: '',
    });
    const [otp, setOtp] = useState({
        value: '',
        errorMessage: '',
    });
    const [phoneNoSubmitted, setPhoneNoSubmitted] = useState(false);
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [resendTimer, setResendTimer] = useState(0);

    const { authState, setStateUpdate, stateUpdate } = useContext(AuthContext);
    const token = window.localStorage.getItem('token');
    const { profile } = authState;
    const history = useHistory();

    useEffect(() => {
        if (token) {
        setStateUpdate(!stateUpdate);
        }
    }, [token]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, setValue: React.Dispatch<React.SetStateAction<{
        value: string;
        errorMessage: string;
    }>>) => {
        setValue({
            value: event.target.value,
            errorMessage: '',
        });
    };

    const phoneSuccessCallback = () => {
        !phoneNoSubmitted && setPhoneNoSubmitted(true);
        setResendTimer(60);
    }

    const phoneErrorCallback = (errorMessage?: string) => {
        if (errorMessage) {
            setPhoneNo((prev) => {
                return {
                    ...prev,
                    errorMessage,
                }
            });
        }
    }

    const emailSuccessCallback = () => {
        !emailSubmitted && setEmailSubmitted(true);
        setResendTimer(60);
    }

    const emailErrorCallback = (errorMessage?: string) => {
        if (errorMessage) {
            setEmailId((prev) => {
                return {
                    ...prev,
                    errorMessage,
                }
            });
        }
    }

    const otpSuccessCallback = () => {
        window.localStorage.getItem('hlUserType') === 'mentor'
          ? (!profile ? history.push('/onboarding/mentorDetailForm') : history.push('/dashboardV2'))
          : history.push('/searchMentor');
    };

    const otpErrorCallback = (errorMessage?: string) => {
        if (errorMessage) {
            setOtp((prev) => {
                return {
                    ...prev,
                    errorMessage,
                }
            });
        }
    }

    const handleAuthModeChange = () => {
        if (authMode === 'Phone') {
            phoneNoSubmitted && setPhoneNoSubmitted(false);
            setPhoneNo({
                value: '',
                errorMessage: '',
            })
            setAuthMode('Mail');
        }

        if (authMode === 'Mail') {
            emailSubmitted && setEmailSubmitted(false);
            setEmailId({
                value: '',
                errorMessage: '',
            })
            setAuthMode('Phone');
        }
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (resendTimer > 0) {
                setResendTimer((prev) => prev - 1);
            }
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [resendTimer]);

    return (
        <div className='authWrapper'>
            <div className="authContainer">
                <Link to="/" style={{ textDecoration: "none" }}>
                    <h1 className="brandName">
                        hyper
                        <span className="brandName--highlighted">learn</span>
                    </h1>
                </Link>
                <div className="authForm">
                    <div className="header">
                        <h4 className="header__title">
                            {phoneNoSubmitted ? 'Enter OTP' : 'Welcome back!'}
                        </h4>
                        <p className="header__message">
                            {
                                phoneNoSubmitted && `Enter the OTP that was sent to your registered phone no. ${maskPhoneNumber(phoneNo.value)} and be careful not to share the OTP with anyone!`
                            }
                            {
                                emailSubmitted && `Enter the OTP that was sent to your registered mail address ${maskEmail(emailId.value)} and be careful not to share the OTP with anyone!`
                            }
                            {
                                !phoneNoSubmitted && !emailSubmitted && 'Please enter your details to continue'
                            }
                        </p>
                    </div>
                    {/* OTP field for Phone */}
                    {
                        authMode === 'Phone' && phoneNoSubmitted && (
                            <AuthInput
                                key="phoneOtp"
                                type='tel'
                                placeholder='Enter 6 digit OTP here'
                                value={otp.value}
                                updateValue={(event) => handleInputChange(event, setOtp)}
                                errorMessage={otp.errorMessage}
                                maxLength={6}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        handlePhoneOtpSubmission(phoneNo.value, otp.value, otpSuccessCallback, otpErrorCallback)
                                    }
                                }}
                            />
                        )
                    }

                    {/* Input field for Phone */}
                    {
                        authMode === 'Phone' && !phoneNoSubmitted && (
                            <AuthInput
                                key="phoneNumber"
                                label='Phone no.'
                                type='tel'
                                placeholder='Enter your registered Phone no.'
                                value={phoneNo.value}
                                updateValue={(event) => handleInputChange(event, setPhoneNo)}
                                errorMessage={phoneNo.errorMessage}
                                showDialCode
                                maxLength={10}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        void handlePhoneSubmission(phoneNo.value, phoneSuccessCallback, phoneErrorCallback);
                                    }
                                }}
                            />
                        )
                    }

                    {/* OTP field for Email */}
                    {
                        authMode === 'Mail' && emailSubmitted && (
                            <AuthInput
                                key="emailOtp"
                                type='tel'
                                placeholder='Enter 6 digit OTP here'
                                value={otp.value}
                                updateValue={(event) => handleInputChange(event, setOtp)}
                                errorMessage={otp.errorMessage}
                                maxLength={6}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        void handleEmailOtpSubmission(emailId.value, otp.value, otpSuccessCallback, otpErrorCallback);
                                    }
                                }}
                            />
                        )
                    }

                    {/* Input field for Email */}
                    {
                        authMode === 'Mail' && !emailSubmitted && (
                            <AuthInput
                                key="emailId"
                                label='Email'
                                type='email'
                                placeholder='Enter your mail address'
                                value={emailId.value}
                                updateValue={(event) => handleInputChange(event, setEmailId)}
                                errorMessage={emailId.errorMessage}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        void handleEmailSubmission(emailId.value, emailSuccessCallback, emailErrorCallback);
                                    }
                                }}
                            />
                        )
                    }

                    {/* Phone Login Button */}
                    {
                        authMode === 'Phone' && (
                            <AuthButton
                                variant='primary'
                                onClick={() => phoneNoSubmitted ? void handlePhoneOtpSubmission(phoneNo.value, otp.value, otpSuccessCallback, otpErrorCallback) : void handlePhoneSubmission(phoneNo.value, phoneSuccessCallback, phoneErrorCallback)}
                                style={{ marginTop: "30px" }}
                                disabled={phoneNoSubmitted ? Boolean(!otp.value) : Boolean(!phoneNo.value)}
                            >
                                {phoneNoSubmitted ? 'Log in' : 'Continue'}
                            </AuthButton>
                        )
                    }
                    
                    {/* Email Login Button */}
                    {
                        authMode === 'Mail' && (
                            <AuthButton
                                variant='primary'
                                onClick={() => emailSubmitted ? void handleEmailOtpSubmission(emailId.value, otp.value, otpSuccessCallback, otpErrorCallback) : void handleEmailSubmission(emailId.value, emailSuccessCallback, emailErrorCallback)}
                                style={{ marginTop: "30px" }}
                                disabled={emailSubmitted ? Boolean(!otp.value) : Boolean(!emailId.value)}
                            >
                                {emailSubmitted ? 'Log in' : 'Continue'}
                            </AuthButton>
                        )
                    }

                    {
                        phoneNoSubmitted || emailSubmitted ? (
                            <ResendOtp
                                handleResend={() => phoneNoSubmitted ? void handlePhoneSubmission(phoneNo.value, phoneSuccessCallback, phoneErrorCallback) : handleEmailSubmission(emailId.value, emailSuccessCallback, emailErrorCallback)} 
                                timer={resendTimer}
                                style={{ marginTop: "40px" }}
                            />
                        ) : (
                            <>
                                <Separator text='Or' style={{ margin: "25px 0" }} />
                                <AuthButton
                                    variant='secondary'
                                    onClick={handleAuthModeChange}
                                >
                                    Login with {authMode === 'Mail' ? 'Phone' : 'Email'}
                                </AuthButton>
                                <div className='authAlternatives' style={{ marginTop: "80px" }}>
                                    <p className="authAlternatives-message">
                                        Don’t have an account?
                                        <Link to={"/signup/student"} className='authAlternatives-link'>Sign up</Link>
                                    </p>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
            <div className="authImage">
                <img src={AuthImage} alt="oeighn" />
            </div>
        </div>
    );
};

export default Login;