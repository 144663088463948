import React from 'react';
import PropTypes from 'prop-types';
import SuccessConfirmation from '../../BasicComponents/successConfirmation/index.ts';
import FailureConfirmation from '../../BasicComponents/failureConfirmation/index.ts';

const Confirmation = ({ isSuccessful, cardType }) => {
  const clickHandler = () => {
    // eslint-disable-next-line no-undef
    window.location.reload();
  };

  if (isSuccessful) {
    return (
      <SuccessConfirmation
        heading="Booking Confirmed"
        description={`We have booked your ${cardType} for selected time.
        You will receive a confirmation email with booking details.`}
        message="Please contact support@hyperlearn.in if you haven't received any email on confirmation"
        button1={{
          btnName: 'Home',
          action: 'redirect',
          url: '/searchMentor',
          handler: null,
        }}
        button2={{
          btnName: 'My Profile',
          action: 'redirect',
          url: '/user/profile',
          handler: null,
        }}
      />
    );
  // eslint-disable-next-line no-else-return
  } else {
    return (
      <FailureConfirmation
        heading="Oops!!"
        description="Something went wrong. Please try again."
        button1={{
          btnName: 'Home',
          action: 'redirect',
          url: '/searchMentor',
          handler: null,
        }}
        button2={{
          btnName: 'Start Over',
          action: 'clickHandle',
          url: null,
          handler: clickHandler,
        }}
      />
    );
  }
};

Confirmation.defaultProps = {
  cardType: 'session',
};

Confirmation.propTypes = {
  isSuccessful: PropTypes.bool.isRequired,
  cardType: PropTypes.string,
};

export default Confirmation;
