import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './confirmPopUp.css';
import { hide, show } from './openClosePopup';
import { CheckCircle, XCircle } from '../../../BasicComponents/icons';

type Props = {
  open: boolean,
  setOpen: (k: boolean) => void,
  singleChoiceData: any,
  correctAnswer: any,
  next: () => void,
  currentCard: number,
  totalCard: number,
  setIsConclusion: (k: boolean) => void,
  conclusionType: any,
  isMultiCorrect: boolean,
  userAnswer?: string,
}

const ConfirmPopUp: React.FC<Props> = ({
  open, setOpen, singleChoiceData, correctAnswer, next, currentCard, totalCard, setIsConclusion,
  conclusionType, isMultiCorrect, userAnswer = '',
}) => {
  const [isClicked, setIsClicked] = useState(false);
  useEffect(() => {
    if (open) {
      show('open-close');
    } else {
      hide('open-close');
    }
  }, [open]);

  const [timeoutId, setTimeoutId] = useState<any>(null);
  const action = () => (currentCard !== totalCard ? next() : setIsConclusion(true));

  const nextPage = () => {
    const timeout: any = setTimeout(() => {
      action();
      setOpen(false);
    }, 3000);
    setTimeoutId(timeout);
  };

  useEffect(() => {
    if (isClicked) {
      action();
      setIsClicked(false);
    } else {
      if (conclusionType === 'single') {
        if (singleChoiceData?.isCorrect) {
          nextPage();
        }
      } else if (isMultiCorrect) {
        nextPage();
      } else {
        if(correctAnswer.toUpperCase() === userAnswer.toUpperCase()){
          nextPage();
        }
      }
    }

    // Clear the timeout when the component unmounts or when the dependency values change
    return () => clearTimeout(timeoutId);
  }, [isClicked, conclusionType, singleChoiceData, isMultiCorrect]);

  const cancellingTimeout = () => {
    // Cancel the timeout when onClick event occurs
    clearTimeout(timeoutId);
    setOpen(false);
  };

  const nextCard = () => {
    action();
    setOpen(false);
  };

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <>
      {conclusionType === 'single' ? (
        <div className="confirmPopUp__container" id="open-close">
          {singleChoiceData?.optionText === correctAnswer
            ? (
              <div className="confirmPopup__main--container">
                <CheckCircle className="confirmPopup__icon green" />
                <p className="green">Correct Answer</p>
                <div
                  role="presentation"
                  className="confirmPopup__bar--main-container mt-3"
                  onClick={() => { setOpen(false); setIsClicked(true); action(); cancellingTimeout(); }}
                >
                  <div
                    className="confirmPopup__bar--sub-container"
                    style={{
                      width: '100%', animationDelay: '600ms', zIndex: -1, height: '52px',
                    }}
                  />
                  <p className="confirmPopup__btn-text">Continue</p>
                </div>
              </div>
            )
            : (
              <div className="confirmPopup__main--container">
                <XCircle className="confirmPopup__icon red" />
                <p className="red">Wrong Answer</p>
                <p>
                  Correct Answer:
                  {' '}
                  {correctAnswer}
                </p>
                <button type="button" className="confirmPopup__gotIt-btn mt-3" onClick={() => { nextCard(); }}>Got it</button>
              </div>
            )}
        </div>
      )
        : (
          conclusionType === 'typeAnswer' ? (
            <div className="confirmPopUp__container" id="open-close">
            {correctAnswer.toUpperCase() === userAnswer.toUpperCase()
              ? (
                <div className="confirmPopup__main--container">
                  <CheckCircle className="confirmPopup__icon green" />
                  <p className="green">Correct Answer</p>
                  <div
                    role="presentation"
                    className="confirmPopup__bar--main-container mt-3"
                    onClick={() => { setOpen(false); setIsClicked(true); action(); cancellingTimeout(); }}
                  >
                    <div
                      className="confirmPopup__bar--sub-container"
                      style={{
                        width: '100%', animationDelay: '600ms', zIndex: -1, height: '52px',
                      }}
                    />
                    <p className="confirmPopup__btn-text">Continue</p>
                  </div>
                </div>
              )
              : (
                <div className="confirmPopup__main--container">
                  <XCircle className="confirmPopup__icon red" />
                  <p className="red">Wrong Answer</p>
                  <p>
                    Correct Answer:
                    {' '}
                      <span>
                        {correctAnswer}
                      </span>
                  </p>
                  <button type="button" className="confirmPopup__gotIt-btn mt-3" onClick={() => { nextCard(); }}>Got it</button>
                </div>
              )}
          </div>
          ) : (
            <div className="confirmPopUp__container" id="open-close">
            {isMultiCorrect
              ? (
                <div className="confirmPopup__main--container">
                  <CheckCircle className="confirmPopup__icon green" />
                  <p className="green">Correct Answer</p>
                  <div
                    role="presentation"
                    className="confirmPopup__bar--main-container mt-3"
                    onClick={() => { setOpen(false); setIsClicked(true); action(); cancellingTimeout(); }}
                  >
                    <div
                      className="confirmPopup__bar--sub-container"
                      style={{
                        width: '100%', animationDelay: '600ms', zIndex: -1, height: '52px',
                      }}
                    />
                    <p className="confirmPopup__btn-text">Continue</p>
                  </div>
                </div>
              )
              : (
                <div className="confirmPopup__main--container">
                  <XCircle className="confirmPopup__icon red" />
                  <p className="red">Wrong Answer</p>
                  <p>
                    Correct Answer:
                    {' '}
                    {/* eslint-disable-next-line max-len */}
                    {singleChoiceData?.length > 1 ? singleChoiceData?.map((list: any, index: number) => (
                      <span key={index}>
                        { (index ? ', ' : '') + list?.optionText }
                      </span>
                    )) : (
                      <span>
                        {correctAnswer}
                      </span>
                    )}
                  </p>
                  <button type="button" className="confirmPopup__gotIt-btn mt-3" onClick={() => { nextCard(); }}>Got it</button>
                </div>
              )}
          </div>
          )
        )}
    </>
  );
};
ConfirmPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  singleChoiceData: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  correctAnswer: PropTypes.string,
  setIsConclusion: PropTypes.func.isRequired,
  currentCard: PropTypes.number.isRequired,
  totalCard: PropTypes.number.isRequired,
  conclusionType: PropTypes.string.isRequired,
  isMultiCorrect: PropTypes.bool.isRequired,
};
ConfirmPopUp.defaultProps = {
  correctAnswer: '',
};

export default ConfirmPopUp;
