import { v4 as uuidv4 } from 'uuid';

interface ImageObject {
  [key: string]: JSX.Element;
}

export const handleImgToBase64 = (text: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/html');
  const imgTags = doc.querySelectorAll('img');

  const imagesObject: ImageObject | any = {};

  Array.from(imgTags).forEach((img: any) => {
    const randomId = uuidv4();
    const src = img.getAttribute('src').split('base64,')[1] || '';
    imagesObject[`media_${randomId}`] = src;
    for (const key in imagesObject) {
      if (imagesObject[key] !== "") {
        img.src = `#{{media_${randomId}}}`;
      }
    }
  });

  for (const key in imagesObject) {
    if (imagesObject[key] === "") {
      delete imagesObject[key];
    }
  }

  const modifiedHtml = doc.documentElement.innerHTML;

  return { medias: imagesObject, description: modifiedHtml };
};

export const handleBase64ToImg = (text: any, medias: any) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/html');
  const imgTagModification = doc.querySelectorAll('img');
  Array.from(imgTagModification).forEach((img: any) => {
    const src = img.getAttribute('src') || '';
    
    medias && medias.filter((media: { url: string; fileName: string }) => {

      if (`#{{${media.fileName}}}` === src) {
        img.src = media.url;
      }
    })
  });

  const convertedText = doc.documentElement.innerHTML
  return convertedText;
};
