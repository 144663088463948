import React, { useEffect, useState } from "react";
import notebg from '../../../hyperNotes/assets/notebg.jpg';
import NavbarContainer from "../../../BasicComponents/navbar";

// Here we are only setting up the stage for any flow component
// We can set the screen size, background color, etc.

/* Function to open fullscreen mode */
function openFullscreen() {
  let elem = document.documentElement as any;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) { /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE/Edge */
    elem = window && window.top && window.top.document.body; // To break out of frame in IE
    elem.msRequestFullscreen();
  }
}

// Events
const output = document.getElementsByClassName('fullscreen') as any;
document.addEventListener('fullscreenchange', () => {
  output.innerHTML = 'fullscreenchange event fired!';
});
document.addEventListener('mozfullscreenchange', () => {
  output.innerHTML = 'mozfullscreenchange event fired!';
});
document.addEventListener('webkitfullscreenchange', () => {
  output.innerHTML = 'webkitfullscreenchange event fired!';
});
document.addEventListener('msfullscreenchange', () => {
  output.innerHTML = 'msfullscreenchange event fired!';
});

const CanvasControl = (WrappedComponent: any,) => {

  const newInnerWidth: number = window.innerWidth;
  const newInnerHeight: number = window.innerHeight;

  const [innWidth, setInnWidth] = useState(newInnerWidth);
  const [innHeight, setInnHeight] = useState(newInnerHeight);
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const mql = window.matchMedia('(max-width: 614px)');
    const view = mql.matches;

    if (view) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, [innWidth]);

  const updateDimensions = () => {
    setInnWidth(window.innerWidth);
    setInnHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  // eslint-disable-next-line react/display-name
  return () => {
    return (
      <>
        <style>
          {`
          .container-fluid {
            font-size: 16px;
            background-image: url(${notebg});
            background-repeat: no-repeapt;
            background-size: cover;
          }
          .mobile__anki--view-container {
            height: ${innHeight}px;
           }
          .desktop-note-container {
            display: flex;
            align-items: center;
            width: 390px; 
            height: 85vh;
            margin: 0 auto;
          }
          .desktop-note-container .mobile__anki--view-container {
            position: relative;
            height: 560px;
            border-radius: 8px;
          }
          .desktop-note-container .mobile__anki--card-container {
            height: 470px;
          }
          .desktop-note-container h3 {
            font-size: 16px;
          }
          .desktop-note-container button {
            font-size: 16px;
          }
          .desktop-note-container .mobile__anki--btn-hard {
            width: 130px;
            height: 38px;
          }
          .desktop-note-container .mobile__anki--btn-easy {
            width: 130px;
            height: 38px;
          }
          .desktop-note-container video {
            height: 428px;
          }
          .desktop-note-container .desktop-only {
            height: 520px;
          }
          .desktop-note-container h1 {
            font-size: 20px !important;
          }
          .desktop-note-container img {
            height: 160px !important;
          } 
          .desktop-note-container .mobile__anki--paragraph {
            font-size: .85em;
          }
          .desktop-note-container .mobile__anki--hint-container {
            bottom: 40px
          }
        `}
        </style>
        {mobileView ? (
          <div className="fullScreen">
            <WrappedComponent {...{ openFullscreen, innHeight }} />
          </div>
        ) : (
          <>
            <div className="container-fluid mb-5">
              <div className="row">
                <NavbarContainer />
              </div>
            </div>
            <div className="container-fluid" style={{ height: `${newInnerHeight - 50}px` }}>
              <div className="row">
                <div className="desktop-note-container mt-5" style={{ display: "Block" }}>
                  <WrappedComponent {...{ innHeight }} />
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  };
}

export default CanvasControl;