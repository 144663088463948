import React from 'react';

const Refund: React.FC = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <h1 className="tearmandcondition--heading">Refund Policy</h1>
        <p className="tearmandcondition--text">
          Please read the terms of service carefully before initiating any
          transaction and paying the fees for the mentorship program.
        </p>
        <p className="tearmandcondition--text">
          {' '}
          Please note that you should not entertain any email/call/payment
          reference that is not sent to you from the official email id of the{' '}
          <span className="purple"> Hyperlearn (admin@hyperlearn.in)</span> and
          does not have a payment page/link for transaction to Hyperlearn.
        </p>
        <p className="tearmandcondition--text">
          Please note that you are entitled to get a refund on your order within
          7 days along with a reason for doing so and issue of a refund stands
          at the sole discretion of the management.
        </p>
        <p className="tearmandcondition--text">
          {' '}
          The deadline for cancelling an order and availing a refund is 7 days
          from the date of payment. Please write to{' '}
          <span className="purple">support@hyperlearn.in</span> in case of any
          queries related to this.
        </p>
      </div>
    </div>
  );
};

export default Refund;
