import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import _ from 'lodash';
import './searchMentor.css';
import { Modal } from 'react-bootstrap';
import { default as ReactSelect, components } from 'react-select';
import { useHistory } from 'react-router-dom';
import SearchMentorInput from './searchMentorInput';
import {
  // eslint-disable-next-line no-unused-vars
  filterArrow, filter, emptyOrder, reset,
} from '../BasicComponents/icons';
import MentorSearchCard from './mentorSearchCard';
import MentorSearchCardMobile from './mentorSearchCardMobile';
import { hlbClient } from '../../Clients/hlbClient';
import Footer from '../BasicComponents/footer/footer';
import { CourseContext } from '../../provider/CourseContext';
import NavbarContainer from '../BasicComponents/navbar';
import { categories, sessionTypes } from '../Constants';

const SearchMentor: React.FC = () => {
  // eslint-disable-next-line no-undef
  const getInnerWidth: number = window.innerWidth;
  const [data, setData] = useState<any[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [counts, setCounts] = useState<number>(0);
  const [skills, setSkills] = useState<string>('');
  const [showSkills, setShowSkills] = useState<boolean>(false);
  const [showCategory, setShowCategory] = useState<boolean>(false);
  const [showSessionType, setShowSessionType] = useState<boolean>(false);
  const [innWidth, setInnWidth] = useState<number>(getInnerWidth);
  const [mobileView, setMobileView] = useState<boolean>(false);
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [selectedCategories, setSelectedCategories] = useState<any[]>([]);
  const [selectedSessionTypes, setSelectedSessionTypes] = useState<any[]>([]);
  const { searchKeyword, sortBy } = useContext(CourseContext);
  const history = useHistory();

  function setWidth() {
    // eslint-disable-next-line no-undef
    setInnWidth(getInnerWidth);
  }

  // eslint-disable-next-line no-undef
  const paramsKey: string | undefined = window.location.search.split('?').pop();
  useEffect(() => {
    // eslint-disable-next-line no-undef
    const mql = window.matchMedia('(max-width: 614px)');
    const view = mql.matches;

    if (view) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, [innWidth]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', setWidth);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', setWidth);
  }, []);
  const updateDimensions = () => {
    setWidth();
  };
  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateDimensions);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);
  useEffect(() => {
    if (paramsKey) {
      setSkills(paramsKey);
    } else {
      setSkills(searchKeyword);
    }
  }, [searchKeyword]);
  // eslint-disable-next-line arrow-body-style
  const queryParams = (key: string, list: any) => {
    // eslint-disable-next-line no-param-reassign
    list = list.map(encodeURIComponent);
    return `${key}[]=${list.map((item: any) => item.toLowerCase()).join(`&${key}[]=`)}`;
  };
  useEffect(() => {
    const searchkey = skills.toString().replace(/,/g, ' ');
    async function fetchData() {
      try {
        if (searchkey !== '') {
          setOffset(0);
          setData([]);
          const resp = await hlbClient().get(`/api/mentor/?offset=${offset}&limit=10&${searchkey && `search=${searchkey}`}&${selectedSessionTypes.length && queryParams('sessions', _.map(selectedSessionTypes, 'value'))}&${selectedCategories.length && queryParams('categories', _.map(selectedCategories, 'value'))}`);
          const requestedData = resp.data[0].paginatedResults;
          setData(requestedData);
          setCounts(resp.data[0].totalCount[0].count);
        } else {
          const resp = await hlbClient().get(`/api/mentor/?offset=${offset}&limit=10&${searchkey && `search=${searchkey}`}&${selectedSessionTypes.length && queryParams('sessions', _.map(selectedSessionTypes, 'value'))}&${selectedCategories.length && queryParams('categories', _.map(selectedCategories, 'value'))}`);
          const requestedData = resp.data[0].paginatedResults;
          setData(requestedData);
          setCounts(resp.data[0].totalCount[0].count);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('oops something went wrong');
      }
    }
    fetchData();
  }, [`${offset}`, skills, `${selectedSessionTypes}`, `${sortBy}`, `${selectedCategories}`]);

  const callApi = async (key: string) => {
    try {
      const resp = await hlbClient().get(`/api/mentor/?limit=10&search=${key}`);
      const requestedData = resp.data[0].paginatedResults;
      setData(requestedData);
      setCounts(resp.data[0].totalCount[0].count);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');
    }
  };
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // eslint-disable-next-line max-len
      if ((showCategory || showSessionType || showSkills) && ref.current && !ref.current.contains(e.target)) {
        setShowCategory(false);
        setShowSkills(false);
        setShowSessionType(false);
      }
    };

    // eslint-disable-next-line no-undef
    window.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      // eslint-disable-next-line no-undef
      window.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showCategory, showSessionType, showSkills]);

  const handleCategoryChange = (selected: any) => setSelectedCategories(selected);

  const handleSessionTypeChange = (selected: any) => setSelectedSessionTypes(selected);

  const CustomStyle = {
    // --- TO STYLE SELECT BOX
    control: (styles: any) => ({
      ...styles,
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      color: '#676767',
      minWidth: '100px',
      textAlign: 'center',
    }),
    // --- TO STYLE OPTIONS CONTAINER
    menu: (base: any) => ({
      ...base,
      width: '300px',
    }),
    // --- TO STYLE OPTIONS
    option: (styles: any, { isSelected }: any) => ({
      ...styles,
      backgroundColor: isSelected ? '#f0f0f0' : '#FFFFFF',
      ':active': {
        ...styles[':active'],
        backgroundColor: '#f0f0f0',
      },
    }),
    // --- TO STYLE SELECTED OPTION
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: '#ECE2FF',
      borderRadius: '6px',
      marginRight: '4px',
    }),
    // --- TO STYLE SELECTED OPTION -> LABEL
    multiValueLabel: (styles: any) => ({
      ...styles,
      minWidth: '50px',
      maxWidth: '70px',
      color: '#AB81FF',
      fontWeight: '500',
      fontSize: '16px',
      textOverflow: 'ellipsis',
    }),
    // --- TO STYLE SELECTED OPTION -> REMOVE BTN
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: '#AB81FF',
      ':hover': {
        backgroundColor: '#D9C7FF',
      },
    }),
  };

  /* eslint-disable react/prop-types */
  const Option = (props: any) => (
    <div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <components.Option {...props}>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        <input type="checkbox" checked={props.isSelected} onChange={() => null} />
        {' '}
        {/* eslint-disable-next-line react/destructuring-assignment */}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );

  const resetFilters = () => {
    setSelectedCategories([]);
    setSelectedSessionTypes([]);
  };

  const filterOptions = (
    <div ref={ref}>
      <div className="searchMentor__filters">
        <ReactSelect
          options={categories}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option,
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
          }}
          name="categories"
          value={selectedCategories}
          onChange={handleCategoryChange}
          styles={CustomStyle}
          placeholder="Category"
          isClearable={false}
          isSearchable={false}
        />
        <ReactSelect
          options={sessionTypes}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option,
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
          }}
          name="sessionTypes"
          value={selectedSessionTypes}
          onChange={handleSessionTypeChange}
          styles={CustomStyle}
          placeholder="Session Type"
          isClearable={false}
          isSearchable={false}
        />
        {
          (selectedCategories?.length || selectedSessionTypes?.length) ? (
            <button type="button" className="reset__filters" onClick={resetFilters}>
              Reset
              <img src={reset} alt="reset" />
            </button>
          ) : null
        }
      </div>
    </div>
  );

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <NavbarContainer />
        </div>
      </div>
      <div className="container-fluid">
        <div className="row d-flex justify-content-center g-0">
          <div className="searchMentor__main--container">
            <div className="searchMentor__inputBox">
              <SearchMentorInput {...{ callApi, setSkills }} />
              <button
                className="searchMentor__myDeck-btn"
                type="button"
                onClick={() => history.push('/space-repitition-system')}
              >
                My Notes
              </button>
            </div>
            <div className="row">
              {!mobileView && (
              <div className="col-12">
                <div className="col-12 mt-5 searchMentor__hide">
                  {filterOptions}
                </div>
                <button type="button" onClick={() => setShowFilterModal(true)} className="btn btn-filter mt-4 searchMentor__hide-one-big">
                  Filter
                  {' '}
                  <img src={filter} alt="filter" />
                </button>
              </div>
              )}
            </div>
            {data.length === 0 ? (
              <img src={emptyOrder} alt="no search found" className="img-fluid" />
            ) : (
              <>
                {mobileView ? (
                  <>
                    {data && data.map((mentors, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <MentorSearchCardMobile mentor={mentors} key={index} />
                    ))}
                  </>
                ) : (
                  <>
                    {data && data.map((mentors, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <MentorSearchCard mentor={mentors} key={index} />
                    ))}
                  </>
                )}
              </>
            )}
          </div>
          <div className="col-12 text-center mt-5 mb-5">
            { counts > 10
              ? (
                <button
                  type="button"
                  onClick={() => { setOffset(offset + 10); }}
                  className="btn searchMentor__load-more"
                >
                  Load more mentors
                </button>
              ) : (
                <button
                  type="button"
                  disabled
                  className="btn searchMentor__load-more"
                >
                  No more mentors
                </button>
              )}
          </div>
          <Footer />
          <Modal show={showFilterModal} fullscreen="md" centered onHide={() => setShowFilterModal(false)} className="searchMentor__hide-one-big">
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="button"
                    onClick={() => setShowFilterModal(false)}
                    className="btn-close float-end"
                  />
                </div>
                <div className="co-md-12">
                  <p className="videoUpload__heading">Filter</p>
                  <p className="videoUpload__sub-heading">
                    Search mentor by filter
                  </p>
                </div>
                <div className="col-12">
                  {filterOptions}
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default SearchMentor;
