/* eslint-disable react/jsx-key */
import React from 'react';
import {
  Formik, useField, useFormikContext, Form, Field, ErrorMessage,
} from 'formik';
import dayjs from 'dayjs';
import en from 'dayjs/locale/en';
import duration from 'dayjs/plugin/duration';
import * as Yup from 'yup';
import DatePicker from '../../BasicComponents/FormikDatepicker';
import Label from '../../BasicComponents/InputLabel';

type StepOneProps = {
  data: any;
  next: (newData: any, isFinalStep?: boolean) => void;
  isWebinarPublished: boolean;
}

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Cannot be left blank!')
    .min(20, 'Title is too short')
    .max(60, 'You have reached the limit for title'),
  summary: Yup.string()
    .min(20, 'Summary is too short!')
    .max(200, 'You have reached the limit for summary!')
    .required('Cannot be left blank!'),
  date: Yup.string().required('Please select webinar date!'),
  startTime: Yup.string().required('Please select a start time!'),
  endTime: Yup.string().required('Please select a end time!'),
  originalPrice: Yup.number()
    .typeError('Amount must be a number')
    .min(1, 'Price cannot be zero!')
    .required('Cannot be left blank!'),
  discountPrice: Yup
    .number()
    .integer()
    .min(1, 'Price cannot be zero!')
    .test({
      name: 'originalPrice',
      exclusive: false,
      params: {},
      message: 'Discount price must be less than original price',
      test(value) {
        return value === null || value === undefined
          ? this.parent.originalPrice > 0
          : value < this.parent.originalPrice;
      },
    })
    .nullable(true),
}).required();

dayjs.locale(en);
dayjs.extend(duration);
const hours: string[] = [];

// eslint-disable-next-line no-plusplus
for (let hour = 0; hour < 24; hour++) {
  hours.push(dayjs().startOf('day').hour(hour).format('h:mm A'));
  hours.push(dayjs().startOf('day').hour(hour).add(30, 'minutes')
    .format('h:mm A'));
}

const StepOne = ({ data, next, isWebinarPublished }: StepOneProps) => {
  const DatePickerField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField({
      ...props,
      // eslint-disable-next-line react/prop-types
      name: props.name,
    });

    return (
      <DatePicker
        /* eslint-disable react/jsx-props-no-spreading */
        {...field}
        {...props}
        selected={(field.value && new Date(field.value))}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
        autoComplete="off"
        disabled={isWebinarPublished}
      />
    );
  };

  const handleSubmit = (values: any) => {
    next(values);
  };

  return (
    <Formik
      initialValues={data}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values }) => (
        <Form className="formContainer__form">
          <div className="form__field form__title">
            <Label HTMLFor="webinar--title" isRequired label="Title" />
            <Field
              name="title"
              id="webinar--title"
              type="text"
              className="form__field--inputFull"
              placeholder="Add a title for your webinar"
              disabled={isWebinarPublished}
            />
            <ErrorMessage component="span" name="title" className="form__field--error" />
          </div>
          <div className="form__field form__summary">
            <Label HTMLFor="webinar--summary" isRequired label="Short Summary" tooltip="Describe your webinar shortly" limitPoints={{ currentCount: values?.summary?.length, limit: 200 }} />
            <Field
              as="textarea"
              id="webinar--summary"
              name="summary"
              placeholder="Write a few lines about your webinar"
              className="form__field--textarea"
              rows="5"
            />
            <ErrorMessage component="span" name="summary" className="form__field--error" />
          </div>
          <div className="form__field form__date">
            <Label HTMLFor="webinar--date" isRequired label="Select a Date" tooltip="Select a date for your webinar to happen." />
            <DatePickerField name="date" placeholderText="Please select a date" minDate={new Date()} />
            <ErrorMessage component="span" name="date" className="form__field--error" />
          </div>
          <div className="form__field-group form__price">
            <div className="form__price--original">
              <Label HTMLFor="webinar--originalPrice" isRequired label="Enter the price" />
              <Field
                id="webinar--originalPrice"
                name="originalPrice"
                placeholder="₹1299"
                className="form__field--inputFull"
                type="number"
              />
              <ErrorMessage component="span" name="originalPrice" className="form__field--error" />
            </div>
            <div className="form__price--discounted">
              <Label HTMLFor="webinar--discountPrice" label="Add a discount" />
              <Field
                id="webinar--discountPrice"
                name="discountPrice"
                placeholder="₹699"
                className="form__field--inputFull"
                type="number"
              />
              <ErrorMessage component="span" name="discountPrice" className="form__field--error" />
            </div>
          </div>
          <div className="form__field form__price--startTime">
            <Label HTMLFor="webinar--startTime" label="Select start time" isRequired />
            <Field
              id="webinar--startTime"
              as="select"
              name="startTime"
              className="form__field--select"
            >
              <option disabled selected value="">select an option</option>
              {
                hours.map((hour) => (
                  <option value={hour}>{hour}</option>
                ))
              }
            </Field>
            <ErrorMessage component="span" name="startTime" className="form__field--error" />
          </div>
          <div className="form__field form__price--endTime">
            <Label HTMLFor="webinar--endTime" label="Select end time" isRequired />
            <Field
              id="webinar--endTime"
              as="select"
              name="endTime"
              className="form__field--select"
            >
              <option disabled selected value="">select an option</option>
              {
                values?.startTime ? hours.slice(hours.indexOf(values.startTime) + 1).map((hour) => (
                  <option value={hour}>{hour}</option>
                )) : hours.map((hour) => (
                  <option value={hour}>{hour}</option>
                ))
              }
            </Field>
            <ErrorMessage component="span" name="endTime" className="form__field--error" />
          </div>
          <div className="form__navigationButtons">
            <button className="form__navigationButton form__navigationButton--next" type="submit">Next</button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default StepOne;
