import React from 'react';
import { BookBookmark } from 'phosphor-react';
import "./EmptyStates.css";

const EmptyNotes = () => {
    return (
        <div className="emptyContainer emptyContainer__myNotes">
            <div className="emptyContainer__icon--wrapper">
            <BookBookmark size={32} weight="fill" color="#FFFFFF" className="emptyContainer__icon"  />
            </div>
            <div className="emptyContainer__info">
                <h4 className="info__heading">No Notes found!</h4>
                <p className="info__subtext">Something went wrong while <br /> fetching Notes!</p>
            </div>
        </div>
    );
};

export default EmptyNotes;