// eslint-disable-next-line no-unused-vars
import React from 'react';
import Select, { GroupBase } from 'react-select';

type TaggedInputProps = {
  setLanguages: React.Dispatch<React.SetStateAction<string[]>>;
  options: readonly (string | GroupBase<string>)[];
  placeholder: string;
  previousValue: string;
};

// eslint-disable-next-line arrow-body-style
const TaggedInput: React.FC<TaggedInputProps> = ({
  setLanguages,
  options,
  placeholder,
  previousValue,
}) => {
  const customTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#9281fc',
      primary25: 'rgba(167, 118, 228, 0.08)',
      dangerLight: 'transparent',
      danger: '#1a1a1a',
      neutral50: '#C4C4C4',
    },
  });

  const addLanguages = (values: any) => {
    const enteredLanguages = values.map((val: any) => val.label);
    setLanguages([...enteredLanguages]);
  };

  return (
    <Select
      options={options}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      className="mb-2 mentor--tagged-input"
      theme={customTheme}
      defaultValue={previousValue}
      onChange={(values) => addLanguages(values)}
      noOptionsMessage={() => 'No such language found!'}
      placeholder={placeholder}
      isMulti
      isSearchable
    />
  );
};

export default TaggedInput;
