/* eslint-disable react/prop-types */
import React from 'react';
import _ from 'lodash';
import './announcementCard.css';
import { announcement } from '../icons';

type Props = {
  data: { description: string; link: string },
}

const AnnouncementCard: React.FC<Props> = ({ data }) => (
  <>
    {!data.description
      ? (
        <div className="announcementCard__container">
          <p className="announcementCard__container--paragraph">
            Got some news to share with your mentees?
            Write your message here and add your announcement on your public profile.
          </p>
        </div>
      ) : (
        <div className="annuncement-card mb-4 hide-on--screen">
          <div className="announcement-box">
            <img src={announcement} alt="announcement" />
          </div>
          <p className="announcement-text" style={{ marginBottom: '0px' }}>
            {_.get(data, 'description', '')}
          </p>
          {_.get(data, 'link', '') && <a href={_.get(data, 'link', '')} target="_blank" className="announcement-text" rel="noreferrer"><i>Click here</i></a>}
        </div>
      )}
  </>
);

export default AnnouncementCard;
