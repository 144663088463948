type HomePageNotesType = {
  type: string,
  search?: string[],
  filter?: any[],
  ids?: string[],
  limit: number,
};

import { myConfig } from "../../config";



const prodConfig: { category: string, body: HomePageNotesType}[] = [{
  category: 'Featured Notes',
  body: {
    type: 'note',
    ids: [
      "64ae8bceed29ffb6423883b3",
      "643a5e3b6c6e526a0dcfa544",
      "63a48e0fd0119b64e6e54226",
      "64671045557c4ca2fdb3ddf5"
    ],
    limit: 5,
  },
},
{
  category: "Current affairs",
  body: {
    type: "note",
    search: ["current affairs"],
    limit: 5,
  },
}
];


const stagingConfig : { category: string, body: HomePageNotesType}[] = [{
  category: 'Featured Notes',
  body: {
    type: 'note',
    ids: [
      '64c79bbd5cf507d4659e3540',
      '646c5baa7ef49a9a7dfb66b8',
      '65092686258376075f4bf3d2',
      '652cd2fffd613d6abcfd6e01',
      '6436cdb078cff1cfe9361d70',
      '6436e8252dfdff05c6a75c62',
      '651587e8087b7696046a4971',
      '649d57aed22589cc2273efea',
      '632ee0331af217a71f13dce9',
      '641696d2688e6ea698092a78',
      '644e381a5c5d8c77536deba7',
    ],
    limit: 5,
  },
},
{
  category: 'React Notes',
  body: {
    type: 'note',
    search: ['react'],
    limit: 5,
  },
},
{
  category: 'Free Notes',
  body: {
    type: 'note',
    filter: [
      {
        key: 'price',
        value: {
          eq: 0,
        },
      },
    ],
    limit: 5,
  },
}
];

export const homePageNotes = myConfig.environment === 'production' ? prodConfig : stagingConfig;