import React, { ButtonHTMLAttributes } from 'react';
import './AuthButton.css';

interface AuthButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant: "primary" | "secondary",
}

const AuthButton = ({ variant, children, disabled, onClick, ...rest }: AuthButtonProps) => {
    return (
        <button 
            className={`authButton authButton-${variant}`}
            disabled={disabled}
            onClick={onClick}
            {...rest}
        >
            {children}
        </button>
    );
};

export default AuthButton;