import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import ReactCrop, { Crop } from 'react-image-crop';
import CreatableSelect from 'react-select/creatable';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { NoteCard } from '@abhijeet_hyperlearn/hlf_lib';
import Tooltip from '../../../BasicComponents/Tooltip';
import {
  defaultUploadImage,
  info,
  redCautionIcon,
} from '../../../BasicComponents/icons';
import { hlbClient } from '../../../../Clients/hlbClient';
import { AuthContext } from '../../../../provider/AuthContext';

import './createNoteModal.css';
import { useDispatch } from 'react-redux';
import { addToast } from '../../../../reducers/toast.reducer';

type createNoteProps = {
  createDeckModal: boolean;
  toggleCreateDeckModal: () => void;
  getMyDeck: () => any;
  data?: any;
  type?: string;
  deckPhoto?: any;
};

type tagOption = {
  readonly label: string;
  readonly value: string;
};

const createOption = (label: string) => ({
  label,
  value: label,
});

const CreateNoteModal: React.FC<createNoteProps> = ({
  createDeckModal,
  toggleCreateDeckModal,
  getMyDeck,
  data = [],
  type = '',
}) => {
  const MAX_IMAGE_SIZE_IN_MB = 5;
  const TITLE_LIMIT = 60;
  const TAGS_LIMIT = 5;
  const DESCRIPTION_LIMIT = 300;
  const CROP_WIDTH_MULTIPLIER = 16;
  const CROP_HEIGHT_MULTIPLIER = 9;

  const [isLoading, setIsLoading] = useState(false);
  const { authState } = useContext(AuthContext);
  const [profileData, setProfileData] = useState({
    profileImage:
      'https://res.cloudinary.com/vancitydc19/image/upload/v1682694831/hygz00uic751cwppb3fy.png',
    creatorName: 'Anonymous',
    creatorIntro: '',
  });

  useEffect(() => {
    const getProfileDetails = async () => {
      try {
        const data = await hlbClient()
          .get(`api/users/${authState._id}/profile`)
          .then((res) => res.data[0]);
        setProfileData({
          profileImage: data.profilePic.url,
          creatorName: data.userName,
          creatorIntro: data.shortBio,
        });
      } catch (err) {
        console.log(err);
      }
    };

    getProfileDetails();
  }, []);

  useEffect(() => {
    if (type === 'edit' && data) {
      setPic(data?.image?.url);
      setDisplayPic(data?.image?.url);
      data?.tags?.map((tag: string) =>
        setTagsArray((prev) => [...prev, createOption(tag)])
      );
    }
  }, [data]);

  const [imageSize, setImageSize] = useState(0);
  const [deckPhoto, setDeckPhoto] = useState('');
  const [displayPic, setDisplayPic] = useState('');
  const [pic, setPic] = useState<any>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    width: 102,
    aspect: CROP_WIDTH_MULTIPLIER / CROP_HEIGHT_MULTIPLIER,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [cropModal, setCropModal] = useState(false);

  const uploadPicture = (e: any) => {
    setDeckPhoto(e.target.files[0].name);
    setImageSize(e.target.files[0].size / 1024 / 1024);
  };

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader: any = new window.FileReader();
      reader.addEventListener('load', () => setDisplayPic(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const uploadPicWithoutCrop = async (newdisplayPic: any) => {
    const base64Response = await fetch(newdisplayPic);
    const blob = await base64Response.blob();
    const profilePic: any = new File([blob], deckPhoto, {
      type: "'image/jpeg', 'image/jpg', 'image/png'",
      lastModified: new Date().getTime(),
    });
    setPic(profilePic);
  };

  const onLoad = useCallback((img: any) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image: any = imgRef.current;
    const canvas: any = previewCanvasRef.current;
    const crop: any = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const saveProfilePic = (canvas: any, newPic: any) => {
    if (imageSize > MAX_IMAGE_SIZE_IN_MB) {
      console.log('image size too big');
    } else {
      canvas.toBlob(
        (blob: any) => {
          const previewUrl: any = window.URL.createObjectURL(blob);
          setDisplayPic(previewUrl);
          const profilePic: any = new File([blob], newPic, {
            type: "'image/jpeg', 'image/peg', 'image/png'",
            lastModified: new Date().getTime(),
          });
          setPic(profilePic);
        },
        newPic,
        1
      );
    }
  };

  const imagePreviousState = () => {
    setPic(data?.image?.url || null);
    setDisplayPic(data?.image?.url || '');
  };

  const [inputTagValue, setInputTagValue] = useState('');
  const [tagsArray, setTagsArray] = useState<readonly tagOption[]>([]);

  const handleKeyDown = (
    e: React.KeyboardEvent<Element>,
    values: valueProps
  ) => {
    if (!inputTagValue) return;
    if (tagsArray.length >= TAGS_LIMIT) {
      setInputTagValue('');
      return;
    }
    switch (e.key) {
      case 'Enter':
      case 'Tab':
        if (![...tagsArray.map((tag) => tag.value)].includes(inputTagValue)) {
          setTagsArray((prev) => [...prev, createOption(inputTagValue)]);
          values.tags = [...tagsArray.map((tag) => tag.value), inputTagValue];
        }
        setInputTagValue('');
        e.preventDefault();
    }
  };

  // const handleIsPaidChange = (e: any, values: valueProps) => {
  //   values.isPaid = e.target.checked;
  //   !values.isNotePublic ? (values.isPaid = false) : null;
  //   !values.isNotePublic || !values.isPaid || !values.price
  //     ? (values.price = 0)
  //     : null;
  //   !values.isNotePublic || !values.isPaid || !values.discountedPrice
  //     ? (values.discountedPrice = 0)
  //     : null;
  // };

  // const handleIsNotePublicChange = (e: any, values: valueProps) => {
  //   values.isNotePublic = e.target.checked;
  //   !values.isNotePublic ? (values.isPaid = false) : null;
  //   !values.isNotePublic || !values.isPaid || !values.price
  //     ? (values.price = 0)
  //     : null;
  //   !values.isNotePublic || !values.isPaid || !values.discountedPrice
  //     ? (values.discountedPrice = 0)
  //     : null;
  // };

  type valueProps = {
    image: any;
    title: string;
    tags: string[];
    description: string;
    isNotePublic: boolean;
    isPaid: boolean;
    price: number;
    isDiscounted: boolean;
    discountedPrice: number;
  };

  const initialValues: valueProps = {
    image: pic ?? null,
    title: data?.name ?? '',
    tags: data?.tags ?? [],
    description: data?.description ?? '',
    isNotePublic: data?.shareType
      ? data?.shareType === 'private'
        ? false
        : true
      : false,
    isPaid: typeof data?.isFree === 'boolean' ? !data.isFree : false,
    price: data?.price ?? 0,
    isDiscounted: data?.isDiscounted ?? false,
    discountedPrice: data?.discountedPrice ?? 0,
  };

  const validationSchema = Yup.object().shape({
    image: Yup.mixed()
      .test(
        'is-filled',
        'Note Image is required',
        () => pic && pic.length !== 0
      )
      .test('is-valid-size', 'Max allowed size is 5MB', () =>
        pic?.size ? pic.size / 1024 / 1024 <= MAX_IMAGE_SIZE_IN_MB : true
      ),
    title: Yup.string()
      .required('Title is required')
      .max(60, 'Cannot have more than ${max} characters'),
    tags: Yup.array().min(1, 'Must have at least ${min} tag'),
    description: Yup.string()
      .required('Description is required')
      .max(300, 'Cannot have more than ${max} characters'),
    isPaid: Yup.boolean(),
    price: Yup.number().when('isPaid', {
      is: true,
      then: Yup.number().moreThan(0, 'Price cannot be negative or zero'),
    }),
    isDiscounted: Yup.boolean(),
    discountedPrice: Yup.number().when('isDiscounted', {
      is: true,
      then: Yup.number()
        .min(0, 'Discounted price cannot be negative')
        .lessThan(
          Yup.ref('price'),
          'Discounted price should be less than Price'
        ),
    }),
  });

  const dispatch = useDispatch();

  const submitHandler = async (formData: any) => {
    if (type === 'edit') {
      const resp = await hlbClient('multipart/form-data').put(
        `/api/srs/deck/${data?._id}`,
        formData
      );
      setPic([]);
      if (resp?.data?.success) {
        dispatch(addToast({ type: 'success', message: resp?.data?.msg }));
        getMyDeck();
        toggleCreateDeckModal();
      }
    } else {
      const resp = await hlbClient('multipart/form-data').post(
        '/api/srs/deck/create',
        formData
      );
      setPic([]);
      if (resp?.data?.success) {
        dispatch(addToast({ type: 'success', message: resp?.data?.msg }));
        getMyDeck();
        toggleCreateDeckModal();
      }
    }
  };

  const handleSubmit = async (values: valueProps) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('image', pic);
    formData.append('name', values.title);
    values.tags.forEach((tag) => formData.append('tags', tag));
    formData.append('description', values.description);
    formData.append('shareType', values.isNotePublic ? 'public' : 'private');
    formData.append('isFree', JSON.stringify(!values.isPaid));
    formData.append('price', values.price.toString() || '0');
    formData.append('isDiscounted', JSON.stringify(values.isDiscounted));
    formData.append(
      'discountedPrice',
      values.discountedPrice.toString() || '0'
    );
    try {
      submitHandler(formData);
    } catch (err) {
      const error: any = err;
      const message = error?.response?.data?.result?.errors[0]?.msg;
      dispatch(addToast({ type: 'error', message }));
    }
    setIsLoading(false);
  };

  return (
    <>
      <Modal
        show={createDeckModal}
        size="xl"
        centered
        backdrop="static"
        onHide={() => toggleCreateDeckModal()}
        className="create-note__modal"
      >
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ values }) => (
              <Form>
                <div className="create-note__container">
                  <button
                    type="button"
                    onClick={() => toggleCreateDeckModal()}
                    className="btn-close float-end"
                    style={{ width: '12px', height: '12px' }}
                  />
                  <p>{type === 'edit' ? 'Edit Note' : 'Create Note'}</p>
                  <div className="create-note__wrapper">
                    <div className="create-note__col-preview">
                      <div className="preview-title">
                        <p className="create-note__label">Preview</p>
                        <p>
                          This is how your Note will look! Hover on the Note to
                          see the details.
                        </p>
                      </div>
                      <div className="create-note__notecard">
                        <NoteCard
                          id="note-placeholder-id"
                          image={displayPic || defaultUploadImage}
                          isTrending={false}
                          title={values.title || 'Your note title'}
                          subscriberCount={0}
                          cardCount={0}
                          tags={values.tags}
                          buttonText={'Explore'}
                          profileImage={profileData.profileImage}
                          description={
                            values.description ||
                            'Your note description will appear here'
                          }
                          creatorName={profileData.creatorName}
                          creatorIntro={profileData.creatorIntro}
                          handleClicked={() => {
                            console.log('Create the note to Explore it!');
                          }}
                        />
                      </div>
                    </div>

                    <div className="create-note__col-form">
                      <div className="create-note__field">
                        <label htmlFor="image" className="create-note__label">
                          Image
                        </label>
                        <div className="create-note__upload-image">
                          <label
                            htmlFor="image-file"
                            className="btn create-note__btn-upload"
                          >
                            {`${
                              displayPic?.length !== 0 ? 'Change' : 'Upload'
                            } Thumbnail`}
                          </label>
                          <input
                            type="file"
                            id="image-file"
                            name="image"
                            accept="image/*"
                            onChange={(e) => {
                              uploadPicture(e);
                              onSelectFile(e);
                              setCropModal(true);
                            }}
                            onClick={(e: any) => {
                              e.target.value = null;
                            }}
                          />
                          <ErrorMessage
                            name="image"
                            component="p"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="create-note__field">
                        <div
                          style={{
                            display: 'flex',
                            gap: '20px',
                            alignItems: 'center',
                          }}
                        >
                          <label htmlFor="title" className="create-note__label">
                            Title
                          </label>
                          <p>{`${TITLE_LIMIT - values.title.length} ${
                            TITLE_LIMIT - values.title.length <= 1
                              ? 'character'
                              : 'characters'
                          } remaining`}</p>
                          <ErrorMessage
                            name="title"
                            component="p"
                            className="error"
                          />
                        </div>
                        <Field
                          name="title"
                          type="text"
                          placeholder="Type the note title..."
                          maxlength={TITLE_LIMIT}
                        />
                      </div>
                      <div className="create-note__field">
                        <div
                          style={{
                            display: 'flex',
                            gap: '20px',
                            alignItems: 'center',
                          }}
                        >
                          <label htmlFor="tags" className="create-note__label">
                            Tags
                          </label>
                          <p>{`${TAGS_LIMIT - values.tags.length} ${
                            TAGS_LIMIT - values.tags.length <= 1
                              ? 'tag'
                              : 'tags'
                          } remaining`}</p>
                          <ErrorMessage
                            name="tags"
                            component="p"
                            className="error"
                          />
                        </div>
                        <CreatableSelect
                          name="tags"
                          components={{ DropdownIndicator: null }}
                          inputValue={inputTagValue}
                          isClearable
                          isMulti
                          menuIsOpen={false}
                          onChange={(newValue) => {
                            setTagsArray(newValue);
                            values.tags = [...newValue.map((val) => val.value)];
                          }}
                          onInputChange={(newValue) =>
                            setInputTagValue(newValue)
                          }
                          onKeyDown={(e) => handleKeyDown(e, values)}
                          placeholder="Type each tag and press enter..."
                          value={tagsArray}
                          className={`field-multiselect ${
                            tagsArray.length >= TAGS_LIMIT ? 'disable' : ''
                          }`}
                          classNamePrefix="field-multiselect"
                        />
                      </div>
                      <div className="create-note__field">
                        <div
                          style={{
                            display: 'flex',
                            gap: '20px',
                            alignItems: 'center',
                          }}
                        >
                          <label
                            htmlFor="description"
                            className="create-note__label"
                          >
                            Description
                          </label>
                          <p>{`${
                            DESCRIPTION_LIMIT - values.description.length
                          } ${
                            DESCRIPTION_LIMIT - values.description.length <= 1
                              ? 'character'
                              : 'characters'
                          } remaining`}</p>
                          <ErrorMessage
                            name="description"
                            component="p"
                            className="error"
                          />
                        </div>
                        <Field
                          name="description"
                          type="text"
                          as="textarea"
                          placeholder="Type the note description..."
                          maxlength={DESCRIPTION_LIMIT}
                        />
                      </div>
                      <div className="create-note__field field-checkbox">
                        <Field
                          name="isNotePublic"
                          type="checkbox"
                          checked={values.isNotePublic}
                          // onClick={(e: any) =>
                          //   handleIsNotePublicChange(e, values)
                          // }
                        />
                        <label
                          htmlFor="isNotePublic"
                          className="create-note__label"
                        >
                          Make the note public*
                        </label>
                      </div>
                      <ErrorMessage
                        name="price"
                        component="p"
                        className="error"
                      />
                      <ErrorMessage
                        name="discountedPrice"
                        component="p"
                        className="error"
                      />
                      {values.isNotePublic && (
                        <div className="create-note__field public-fields">
                          <div className="field-price">
                            <div className="field-checkbox">
                              <Field
                                name="isPaid"
                                type="checkbox"
                                checked={values.isNotePublic && values.isPaid}
                              />
                              <label
                                htmlFor="isPaid"
                                className="create-note__label"
                              >
                                Click to make it paid!
                              </label>
                            </div>
                            <div className="create-note__field">
                              <label
                                htmlFor="price"
                                className={`create-note__label ${
                                  !values.isPaid ? 'disabled' : ''
                                }`}
                              >
                                Price
                              </label>
                              <Field
                                name="price"
                                type="number"
                                placeholder=""
                                disabled={!values.isPaid}
                                min={0}
                                value={values.isPaid ? values.price : ''}
                              />
                            </div>
                          </div>
                          <div className="field-price">
                            {values.isPaid && (
                              <div className="field-checkbox">
                                <Field
                                  name="isDiscounted"
                                  type="checkbox"
                                  checked={
                                    values.isNotePublic &&
                                    values.isPaid &&
                                    values.isDiscounted
                                  }
                                />
                                <label
                                  htmlFor="isDiscounted"
                                  className="create-note__label"
                                >
                                  Click to give a discount!
                                </label>
                              </div>
                            )}
                            <div className="create-note__field">
                              <label
                                htmlFor="discountedPrice"
                                className={`create-note__label ${
                                  !values.isPaid ? 'disabled' : ''
                                }`}
                              >
                                Discounted Price
                              </label>
                              <Field
                                name="discountedPrice"
                                type="number"
                                placeholder=""
                                disabled={!values.isDiscounted}
                                min={0}
                                value={
                                  values.isDiscounted
                                    ? values.discountedPrice
                                    : ''
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="create-note__footer">
                    <p>
                      *The Note will currently be created as private. You may
                      request to make the Note public.
                    </p>
                    <div className="create-note__footer-btns">
                      <button
                        disabled={isLoading}
                        type="button"
                        className="btn create-note__btn-secondary"
                        onClick={() => toggleCreateDeckModal()}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn create-note__btn-primary"
                      >
                        {isLoading && (
                          <Spinner
                            animation="border"
                            className="mx-2"
                            role="status"
                            size="sm"
                          />
                        )}
                        {type === 'edit' ? 'Save' : 'Create'}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      {cropModal && (
        <Modal show={cropModal} centered backdrop="static">
          <Modal.Body>
            <p className="mb-2">Crop the image</p>
            <div>
              <ReactCrop
                src={displayPic}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c: any) => setCrop(c)}
                onComplete={(c: any) => setCompletedCrop(c)}
              />
              <div className="crop-image__preview-container">
                <p className="create-note__label">Crop preview :</p>
                <div className="crop-image__preview-wrapper">
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      width: Math.round(
                        completedCrop ? CROP_WIDTH_MULTIPLIER * 10 : 0
                      ),
                      height: Math.round(
                        completedCrop ? CROP_HEIGHT_MULTIPLIER * 10 : 0
                      ),
                    }}
                  />
                </div>
              </div>

              <p className="crop-image__label">
                <img
                  src={redCautionIcon}
                  alt=".."
                  style={{ marginTop: '-2px' }}
                />{' '}
                GIFs will not work if you crop it.
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="create-note__footer-btns">
              <button
                className="btn create-note__btn-secondary"
                type="button"
                onClick={() => {
                  setCropModal(false);
                  imagePreviousState();
                }}
              >
                Cancel
              </button>
              <button
                className="btn create-note__btn-primary"
                type="button"
                onClick={() => {
                  setCropModal(false);
                  uploadPicWithoutCrop(displayPic);
                }}
              >
                Save GIF
              </button>
              <button
                onClick={() => {
                  setCropModal(false);
                  saveProfilePic(previewCanvasRef.current, deckPhoto);
                }}
                className="btn create-note__btn-primary"
                type="button"
              >
                Crop & Save
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default CreateNoteModal;
