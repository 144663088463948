import React, { useCallback, useEffect, useState } from 'react';
import { EntityContainer, NoteCard } from '@abhijeet_hyperlearn/hlf_lib';
import { CuratedNotes } from '../studentDashboard.config';
import { hlbClient } from '../../../Clients/hlbClient';
import { buildEntitiesResponse, defaultEntityContainerConfig } from '../StudentDashboard';
import EntityShowcase from '../entityShowcase';
import { noop } from 'lodash';
import { X } from 'phosphor-react';
import { EmptyNotes, EmptySearch } from '../emptyStates';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setFavouriteNotes } from '../../../reducers/userFavouriteNotes.reducer';

interface DashboardProps {
    searchTerm?: string;
    clearSearch?: () => void;
    openAuthModal?: () => void;
}

export interface Favourite {
    entityId: string;
    entityType: string;
    _id: string;
}

const Dashboard = ({ searchTerm = "", clearSearch = noop, openAuthModal = noop }: DashboardProps) => {
    
    const [favNoteIds, setFavNoteIds] = useState<string[]>([]);
    const [renderEntityShowcase, setRenderEntityShowcase] = useState(false);
    const [entityContainerConfig, setEntityContainerConfig] = useState(defaultEntityContainerConfig);
    const [emptyContainers, setEmptyContainers] = useState({
        noSearchResults: false,
        noNotesFound: false,
    });

    const { noSearchResults, noNotesFound } = emptyContainers;
    const token = window.localStorage.getItem('token');
    const dispatch = useDispatch();
    const userFavNoteIds: string[] = useSelector((state: any) => state.favNoteIds.favouriteNoteIds || []);

    const handleExplore = (id: number, entityDetails: any) => {
        const price = entityDetails?.price;
        const history = useHistory();

        if (token) history.push(`/notes/flow-mode-v2/${id}`);

        if (!token && price > 0) openAuthModal();

        if (!token && price === 0) history.push(`/notes/flow-mode-v2/${id}`);
    };

    const toggleFavourite = async (entityId: string) => {
        try {
            const resp = await hlbClient().post('/api/favourites', {
                entityType: "note",
                entityId,
            });
            const favList: Favourite[] = resp.data.result.favourites || [];
            const noteList = favList.filter((item) => item.entityType === "note")?.map((item) => item.entityId);
            setFavNoteIds(noteList);
            dispatch(setFavouriteNotes({
                favouriteNoteIds: noteList,
            }));
        } catch (error) {
            console.error("Something went wrong while toggling favourites!", error);
        }
    };

    const fetchNotes = useCallback(async (type: string, search?: string, filter?: any[], favList?: string[]) => {
        try {
          const resp = await hlbClient().post('/api/entities/getEntities', {
            type,
            search,
            filter,
            limit: 5,
          });
          if (resp.data?.data?.entities?.length === 0) {
            searchTerm ? setEmptyContainers((prev) => {
                return { ...prev, noSearchResults: true };
            }) : setEmptyContainers((prev) => {
                return { ...prev, noNotesFound: true };
            })
          } else {
            return buildEntitiesResponse(resp?.data?.data?.entities, resp?.data?.data?.total, handleExplore, toggleFavourite, favList);
          }
        } catch {
          console.error('Something went wrong while fetching notes!');
        }
    }, []);

    const fetchFavourites = async () => {
        try {
            const response = await hlbClient().get("/api/favourites");
            const favList: Favourite[] = response.data?.result?.favourites || [];
            
            if (favList.length > 0) {
                const noteIds = favList.filter((item: Favourite) => item.entityType === "note")?.map((item) => item.entityId);
                setFavNoteIds(noteIds);
                dispatch(setFavouriteNotes({
                    favouriteNoteIds: noteIds,
                }));
            }
        } catch (error) {
            console.error("Something went wrong while fetching your favourites!", error);
        }
    };

    const closeEntityShowcase = () => {
        setRenderEntityShowcase(false);
    };

    // closes the entity showcase if user searches for anything while entity showcase is visible
    useEffect(() => {
        if (searchTerm) {
            setRenderEntityShowcase(false);
            setEntityContainerConfig(defaultEntityContainerConfig);
        }
    }, [searchTerm]);

    useEffect(() => {
        fetchFavourites();
    }, []);

    useEffect(() => {
        if (userFavNoteIds.length > 0) {
            setFavNoteIds(userFavNoteIds);
        }
    }, [userFavNoteIds]);

    if (noNotesFound) {
        return <EmptyNotes />;
    }

    if (renderEntityShowcase) {
        return (
            <EntityShowcase
                entityType={entityContainerConfig.entityType}
                title={entityContainerConfig.title}
                search={entityContainerConfig.search || searchTerm}
                filter={entityContainerConfig.filter}
                ids={entityContainerConfig.ids}
                closeShowcase={closeEntityShowcase}
            />
        )
    }

    if (searchTerm) {
        return (
            <>
                <h2 className='search__result-heading'>
                    Results for
                    {" "}
                    <span className='search__term'>{`"${searchTerm}"`}</span>
                    {" "}
                    <X size={16} onClick={clearSearch} weight="bold" style={{ cursor: "pointer" }} />
                </h2>

                {
                    noSearchResults ? (
                        <EmptySearch />
                    ) : (
                        <EntityContainer
                            key={searchTerm}
                            title={searchTerm}
                            entityDetails={{
                                component: NoteCard,
                                loadEntityData: () => {
                                    return fetchNotes("note", searchTerm, [], favNoteIds);
                                }
                            }}
                            handleLoadMore={() => {
                                setRenderEntityShowcase(true);
                                setEntityContainerConfig({
                                    title: searchTerm,
                                    entityType: "note",
                                    search: searchTerm,
                                    filter: [],
                                    ids: [],
                                    showcaseItems: []
                                })
                            }}
                        />
                    )
                }
            </>
        );
    }

    return (
        <>
            {
                // renders the curating notes if no search term is present
                CuratedNotes?.map((item) => {
                    return (
                        <EntityContainer
                            key={item.containerLabel}
                            title={item.containerLabel}
                            entityDetails={{
                                component: NoteCard,
                                loadEntityData: () => {
                                    return fetchNotes(item.type, item.search, item.filter, favNoteIds);
                                }
                            }}
                            handleLoadMore={() => {
                                setRenderEntityShowcase(true);
                                setEntityContainerConfig({
                                  title: item.containerLabel,
                                  entityType: item.type,
                                  search: item.search,
                                  filter: item.filter,
                                  ids: item.ids,
                                  showcaseItems: [],
                                });
                            }}
                        />
                    )
                })
            }
        </>
    )
};

export default Dashboard;