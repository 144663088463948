import React, { useEffect, useState } from 'react';
import './AboutWebinar.css';
import { hlbClient } from '../../../../Clients/hlbClient';

type AboutWebinarProps = {
  webinar: any;
  isMobileView: boolean;
}

const AboutWebinar = ({ webinar, isMobileView }: AboutWebinarProps) => {
  const [showMore, setShowMore] = useState(false);
  const [hostDetails, setHostDetails] = useState<any>({});
  const { createdBy } = webinar;
  const getYoutubeEmbedId = (url: string) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  };

  useEffect(() => {
    const fetchHostDetails = async () => {
      try {
        const response = await hlbClient().get(`/api/users/${createdBy}/profile`);
        setHostDetails(response.data[0]);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    // eslint-disable-next-line no-unused-expressions
    createdBy && fetchHostDetails();
  }, [createdBy]);

  const renderHostProfile = () => (
    <div className="webinar__hostProfile--mobile">
      <img
        src={hostDetails?.profilePic?.url}
        alt="avatar"
        className="hostProfile--avatar"
      />
      <div className="hostProfile__details">
        <h4 className="hostProfile__details--name">{`${hostDetails?.userName} (HOST)`}</h4>
        <h6 className="hostProfile__details--workingAt">
          {hostDetails?.organisations?.length > 0 ? hostDetails?.organisations[0]?.name : 'User'}
        </h6>
      </div>
    </div>
  );

  const renderWebinarDetails = () => (
    <div className="about__details">
      <h2 className="about__header">About webinar</h2>
      {
        webinar?.introVideoLink ? (
          <>
            <p className="details__description">
              {webinar?.detailedDescription.slice(0, showMore ? 400 : 200)}
              {webinar?.detailedDescription.length > 200 && (
                <button
                  type="button"
                  onClick={() => setShowMore(!showMore)}
                  className="details__description--truncate"
                >
                  {
                    showMore ? 'read less' : 'read more..'
                  }
                </button>
              )}
            </p>
          </>
        ) : (
          <p className="details__description">{webinar?.detailedDescription}</p>
        )
      }
    </div>
  );

  const renderWebinarKeypoints = () => (
    <div className="about__keypoints">
      {isMobileView ? renderHostProfile() : null}
      { !webinar?.introVideoLink || isMobileView ? <h2 className="about__header">We’ll be exploring</h2> : null }
      <div className="keypoints__list">
        {
            webinar?.keyPoints?.length > 0 && webinar?.keyPoints?.map((point: any) => (
              <h2 className="keypoints__list--keypoint" key={Math.random() * 10000}>{point}</h2>
            ))
          }
      </div>
    </div>
  );

  return (
    <section className="webinar__about">
      <div className="webinar__about--wrapper">
        {
          webinar?.introVideoLink ? (
            <div className="about__video">
              <iframe
                src={`https://www.youtube.com/embed/${getYoutubeEmbedId(webinar?.introVideoLink)}`}
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="video"
                className="video__embedVideo"
              />
            </div>
          ) : renderWebinarDetails()
        }
        <div className="about__detailsContainer">
          {webinar?.introVideoLink && renderWebinarDetails()}
          {!isMobileView && renderWebinarKeypoints()}
        </div>
      </div>
      { isMobileView ? renderWebinarKeypoints() : null }
    </section>
  );
};

export default AboutWebinar;
