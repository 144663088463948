export const getMessage = (): string => {
 const today = new Date();

 if (today.getHours() < 12) {
    return "Good Morning";
 } else if (today.getHours() < 18) {
    return "Good Afternoon";
 } else {
    return "Good Evening";
 }
};