import React, { Dispatch, SetStateAction, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { hlbClient } from '../../../../Clients/hlbClient';
import { addToast } from '../../../../reducers/toast.reducer';

type props = {
  showDelete: boolean, 
  setShowDelete: Dispatch<SetStateAction<boolean>>, 
  cardId: string, 
  deckId: string, 
  type: string, 
  name: any,
  getMyDeck: () => void,
  cardType?: string,
}

const DeleteModal: React.FC<props> = ({
  showDelete, setShowDelete, cardId, deckId, type, name,
  getMyDeck, cardType = '',
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isloading, setIsloading] = useState(false);

  const deleteCardAndDeck = async () => {
    setIsloading(true);
    if (type === 'deck') {
      try {
        const resp = await hlbClient().post(`/api/srs/deck/${deckId}/archive`);
        if (resp.data.success) {
          dispatch(addToast({ type: 'success', message: resp?.data?.msg }));
          setShowDelete(false);
          setIsloading(false);
          history.push('/space-repitition-system');
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('oops something went wrong');
        setIsloading(false);
      }
    } else {
      try {
        const endPoint = `/api/notesv2/${deckId}/${cardType}/${cardId}/archive`;
        const resp = await hlbClient().post(endPoint);
        if (resp.data.success) {
          dispatch(addToast({ type: 'success', message: resp?.data?.msg }));
          setIsloading(false);
          setShowDelete(false);
          getMyDeck();
        }
      } catch (err) {
        setIsloading(false);
        // eslint-disable-next-line no-console
        console.log('oops something went wrong');
      }
    }
  };

  return (
    <Modal show={showDelete} centered onHide={() => setShowDelete(false)} className="custom-modal-style">
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              onClick={() => setShowDelete(false)}
              className="btn-close float-end"
            />
          </div>
        </div>
        <div className="col-12 text-center">
          <p className="sessionCard__deleteMessage">Are you sure you want to delete?</p>
          <p className="sessionCard__deleteMessage-sub-text">
            Click on
            {' '}
            <span style={{ color: '#FF6B4A' }} className="mx-1"> &quot;Confirm&quot; </span>
            {' '}
            to delete your
            {' '}
            {type}
            {' '}
            <span style={{ color: '#FF6B4A' }} className="mx-1">
              {' '}
              {name}
              {' '}
            </span>
          </p>
          <button disabled={isloading} type="button" className=" btn sessionCard__cancel-btn" onClick={() => setShowDelete(false)}>Cancel</button>
          <button disabled={isloading} type="button" className="btn sessionCard__delete-btn" onClick={() => { deleteCardAndDeck(); }}>
            {isloading ? 'Deleting' : 'Confirm'}
            </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DeleteModal;
