import React, { useState } from 'react';
import _ from 'lodash';
import {
  clock, downLite, upArrow, deleteIcon, edit,
} from '../../BasicComponents/icons';

export type EnlistedAccordionProps = {
  elData: any;
  index: number;
  limitWordLength: (string: string, limit?: number) => string;
  getSessionType: (sessionType: string) => string;
  capitalize: (words: string) => string;
  setSelectedEditSession: React.Dispatch<React.SetStateAction<null>>;
  setEditShow: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteShow: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedSessionId: React.Dispatch<React.SetStateAction<null>>;
}

const EnlistedAccordion = ({
  elData, index, limitWordLength, getSessionType, capitalize,
  setSelectedEditSession, setEditShow, setSelectedSessionId,
  setDeleteShow,
}: EnlistedAccordionProps) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="sessions__enlisted">
      <div
        className={`enlisted__overview ${isActive ? 'enlisted__overview--expanded' : ''}`}
        onClick={() => setIsActive(!isActive)}
      >
        <div className="enlisted--wrapper">
          <div className="enlisted__overview--number">
            <span>{index + 1}</span>
            .
          </div>
          <div className="enlisted__overview--type">
            <p>{limitWordLength(capitalize(_.get(elData, 'name', '')))}</p>
          </div>
        </div>
        <div className="enlisted__info">
          <p className="enlisted__info--duration">
            <img src={clock} alt="Clock" />
            {_.get(elData, 'duration', 0)}
            {' '}
            minutes
            ,
          </p>
          <span className="enlisted__info--category">
            {getSessionType(_.get(elData, 'sessionType', ''))}
          </span>
        </div>
        <div className="enlisted__actions">
          <button type="button" onClick={() => setIsActive(!isActive)}>
            <img src={isActive ? upArrow : downLite} alt="arrow" />
          </button>
        </div>
      </div>
      <div className={`enlisted__details ${isActive ? 'show' : ''}`}>
        <div className="enlisted__details--description">
          {_.get(elData, 'description', '')}
        </div>
        <div className="enlisted__pricebox">
          <div className="pricebox__price">
            <p className="pricebox__price--text">Price</p>
            {
              _.get(elData, 'isFree', false) ? (
                <p className="pricebox__price--offer">
                  <span>Free</span>
                </p>
              ) : (
                <>
                  {
                    _.get(elData, 'discountPrice') && _.get(elData, 'price') ? (
                      <>
                        <p className="pricebox__price--offer">
                          <span>₹</span>
                          {_.get(elData, 'discountPrice', 0)}
                        </p>
                        <p className="pricebox__price--original">
                          <span>₹</span>
                          {_.get(elData, 'price', 0)}
                        </p>
                      </>
                    ) : (
                      <p className="pricebox__price--offer">
                        <span>₹</span>
                        {_.get(elData, 'price', 0)}
                      </p>
                    )
                  }
                </>
              )
            }
          </div>
          <div className="pricebox__actions">
            <button
              type="button"
              className="pricebox__actions--edit"
              onClick={() => {
                setEditShow(true);
                setSelectedEditSession(elData);
                setSelectedSessionId(_.get(elData, '_id', ''));
              }}
            >
              <img src={edit} alt="Edit" />
            </button>
            <button
              type="button"
              className="pricebox__actions--delete"
              onClick={() => {
                setDeleteShow(true);
                setSelectedSessionId(_.get(elData, '_id', ''));
              }}
            >
              <img src={deleteIcon} alt="Delete" />
            </button>
            {/* <Link to="/mentorProfileEdit" className="pricebox__actions--delete">
              <img src={edit} alt="Edit" />
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnlistedAccordion;
