import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import { myConfig } from './config';

const analytics = Analytics({
  app: 'hyperlearn production',
  plugins: [
    googleAnalytics({
      measurementIds: [myConfig.gaTracking]
    })
  ]
})

export default analytics;