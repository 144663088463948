import React from 'react';
import { useHistory } from 'react-router-dom';
import './NotFound.css';
import { backWhite } from '../icons';

const NotFound: React.FC = () => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="notFound--wrapper">
      <div className="notFound">
        <h2 className="notFound__status">404</h2>
        <p className="notFound__info">Oops! Page not found</p>
        <p className="notFound__message">The link you clicked may be broken or the page may have been removed or renamed!</p>
        <button
          type="button"
          className="notFound__button"
          onClick={goBack}
        >
          <img src={backWhite} alt="back" />
          Go back
        </button>
      </div>
    </div>
  );
};

export default NotFound;
