import React from 'react';
import { Switch, Route } from 'react-router';
import { Link } from 'react-router-dom';
import NavbarContainer from '../BasicComponents/navbar';
import Profile from './profile';
import './settings.css';
import SettingSession from './settingSession';

function Settings() {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <NavbarContainer />
        </div>
      </div>
      <div className="settings__container">
        <h1 className="settings__heading">Settings</h1>
        <ul className="setting__options">
          <li className="setting__options--list">
            <Link to="/settings" className="setting__options--link setting__options--active">Profile</Link>
          </li>
          <li className="setting__options--list">
            <Link to="/settings/session" className="setting__options--link">Session</Link>
          </li>
        </ul>
        <div className="settings__divider" />
        <Switch>
          <Route exact path="/settings" component={Profile} />
          <Route exact path="/settings/session" component={SettingSession} />
        </Switch>
      </div>
    </>
  );
}

export default Settings;
