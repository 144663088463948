import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import Tooltip from '../../BasicComponents/Tooltip';
import {
  clock, assistanceGray, calendarImage, earth, userImage,
} from '../../BasicComponents/icons';

interface AccordionProps {
  elData: any;
  correctTime: (dateObj: Date | string) => string;
  getSessionType: (sessionType: string) => string;
  capitalize: (words: string) => string;
  limitWordLength: (string: string, limit?: number) => string;
}

const Accordion: React.FC<AccordionProps> = (props) => {
  const {
    /* eslint-disable react/prop-types */
    elData, correctTime, getSessionType, capitalize, limitWordLength,
  } = props;
  const [isActive, setIsActive] = useState(false);
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const getDay = (dateObj: string | Date | Dayjs): string => {
    const helperDate = dayjs(dateObj);
    return `${days[helperDate.get('day')].slice(0, 3)}, ${helperDate.get('date')} ${months[helperDate.get('month')].slice(0, 3)}`;
  };

  return (
    <div className="upcoming__session">
      <div
        className={`session__overview ${isActive ? 'session__overview--expanded' : ''}`}
        onClick={() => setIsActive(!isActive)}
      >
        <div className="session__overview--mentee">
          <div className="mentee--img">
            <img src={_.get(elData, 'userId.profile.profilePic.url', userImage)} alt="user profile" />
          </div>
          <div className="mentee__details">
            <h2 className="mentee__details--name">{capitalize(_.get(elData, 'userId.name', ''))}</h2>
            <p className="mentee__details--email">{_.get(elData, 'userId.email', '')}</p>
          </div>
        </div>
        <div className="session__overview--type">
          <p>
            <Tooltip content={_.get(elData, 'sessionId.name', '')}>
              {capitalize(limitWordLength(_.get(elData, 'sessionId.name', '')))}
            </Tooltip>
            {' '}
            {getSessionType(_.get(elData, 'sessionId.sessionType', ''))}
          </p>
        </div>
        <div className="session__overview--duration">
          <img src={clock} alt="clock" />
          <p>
            {_.get(elData, 'sessionId.duration', 0)}
            {' '}
            minutes
          </p>
        </div>
        <div className="session__overview--booking-day">
          <p>{getDay(_.get(elData, 'startTime', new Date()))}</p>
        </div>
        <div className="session__overview--starts">
          <p>
            {correctTime(_.get(elData, 'startTime', new Date()))}
          </p>
        </div>
      </div>
      <div className={`${isActive ? 'session__details show' : 'session__details'}`}>
        <div className="details__sec details__sec--1">
          <div className="details__sec--grouping">
            <h2 className="details--header">Booking details</h2>
          </div>
          <div className="details__sec--grouping">
            <div className="details__type details__box">
              <img src={assistanceGray} alt="help" />
              <p className="details__type--text">{_.get(elData, 'sessionId.sessionType', '')}</p>
            </div>
            <div className="details__duration details__box">
              <img src={clock} alt="time" />
              <p className="details__duration--text">
                {_.get(elData, 'sessionId.duration', 0)}
                {' '}
                minutes
              </p>
            </div>
            <div className="details__calendar details__box">
              <img src={calendarImage} alt="calendar" />
              <p className="details__calendar--text">
                {days[new Date(_.get(elData, 'startTime', new Date())).getDay()]}
                {', '}
                {months[new Date(_.get(elData, 'startTime', new Date())).getMonth()]}
                {' '}
                {new Date(_.get(elData, 'startTime', new Date())).getDate()}
                &nbsp;&nbsp;
                {correctTime(_.get(elData, 'startTime', ''))}
                {' - '}
                {correctTime(_.get(elData, 'endTime', ''))}
              </p>
            </div>
            <div className="details__time details__box">
              <img src={earth} alt="timezone" />
              <p className="details__time--text">Indian Standard Time</p>
            </div>
            <div className="details__price details__box">
              <p className="details__price--text">
                Price
                {
                  _.get(elData, 'orderId.total', 0) === 0 ? (
                    <p className="pricebox__price--offer">
                      <span>Free</span>
                    </p>
                  ) : (
                    <>
                      {
                        _.get(elData, 'orderId.total') && (
                          <p className="pricebox__price--offer">
                            <span>₹</span>
                            {_.get(elData, 'orderId.total', 0)}
                          </p>
                        )
                      }
                    </>
                  )
                }
              </p>
            </div>
          </div>
          <div className="details__sec--grouping">
            <div className="details__desc">
              <p className="details__desc--text">
                {_.get(elData, 'sessionId.description', '')}
              </p>
            </div>
          </div>
        </div>
        <div className="details__sec details__sec--2">
          <h2 className="details--header">Session details</h2>
          <div className="details__user">
            <h3 className="details__user--name">{_.get(elData, 'userId.name', '')}</h3>
            <p className="details__user--email">{_.get(elData, 'userId.email', '')}</p>
          </div>
          <div className="details__desc">
            <p className="details__desc--text">
              {_.get(elData, 'comments', '')}
            </p>
          </div>
          <div className="details__footer">
            {/* <div className="details__footer--links">
              <Link to="/">
                <img src={zoom} alt="Zoom" />
              </Link>
              <Link to="/">
                <img src={meet} alt="Google Meet" />
              </Link>
              <Link to="/">
                <img src={teams} alt="Microsoft Teams" />
              </Link>
            </div> */}
            {/* <div className="details__footer--reschedule">
              <a href="/">
                <img src={reschedule} alt="clock" />
                Reschedule
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
