import React from 'react';
import { FileSearch } from 'phosphor-react';
import "./EmptyStates.css";

const EmptySearch = () => {
    return (
        <div className="emptyContainer emptyContainer__search">
            <div className="emptyContainer__icon--wrapper">
            <FileSearch size={38} color="#797979" className="emptyContainer__icon"  />
            </div>
            <div className="emptyContainer__info">
            <h4 className="info__heading">No Results Found!</h4>
            <p className="info__subtext">Try searching for different or <br /> related keyword.</p>
            </div>
        </div>
    );
};

export default EmptySearch;