/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import NavbarContainer from '../../BasicComponents/navbar';
import { hlbClient } from '../../../Clients/hlbClient';
import { studentLearning2, warningRed, backLite } from '../../BasicComponents/icons';
import '../loginSignup.css';
import { AuthContext } from '../../../provider/AuthContext';

type Timeout = /*unresolved*/ any

const UserLogin: React.FC = () => {
  const [proceedWithMobile, setProceedWithMobile] = useState(true);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [isPhoneFilled, setIsPhoneFilled] = useState(false);
  const [isEmailFilled, setIsEmailFilled] = useState(false);
  const [dialingCode, setDialingCode] = useState('+91');
  const [phoneErr, setPhoneErr] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [otpErr, setOtpErr] = useState('');
  const [timer, setTimer] = useState(0);
  const history = useHistory();

  const handleEmailSubmit = async (e: any) => {
    e.preventDefault();
    if (email) {
      try {
        const response = await hlbClient().post('/api/users/email/otp', {
          email,
        });
        if (response.data) {
          setIsEmailFilled(true);
          setTimer(60);
        }
      }catch (err: any) {
        if (err.response && err.response.data && err.response.data.msg) {
          setEmailErr(err.response.data.msg);
        }
      }
    }
  };

  const handleChangeMode = () => {
    setPhone('');
    setEmail('');
    setProceedWithMobile(!proceedWithMobile);
  };

  const handleBackButton = () => {
    if (isPhoneFilled) {
      setIsPhoneFilled(false);
      setPhone('');
      setOtp('');
      setOtpErr('');
      setTimer(0);
    } else if (isEmailFilled) {
      setIsEmailFilled(false);
      setEmail('');
      setOtp('');
      setOtpErr('');
      setTimer(0);
    }
  };

  const setHlDetails = (token: any) => {
    const decoded: { user: { id: string, user_type: string } } = jwt_decode(token);
    /* eslint-disable no-undef */
    window.localStorage.setItem('hlUserId', decoded.user.id);
    window.localStorage.setItem('hlUserType', decoded.user.user_type);
  };

  const handlePhoneSubmit = async (e: any) => {
    e.preventDefault();
    if (phone && phone.match(/^[0-9]{10}$/g)) {
      try {
        const response = await hlbClient().post('/api/users/phone/otp', {
          phone,
        });
        if (response.data) {
          setIsPhoneFilled(true);
          setTimer(60);
        }
      } catch (err: unknown) {
        if (err instanceof Error) {
          const { response }: any = err;
          if (response && response.data && response.data.msg === 'User Not exists') {
            setPhoneErr('User does not exist');
          } else {
            setPhoneErr('Please enter a valid number');
          }
        } else {
          setPhoneErr('An error occurred. Please try again.');
        }
      }
    } else {
      setPhoneErr('Please enter valid number');
    }
  };
  const { authState, setStateUpdate, stateUpdate } = useContext(AuthContext);

  // eslint-disable-next-line no-undef
  const token = window.localStorage.getItem('token');
  useEffect(() => {
    if (token) {
      setStateUpdate(!stateUpdate);
    }
  }, [token]);
  const { profile } = authState;

  const handlePhoneOtpSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await hlbClient().post('/api/auth/login', {
        otp,
        query: {
          phone,
        },
      });
      if (response.data) {
        window.localStorage.setItem('token', response.data.token);
        setHlDetails(response.data.token);
        // eslint-disable-next-line no-nested-ternary
        window.localStorage.getItem('hlUserType') === 'mentor'
          ? (!profile ? history.push('/onboarding/mentorDetailForm') : history.push('/dashboardV2'))
          : history.push('/searchMentor');
      }
    } catch (err: any) {
      setOtpErr(err.response.data.msg);
    }
  };

  const handleEmailOtpSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await hlbClient().post('/api/auth/login', {
        otp,
        query: {
          email,
        },
      });
      if (response.data) {
        // eslint-disable-next-line no-undef
        window.localStorage.setItem('token', response.data.token);
        setHlDetails(response.data.token);
        // eslint-disable-next-line no-unused-expressions
        window.localStorage.getItem('hlUserType') === 'mentor'
          ? history.push('/dashboardV2')
          : history.push('/searchMentor');
      }
    } catch (err: any) {
      setOtpErr(err.response.data.msg);
    }
  };

  const renderPhoneFormHeadings = () => {
    if (!isPhoneFilled) {
      return (
        <h2 className="auth__message marginHelper-bottom-lg">
          Login to your account
        </h2>
      );
    }
    return (
      <h2 className="auth__message marginHelper-bottom-small">
        Enter the OTP sent to your mobile
      </h2>
    );
  };

  const renderEmailFormHeadings = () => {
    if (!isEmailFilled) {
      return (
        <h2 className="auth__message marginHelper-bottom-lg">
          Login to your account
        </h2>
      );
    }
    return (
      <h2 className="auth__message marginHelper-bottom-small">
        Enter the OTP sent to your Email
      </h2>
    );
  };

  const renderPhoneSubheadings = () => {
    if (isPhoneFilled) {
      return (
        <h2 className="auth__subheading auth__otp--subheading">
          {`We've sent it to +91 ${phone}`}
          <button
            type="button"
            className="auth__subheading--link"
            onClick={() => {
              setOtp('');
              setIsPhoneFilled(false);
              setTimer(0);
            }}
          >
            Edit
          </button>
        </h2>
      );
    }
    return null;
  };

  const renderEmailSubheadings = () => {
    if (isEmailFilled) {
      return (
        <h2 className="auth__subheading auth__otp--subheading">
          {`We've sent it to ${email}`}
          <button
            type="button"
            className="auth__subheading--link"
            onClick={() => {
              setOtp('');
              setIsEmailFilled(false);
              setTimer(0);
            }}
          >
            Edit
          </button>
        </h2>
      );
    }
    return null;
  };

  const renderPhoneComponents = () => {
    if (!isPhoneFilled) {
      return (
        <>
          <form className="auth__form">
            <div className={`auth__contact--field ${phoneErr && 'warning'} /> : ''}`}>
              <select
                className="auth__dialing-code"
                onChange={(e) => setDialingCode(e.target.value)}
                value={dialingCode}
              >
                <option value="+91">+91</option>
              </select>
              <input
                className="auth__contact-no"
                type="tel"
                maxLength={10}
                value={phone}
                onChange={(e) => {
                  setPhoneErr('');
                  setPhone(e.target.value);
                }}
                placeholder="Mobile number"
                autoComplete="off"
                required
              />
              {phoneErr && (
              <p className="auth__error-msg">
                <img src={warningRed} alt="warning" />
                {phoneErr}
              </p>
              )}
            </div>
            <button
              className="auth__button"
              type="submit"
              disabled={phone.length < 10}
              onClick={handlePhoneSubmit}
            >
              Continue
            </button>
          </form>
        </>
      );
    // eslint-disable-next-line no-else-return
    } else {
      return (
        <form className="auth__form">
          <div className={`auth__otp--field ${otpErr && 'warning'}`}>
            <input
              type="tel"
              maxLength={6}
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value);
                setOtpErr('');
              }}
              placeholder="Enter OTP"
              className="auth__otp"
              autoComplete="off"
              required
            />
            {
              otpErr && (
                <p className="auth__error-msg">
                  <img src={warningRed} alt="warning" />
                  {otpErr}
                </p>
              )
            }
          </div>
          {
            isPhoneFilled && (
              <div className="auth__otpInfo">
                <span className="auth__otpInfo--text">
                  Didn’t receive OTP?
                </span>
                {
                  timer > 0 ? (
                    <span className="auth__otpInfo--timer">
                      {timer < 10 ? `0${timer}` : timer}
                    </span>
                  ) : (
                    <button
                      type="button"
                      className="auth__otpInfo--resend"
                      onClick={handlePhoneSubmit}
                    >
                      Resend
                    </button>
                  )
                }
              </div>
            )
          }
          <button
            className="auth__button"
            type="submit"
            disabled={otp.length < 6}
            onClick={handlePhoneOtpSubmit}
          >
            Continue
          </button>
        </form>
      );
    }
  };

  const renderEmailComponents = () => {
    if (!isEmailFilled) {
      return (
        <form className="auth__form">
          <div className={`auth__email--field ${emailErr && 'warning'}`}>
            <input
              className="auth__email"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailErr('');
              }}
              placeholder="Email"
              required
            />
            {
              emailErr && (
                <p className="auth__error-msg">
                  <img src={warningRed} alt="warning" />
                  {emailErr}
                </p>
              )
            }
          </div>
          <button
            className="auth__button"
            type="submit"
            disabled={email.length === 0}
            onClick={handleEmailSubmit}
          >
            Continue
          </button>
        </form>
      );
    }
    return (
      <form className="auth__form">
        <div className={`auth__otp--field ${otpErr && 'warning'}`}>
          <input
            type="tel"
            maxLength={6}
            value={otp}
            onChange={(e) => {
              setOtp(e.target.value);
              setOtpErr('');
            }}
            placeholder="Enter OTP"
            className="auth__otp"
            autoComplete="off"
            required
          />
          {
              otpErr && (
                <p className="auth__error-msg">
                  <img src={warningRed} alt="warning" />
                  {otpErr}
                </p>
              )
            }
        </div>
        {
            isEmailFilled && (
              <div className="auth__otpInfo">
                <span className="auth__otpInfo--text">
                  Didn’t receive OTP?
                </span>
                {
                  timer > 0 ? (
                    <span className="auth__otpInfo--timer">
                      {timer < 10 ? `0${timer}` : timer}
                    </span>
                  ) : (
                    <button
                      type="button"
                      className="auth__otpInfo--resend"
                      onClick={handleEmailSubmit}
                    >
                      Resend
                    </button>
                  )
                }
              </div>
            )
          }
        <button
          className="auth__button"
          type="submit"
          disabled={otp.length < 6}
          onClick={handleEmailOtpSubmit}
        >
          Continue
        </button>
      </form>
    );
  };

  useEffect(() => {
    const timerValue: string | number | Timeout | undefined = timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
    return () => clearInterval(timerValue);
  }, [timer]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <NavbarContainer />
        </div>
      </div>
      <div className="auth auth__container">
        <div className="auth__card">
          <div className="card__sec card__sec--1 auth__image">
            <img src={studentLearning2} alt="learning" />
          </div>
          <div className="card__sec card__sec--2 auth__login">
            {
              (isPhoneFilled || isEmailFilled) && (
                <button
                  type="button"
                  className="auth__back-button"
                  onClick={handleBackButton}
                >
                  <img src={backLite} alt="back" />
                </button>
              )
            }
            {
              proceedWithMobile ? (
                <>
                  {renderPhoneFormHeadings()}
                </>
              ) : (
                <>
                  {renderEmailFormHeadings()}
                </>
              )
            }
            {
              proceedWithMobile ? (
                <>
                  {renderPhoneSubheadings()}
                </>
              ) : (
                <>
                  {renderEmailSubheadings()}
                </>
              )
            }
            {
              proceedWithMobile ? (
                <>
                  {
                    renderPhoneComponents()
                  }
                </>
              ) : (
                <>
                  {
                  renderEmailComponents()
                  }
                </>
              )
            }
            {
              (!isPhoneFilled && !isEmailFilled) && (
                <div className="auth__switch-link">
                  <button
                    onClick={handleChangeMode}
                    type="button"
                  >
                    Proceed with
                    {' '}
                    { proceedWithMobile ? 'Email' : 'Mobile'}
                  </button>
                </div>
              )
            }
            {
              (!isPhoneFilled && !isEmailFilled) && (
                <div className="auth__alternatives">
                  <p className="auth__alternatives--text">
                    Don’t have an account?
                  </p>
                  <Link to="/signup/student" className="auth__alternatives--link">
                    Sign Up
                  </Link>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default UserLogin;
