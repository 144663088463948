import React, { Dispatch, SetStateAction } from 'react';
import _ from 'lodash';

type props = {
  data: {
    image?: { url: string },
    name: string,
  }, 
  percentageCount: number, 
  openFullscreen: () => void, 
  isLoading: boolean, 
  setIsStart: Dispatch<SetStateAction<boolean>>, 
  startTimer: () => void,
  getCardIndex: any, 
  setIsWatchTutorial: Dispatch<SetStateAction<boolean>>, 
  mobileView: boolean,
}

const MobileLoadingCard: React.FC<props> = ({
  data, percentageCount, openFullscreen, isLoading, setIsStart, startTimer,
  getCardIndex=0, setIsWatchTutorial, mobileView,
}) => {
  const goFullScreen = () => {
    if (mobileView === true) {
      openFullscreen();
    }
  };
  return (
    <div className="mobile__anki--view-container">
      <style>
        {`
      .progress {
        display: flex;
        height: 1rem;
        overflow: hidden;
        font-size: .75rem;
        background-color: #bbbcbd;
        border-radius: 0.25rem;
      }
      .anki__deck--name-onloading {
        padding: 20px;
        width: 100%;
        text-align: center;
      }
      .sub-container {
        height: 100%;
        background: #fff;
      }
      .note__watch--tutorials-link{
        position: absolute;
        bottom: 70px;
      }
      `}
      </style>
      <div className="mobile__anki--card-container desktop-only">
        {_.get(data, 'image.url', '') && (
          <div className="mobile__anki--image-Container">
            <img src={_.get(data, 'image.url', '')} alt="" className="mobile__anki--card-container-image" />
          </div>
        )}
        {_.get(data, 'name', '') && (
        <div className="anki__deck--name-onloading mt-5 mb-5">
          <h3>{_.get(data, 'name', '')}</h3>
        </div>
        )}
        {isLoading && (
          <div className="anki__deck--name-onloading">
            <div className="progress" style={{ height: '5px', width: '90%' }}>
              {/* eslint-disable-next-line react/self-closing-comp */}
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${percentageCount}%` }}
                aria-valuenow={percentageCount}
                aria-valuemin={0}
                aria-valuemax={100}
              >
              </div>
            </div>
          </div>
        )}
        <div className="anki__deck--name-onloading">
          {isLoading ? <p>Loading your note.</p>
            : (
              <>
                <p
                  role="presentation"
                  className="note__watch--tutorials-link"
                  onClick={() => setIsWatchTutorial(true)}
                >
                  Watch Tutorial
                </p>
                <div className="mobile__anki--btn-container">
                  <button
                    type="button"
                    className="mobile__anki--btn-purple"
                    onClick={() => {
                      setIsStart(true);
                      goFullScreen();
                      startTimer();
                      getCardIndex((prev: number) => prev + 1);
                    }}
                  >
                    Start
                  </button>
                </div>
              </>
            )}
        </div>
      </div>
    </div>
  );
}

export default MobileLoadingCard;
