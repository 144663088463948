import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion/dist/framer-motion';
import { ArrowDown, ArrowUp } from 'phosphor-react';

type props = {
    children: string | JSX.Element | JSX.Element[] | (() => JSX.Element),
};

const LongQuestionCardWrapper: React.FC<props> = ({ children }) => {

    const containerRef = useRef<any>(null);
    const [showScrollButtons, setShowScrollButtons] = useState(false);

    const handleScroll = () => {
        const container = containerRef.current;
        if (container.scrollHeight > container.clientHeight) {
            setShowScrollButtons(true);
        } else {
            setShowScrollButtons(false);
        }
    };

    const scrollToTop = () => {
        containerRef.current.scrollTo({
            top: containerRef.current.scrollTop - 200,
            behavior: 'smooth'
        });
    };

    const scrollToBottom = () => {
        containerRef.current.scrollTo({
            top: containerRef.current.scrollTop + 200,
            behavior: 'smooth'
        });
    };

    const handleImageLoad = () => {
        handleScroll(); // Recalculate scroll height after images have loaded
    };

    useEffect(() => {
        const container = containerRef.current;
        container.addEventListener('scroll', handleScroll);

        // Check on initial render
        handleScroll();

        // Listen for image load events
        const images = container.getElementsByTagName('img') as HTMLCollectionOf<HTMLImageElement>;
        Array.from(images).forEach((image) => {
            image.addEventListener('load', handleImageLoad);
        });


        return () => {
            container.removeEventListener('scroll', handleScroll);
            Array.from(images).forEach((image) => {
                image.removeEventListener('load', handleImageLoad);
            });
        };
    }, []);

    return (
        <motion.div className="long-question-wrapper__container"
            ref={containerRef}
        >
            <style>{`
            .long-question-wrapper__container {
                padding: 0.75rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;
                background: #fff;
                height: 100%;
                overflow-y: auto;
            }
            .long-question-wrapper__container::-webkit-scrollbar {
                display: none;
            }
      .handle {
        position: absolute;
        cursor: move;
        color: black;
        max-width: 215px;
        border-radius: 5px;
        padding: 1em;
        margin: auto;
        user-select: none;
      }
      .long-question-floating-btn {
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 12%;
        right: 10px;
      }
      
      .long-question-floating-btn-top {
        background-color: #fd8e4a5e;
        padding: 2px 5px;
        border-radius: 10px 10px 0px 0px;
        color: #f37222;
        border: 2px solid #f37222;
        margin-bottom: 2px;
      }
      .long-question-floating-btn-bottom {
        background-color: #fd8e4a5e;
        padding: 2px 5px;
        border-radius: 0px 0px 10px 10px;
        color: #f37222;
        border: 2px solid #f37222;
      }
      `}</style>
            {children}
            {showScrollButtons && <motion.div className="long-question-floating-btn" drag dragConstraints={containerRef} >
                <button className="long-question-floating-btn-top" onClick={() => scrollToTop()}>
                    <ArrowUp />
                </button>
                <button className="long-question-floating-btn-bottom" onClick={() => scrollToBottom()}>
                    <ArrowDown />
                </button>
            </motion.div>}
        </motion.div>
    )
}

export default LongQuestionCardWrapper;