import React, { useState, useEffect, useRef } from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import _ from 'lodash';
import FormData from 'form-data';
import { plus, viewPortImage, crossFeaturedWork, smallPlus } from '../icons';
import './featuredWorkUploader.css';
import { hlbClient } from '../../../Clients/hlbClient';

type Image = {
  url: string;
};

type Entity = 'normalPage' | 'editPage' | 'checkList';

type FeaturedWorkUploaderProps = {
  data?: Image[];
  apiCall: VoidFunction;
  entityType?: Entity;
};

const FeaturedWorkUploader: React.FC<FeaturedWorkUploaderProps> = ({
  data = [],
  apiCall,
  entityType = '',
}) => {
  const [showFeaturedWorkUploader, setShowFeaturedWorkUploader] =
    useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [validFiles, setValidFiles] = useState<any>([]);
  const [featuredCollections, setFeaturedCollections] = useState<Image[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const [uploadMessage, setUploadMessage] = useState<string>('');
  const dataCounts: number = 6 - data.length;

  useEffect(() => {
    const filteredArray = selectedFiles.reduce((file: any, current: any) => {
      const x = file.find((item: any) => item.name === current.name);
      if (!x) {
        return file.concat([current]);
      }
      return file;
    }, []);
    setValidFiles([...filteredArray]);
  }, [selectedFiles]);
  const dragOver = (e: any) => {
    e.preventDefault();
  };

  const dragEnter = (e: any) => {
    e.preventDefault();
  };

  const dragLeave = (e: any) => {
    e.preventDefault();
  };
  const validateFile = (file: any) => {
    const validTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/x-icon',
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };
  const handleFiles = (files: any) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles((prevArray: any) => [...prevArray, files[i]]);
      } else {
        setSelectedFiles((prevArray: any) => [...prevArray, files[i]]);
      }
    }
  };
  const fileDrop = (e: any) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    if (files.length) {
      handleFiles(files);
    }
  };
  const fileInputRef: React.MutableRefObject<any> = useRef();
  const fileInputClicked = () => {
    fileInputRef.current.click();
  };
  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  async function fetchData() {
    try {
      setFeaturedCollections([...data, ...featuredCollections]);
      if (featuredCollections.length !== 0) {
        hlbClient().put('/api/users/featured', {
          featured: featuredCollections,
        });
      }
    } catch {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');
    }
    if (entityType !== 'checkList') {
      setTimeout(() => {
        apiCall();
      }, 3000);
    }
  }

  const uploadFiles = async () => {
    setProgress(0);
    setUploadMessage('');
    try {
      const formData = new FormData();
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < validFiles.length; i++) {
        formData.append('image', validFiles[i]);
      }
      const resp = await hlbClient('multipart/form-data').post(
        '/api/utils/imageupload',
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const uploadPercentage: number = Math.floor(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setProgress(uploadPercentage);
            if (uploadPercentage === 100) {
              setUploadMessage('File(s) Uploaded');
            }
          },
        }
      );
      setFeaturedCollections(resp.data);
      await fetchData();
      // eslint-disable-next-line no-console
      console.log(resp.data);
    } catch {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');
    }
  };
  
  useEffect(() => {
    if (validFiles.length !== 0) {
      uploadFiles();
    }
  }, [validFiles]);
  const deleteImage = async (url: string) => {
    const featured = data.filter((d) => d.url !== url);
    try {
      await hlbClient().put('/api/users/featured', {
        featured,
      });
    } catch {
      // eslint-disable-next-line no-console
      console.log('Oops something wrong');
    }
    if (entityType !== 'checkList') {
      apiCall();
    }
    if (entityType === 'checkList') {
      // eslint-disable-next-line no-undef
      window.location.reload();
    }
  };
  return (
    <div>
      {entityType === 'checkList' ? (
        <img
          onClick={() => {
            setShowFeaturedWorkUploader(true);
          }}
          onKeyPress={() => {
            setShowFeaturedWorkUploader(true);
          }}
          role="presentation"
          src={smallPlus}
          alt="add session"
          style={{ position: 'absolute', right: '8.67%', height: '12px' }}
        />
      ) : (
        <img
          onClick={() => {
            setShowFeaturedWorkUploader(true);
          }}
          onKeyPress={() => {
            setShowFeaturedWorkUploader(true);
          }}
          role="presentation"
          src={plus}
          alt="add session"
          className="profile__edit-addsession--edit"
        />
      )}

      <Modal
        show={showFeaturedWorkUploader}
        fullscreen="md"
        centered
        onHide={() => setShowFeaturedWorkUploader(false)}
      >
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <button
                type="button"
                onClick={() => setShowFeaturedWorkUploader(false)}
                className="btn-close float-end"
              />
            </div>
            <div className="co-md-12">
              <p className="featuredWorkUploader__heading">
                Share your work related images
              </p>
              <p className="featuredWorkUploader__sub-heading">
                Flaunt your knowledge and accolades here. And let the world
                know.
              </p>
              <form style={{ marginLeft: '0px' }}>
                <div
                  className="featuredWorkUploader__dropArea text-center"
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={fileDrop}
                >
                  <img src={viewPortImage} alt="upload-file" />
                  <p className="featuredWorkUploader__dropArea--text-primary">
                    Drag & drop images{' '}
                  </p>
                  <p className="featuredWorkUploader__dropArea--text-secondary">
                    or{' '}
                    <span
                      onClick={fileInputClicked}
                      onKeyPress={() => {
                        setShowFeaturedWorkUploader(true);
                      }}
                      role="presentation"
                      style={{ cursor: 'pointer' }}
                    >
                      browse files
                      <input
                        ref={fileInputRef}
                        className="file-input"
                        type="file"
                        multiple
                        onChange={filesSelected}
                        style={{ position: 'absolute' }}
                      />
                    </span>{' '}
                    on your computer
                  </p>
                  {progress && (
                    <p>
                      {uploadMessage !== 'File(s) Uploaded'
                        ? `${progress}% uploading...`
                        : 'File(s) Uploaded'}
                    </p>
                  )}
                  {progress && (
                    <ProgressBar now={progress} label={`${progress}%`} srOnly />
                  )}
                </div>
              </form>
            </div>
            <div className="text-center mt-3" />
            {data &&
              data.map((image) => (
                // eslint-disable-next-line react/jsx-key
                <div className="col-4 mt-3" style={{ position: 'relative' }}>
                  {_.get(image, 'url', '') ? (
                    <img
                      src={crossFeaturedWork}
                      onClick={() => {
                        deleteImage(image.url);
                      }}
                      alt=".."
                      className="featuredWorkUploader__delete--image"
                    />
                  ) : (
                    <></>
                  )}
                  {_.get(image, 'url', '') ? (
                    <img
                      src={_.get(image, 'url', '')}
                      alt=".."
                      className="featuredWorkUploader__img-box"
                    />
                  ) : (
                    <div className="featuredWorkUploader__img-box" />
                  )}
                </div>
              ))}
            {data.length !== 0 && (
              <>
                {/* eslint-disable-next-line no-shadow */}
                {Array.from({ length: dataCounts }, (_, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div
                    className="col-4 mt-3"
                    style={{ position: 'relative' }}
                    key={i}
                  >
                    <div className="featuredWorkUploader__img-box" />
                  </div>
                ))}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FeaturedWorkUploader;
