import React, { Dispatch, SetStateAction } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { hlbClient } from '../../../../Clients/hlbClient';
import { addToast } from '../../../../reducers/toast.reducer';

type props = {
  showHide: boolean;
  setShowHide: Dispatch<SetStateAction<boolean>>;
  hidden: boolean;
  cardId: string;
  deckId: string;
  getMyDeck: () => void;
  cardType: string;
};

const HideModal: React.FC<props> = ({
  showHide,
  setShowHide,
  hidden,
  cardId,
  deckId,
  getMyDeck,
  cardType,
}) => {
  const dispatch = useDispatch();

  const hideCard = async () => {
    try {
      const resp = await hlbClient().post(
        `api/notesv2/${deckId}/${cardType}/${cardId}/hideToggle`
      );
      if (resp.data.success) {
        dispatch(addToast({ type: 'success', message: resp?.data?.msg }));
        setShowHide(false);
        getMyDeck();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');
    }
  };
  return (
    <Modal
      show={showHide}
      centered
      onHide={() => setShowHide(false)}
      className="custom-modal-style"
    >
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              onClick={() => setShowHide(false)}
              className="btn-close float-end"
            />
          </div>
        </div>
        <div className="col-12 text-center">
          <p className="sessionCard__deleteMessage">
            Are you sure you want to {hidden ? 'unhide' : 'hide'}?
          </p>
          <p className="sessionCard__deleteMessage-sub-text">
            Click on{' '}
            <span style={{ color: '#FF6B4A' }} className="mx-1">
              {' '}
              &quot;Confirm&quot;{' '}
            </span>{' '}
            to {hidden ? 'unhide' : 'hide'} your card
          </p>
          <button
            type="button"
            className=" btn sessionCard__cancel-btn"
            onClick={() => setShowHide(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn sessionCard__delete-btn"
            onClick={() => {
              hideCard();
            }}
          >
            Confirm
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HideModal;
