/* eslint-disable no-undef */
import React from 'react';
import _ from 'lodash';
import './checkListCard.css';
import { greenTick, lightGreyTick, smallPlus } from '../icons';
import CustomUrl from '../customUrl';
import VideoUploader from '../videoUploader';
import FeaturedWorkUploader from '../featuredWorkUploader';
import Socials from '../socials';

type props = {
  checkList: { enable: boolean; name: string }[];
  setPopUpHeading: React.Dispatch<React.SetStateAction<string>>;
  setEditShow: React.Dispatch<React.SetStateAction<boolean>>;
  setModalType: React.Dispatch<React.SetStateAction<string>>;
};

type addcheckListDataProps = {
  [key: string]: string;
  skills: string;
  organisations: string;
  sessionBooking: string;
  calenderSync: string;
  socials: string;
  introVideo: string;
  featuredWork: string;
  publicUrl: string;
};

type addChecklistProps = {
  [key: string]: any;
  skills: JSX.Element;
  organisations: JSX.Element;
  sessionBooking: JSX.Element;
  calenderSync: JSX.Element;
  socials: JSX.Element;
  introVideo: JSX.Element;
  featuredWork: JSX.Element;
  publicUrl: JSX.Element;
};

const CheckListCard: React.FC<props> = ({
  // eslint-disable-next-line react/prop-types
  checkList,
  setPopUpHeading,
  setEditShow,
  setModalType,
}) => {
  const checklistData: addcheckListDataProps = {
    shortBio: 'Tagline',
    experience: 'Experience',
    languages: 'Languages',
    skills: 'Core Skills',
    organisations: 'Organisations',
    sessionBooking: 'Enlist Booking Session',
    calenderSync: 'Sync Google Calendar',
    socials: 'Social Links',
    introVideo: 'Video Introduction',
    featuredWork: 'Featured Work',
    publicUrl: 'Custom URL',
  };
  const addChecklist: addChecklistProps = {
    skills: (
      <img
        role="presentation"
        onClick={() => {
          setEditShow(true);
          setModalType('editProfile');
          setPopUpHeading('Edit your profile');
        }}
        src={smallPlus}
        alt="add"
        className="checklist__card--plus-icon"
      />
    ),
    organisations: (
      <img
        role="presentation"
        onClick={() => {
          setEditShow(true);
          setModalType('editProfile');
          setPopUpHeading('Edit your profile');
        }}
        src={smallPlus}
        alt="add"
        className="checklist__card--plus-icon"
      />
    ),
    sessionBooking: (
      <a href="/Onboarding/FirstSession">
        <img src={smallPlus} alt="add" className="checklist__card--plus-icon" />
      </a>
    ),
    calenderSync: (
      <a href="/Onboarding/setAvailability">
        <img src={smallPlus} alt="add" className="checklist__card--plus-icon" />
      </a>
    ),
    socials: <Socials componentType="checkList" />,
    introVideo: <VideoUploader entityType="checkList" />,
    featuredWork: (
      <FeaturedWorkUploader entityType="checkList" apiCall={() => null} />
    ),
    publicUrl: <CustomUrl />,
  };

  return (
    <div className="checklist__card__container">
      {/* eslint-disable-next-line react/prop-types */}
      {checkList &&
        checkList.map((list) => (
          // eslint-disable-next-line react/jsx-key
          <p
            className={
              _.get(list, 'enabled', false)
                ? 'checklist__paragraph--green'
                : 'checklist__paragraph--grey'
            }
          >
            <img
              className="checklist__card--tick"
              src={_.get(list, 'enabled', false) ? greenTick : lightGreyTick}
              alt="tick"
            />{' '}
            {checklistData[_.get(list, 'name', '')]}
            {!_.get(list, 'enabled', false) &&
              addChecklist[_.get(list, 'name', '')]}
          </p>
        ))}
    </div>
  );
};
export default CheckListCard;
