import React, { useRef, useState } from 'react'
import HtmlParser from '../../../BasicComponents/htmlParser'
import _ from 'lodash'

type Props = {
    queImg: any,
    questionOption?: any,
    questionText: any,
    answer: string,
    getSingleChoiceData?: (k: any) => void,
    setOpen?: (k: boolean) => void,
    getCorrectAnswer?: (k: string) => void,
    setUserAnswer?: (k: string) => void,
    cardType?: string,
    isSingleChoice?: { isCorrect?: boolean },
    setConclusionType?: any
}
const TypeAnswerCard: React.FC<Props> = ({
    questionText, queImg,
    setOpen, getCorrectAnswer,
    cardType = '', setConclusionType, answer,
    setUserAnswer,
}) => {

    const inputRef = useRef<any>(null);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        // Your form submission logic here
      };
    
      const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          // When Enter key is pressed, blur the input field to hide the keyboard
          if (inputRef.current) {
            inputRef.current.blur();
          }
        }
      };

    return (
        // eslint-disable-next-line react/jsx-filename-extension
        <>
            <style>
                {`
        .form-control {
            display: block;
            width: 90%;
            padding: 0.375rem 0.75rem;
            margin-left: 15px;
        }
        `}
            </style>
            <form className="mobile__anki--card-container" onSubmit={handleSubmit}>
                <div className="">
                    {_.get(queImg, 'file', '') && (
                        <div className="mobile__anki--image-Container">
                            <img
                                src={queImg?.file}
                                alt=""
                                className={'mobile__anki--card-container-image'}
                            />
                        </div>
                    )}
                    <p className="mobile__anki--paragraph">
                        <HtmlParser text={questionText} />
                    </p>
                    <p className="mobile__anki--paragraph">Write your answer</p>
                    {cardType === 'preview' ? (
                        <div className="form-control">{answer}</div>
                    ) : (
                        <input
                            ref={inputRef}
                            onKeyDown={handleKeyPress}
                            className="form-control" placeholder='Type your answer.' onChange={(e) => setUserAnswer?.(e.target.value)} />
                    )}
                    {cardType !== 'preview'
                        ? <button className="multiple-option--done-btn"
                            onClick={() => { setConclusionType('typeAnswer'); setOpen?.(true); getCorrectAnswer?.(answer); }}
                            type="submit"
                        >Done</button>
                        : <button className="multiple-option--done-btn" type="button">Done</button>}
                </div>
            </form>
        </>
    )
}

export default TypeAnswerCard;