import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NavbarContainer from '../../BasicComponents/navbar';
import FeatureFlag from '../../FeatureFlag';
import AboutWebinar from './About';
import HeroSection from './HeroSection';
import Testimonials from './Testimonials';
import './StudentWebinar.css';
import { hlbClient } from '../../../Clients/hlbClient';

export interface RouteParams {
  webinarId: string;
  publicUrl: string;
}

const StudentWebinar = () => {
  const [webinarData, setWebinarData] = useState({});
  // eslint-disable-next-line no-undef
  const [innWidth, setInnWidth] = useState(window.innerWidth);
  const [isMobileView, setIsMobileView] = useState(false);
  const { webinarId = '', publicUrl = '' } = useParams<RouteParams>();

  function setWidth() {
    // eslint-disable-next-line no-undef
    setInnWidth(window.innerWidth);
  }

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);
  }, []);

  const fetchWebinarDetails = async () => {
    const apiEndpoint = webinarId ? `/api/webinar/${webinarId}` : `/api/webinar/getWebinarByCode/${publicUrl}`;
    try {
      const response = await hlbClient().get(apiEndpoint);
      setWebinarData(response?.data?.result);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    (webinarId || publicUrl) && fetchWebinarDetails();
  }, [webinarId]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const mql = window.matchMedia('(max-width: 414px)');
    const view = mql.matches;

    if (view) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  }, [innWidth]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', setWidth);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', setWidth);
  }, []);

  return (
    <FeatureFlag name="webinar">
      <div className="container-fluid">
        <div className="row">
          <NavbarContainer />
        </div>
      </div>
      <div className="webinar--wrapper">
        <div className="webinar--container">
          <HeroSection
            webinar={webinarData}
            isMobileView={isMobileView}
          />
          <AboutWebinar webinar={webinarData} isMobileView={isMobileView} />
          <Testimonials
            webinar={webinarData}
            refetchWebinarDetails={fetchWebinarDetails}
            isMobileView={isMobileView}
          />
        </div>
      </div>
    </FeatureFlag>
  );
};

export default StudentWebinar;
