import { Smiley } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { completionMessage } from '../noteConstant';

const ConclusionDesktop: React.FC = () => {
  const history = useHistory();
  const [textValue, setTextValue] = useState<{ heading: string, content: string  } | null>(null);

  useEffect(() => {
    const len = completionMessage.length;
    const randomIndex: number = Math.floor(Math.random() * len);
    const randomContent: { heading: string, content: string  } = completionMessage[randomIndex];
    setTextValue(randomContent);
  }, []);

  const reloadPage = () => {
    // eslint-disable-next-line no-undef
    window.location.reload();
  };

  return (
    <div className="mobile__anki--view-container">
      <style>
        {`
      .mobile__anki--view-container {
        width: 290px;
        height: 450px;
        margin: 0 auto;
      }
    .mobile__anki--card-container {
        display: flex;
        flex-direction: column;
        padding: 10px;
        align-items: center;
        justify-content: center;
    }
    .previewCard__btn {
      position: relative;
    }
  `}
      </style>
      <div className="mobile__anki--card-container">
        <div className="mobile__anki--card-container">
          <Smiley style={{ color: '#24AA42' }} size={55} />
          <h5 className="text-center mt-3">
            {textValue?.heading}
          </h5>
          <p className="text-center mt-3" style={{ fontSize: '14px' }}>
            {textValue?.content}
          </p>
          <button
            type="button"
            style={{ borderRadius: '8px' }}
            className="previewCard__btn mt-5"
            onClick={() => reloadPage()}
          >
            Review again
          </button>
          <button
            type="button"
            style={{ borderRadius: '8px' }}
            className="previewCard__btn mt-3"
            onClick={() => history.push('/space-repitition-system')}
          >
            Try another note
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConclusionDesktop;
