import dayjs from 'dayjs';

type StringOrDate = string | Date;

export const getTimeDifference = (
  startTime: StringOrDate,
  endTime: StringOrDate,
  keepShort = true,
): string => {
  const fromTime = dayjs(startTime);
  const toTime = dayjs(endTime);

  const mins = toTime.diff(fromTime, 'minutes', true);
  const hours = parseInt(String(mins / 60), 10);
  const minutes = parseInt(dayjs().minute(mins).format('mm'));

  return minutes > 0 ? `${hours} ${keepShort ? 'Hr' : hours > 1 ? 'Hours' : 'Hour'} ${minutes} ${keepShort ? 'Min' : 'Minutes'}` : `${hours} ${keepShort ? 'Hr' : hours > 1 ? 'Hours' : 'Hour'}`;
};

export const formatFees = (amount: number) => {
  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return currencyFormat.format(amount);
};

export const formatDate = (date: Date | string) => dayjs(date).format('YYYY-MM-DD');

export const getISOString = (date: StringOrDate, time: StringOrDate) => dayjs(dayjs(`${date} ${time}`).format('YYYY-MM-DD HH:mm:00')).toISOString();

export const getWebinarSeats = (seats: string) => {
  switch (seats) {
    case '1-50':
      return 50;
    case '51-100':
      return 100;
    case '101-200':
      return 200;
    default:
      return 10000;
  }
};

export const getWebinarPlatformValue = (platform: string) => (platform === 'gmeet' ? 'gmeet' : 'zoom');

export const getSeatOptionsValue = (seatOption: number) => {
  switch (seatOption) {
    case 50:
      return '1-50';
    case 100:
      return '51-100';
    case 200:
      return '101-200';
    default:
      return 'noLimits';
  }
};
