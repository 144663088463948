import React from 'react';
import ReactDOM from 'react-dom';
import LoginModal from './LoginModal';
import SignupModal from './SignupModal';


interface AuthModalProps {
    open: boolean;
    closeModal: () => void;
}

const AuthModal = ({ open, closeModal } : AuthModalProps) => {
    const [authMode, setAuthMode] = React.useState<"Login" | "Signup">("Login");
    const portalRoot = document.querySelector("#portal-root");

    const changeAuthMode = () => {
        authMode === "Login" ? setAuthMode("Signup") : setAuthMode("Login");
    }

    if (authMode === "Login" && portalRoot && open) {
        return ReactDOM.createPortal(<LoginModal changeAuthMode={changeAuthMode} closeModal={closeModal} />, portalRoot);
    }

    if (authMode === "Signup" && portalRoot && open) {
        return ReactDOM.createPortal(<SignupModal changeAuthMode={changeAuthMode} closeModal={closeModal} />, portalRoot);
    }

    return null
};

export default AuthModal;