import React from 'react';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import 'react-calendar/dist/Calendar.css';

function Calendso({
  // eslint-disable-next-line no-unused-vars
  setDate, selectedDate, daysAllowed, startDay, disabledDays, timeZone,
}) {
  // eslint-disable-next-line consistent-return
  function tileDisabled({ date, view }) {
    if (view === 'month') {
      return disabledDays.includes(date.getDay());
    }
  }
  return (
    <div>
      <Calendar
        onChange={setDate}
        value={selectedDate}
        minDate={dayjs().add(startDay, 'day').tz(timeZone).toDate()}
        maxDate={dayjs().add(daysAllowed, 'day').tz(timeZone).toDate()}
        showNavigation
        next2Label=""
        prev2Label=""
        minDetail="year"
        // eslint-disable-next-line react/jsx-no-bind
        tileDisabled={tileDisabled}
      />
    </div>
  );
}
Calendso.propTypes = {
  setDate: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  daysAllowed: PropTypes.number.isRequired,
  startDay: PropTypes.number.isRequired,
  timeZone: PropTypes.string.isRequired,
  disabledDays: PropTypes.arrayOf(PropTypes.number).isRequired,
};
export default Calendso;
