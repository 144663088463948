import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Modal } from 'react-bootstrap';
import { hlbClient } from '../../../../Clients/hlbClient';
import {
  deleteIcon,
  dots,
  edit,
  hideIcon,
  shareIcon,
  tickGreen,
} from '../../../BasicComponents/icons';
import Tooltip from '../../../BasicComponents/Tooltip';
import NavbarContainer from '../../../BasicComponents/navbar';
import CardRenderer from '../../cards/cardRenderer/cardRenderer';
import addcardImage from '../../../hyperNotes/assets/addCardImage.svg';
import { CardLoader } from '../../../BasicComponents/ContentLoader';
import Pagination from '../../../BasicComponents/Pagination';
import PreviewModal from '../../../hyperNotes/manage/Modals/PreviewModal';
import CreateNoteModal from '../../../hyperNotes/manage/Modals/createNoteModal';
import DeleteModal from '../../../hyperNotes/manage/Modals/deleteModal';
import HideModal from '../../../hyperNotes/manage/Modals/hideCardModal';
import ToastMessage from '../../../BasicComponents/toastMessage';
import CreateCardModal from '../../../hyperNotes/manage/Modals/createCardModal';
import CardWrapper from './cardWrapper';
import ManageCard from '../../manageV2/manageCards/manageCard';

type cardDataProps = {
  name: string | undefined;
  cardData: {
    total: number;
    cards: {
      answerText: any;
      questionText: any;
      questionImage: { file: string; url: string };
      answerImage: { file: string; url: string };
      hintText: string;
      questionOption: any[];
      cardType: string;
      _id: string;
    }[];
  };
};

type cardFinalData = {
  answerText: any;
  questionText: any;
  questionImage: { file: string; url: string };
  answerImage: { file: string; url: string };
  hintText: string;
  questionOption: any[];
  cardType: string;
  _id: string;
}[];

const CardsListPageV2: React.FC = () => {
  const [createCardModal, setCreateCardModal] = useState(false);
  const toggleCreateCardModal = () => setCreateCardModal(!createCardModal);
  const [previewModal, setPreviewModal] = useState(false);
  const togglePreviewModal = () => setPreviewModal(!previewModal);
  const [createDeckModal, setCreateDeckModal] = useState(false);
  const toggleCreateDeckModal = () => setCreateDeckModal(!createDeckModal);
  const [showDelete, setShowDelete] = useState(false);
  const [showHide, setShowHide] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const toggleConfirmModal = () => setConfirmModal(!confirmModal);

  const [previewData, setPreviewData] = useState({});
  const [editdata, setEditData] = useState({});
  const [entityType, setEntityType] = useState('');

  const [compType, setCompType] = useState('');
  const [deletingItemName, setDeletingItemName] = useState<string | undefined>(
    ''
  );
  const [cardId, setCardId] = useState('');
  const [deckId, setDeckId] = useState('');

  const parms: { id: string } = useParams();
  const { id } = parms;
  const history = useHistory();
  const [data, setData] = useState<cardDataProps | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [offset, setOffset] = useState(0);
  const limit = 15;

  const [openCardsManageV2, setOpenCardsManageV2] = useState(false);
  const [cardEditDataV2, setCardEditDataV2] = useState({});
  const [cardEntityTypeV2, setCardEntityTypeV2] = useState('');

  const getDeckById = async () => {
    try {
      const resp = await hlbClient().get(
        `/api/notesv2/noteDetailedInfo/${id}?offset=${offset}&limit=${limit}`
      );
      if (resp.data.success) {
        setData(resp.data.result);
        setIsLoading(false);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    getDeckById();
  }, [offset]);
  const deleteDeck = () => {
    setCompType('deck');
    setDeckId(id);
    setDeletingItemName(data?.name);
    setShowDelete(!showDelete);
  };
  const DropdownMenu = (
    <DropdownButton
      key="start"
      id="dropdown-button-drop"
      drop="down"
      title={<img src={dots} alt="..." />}
    >
      <Dropdown.Item
        eventKey="1"
        className="notes__menu-item"
        onClick={() => {
          toggleCreateDeckModal();
        }}
      >
        <img className="mx-2" height="16px" width="16px" src={edit} alt="..." />{' '}
        <span className="notes__card-menu-style">Edit note</span>
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="2"
        className="notes__menu-item"
        onClick={() => deleteDeck()}
      >
        <img
          className="mx-2"
          height="16px"
          width="16px"
          src={deleteIcon}
          alt="..."
        />
        <span className="notes__card-menu-style">Delete note</span>
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="2"
        className="notes__menu-item"
        onClick={() => {
          setOpenCardsManageV2(true);
          setCardEntityTypeV2('create');
          setCardEditDataV2({});
        }}
      >
        {/* <img
          className="mx-2"
          height="16px"
          width="16px"
          src={}
          alt="..."
        /> */}
        <span className="notes__card-menu-style">Create note V2</span>
      </Dropdown.Item>
    </DropdownButton>
  );

  const [cardType, setCardType] = useState<string>('');
  const deleteCard = (cardId: any, cardType: string) => {
    setCardId?.(cardId);
    setDeletingItemName?.('');
    setCompType?.('card');
    setDeckId?.(id);
    setShowDelete?.(true);
    setCardType(cardType);
  };

  const hideCard = (cardId: any, hidden: boolean, cardType: string) => {
    setCardId(cardId);
    setDeckId(id);
    setShowHide((showHide) => !showHide);
    setHidden(hidden);
    setCardType(cardType);
  };

  const hideEye = (hidden: boolean) => {
    return (
      hidden && (
        <Tooltip
          content={'This card is hidden'}
          direction="bottom"
          triggerTimeInms={0}
        >
          <div className="hide-eye">
            <img
              className="mx-2"
              height="24px"
              width="24px"
              src={hideIcon}
              alt="..."
              style={{ margin: '6px', cursor: 'pointer' }}
            />
          </div>
        </Tooltip>
      )
    );
  };

  const showEditCardModal = (card: any) => {
    toggleCreateCardModal();
    setEntityType('edit');
    setEditData({
      answer: card?.answerText,
      question: card?.questionText,
      queImg: card?.questionImage,
      ansImg: card?.answerImage,
      hint: card?.hintText,
      questionOption: card?.questionOption,
      cardType: card?.cardType,
      id: card?._id,
      deckId: id,
    });
  };
  const showEditCardModalV2 = (card: any) => {
    setOpenCardsManageV2(true);
    setCardEntityTypeV2('edit');
    setCardEditDataV2({
      cardId: card._id,
      cardType: card.cardType,
      topics: card.topics,
      description: card.description,
      medias: card.medias,
      hint: card.hint,
      correctAnswer: card.correctAnswer,
      options: card.options,
      answerDescription: card.answerDescription,
    });
  };

  const v2Cards = [
    'multiplechoicev2',
    'singlechoicev2',
    'typeanswerv2',
    'factv2',
    'reviewv2',
  ];

  const DropdownMenuCard = (card: any, cardType: string) => {
    return (
      <DropdownButton
        key="start"
        id="dropdown-button-drop"
        drop="down"
        title={<img src={dots} alt="..." />}
      >
        <Dropdown.Item
          eventKey="1"
          className="notes__menu-item"
          onClick={() =>
            v2Cards.includes(card.cardType)
              ? showEditCardModalV2(card)
              : showEditCardModal(card)
          }
        >
          <img
            className="mx-2"
            height="16px"
            width="16px"
            src={edit}
            alt="..."
          />{' '}
          <span className="notes__card-menu-style">Edit</span>
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="2"
          className="notes__menu-item"
          onClick={() => deleteCard(card?._id, cardType)}
        >
          <img
            className="mx-2"
            height="16px"
            width="16px"
            src={deleteIcon}
            alt="..."
          />
          <span className="notes__card-menu-style">Delete</span>
        </Dropdown.Item>
        <Dropdown.Item eventKey="3" className="notes__menu-item">
          <img
            className="mx-2"
            height="16px"
            width="16px"
            src={shareIcon}
            alt="..."
          />
          <span className="notes__card-menu-style">Share</span>
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="4"
          className="notes__menu-item"
          onClick={() => hideCard(card?._id, card.isHidden, cardType)}
        >
          <img
            className="mx-2"
            height="16px"
            width="16px"
            src={hideIcon}
            alt="..."
          />
          <span className="notes__card-menu-style">
            {card.isHidden ? 'Unhide' : 'Hide'}
          </span>
        </Dropdown.Item>
      </DropdownButton>
    );
  };

  const [finalData, setFinalData] = useState<cardFinalData | null>(null);
  // eslint-disable-next-line no-async-promise-executor
  useEffect(() => {
    const fetchData = async () => {
      const newData:
        | {
            answerText: any;
            questionText: any;
            questionImage: { file: string; url: string; name: string };
            answerImage: { file: string; url: string; name: string };
          }[]
        | any = data?.cardData.cards;
      if (newData) {
        for (const i of newData) {
          if (i?.answerImage?.url) {
            (i.answerImage.file = i.answerImage.url), i.answerImage?.name;
          }
          if (i?.questionImage?.url) {
            (i.questionImage.file = i.questionImage.url), i.questionImage?.name;
          }
        }
      }
      setFinalData(newData);
    };

    fetchData();
  }, [data]);

  return (
    <>
      <style>{`
          .single-choice__preview .mobile__anki--btn-hard {
            width: 130px;
            height: 38px;
          }
          .single-choice__preview .mobile__anki--btn-easy {
            width: 130px;
            height: 38px;
          }
          .single-choice__preview button {
            font-size: 16px;
        }
         .single-choice__preview .mobile__anki--paragraph {
          font-size: .85em;
        }
      .mobile__anki--card-container-image {
        width: 100%;
        height: 160px !important;
        border-radius: 8px 8px 0px 0px;
    }
    .add-container {

    }
    .add-container:hover {
      box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.35);
      transition: .2s ease;
    }
    `}</style>
      <div className="container-fluid mb-5">
        <div className="row">
          <NavbarContainer />
        </div>
      </div>
      <div className="container">
        <div className="row mt-3">
          <div className="notes__header">
            <p>{data?.name} Note</p>
          </div>
          <div className="col-md-6" />
          <div className="col-md-6">
            <div
              className="notes_dropdown float-end"
              style={{
                position: 'relative',
                marginTop: '-3px',
                background: '#eddfff',
                borderRadius: '4px',
              }}
            >
              {DropdownMenu}
            </div>
            <button
              type="button"
              className="notes__add--create-card-btn float-end mx-3"
              onClick={() => {
                toggleCreateCardModal();
                setEntityType('add');
                setEditData({});
              }}
            >
              Create Card
            </button>
            <button
              type="button"
              className="share-btn float-end"
              onClick={() => history.push(`/notes/flow-mode-v2/${id}`)}
            >
              Preview
            </button>
          </div>
          <div className="col-md-12 mb-4">
            <div className="row">
              {!isLoading ? (
                <>
                  <div className="row g-4">
                    {finalData &&
                      finalData.map((card: any, index: number) => (
                        <CardWrapper
                          key={index}
                          card={card}
                          hideEye={hideEye}
                          DropdownMenuCard={DropdownMenuCard}
                        >
                          <CardRenderer
                            {...{
                              card,
                              disableInteraction: false,
                            }}
                            index={offset + index}
                            totalCards={data?.cardData?.total}
                            previous={() => null}
                            next={() => null}
                            verifyAnswer={() => true}
                            done={() => null}
                            closeCard={() => null}
                          />
                        </CardWrapper>
                      ))}
                    {data &&
                    (offset ===
                      data.cardData.total - (data.cardData.total % limit) ||
                      offset === data.cardData.total - limit) ? (
                      <div className="col-sm-6 col-md-4 col-lg-4 d-flex align-items-center justify-content-center ">
                        <div
                          className="cursor-pointer add-container mb-2"
                          onClick={() => {
                            toggleCreateCardModal();
                            setEntityType('add');
                            setEditData({});
                          }}
                        >
                          <img
                            src={addcardImage}
                            alt="add card"
                            className="img-res"
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              ) : (
                <>
                  {[...Array(6)].map(() => (
                    // eslint-disable-next-line react/jsx-key
                    <div className="col-12 col-sm-6 col-md-4">
                      <CardLoader />
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <div
            className="col-md-12 mb-4"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            {data && data.cardData?.total > limit && (
              <Pagination
                offset={offset}
                setOffset={setOffset}
                limit={limit}
                totalCount={data.cardData.total}
              />
            )}
          </div>
        </div>
      </div>
      {createCardModal && (
        <CreateCardModal
          {...{
            toggleCreateCardModal,
            createCardModal,
            togglePreviewModal,
            id,
            setPreviewData,
            editdata,
            entityType,
            toggleConfirmModal,
          }}
          getMyDeck={getDeckById}
        />
      )}
      {previewModal && (
        <PreviewModal {...{ previewModal, togglePreviewModal, previewData }} />
      )}
      {createDeckModal && (
        <CreateNoteModal
          {...{
            toggleCreateDeckModal,
            createDeckModal,
            data,
            type: 'edit',
          }}
          getMyDeck={getDeckById}
        />
      )}
      {openCardsManageV2 && (
        <ManageCard
          {...{
            noteId: id,
            openModal: openCardsManageV2,
            setOpenModal: setOpenCardsManageV2,
            getDeckById,
            type: cardEntityTypeV2,
            data: cardEditDataV2,
          }}
        />
      )}
      {showDelete && (
        <DeleteModal
          {...{
            showDelete,
            setShowDelete,
            cardId,
            deckId,
            type: compType,
            name: deletingItemName,
            getMyDeck: getDeckById,
            cardType,
          }}
        />
      )}
      {showHide && (
        <HideModal
          {...{
            showHide,
            setShowHide,
            hidden,
            cardId,
            deckId,
            getMyDeck: getDeckById,
            cardType,
          }}
        />
      )}
      <Modal
        show={confirmModal}
        className="notes__modal--confirm-success"
        centered
        onHide={() => toggleConfirmModal()}
      >
        <Modal.Body>
          <div className="confirmation-success-container mt-3">
            <div className="confirmation-modal-box">
              <div className="confirmation__head">
                <img src={tickGreen} alt="Successful" />
                <h3 className="confirmation__head--title">
                  Successfully Created!
                </h3>
              </div>
              <div className="confirmation__links mt-3 mb-3">
                <button
                  type="button"
                  className="confirmation__link"
                  onClick={() => toggleConfirmModal()}
                >
                  I am done
                </button>
                <button
                  type="button"
                  className="confirmation__link"
                  onClick={() => {
                    toggleConfirmModal();
                    toggleCreateCardModal();
                  }}
                >
                  Create New Card
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastMessage />
    </>
  );
};

export default CardsListPageV2;
