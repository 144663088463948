import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    title: "",
    entityType: "",
    search: "",
    ids: [],
    filter: [],
    showcaseItems: [],
};

export const showcaseConfigSlice = createSlice({
    name: 'showcaseConfig',
    initialState,
    reducers: {
        updateShowcaseConfig: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        },
        resetShowcaseConfig: () => {
            return {
                ...initialState,
            }
        }
    },
});

export const { updateShowcaseConfig, resetShowcaseConfig } = showcaseConfigSlice.actions;

export default showcaseConfigSlice.reducer;