import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import './videoUploader.css';
import { noop } from 'lodash';
import {
  edit, plus, deleteIcon, cross, smallPlus,
} from '../icons';
import VideoCard from '../videoCard';
import { hlbClient } from '../../../Clients/hlbClient';
import { addToast } from '../../../reducers/toast.reducer';

/* eslint-disable react/require-default-props */
type VideoUploaderProps = {
  data?: string;
  apiCall?: () => void;
  entityType?: string;
}

function VideoUploader({ data = '', apiCall = noop, entityType = '' }: VideoUploaderProps) {
  const [showVideo, setShowVideo] = useState(false);
  const [videoLink, setVideoLink] = useState('');
  const dispatch = useDispatch();
  const uploadVideo = async (e: any) => {
    e.preventDefault();
    try {
      await hlbClient().put('/api/users/introvideo', {
        introVideo: videoLink,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
    dispatch(addToast({ type: 'success', message: 'Video Uploaded Successfully' }));
    setShowVideo(false);
    if (entityType !== 'checkList') {
      // eslint-disable-next-line no-unused-expressions
      apiCall && apiCall();
    }
    if (entityType === 'checkList') {
      // eslint-disable-next-line no-undef
      window.location.reload();
    }
  };
  // eslint-disable-next-line no-undef
  const deleteVideo = async (e: any) => {
    e.preventDefault();
    try {
      await hlbClient().put('/api/users/introvideo', {
        introVideo: '',
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
    dispatch(addToast({ type: 'success', message: 'Video Deleted Successfully' }));
    // eslint-disable-next-line no-unused-expressions
    apiCall && apiCall();
    setVideoLink('');
  };
  const clearText = () => {
    setVideoLink('');
  };
  return (
    <>
      <div>
        {entityType === 'checkList'
          ? (
            <img
              onClick={() => {
                setShowVideo(true);
              }}
              onKeyPress={() => {
                setShowVideo(true);
              }}
              role="presentation"
              src={smallPlus}
              alt="add session"
              style={{
                position: 'absolute', right: '8.67%', marginTop: '-5px', height: '12px',
              }}
            />
          )
          : (
            <>
              {data ? (
                <>
                  <img
                    onClick={() => {
                      setShowVideo(true);
                    }}
                    onKeyPress={() => {
                      setShowVideo(true);
                    }}
                    role="presentation"
                    src={edit}
                    alt="add session"
                    className="profile__edit-addsession--edit"
                  />
                  <img
                    onClick={deleteVideo}
                    onKeyPress={() => {
                      setShowVideo(true);
                    }}
                    role="presentation"
                    src={deleteIcon}
                    alt="add session"
                    className="profile__edit-addsession--edit"
                  />
                </>
              ) : (
                <img
                  onClick={() => {
                    setShowVideo(true);
                  }}
                  onKeyPress={() => {
                    setShowVideo(true);
                  }}
                  role="presentation"
                  src={plus}
                  alt="add session"
                  className="profile__edit-addsession--edit"
                />
              )}
            </>
          )}

        <Modal show={showVideo} fullscreen="md" centered onHide={() => setShowVideo(false)}>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <button
                  type="button"
                  onClick={() => setShowVideo(false)}
                  className="btn-close float-end"
                />
              </div>
              <div className="co-md-12">
                <p className="videoUpload__heading">Share your introduction video</p>
                <p className="videoUpload__sub-heading">
                  Adding a introductory video helps the student
                  connect with you and it makes it more interactive
                  for them. Significantly increases the chances of
                  booking a session with you.
                </p>
                <form onSubmit={uploadVideo} style={{ marginLeft: '0px', position: 'relative' }}>
                  <input
                    className="form-input"
                    value={videoLink}
                    onChange={(e) => { setVideoLink(e.target.value); }}
                    type="text"
                    placeholder="E.g. https://"
                  />
                  {videoLink && (
                  <img
                    onClick={clearText}
                    onKeyPress={clearText}
                    alt="clear button"
                    role="presentation"
                    src={cross}
                    className="videoUpload__clearn-btn"
                  />
                  )}
                  {videoLink && (
                  <div className="text-center mt-3">
                    <VideoCard data={videoLink} entityType="videoUploader" />
                    <button className="videoUpload__btn btn mt-3" type="submit">Upload</button>
                  </div>
                  )}
                </form>
              </div>

            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default VideoUploader;
