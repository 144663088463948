import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { resetState } from '../../../reducers/chat.reducer';
import { SocketContext } from '../../../provider/SocketContext';
import { AuthContext } from '../../../provider/AuthContext';

function LogOut() {
  const { setToken, setStateUpdate, stateUpdate } = useContext(AuthContext);
  const { setEnrollment } = useContext(SocketContext);
  // eslint-disable-next-line no-undef
  const token: string | null = localStorage.getItem('token');
  useEffect(() => {
    if (token) {
      setStateUpdate(!stateUpdate);
    }
  }, []);
  const history = useHistory();
  const dispatch = useDispatch();
  const logOut = () => {
    // eslint-disable-next-line no-undef
    setToken(localStorage.removeItem('token'));
    // eslint-disable-next-line no-undef
    window.localStorage.setItem('hlUserId', '');
    // eslint-disable-next-line no-undef
    window.localStorage.setItem('hlUserType', '');
    history.push('/login');
  };
  return (
    <button
      className="log__out--btn-V2"
      type="button"
      onClick={() => {
        logOut();
        dispatch(resetState());
        setEnrollment('');
      }}
    >
      Logout
    </button>
  );
}

export default LogOut;
