import {
  facebook, twitter, linkedIn, behance, reddit, dribbble,
  instagram, gitHub, discord, medium, link,
} from '../BasicComponents/icons';

export const options = [
  {
    value: 'linkedIn',
    image: linkedIn,
  },
  {
    value: 'twitter',
    image: twitter,
  },
  {
    value: 'facebook',
    image: facebook,
  },
  {
    value: 'instagram',
    image: instagram,
  },
  {
    value: 'github',
    image: gitHub,
  },
  {
    value: 'dribbble',
    image: dribbble,
  },
  {
    value: 'behance',
    image: behance,
  },
  {
    value: 'reddit',
    image: reddit,
  },
  {
    value: 'discord',
    image: discord,
  },
  {
    value: 'medium',
    image: medium,
  },
  {
    value: 'portfolio',
    image: link,
  },
];
