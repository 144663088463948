/* eslint-disable react/jsx-key */


/**
 *  Init Flow Mode
 *   Here we show the user the start button screen, on pressing this button the user will be taken to the first card of the flow mode
 * 
 *  @param {string} noteId - The id of the note for which the flow mode is to be started
 * 
 *  @returns {JSX.Element} - Returns the start button screen
 * 
 *  get Cards / load cards
 * 
 *  Here we load cards for the flow mode
 * 
 *  
 * 
 * 
 *  Internal state
 *  cards: Array of all the cards for the flow mode
  *  currentCard: The index of current card being shown to the user
  *  totalCards:  cards.length
  *  next : Function to go to the next card
  *  prev : Function to go to the previous card
  *  VerifyAnswer : Function to verify the answer of the user
  *  displayResult: Function to display the result of the user answer
  *  done: Function to mark the card as done
  *  InitiateConclusion: Function to initiate the conclusion screen
  *  close: Funcction to close the flow mode
 */

import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import CardRenderer from "../../cards/cardRenderer/cardRenderer";
import { useHistory, useParams } from "react-router";
import { hlbClient } from "../../../../Clients/hlbClient";
import NoteStart from "../../common/noteStart";
import { CommonCardDTO, ReviewCard, TypeAnswerCard, SCQCard, MCQCard, OptionType, FactCard, TypeAnswerV2Card, SCQCardV2, MCQCardV2, OptionTypeV2, ReviewCardV2 } from "../../types";
import { Conclusion, ExitPopup, ResultPopup } from "../../common";
import { boolean } from "yup/lib/locale";

const FlowMode = ({ openFullscreen= () => {console.log("a")}, innHeight }: { openFullscreen: () => void, innHeight: number }) => {

  const router = useHistory();
  const parms: { id: string } = useParams();
  const { id } = parms;


  const [cards, setCards] = useState<CommonCardDTO[]>([]);
  const [currentCard, setCurrentCard] = useState<number>(-1);

  const [correctAnswer, setCorrectAnswer] = useState<string>('');
  // const [reason, setReason] = useState<string>('');
  const [showConclusionCard, setShowConclusioncard] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false);
  const [percentageCount, setPercentageCount] = useState(0);
  const [isConclusion, setIsConclusion] = useState<boolean>(false);
  const [exitModal, setExitModal] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [ ansIsCorrect, setAnsIsCorrect ] = useState<boolean>(false);
  const toggleExitModal = () => setExitModal(!exitModal);

  const [data, setData] = useState<{
    cards: any[];
    discountedPrice: number,
    price: number,
    name: string,
    creatorName: string,
    _id: string,
    subscriberCount: number,
    difficulty: string,
    image: { url: string },
  } | any>({ name: '' });
  const [loading, setloading] = useState(false);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
  };

  const slider = useRef<any>();
  function next() {
    slider.current.slickNext();
    setCurrentCard(curr => curr + 1);
    if(currentCard + 1 >= cards.length) {
      setIsConclusion(true);
    }
  }

  function prev() {
    slider.current.slickPrev();
    setCurrentCard(curr => curr - 1);
  }

  function done(cardType: string, result: boolean) {
    // when the last action has been initiated by the user
    setAnsIsCorrect(result)
    if (cardType === 'review') {
      next()
    } else {
      initResultFlow()
    }
  }

  function initResultFlow() {
    setShowResult(true)
  }

  function finishResultFlow() {
    setShowResult(false)
    setCorrectAnswer('')
  }

  function verifyAnswer(cardType: string, payload: any) {
    // set the correct answer and reason
    // and return the result
    if (cardType === 'mcq') {
      const { questionOption, selectedOption }: { questionOption: OptionType[], selectedOption: OptionType[]} = payload; 
      const questionCorrect = questionOption.filter(
        (x: { isCorrect: boolean }) => x && x.isCorrect
      ).map(x => x.optionText).sort();
      const answerCorrect = selectedOption.filter(
        (x: { isCorrect: boolean }) => x && x.isCorrect
      ).map(x => x.optionText).sort();
      setCorrectAnswer(questionCorrect.join(", "))
      return questionCorrect.join() === answerCorrect.join();
    }
    if (cardType === 'scq') {
     const { selectedOption, questionOption}: { selectedOption: OptionType, questionOption: OptionType[]} = payload;
     const correctAnswer = questionOption.find((x) => x.isCorrect)
      setCorrectAnswer(correctAnswer?.optionText || "")
      return selectedOption.optionText === correctAnswer?.optionText;
    }
    if (cardType === 'writeAnswer') {
      const correctAnswer = payload.correctAnswer;
      const userAnswer = payload.userAnswer;
      setCorrectAnswer(correctAnswer)
      return correctAnswer.toUpperCase() === userAnswer.toUpperCase();
    }
    if (cardType === 'typeanswerv2') {
      const correctAnswer = payload.correctAnswer;
      const userAnswer = payload.userAnswer;
      setCorrectAnswer(correctAnswer)
      return correctAnswer.toUpperCase() === userAnswer.toUpperCase();
    }
    if (cardType === 'multiplechoicev2') {
      const { options, selectedOption }: { options: OptionTypeV2[], selectedOption: OptionTypeV2[]} = payload; 
      const questionCorrect = options.filter(
        (x: { isCorrect: boolean }) => x && x.isCorrect
      ).map(x => x.text).sort();
      const answerCorrect = selectedOption.map(x => x.text).sort();
      setCorrectAnswer(questionCorrect.join(", "))
      return questionCorrect.join() === answerCorrect.join();
    }
    if (cardType === 'singlechoicev2') {
     const { selectedOption, options}: { selectedOption: OptionTypeV2, options: OptionTypeV2[]} = payload;
     const correctAnswer = options.find((x) => x.isCorrect);
      setCorrectAnswer(correctAnswer?.text || "");
      const isCorrect = selectedOption.text === correctAnswer?.text;
      return isCorrect;
    }
    return false
  }



  function close(pageURL: string) {
    router.push(pageURL)
  }

  function initiateConclusion() {
    setShowConclusioncard(true)
  }

  const getNoteById = async () => {
    setloading(true);
    try {
      // const resp = await hlbClient().get(`/api/srs/deck/${id}`);
      const resp = await hlbClient().get(`/api/notesv2/${id}`); // /api/notesv2/:noteid
      if (resp?.data?.success) {
        setData(resp?.data?.result);
        setloading(false);
        getCards(resp?.data?.result?.cards as any);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('oops something went wrong');
    }
  };

  useEffect(() => {
    getNoteById();
  },[])

  function getCards(cards: any) {
    // should call the api and set the cards
    const tcards: CommonCardDTO[] = [];

    cards.forEach((card: any) => {
      const cardType = card.cardType;
      if (cardType === 'review') {
        const _c = card as (ReviewCard & { _id: string })
        tcards.push(_c);
      } else if (cardType === 'writeAnswer') {
        const _c = card as (TypeAnswerCard & { _id: string })
        tcards.push(_c);
      } else if (cardType === 'mcq') {
        const correctCount = card.questionOption.filter((x: { isCorrect: boolean }) => x && x.isCorrect).length;
        const isSCQ = correctCount === 1;
        if (isSCQ) {
          const _c = card as (SCQCard & { _id: string })
          tcards.push(_c);
        } else {
          const _c = card as (MCQCard & { _id: string })
          tcards.push(_c);
        }
      } else if (cardType === 'factv2') {
        const _c = card as (FactCard & { _id: string })
        tcards.push(_c);
      } else if (cardType === 'typeanswerv2') {
        const _c = card as (TypeAnswerV2Card & { _id: string })
        tcards.push(_c);
      } else if (cardType === 'singlechoicev2') {
        const _c = card as (SCQCardV2  & { _id: string })
        tcards.push(_c);
      } else if (cardType === 'multiplechoicev2') {
        const _c = card as (MCQCardV2  & { _id: string })
        tcards.push(_c);
      } else if (cardType === 'reviewv2') {
        const _c = card as (ReviewCardV2  & { _id: string })
        tcards.push(_c);
      }
    })
    
    setCards(tcards);
  }

  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const mql = window.matchMedia('(max-width: 614px)');
    const view = mql.matches;

    if (view) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  }, [window.innerWidth]);

  const renderCards = isConclusion ? (
    <Conclusion {...{
      innHeight, name, noteId: id, data,
    }} />
  ) : (
    <div style={{ translate: `0  ${isMobileView ? 0 : 10}%` }}>
      <Slider
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...settings}
        // eslint-disable-next-line no-return-assign
        ref={(c: any) => (slider.current = c)}
      >
        {cards && cards.map((card, index) => (
          <>
            <style>
              {`
                .card__container-height {
                  margin: auto;
                  height: ${isMobileView ? window.innerHeight : 480}px;
                  width: ${isMobileView ? window.innerWidth : 326}px;
                }
              `}
            </style>
            <div className="card__container-height">
              <CardRenderer
                key={`card-${index}`}
                card={card}
                next={next}
                previous={prev}
                verifyAnswer={verifyAnswer}
                disableInteraction={false}
                index={index}
                totalCards={cards?.length}
                reason={""}
                done={done}
                closeCard={toggleExitModal}
              />
            </div>
          </>
        ))}
      </Slider>
      {exitModal && <ExitPopup {...{exitModal, toggleExitModal, setIsConclusion }} />}
      {showResult && <ResultPopup  next={next} correctAnswerText={correctAnswer} finishResultFlow={finishResultFlow}
              isCorrect={ansIsCorrect}
      />}
    </div>
  )

  return (
    currentCard === -1 ?
      <NoteStart {...{ setCurrentCard, data, isLoading, percentageCount, openFullscreen }} /> : (
        renderCards
      )
  )

}
export default FlowMode;