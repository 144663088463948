import React from 'react';
import ContentLoader from 'react-content-loader';

const CardLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={350}
    height={150}
    viewBox="0 0 350 150"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <rect x="9" y="8" rx="5" ry="5" width="144" height="136" />
    <rect x="170" y="59" rx="5" ry="5" width="170" height="18" />
    <rect x="171" y="83" rx="5" ry="5" width="170" height="11" />
    <rect x="171" y="100" rx="5" ry="5" width="170" height="11" />
    <circle cx="132" cy="62" r="11" />
    <rect x="174" y="124" rx="0" ry="0" width="15" height="15" />
    <rect x="201" y="124" rx="0" ry="0" width="15" height="15" />
    <rect x="323" y="123" rx="0" ry="0" width="15" height="15" />
    <rect x="173" y="18" rx="5" ry="5" width="29" height="31" />
  </ContentLoader>
);

export default CardLoader;
