import _ from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import WatchTutorial from './watchTutorial';
type props = {
    data: {
      image?: { url: string },
      name: string,
    }, 
    percentageCount: number, 
    isLoading: boolean, 
    setCurrentCard: Dispatch<SetStateAction<number>>,
    openFullscreen: () => void,
  }
const NoteStart: React.FC<props> = ({
    data, percentageCount, isLoading, setCurrentCard, openFullscreen
}) => {
  const [isWatchTutorial, setIsWatchTutorial] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [innWidth, setInnWidth] = useState(window.innerWidth);
  const [innHeight, setInnHeight] = useState(window.innerHeight);

  useEffect(() => {
    const mql = window.matchMedia('(max-width: 614px)');
    const view = mql.matches;

    if (view) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, [innWidth]);

  const updateDimensions = () => {
    setInnWidth(window.innerWidth);
    setInnHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []); 

  return (
    <>
    {!isWatchTutorial 
      ? 
    <div className="mobile__anki--view-container">
    <style>
      {`
    .progress {
      display: flex;
      height: 1rem;
      overflow: hidden;
      font-size: .75rem;
      background-color: #bbbcbd;
      border-radius: 0.25rem;
    }
    .anki__deck--name-onloading {
      padding: 20px;
      width: 100%;
      text-align: center;
    }
    .sub-container {
      height: 100%;
      background: #fff;
    }
    .note__watch--tutorials-link{
      position: absolute;
      bottom: 70px;
    }
    `}
    </style>
    <div className="mobile__anki--card-container desktop-only">
      {_.get(data, 'image.url', '') && (
        <div className="mobile__anki--image-Container">
          <img src={_.get(data, 'image.url', '')} alt="" className="mobile__anki--card-container-image" />
        </div>
      )}
      {_.get(data, 'name', '') && (
      <div className="anki__deck--name-onloading mt-5 mb-5">
        <h3>{_.get(data, 'name', '')}</h3>
      </div>
      )}
      {isLoading && (
        <div className="anki__deck--name-onloading">
          <div className="progress" style={{ height: '5px', width: '90%' }}>
            {/* eslint-disable-next-line react/self-closing-comp */}
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${percentageCount}%` }}
              aria-valuenow={percentageCount}
              aria-valuemin={0}
              aria-valuemax={100}
            >
            </div>
          </div>
        </div>
      )}
      <div className="anki__deck--name-onloading">
        {isLoading ? <p>Loading your note.</p>
          : (
            <>
              <p
                role="presentation"
                className="note__watch--tutorials-link"
                onClick={() => setIsWatchTutorial(true)}
              >
                Watch Tutorial
              </p>
              <div className="mobile__anki--btn-container">
                <button
                  type="button"
                  className="mobile__anki--btn-purple"
                  onClick={() => {
                    setCurrentCard(0);
                    openFullscreen();
                  }}
                >
                  Start
                </button>
              </div>
            </>
          )}
      </div>
    </div>
  </div>
    : 
    <WatchTutorial 
      setIsWatchTutorial={setIsWatchTutorial}
      innHeight={innHeight}
      mobileView={mobileView}
    />}
    </>
  )
}

export default NoteStart