/* eslint-disable no-multi-assign */
import React, { useRef } from 'react';
import '../../mentorProfile/MentorProfile.css';
import { rightArrow, leftArrow, dummyImage } from '../icons';
import FeaturedWorkUploader from '../featuredWorkUploader';

type Image = {
  url: string;
};

type FeaturedWorkSliderProps = {
  featured: Image[];
  entityType?: string;
  apiCall?: VoidFunction;
};

function FeaturedWorkSlider({
  featured,
  entityType = 'normalPage',
  apiCall = () => {
    // empty function
  },
}: FeaturedWorkSliderProps) {
  const ref: React.MutableRefObject<any> = useRef();
  const buttonRight = (scrollOffset: number) => {
    ref.current.scrollLeft += scrollOffset;
  };
  const buttonLeft = (scrollOffset: number) => {
    ref.current.scrollLeft += scrollOffset;
  };
  const featuredWork = featured.length || null;
  return (
    <div className="row">
      <div className="col-md-12 mb-4">
        <div className="profile__edit-addsession mt-3">
          <p className="profile__edit-addsession--text">Featured Work</p>
          {entityType === 'editPage' && (
            <>
              <FeaturedWorkUploader data={featured} apiCall={apiCall} />
            </>
          )}
          <img
            src={rightArrow}
            onKeyPress={() => buttonLeft(200)}
            role="presentation"
            alt=""
            className="btn profile__edit-addsession--edit"
            onClick={() => buttonLeft(200)}
            id="slideRight"
          />
          <img
            src={leftArrow}
            onKeyPress={() => buttonLeft(-200)}
            role="presentation"
            alt=""
            className="btn profile__edit-addsession--edit"
            onClick={() => buttonRight(-200)}
            id="slideLeft"
          />
        </div>
        {featuredWork !== null ? (
          <div
            className="slider"
            ref={ref}
            style={{ scrollBehavior: 'smooth' }}
          >
            {featured.map((image: { url: string }) => (
              // eslint-disable-next-line react/jsx-key
              <div className="image-slider-container">
                <img
                  src={image.url}
                  alt=""
                  className="img-fuild"
                  style={{
                    width: '318px',
                    height: '203px',
                    borderRadius: '8px',
                  }}
                />
              </div>
            ))}
          </div>
        ) : (
          <div
            className="slider"
            ref={ref}
            style={{ scrollBehavior: 'smooth' }}
          >
            <div className="image-slider-container">
              <img
                src={dummyImage}
                alt=""
                className="img-fuild mx-2"
                style={{ width: '318px', height: '203px', borderRadius: '8px' }}
              />
              <img
                src={dummyImage}
                alt=""
                className="img-fuild"
                style={{ width: '318px', height: '203px', borderRadius: '8px' }}
              />
              <img
                src={dummyImage}
                alt=""
                className="img-fuild mx-2"
                style={{ width: '318px', height: '203px', borderRadius: '8px' }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FeaturedWorkSlider;
