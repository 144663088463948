import React, { useState, useEffect, useContext } from 'react';
import './style.css';
import MyDeck from './myDeck';
import CreateNoteModal from './Modals/createNoteModal';
import { hlbClient } from '../../../Clients/hlbClient';
import ToastMessage from '../../BasicComponents/toastMessage';
import Empty from '../../BasicComponents/Empty';
import { AuthContext } from '../../../provider/AuthContext';
import NavbarContainer from '../../BasicComponents/navbar';
import { DeckCardLoader } from '../../BasicComponents/ContentLoader';

const MainPage: React.FC = () => {
  const [createDeckModal, setCreateDeckModal] = useState(false);
  const toggleCreateDeckModal = () => setCreateDeckModal(!createDeckModal);
  const { authState, setStateUpdate, stateUpdate } = useContext(AuthContext);
  // eslint-disable-next-line no-undef
  const token = localStorage.getItem('token');
  useEffect(() => {
    if (token) {
      setStateUpdate(!stateUpdate);
    }
  }, []);
  const currentUserName = authState.name;

  const [data, setData] = useState([]);
  const [sub, setSubDecks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getMyDeck = async () => {
    try {
      const resp = await hlbClient().get('/api/srs/mydecks');
      if (resp.data.success) {
        setData(resp.data.result);
        setIsLoading(false);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');
      setIsLoading(false);
    }
  };
  const getSubDecks = async () => {
    try {
      const resp = await hlbClient().get('/api/srs/deck/subscribedDecks');
      if (resp.data.success) {
        setSubDecks(resp.data.result);
        setIsLoading(false);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    getMyDeck();
    getSubDecks();
  }, []);

  return (
    <>
      <div className="container-fluid mb-5">
        <div className="row">
          <NavbarContainer />
        </div>
      </div>
      <div className="container">
        <div className="row mt-3">
          <div className="notes__header">
            <p>
              Hi,
              {' '}
              {currentUserName}
            </p>
            <button type="button" className="anki__create-btn" onClick={() => toggleCreateDeckModal()}>+ Create Note</button>
          </div>
          <div className="col-md-6" />
          {!isLoading ? (
            <div>
              {data.length !== 0 ? <MyDeck data={data} headerName="My Notes" />
                : (
                  <div className="mt-5">
                    <Empty type="button" msg="Create Note" onClick={toggleCreateDeckModal} />
                  </div>
                )}
            </div>
          ) : (
            <div className="col-md-12">
              <div className="row">
                {[...Array(3)].map(() => (
                  // eslint-disable-next-line react/jsx-key
                  <div className="col-12 col-sm-6 col-md-4"><DeckCardLoader /></div>
                ))}
              </div>
            </div>
          )}
          {!isLoading ? (
            <div>
              {sub.length !== 0 ? <MyDeck headerName="Subscribed Notes" data={sub} entityType="redirect" />
                : <div className="mt-5"><Empty type="normal" msg="No subscribed note found!" /></div>}
            </div>
          ) : (
            <div className="col-md-12">
              <div className="row">
                {[...Array(3)].map(() => (
                  // eslint-disable-next-line react/jsx-key
                  <div className="col-12 col-sm-6 col-md-4"><DeckCardLoader /></div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {createDeckModal && (
        <CreateNoteModal
          {...{
            toggleCreateDeckModal, createDeckModal, getMyDeck,
          }}
        />
      )}
      <ToastMessage />
    </>
  );
}

export default MainPage;
