import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import NavbarContainer from '../BasicComponents/navbar';
import {
  // eslint-disable-next-line no-unused-vars
  edit, downLite, userImage,
} from '../BasicComponents/icons';
import BookingsAccordion from './bookingsAccordion';
import { hlbClient } from '../../Clients/hlbClient';
import { AuthContext } from '../../provider/AuthContext';
import './UserProfile.css';
import '../MentorDashboard/MentorDashboard.css';

const UserProfile: React.FC = () => {
  const [bookingList, setBookingList] = useState([]);
  const [availBookingResults, setAvailBookingResults] = useState(10);
  const { authState, setStateUpdate, stateUpdate } = useContext(AuthContext);
  // eslint-disable-next-line no-undef
  const token = window.localStorage.getItem('token');

  const getSessionType = (sessionType: string) => {
    switch (sessionType) {
      case 'one-on-one':
        return '(1x1)';
      default:
        return `(${sessionType})`;
    }
  };
  const capitalize = (word: string) => {
    if (word) {
      const [first, ...rest]: any = word;
      return first.toUpperCase() + rest.join('');
    }
    return word;
  };
  const correctTime = (dateObj: any) => {
    const helperDate = new Date(dateObj);
    let hours = helperDate.getHours();
    let minutes: string | number = helperDate.getMinutes();
    const AmOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = (hours % 12) || hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const timeString = `${hours}:${minutes} ${AmOrPm}`;
    return timeString;
  };
  const limitWordLength = (word: string) => {
    if (word.length > 14) {
      return `${word.substring(0, 6)}...`;
    }
    return word;
  };
  const handleShowMore = () => setAvailBookingResults((prevState) => prevState + 5);

  // -------------- TO FETCH BOOKED SESSIONS DATA
  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await hlbClient().get('/api/dashboardV2/getMyBookings');
        if (response.data) {
          setBookingList(response.data);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Something went wrong! 🤖');
      }
    };
    fetchBookings();
  }, []);

  useEffect(() => {
    if (token) {
      setStateUpdate(!stateUpdate);
    }
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <NavbarContainer />
        </div>
      </div>
      <div className="uprofile--wrapper">
        <div className="uprofile">
          <section className="uprofile__sidebar">
            <div className="sidebar__profile">
              <div className="profile__avatar">
                <img src={_.get(authState, 'profile.profilePic.url', userImage)} alt="user avatar" className="profile__avatar--image" />
                <h2 className="profile__avatar--name">
                  { capitalize(_.get(authState, 'name', '')) }
                  {/* <button type="button" className="profile__avatar--edit">
                    <img src={edit} alt="Edit" />
                  </button> */}
                </h2>
              </div>
              <div className="main__intro--mobile">
                <h2>
                  Hi,
                  {' '}
                  { capitalize(_.get(authState, 'name', '')) }
                </h2>
              </div>
              {/* BORDER NEEDED FOR SEPERATION (PRESENT IN STYLESHEETS ON RESP. CLASSNAME) */}
              {/* <div className="profile__stats">
                <div className="profile__stat">
                  <h3 className="profile__stat--label">Upcoming sessions</h3>
                  <span className="profile__stat--value value--purple">
                    5
                  </span>
                </div>
                <div className="profile__stat">
                  <h3 className="profile__stat--label">Total sessions</h3>
                  <span className="profile__stat--value">
                    12
                  </span>
                </div>
              </div> */}
              <div className="session__intro--mobile">
                <h2>Your sessions</h2>
              </div>
            </div>
          </section>
          <section className="uprofile__main">
            <div className="main__intro">
              <h2 className="main__intro--greetings">
                Hi,
                {' '}
                { capitalize(_.get(authState, 'name', '')) }
              </h2>
              <p className="main__intro--message">Here are your upcoming sessions</p>
            </div>
            {
              bookingList.length ? (
                <div className="mdashboard__card-rounded main__bookings">
                  <div className="upcoming__sessions--wrapper">
                    <div className="upcoming__label">
                      <p className="upcoming__label--mentee">Mentor</p>
                      <p className="upcoming__label--session-type">Session type</p>
                      <p className="upcoming__label--duration">Duration</p>
                      <p className="upcoming__label--starts-at">Starts at</p>
                    </div>
                    {
                      bookingList.length && (
                        <>
                          <div className="upcoming__sessions--container">
                            {
                              bookingList.slice(0, availBookingResults).map((el) => (
                                <BookingsAccordion
                                  elData={el}
                                  correctTime={correctTime}
                                  getSessionType={getSessionType}
                                  capitalize={capitalize}
                                  studentName={_.get(authState, 'name', '')}
                                  studentEmail={_.get(authState, 'email', '')}
                                  limitWordLength={limitWordLength}
                                  key={Math.random()}
                                />
                              ))
                            }
                          </div>
                          <div className="button__showmore">
                            <button
                              type="button"
                              onClick={handleShowMore}
                              // eslint-disable-next-line max-len
                              disabled={availBookingResults === bookingList.length || bookingList.length < availBookingResults}
                            >
                              {availBookingResults === bookingList.length || bookingList.length < availBookingResults ? 'No more bookings found!' : 'Show more'}
                              {availBookingResults === bookingList.length || bookingList.length < availBookingResults ? null : <img src={downLite} alt="down" />}
                            </button>
                          </div>
                        </>
                      )
                    }
                  </div>
                </div>
              ) : (
                <div className="sessions__empty-container">
                  <h3 className="empty__container--heading">
                    It’s all empty in here!
                  </h3>
                  <p className="empty__container--text">
                    Book your first
                    {' '}
                    <Link to="/" className="empty__container--link">session</Link>
                    {' '}
                    and start growing.
                  </p>
                </div>
              )
            }
          </section>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
