import React from 'react';
import { useSelector } from 'react-redux';
import './toastMessage.css';
import Toastbox from './toast';

function ToastMessage() {
  const data = useSelector((state: any) => state.toast.data);

  const action = data?.type;

  switch (action) {
    case 'success': return (
      <Toastbox data={data} />
    );
    case 'warning': return (
      <Toastbox data={data} />
    );
    case 'error': return (
      <Toastbox data={data} />
    );
    default: return null;
  }
}

export default ToastMessage;
