import React, { ReactNode } from 'react'
import Tooltip from '../../../BasicComponents/Tooltip'
import { PlayCircle } from '../../../BasicComponents/icons';
import { ReviewCard as RC } from '../../types';

type props = { 
    children: ReactNode,
    card: RC,
    hideEye: (k: boolean) => false | JSX.Element,
    DropdownMenuCard: (k1: RC, k2: string ) => ReactNode,
 }

const CardWrapper: React.FC<props> = ({ children, card, hideEye, DropdownMenuCard }) => {
    return (
        <div
            className="col-sm-6 col-md-6 col-lg-4 d-flex justify-content-center"
            style={{
                filter: card.isHidden
                    ? 'grayscale(100%)'
                    : 'grayscale(0%)',
            }}
        >
            <div
                className="single-choice__preview"
                style={{ position: 'relative', width: '326px' }}
            >
                <div
                    className="notes_dropdown"
                    style={{ display: 'flex' }}
                >

                    <Tooltip
                        content={'This is card preview button'}
                        direction="bottom"
                        triggerTimeInms={0}
                    >
                        <div className="hide-eye mt-1 mx-3 cursor-pointer">
                            <PlayCircle fontSize="24" />
                        </div>
                    </Tooltip>
                    {hideEye(card.isHidden)}
                    {DropdownMenuCard(card, card.cardType)}
                </div>
                {children}
            </div>
        </div>
    )
}

export default CardWrapper;