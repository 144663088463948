import React from 'react';
import './AuthInput.css'
import { WarningCircle } from 'phosphor-react';
import { noop } from 'lodash';

interface AuthInputProps {
  label?: string;
  type: "number" | "text" | "email" | "tel";
  placeholder?: string;
  errorMessage?: string;
  value: string;
  updateValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
  showDialCode?: boolean;
  maxLength?: number;
}

const AuthInput = ({
  label = "",
  type,
  placeholder = "Value..",
  errorMessage = "",
  value,
  updateValue,
  style = {},
  showDialCode = false,
  maxLength,
  onKeyDown = noop,
}: AuthInputProps) => {
  return (
    <div className={`authInput authInput-${type}`} style={style}>
      {
        label && (
          <label
            className={`authInput-label ${errorMessage && 'authInput-label--error'}`}
            htmlFor={label}
          >
            {label}
            <span className='authInput-label--required'>*</span>
          </label>
        )
      }
      <div className="authInput-wrapper">
        {
          showDialCode && (
            <span className='authInput-dialCode'>+91</span>
          )
        }
        <input
          className={`authInput-input ${errorMessage && 'authInput-input--error'} input-${type}`}
          type={type}
          placeholder={placeholder}
          id={label}
          value={value}
          onChange={updateValue}
          maxLength={maxLength}
          onKeyDown={onKeyDown}
        />
      </div>
      {
        errorMessage && (
          <div className="authInput-errorBlock">
            <WarningCircle size={12} color="#F55555" />
            <span className='errorBlock-message'>{errorMessage}</span>
          </div>
        )
      }
    </div>
  );
};

export default AuthInput;
