import React from 'react';
import { Link } from 'react-router-dom';
import NavbarContainer from '../BasicComponents/navbar';
import './errorPage.css';

const ErrorPage: React.FC = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <NavbarContainer />
        </div>
      </div>
      <div className="errorPage__container">
        <div className="errorPage__main">
          <div className="heading">
            <h1>Sorry, unexpected error!</h1>
          </div>
          <div className="main-erro">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="450"
              height="150"
              viewBox="0 0 726 286"
            >
              <g fill="none" fillRule="evenodd">
                <path
                  fill="#F3F3F3"
                  fillRule="nonzero"
                  d="M113.17 279.56c59.04 0 97.58-40.59 97.58-90.61 0-54.94-38.13-88.15-90.2-88.15-20.91 0-42.23 6.56-54.12 19.68l19.68-76.26h113.57V7.32H59.46L25.02 139.34c13.53 4.92 25.42 10.25 39.36 15.58 11.48-11.89 29.52-19.27 48.38-19.27 30.75 0 57.4 18.45 57.4 54.94 0 32.8-25.42 53.71-56.99 53.71-33.21 0-57.4-22.14-59.86-54.94l-38.54 11.48c3.69 41.82 41.82 78.72 98.4 78.72zm237.8-.41c37.72 0 64.37-16.81 81.59-42.64 17.63-26.24 23.78-59.86 23.78-96.35 0-36.49-6.15-70.11-23.78-96.35-17.22-25.83-43.87-42.64-81.59-42.64-37.72 0-64.37 16.81-81.59 42.64-17.63 26.24-23.78 59.86-23.78 96.35 0 36.49 6.15 70.11 23.78 96.35 17.22 25.83 43.87 42.64 81.59 42.64zm0-35.67c-18.86 0-31.98-6.56-42.23-17.22-15.99-16.81-23.37-45.51-23.37-86.1 0-40.59 7.38-69.29 23.37-86.1 10.25-10.66 23.37-17.22 42.23-17.22 18.86 0 31.98 6.56 42.23 17.22 15.99 16.81 23.37 45.51 23.37 86.1 0 40.59-7.38 69.29-23.37 86.1-10.25 10.66-23.37 17.22-42.23 17.22zm251.74 35.67c37.72 0 64.37-16.81 81.59-42.64 17.63-26.24 23.78-59.86 23.78-96.35 0-36.49-6.15-70.11-23.78-96.35-17.22-25.83-43.87-42.64-81.59-42.64-37.72 0-64.37 16.81-81.59 42.64-17.63 26.24-23.78 59.86-23.78 96.35 0 36.49 6.15 70.11 23.78 96.35 17.22 25.83 43.87 42.64 81.59 42.64zm0-35.67c-18.86 0-31.98-6.56-42.23-17.22-15.99-16.81-23.37-45.51-23.37-86.1 0-40.59 7.38-69.29 23.37-86.1 10.25-10.66 23.37-17.22 42.23-17.22 18.86 0 31.98 6.56 42.23 17.22 15.99 16.81 23.37 45.51 23.37 86.1 0 40.59-7.38 69.29-23.37 86.1-10.25 10.66-23.37 17.22-42.23 17.22z"
                />
                <g>
                  <path
                    fill="#DDDCDA"
                    fillRule="nonzero"
                    stroke="#6C707C"
                    strokeWidth="3"
                    d="M364.5 45L364.5 59 190.5 59 190.5 45M364.5 101L364.5 115 190.5 115 190.5 101"
                    transform="translate(85 169)"
                  />
                  <g
                    fillRule="nonzero"
                    transform="translate(85 169) translate(168.5 55)"
                  >
                    <rect
                      width="211"
                      height="45"
                      x="1.5"
                      y="1.5"
                      fill="#FFF"
                      stroke="#6C707C"
                      strokeWidth="3"
                      rx="6"
                    />
                    <circle cx="185.5" cy="24.5" r="10.5" fill="#6C707C" />
                    <rect
                      width="65"
                      height="4"
                      x="101"
                      y="22"
                      fill="#EDEDED"
                      rx="2"
                    />
                  </g>
                  <g
                    fillRule="nonzero"
                    transform="translate(85 169) translate(168.5)"
                  >
                    <rect
                      width="211"
                      height="45"
                      x="1.5"
                      y="1.5"
                      fill="#FFF"
                      stroke="#6C707C"
                      strokeWidth="3"
                      rx="6"
                    />
                    <circle cx="185.5" cy="24.5" r="10.5" fill="#6C707C" />
                    <rect
                      width="65"
                      height="4"
                      x="101"
                      y="22"
                      fill="#EDEDED"
                      rx="2"
                    />
                  </g>
                  <g transform="translate(85 169) translate(50.5 23)">
                    <path
                      stroke="#6C707C"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M119.753 0c-12.268 4.455-17.352 12.775-15.253 24.959 3.149 18.276 15.047 35.305 10.077 50.739-4.971 15.434-23.468 18.852-44.617 11.789-21.15-7.064-29.382-4.836-37.51-4.836"
                    />
                    <rect
                      width="22.857"
                      height="16"
                      x="15"
                      y="73"
                      fill="#878C9A"
                      fillRule="nonzero"
                      rx="8"
                    />
                    <ellipse
                      cx="17.5"
                      cy="81"
                      fill="#6C707C"
                      fillRule="nonzero"
                      rx="7.5"
                      ry="10"
                    />
                    <ellipse
                      cx="15.5"
                      cy="81"
                      fill="#BABEC9"
                      fillRule="nonzero"
                      rx="7.5"
                      ry="10"
                    />
                    <rect
                      width="13"
                      height="2"
                      y="78"
                      fill="#6C707C"
                      fillRule="nonzero"
                      rx="1"
                    />
                    <rect
                      width="13"
                      height="2"
                      x="5"
                      y="82"
                      fill="#6C707C"
                      fillRule="nonzero"
                      rx="1"
                    />
                  </g>
                  <g
                    fillRule="nonzero"
                    transform="translate(85 169) translate(0 85)"
                  >
                    <circle cx="10.5" cy="10.5" r="10.5" fill="#878C9A" />
                    <circle cx="13.5" cy="10.5" r="2.5" fill="#4A4D57" />
                    <circle cx="7.5" cy="10.5" r="2.5" fill="#4A4D57" />
                  </g>
                </g>
                <rect
                  width="720"
                  height="3"
                  y="283"
                  fill="#6C707C"
                  fillRule="nonzero"
                  rx="1.5"
                />
              </g>
            </svg>
          </div>
          <div className="errorPage__center--box">
            <p className="errorPage__text">
              Don&apos;t worry it&apos;s not your fault, Please check your
              internet connection.
            </p>
            <p style={{ color: '#8c52ff' }}>-- Or --</p>
            <Link to="/" className="errorpage_btn">
              Try Again
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
