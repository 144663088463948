import React, { useRef, useState } from 'react';
import ReviewCard from '../reviewCard/reviewCard';
import SingleChoiceCard from '../scqCard/scqCard';
import MultipleChoiceCard from '../mcqCard/mcqCard';
import TypeAnswer from '../typeAnswer/typeAnswer';
import FactCard from '../factCard/factCard';
import TypeAnswerV2Card from '../typeAnswerV2/typeAnswerV2Card';
import ReviewV2Card from '../reviewCardV2/reviewCardV2';
import {
  cardRendererProps,
  OptionType,
  MCQCard,
  SCQCard,
  TypeAnswerCard,
  ReviewCard as RC,
  FactCard as FC,
  TypeAnswerV2Card as TCV2,
  ReviewCardV2 as RCV2,
  MCQCardV2,
  SCQCardV2,
} from '../../types';
import LongQuestionCardWrapper from './longQuestionCardWrapper';
import { hint, topicIndex, xcircle } from '../../../BasicComponents/icons';

import './cardRenderer.css';
import '../global-card-styles.css';
import SingleChoiceV2Card from '../scqCardV2/scqCardV2';
import MultipleChoiceV2Card from '../mcqCardV2/mcqCardV2';

const CardRenderer: React.FC<cardRendererProps> = ({
  card,
  disableInteraction,
  index = 0,
  totalCards = 1,
  reason,
  previous,
  next,
  verifyAnswer,
  done,
  closeCard,
}) => {
  const cardType = card?.cardType;
  const [isShowHint, setIsShowHint] = useState(false);
  const hintRef = useRef<HTMLDivElement>(null);

  const isHintDisabled = cardType === 'factv2';

  const Card = () => {
    switch (cardType) {
      case 'review': {
        const _c = card as RC;
        return (
          <ReviewCard
            // eslint-disable-next-line
            id={card._id}
            cardType="review"
            disableInteraction={disableInteraction}
            isHidden={_c.isHidden}
            questionText={_c.questionText}
            questionImg={_c.questionImg}
            answerText={_c.answerText}
            answerImg={_c.answerImg}
            hintText={_c.hintText}
            index={index}
            totalCards={totalCards}
            reason={reason}
            next={next}
            previous={previous}
            done={done}
          />
        );
      }
      case 'reviewv2': {
        const _c = card as RCV2;
        return (
          <ReviewV2Card
            // eslint-disable-next-line
            id={card._id}
            cardType="reviewv2"
            disableInteraction={disableInteraction}
            isHidden={_c.isHidden}
            description={_c.description}
            medias={_c.medias}
            answerDescription={_c.answerDescription}
            cardFace={_c.cardFace}
            index={index}
            totalCards={totalCards}
            reason={reason}
            previous={previous}
            next={next}
            done={done}
          />
        );
      }
      case 'mcq': {
        const isSingleChoice =
          // eslint-disable-next-line
          (card as SCQCard | MCQCard).questionOption.filter(
            (option: OptionType) => option && option?.isCorrect
          ).length === 1;
        if (isSingleChoice) {
          const _c = card as SCQCard;
          return (
            <SingleChoiceCard
              // eslint-disable-next-line
              id={card._id}
              cardType="scq"
              disableInteraction={disableInteraction}
              isHidden={_c.isHidden}
              questionText={_c.questionText}
              questionImg={_c.questionImg}
              questionOption={_c.questionOption}
              reason={reason}
              verifyAnswer={verifyAnswer}
              done={done}
            />
          );
        } else {
          const _c = card as MCQCard;
          return (
            <MultipleChoiceCard
              // eslint-disable-next-line
              id={card._id}
              cardType="mcq"
              disableInteraction={disableInteraction}
              isHidden={_c.isHidden}
              questionText={_c.questionText}
              questionImg={_c.questionImg}
              questionOption={_c.questionOption}
              reason={reason}
              verifyAnswer={verifyAnswer}
              done={done}
            />
          );
        }
      }
      case 'singleChoice':
      case 'singlechoicev2': {
        const _c = card as SCQCardV2;
        return (
          <SingleChoiceV2Card
            // eslint-disable-next-line
            id={card._id}
            cardType="scq"
            disableInteraction={disableInteraction}
            isHidden={_c.isHidden}
            description={_c.description}
            medias={_c.medias}
            options={_c.options}
            reason={reason}
            verifyAnswer={verifyAnswer}
            done={done}
          />
        );
      }
      case 'multipleChoice':
      case 'multiplechoicev2': {
        const _c = card as MCQCardV2;
        return (
          <MultipleChoiceV2Card
            // eslint-disable-next-line
            id={card._id}
            cardType="mcq"
            disableInteraction={disableInteraction}
            isHidden={_c.isHidden}
            description={_c.description}
            medias={_c.medias}
            options={_c.options}
            reason={reason}
            verifyAnswer={verifyAnswer}
            done={done}
          />
        );
      }
      case 'writeAnswer': {
        const _c = card as TypeAnswerCard;
        return (
          <TypeAnswer
            // eslint-disable-next-line
            id={card._id}
            cardType="typeAnswer"
            disableInteraction={disableInteraction}
            isHidden={_c.isHidden}
            questionText={_c.questionText}
            questionImg={_c.questionImg}
            answerText={_c.answerText}
            reason={reason}
            verifyAnswer={verifyAnswer}
            done={done}
          />
        );
      }
      case 'typeanswerv2': {
        const _c = card as TCV2;
        return (
          <TypeAnswerV2Card
            // eslint-disable-next-line
            id={card._id}
            cardType="typeanswerv2"
            disableInteraction={disableInteraction}
            isHidden={_c.isHidden}
            description={_c.description}
            medias={_c.medias}
            correctAnswer={_c.correctAnswer}
            reason={reason}
            verifyAnswer={verifyAnswer}
            done={done}
          />
        );
      }
      case 'factv2': {
        const _c = card as FC;
        return (
          <FactCard
            // eslint-disable-next-line
            id={card._id}
            cardType="factv2"
            disableInteraction={disableInteraction}
            isHidden={_c.isHidden}
            description={_c.description}
            medias={_c.medias}
            index={index}
            totalCards={totalCards}
            reason={reason}
            previous={previous}
            next={next}
            done={done}
          />
        );
      }
      default:
        return null;
    }
  };

  const handleTopicIndex = () => {
    if (!disableInteraction) {
      alert('This feature is under beta. Stay tuned!');
    }
  };

  const handleHint = () => {
    if (!isHintDisabled) {
      if (!isShowHint) {
        setIsShowHint(true);
        setTimeout(() => {
          hintRef.current && (hintRef.current.style.translate = '0 0');
        }, 0);
      } else {
        hintRef.current && (hintRef.current.style.translate = '0 -110%');
        setTimeout(() => {
          setIsShowHint(false);
        }, 400);
      }
    }
  };

  const handleClose = () => {
    if (!disableInteraction) {
      closeCard();
    }
  };

  const getHintText = () => {
    switch (cardType) {
      case 'writeAnswer':
      case 'review':
      case 'scq':
      case 'mcq': {
        const c = card as TypeAnswerCard | RC | SCQCard | MCQCard;
        return c.hintText || 'No hint to show!';
      }
      case 'typeanswerv2':
      case 'reviewv2':
      case 'singleChoice':
      case 'multiChoice': {
        const c = card as TCV2 | RCV2 | SCQCardV2 | MCQCardV2;
        return c.hint || 'No hint to show!';
      }
      default: {
        return 'No hint to show!';
      }
    }
  };

  return (
    <>
    <style>{`
     ol {
      display: block;
      list-style-type: decimal;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }
    li {
      font-size: 13px !important;
    }
    p {
      font-size: 13px !important;
    }
    h2 {
      font-size: 1.3em;
    }
    `}</style>
      <div className="card-renderer__container">
        <div className="card-renderer__header">
          <span className="card-renderer__page-number">{`${
            index + 1
          }/${totalCards}`}</span>
          <span className="card-renderer__topic">
            {(((card as FC).topics as string[]) &&
              ((card as FC).topics as string[])[0] &&
              `#${((card as FC).topics as string[])[0]}`) ||
              'Card topic'}
          </span>
          <div className="card-renderer__tools">
            <span
              className={`card-renderer__tool ${
                disableInteraction && 'icon-disabled'
              }`}
              onClick={handleTopicIndex}
            >
              <img src={topicIndex} alt="index" height={24} width={24} />
            </span>
            <span
              className={`card-renderer__tool ${
                isHintDisabled && 'icon-disabled'
              }`}
              onClick={handleHint}
            >
              <img src={hint} alt="hint" height={24} width={24} />
            </span>
            <span
              className={`card-renderer__tool ${
                disableInteraction && 'icon-disabled'
              }`}
              onClick={handleClose}
            >
              <img src={xcircle} alt="close" height={24} width={24} />
            </span>
          </div>
        </div>
        <div className="card-renderer__card">
          <LongQuestionCardWrapper>
            <Card />
          </LongQuestionCardWrapper>
          {isShowHint && (
            <div className="card-renderer-hint__layer">
              <div className="card-renderer-hint__container" ref={hintRef}>
                <span>Hint</span>
                <p>{getHintText()}</p>
                <button onClick={handleHint}>Dismiss</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CardRenderer;
