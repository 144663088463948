import React, {
  useState, useContext, useEffect,
} from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { timeZones } from '../../Constants';
import { AuthContext } from '../../../provider/AuthContext';
import { hlbClient } from '../../../Clients/hlbClient';
import '../Calendar.css';
import CalendarContainerDesktop from './CalendarContainer';
import CalendarContainerMobile from './CalendarContainer.mobile';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(timezone);

function CalendarContainer() {
  const { sessionId } = useParams();
  const [duration, setDuration] = useState(15);
  const [expertId, setExpertId] = useState(false);
  const [data, setData] = useState({});
  const [selectedTime, setTime] = useState('');
  const [showForm, setShowForm] = useState(false);
  const { authState } = useContext(AuthContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState([]);
  const [description, setDescription] = useState('');
  const [selectedDate, setDate] = useState();
  const [booked, setBooked] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [disabledDays, setDisabled] = useState([]);
  const [availability, setAvailability] = useState([]);
  const [availabilityOrg, setAvailabilityOrg] = useState([]);
  const [timeZone, setZone] = useState(dayjs.tz.guess());
  const [successBooking, setSuccessBooking] = useState(false);
  const [failedBooking, setFailedBooking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // state for small screens
  // eslint-disable-next-line no-undef
  const [innWidth, setInnWidth] = useState(window.innerWidth);
  const [mobileView, setMobileView] = useState(false);

  function setWidth() {
    // eslint-disable-next-line no-undef
    setInnWidth(window.innerWidth);
  }

  const capitalizeName = ([first, ...rest]) => first.toUpperCase() + rest.join('');

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const mql = window.matchMedia('(max-width: 414px)');
    const view = mql.matches;

    if (view) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, [innWidth]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', setWidth);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', setWidth);
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const resp = await hlbClient().get(`/api/scheduler/session?sessionId=${sessionId}`);
        setExpertId(resp.data.mentorId);
        setDuration(resp.data.duration);
        setData(resp.data);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('oops something went wrong');
      }
    }
    fetchData();
  }, []);
  function changeAvailability(zone) {
    if (_.get(availabilityOrg[0], 'timeZone', dayjs.tz.guess()) !== zone) {
      const newAvailability = [...availabilityOrg];
      const oldDays = { ...newAvailability[0].days };
      const newDays = {
        0: { slots: [], isAvailable: true },
        1: { slots: [], isAvailable: true },
        2: { slots: [], isAvailable: true },
        3: { slots: [], isAvailable: true },
        4: { slots: [], isAvailable: true },
        5: { slots: [], isAvailable: true },
        6: { slots: [], isAvailable: true },
      };
      for (let i = 0; i < 7; i += 1) {
        if (oldDays[i].isAvailable === true) {
          const { slots } = oldDays[i];
          for (let j = 0; j < slots.length; j += 1) {
            if (dayjs(slots[j].endTime).isBefore(dayjs('1970-01-01').tz(zone).startOf('date'))) {
              newDays[(i + 6) % 7].slots.push({
                startTime: dayjs(slots[j].startTime).add(1, 'day').toISOString(),
                endTime: dayjs(slots[j].endTime).add(1, 'day').toISOString(),
              });
            } else if (dayjs(slots[j].startTime).isBefore(dayjs('1970-01-01').tz(zone).startOf('date'))) {
              newDays[i].slots.push({ startTime: dayjs('1970-01-01').tz(zone).startOf('date'), endTime: slots[j].endTime });
              newDays[(i + 6) % 7].slots.push({
                startTime: dayjs(slots[j].startTime).add(1, 'day').toISOString(),
                endTime: dayjs('1970-01-01').tz(zone).endOf('date').toISOString(),
              });
            } else if (dayjs(slots[j].endTime).isBefore(dayjs('1970-01-01').tz(zone).endOf('date'))) {
              newDays[i].slots.push(slots[j]);
            } else if (dayjs(slots[j].startTime).isAfter(dayjs('1970-01-01').tz(zone).endOf('date'))) {
              newDays[(i + 1) % 7].slots.push({
                startTime: dayjs(slots[j].startTime).subtract(1, 'day').toISOString(),
                endTime: dayjs(slots[j].endTime).subtract(1, 'day').toISOString(),
              });
            } else {
              newDays[i].slots.push({
                startTime: slots[j].startTime,
                endTime: dayjs('1970-01-01').tz(zone).endOf('date').toISOString(),
              });
              newDays[(i + 1) % 7].slots.push({
                startTime: dayjs('1970-01-01').tz(zone).startOf('date').toISOString(),
                endTime: dayjs(slots[j].endTime).subtract(1, 'day').toISOString(),
              });
            }
          }
        }
      }
      for (let i = 0; i < 7; i += 1) {
        if (newDays[i].slots.length === 0) {
          newDays[i].isAvailable = false;
        }
      }
      newAvailability[0].timeZone = zone;
      newAvailability[0].days = newDays;
      setAvailability(newAvailability);
      // eslint-disable-next-line no-console
      console.log(newAvailability, availability);
    }
  }
  function findDisabledDays() {
    const newdisabledDays = [];
    for (let i = 0; i < 7; i += 1) {
      if (_.get(availability[0], `days.${i}.isAvailable`, true) === false || _.get(availability[0], `days.${i}.slots.length`, 0) === 0) {
        newdisabledDays.push(i);
      }
    }
    setDisabled(newdisabledDays);
  }
  useEffect(() => {
    findDisabledDays();
  }, [availability]);
  useEffect(() => {
    async function fetchData() {
      try {
        const resp = await hlbClient().get(`/api/scheduler/availability/mentor/${expertId}`);
        setAvailabilityOrg(resp.data);
        setAvailability(resp.data);
        changeAvailability(dayjs.tz.guess());
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('oops something went wrong');
      }
    }
    if (expertId) {
      fetchData();
    }
  }, [expertId]);
  useEffect(() => {
    if (authState) {
      setName(authState.name);
      setEmail(() => [authState.email]);
    }
  }, [authState]);
  useEffect(() => {
    async function fetchData() {
      try {
        const resp = await hlbClient().post(`api/scheduler/booking/user/${expertId}`, {
          from: dayjs(selectedDate).tz(timeZone).toISOString(),
          to: dayjs(selectedDate).tz(timeZone).add(1, 'day').toISOString(),
        });
        setBooked(resp.data.googleCal);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('oops something went wrong');
      }
    }
    if (selectedDate) {
      fetchData();
    }
  }, [selectedDate, availability]);
  function bookedSlots(slot, busy) {
    if (dayjs(slot.time).isBetween(booked[busy].startTime, booked[busy].endTime, null, '[)')) {
      return { time: slot.time, booked: true };
    }
    return slot;
  }
  function pastSlots(slot) {
    if (dayjs(slot.time).isSameOrBefore(dayjs().add(_.get(availability[0], 'bookingAllowedBefore', 0), 'hour'), 'minute')) {
      return { time: slot.time, booked: true };
    }
    return slot;
  }
  useEffect(() => {
    if (selectedDate) {
      const selectedDay = dayjs(selectedDate).get('day');
      let Slots = [];
      if (availability[0].days[selectedDay].isAvailable) {
        for (let slotNum = 0;
          slotNum < availability[0].days[selectedDay].slots.length; slotNum += 1) {
          const availableSlot = availability[0].days[selectedDay].slots[slotNum];
          const start = dayjs(selectedDate).set('hour',
            dayjs(availableSlot.startTime).get('hour')).set('minute',
            dayjs(availableSlot.startTime).get('minute'));
          const end = dayjs(selectedDate).set('hour',
            dayjs(availableSlot.endTime).get('hour')).set('minute',
            dayjs(availableSlot.endTime).get('minute'));
          for (let i = start; dayjs(i).add(duration, 'minute').isSameOrBefore(end); i = i.add(duration, 'minute')) {
            Slots.push({ time: dayjs(i).unix() * 1000, booked: false });
          }
        }
        Slots = Slots.filter((slot, index) => Slots.indexOf(slot) === index);
        for (let busy = 0; busy < booked.length; busy += 1) {
          Slots = Slots.map((slot) => bookedSlots(slot, busy));
        }
        Slots.sort((a, b) => (a.time < b.time ? -1 : 1));
      }
      if (dayjs().isSame(dayjs(selectedDate), 'day')) {
        Slots = Slots.map((slot) => (
          pastSlots(slot)
        ));
      }
      setTimeSlots(Slots);
    }
  }, [booked, availability]);

  const componentDesktop = (
    // eslint-disable-next-line react/jsx-filename-extension
    <CalendarContainerDesktop
      showForm={showForm}
      setShowForm={setShowForm}
      data={data}
      expertId={expertId}
      selectedTime={selectedTime}
      duration={duration}
      name={name}
      setName={setName}
      email={email}
      setEmail={setEmail}
      description={description}
      setDescription={setDescription}
      timeSlots={timeSlots}
      selectedDate={selectedDate}
      setTime={setTime}
      setDate={setDate}
      availability={availability}
      disabledDays={disabledDays}
      timeZone={timeZone}
      timeZones={timeZones}
      // eslint-disable-next-line react/jsx-no-bind
      changeAvailability={changeAvailability}
      setZone={setZone}
      successBooking={successBooking}
      setSuccessBooking={setSuccessBooking}
      failedBooking={failedBooking}
      setFailedBooking={setFailedBooking}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      capitalize={capitalizeName}
    />
  );

  const componentMobile = (
    // eslint-disable-next-line react/jsx-filename-extension
    <CalendarContainerMobile
      data={data}
      showForm={showForm}
      setShowForm={setShowForm}
      expertId={expertId}
      selectedTime={selectedTime}
      duration={duration}
      name={name}
      setName={setName}
      email={email}
      setEmail={setEmail}
      description={description}
      setDescription={setDescription}
      timeSlots={timeSlots}
      setDate={setDate}
      selectedDate={selectedDate}
      setTime={setTime}
      availability={availability}
      disabledDays={disabledDays}
      timeZone={timeZone}
      timeZones={timeZones}
      setZone={setZone}
      innWidth={innWidth}
      setInnWidth={setInnWidth}
      successBooking={successBooking}
      setSuccessBooking={setSuccessBooking}
      failedBooking={failedBooking}
      setFailedBooking={setFailedBooking}
      capitalize={capitalizeName}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
    />
  );

  if (mobileView) {
    return componentMobile;
  // eslint-disable-next-line no-else-return
  } else {
    return componentDesktop;
  }
}

export default CalendarContainer;
