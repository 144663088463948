export const mentor = [
  {
    id: 1,
    que: 'Can I become a mentor?',
    ans: 'Yes, anyone can become a mentor on Hyperlearn, just create an impressive ‘Hyperlearn Profile’, share your knowledge and help the community, build their skills.',
  },
  {
    id: 2,
    que: 'What kind of session can I give as a mentor?',
    ans: 'As a mentor, you can choose which kind of sessions to go with. We currently support 1×1 sessions. Group sessions and webinars are on the roadmap.',
  },
  {
    id: 3,
    que: 'Is there a limit on how many sessions should I take in a week?',
    ans: 'No, there is no such quota, providing sessions depends on your schedule. ',
    extra: 'Hyperlearn also lets you define your availability so that a student can book the sessions, only in the time slots available.',
    endingPara: 'Similarly, if you are going on a vacation you can disable the new incoming bookings.',
  },
  {
    id: 4,
    que: 'What are the perks for mentors in hyperlearn?',
    ans: 'At Hyperlearn, we are continuously striving to make the process of finding and connecting with a mentor easy, so we simplify payments and bookings for the mentor.',
  },
  {
    id: 5,
    que: 'What if I am already a mentor on a different website, will that be an issue?',
    ans: 'No issues, as a mentor you just have to be present for the session when a student books a session with you.',
  },
  {
    id: 6,
    que: 'How do mentors get paid?',
    ans: 'Whenever a payment is made on Hyperlearn, we take 2% of the amount (payment gateway charges) and the remaining money is processed to the mentors on a fortnightly basis.',
  },
  {
    id: 7,
    que: 'Can I become a mentor even if I don’t have a laptop?',
    ans: 'It depends on the type of session you are providing to the students. Some sessions would require a laptop while others can be done easily on cell phones.',
  },
];

export const student = [
  {
    id: 8,
    que: 'What are the topics on which Hyperlearn can assist me?',
    ans: 'Hyperlearn focuses on technical topics, especially in the following domains: Software engineering, Product Management, Data Science, Analytics, Finance, Soft Skills etc.',
  },
  {
    id: 9,
    que: 'How can I book a session with a mentor?',
    ans: 'You can easily book a session with a mentor, by following the steps given below:-',
    list: [
      'Go to the Mentor profile page.',
      'Select the session you are interested in.',
      'Now, select a date for the session to occur.',
      'Select your time slot.',
      'After you select a time slot, you will be directed to a form, where your name and e-mail id, would be already indicated, fill out the rest necessary details and proceed further.',
      'You must now go ahead and pay for the session.',
    ],
    extra: 'That’s all to easily book a session!',
  },
  {
    id: 10,
    que: 'How must I pay a mentor?',
    ans: 'All verified mentors are to be paid via the Hyperlearn platform, you just have to click on the ‘Pay Now’ button and a payment dialogue box will appear, where you can easily fill in your payment details.',
  },
  {
    id: 11,
    que: 'Can I cancel a scheduled session?',
    ans: 'Yes, you can easily cancel a session on Hyperlearn, if you do it at least 2 hours before the session was scheduled and send an e-mail with the subject “Cancel Session | Mentor’s Name | Date & Time” to support@hyperlearn.in, also please note that the refund process may take about 5-7 business days.',
  },
  {
    id: 12,
    que: 'Are there any free sessions available on Hyperlearn?',
    ans: 'Yes, we are actively trying to increase our collection of free sessions.',
  },
  {
    id: 13,
    que: 'I am quite uncomfortable showing my face, so do I need to show my face in a session?',
    ans: 'It’s not a problem, most sessions would not require you to switch on the camera, but this may change depending on the type of session though.',
  },
  {
    id: 14,
    que: 'How long can one session be?',
    ans: 'A length of a session can vary from 15 minutes to 30 minutes to 60 minutes.',
  },
];
