import React, { ReactNode, useState } from 'react';
import './Tooltip.css';

type props = {
  children: ReactNode;
  direction?: string;
  content: string;
  triggerTimeInms?: number;
};

/* eslint-disable react/prop-types */
const Tooltip: React.FC<props> = ({
  children,
  direction = 'bottom',
  content,
  triggerTimeInms = 1200,
}) => {
  let timeout: any;
  const [isActive, setIsActive] = useState(false);

  const showTooltip = () => {
    timeout = setTimeout(() => {
      setIsActive(true);
    }, triggerTimeInms);
  };

  const hideTooltip = () => {
    clearInterval(timeout);
    setIsActive(false);
  };

  return (
    <div
      className="Tooltip-Wrapper"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {isActive && <div className={`Tooltip-Tip ${direction}`}>{content}</div>}
    </div>
  );
};

export default Tooltip;
