import React, { useState } from 'react';
import { hlbClient } from '../../../Clients/hlbClient';
import './RequestDemo.css';

type RequestDemoProps = {
  mentorId: string;
  setShowRequestDemo: React.Dispatch<React.SetStateAction<boolean>>;
};

const RequestDemo: React.FC<RequestDemoProps> = ({
  mentorId,
  setShowRequestDemo,
}) => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [contact, setContact] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const passFormData = async () => {
    const response = await hlbClient().post('/api/students/lead', {
      name,
      email,
      phone: contact,
      mentorId,
      description,
    });
    if (response.data) {
      setShowRequestDemo(false);
      setFormSubmitted(true);
      setName('');
      setEmail('');
      setContact('');
      setDescription('');
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> | undefined = (
    e
  ) => {
    e.preventDefault();
    if (name && email && description) {
      passFormData();
    }
  };

  function allFieldsFilled() {
    if (name && email && description) {
      return true;
    }
    return false;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="exampleInputEmail1" className="demo-label">
          Name *
        </label>
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          className="demo-input-field"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="exampleInputPassword1" className="demo-label">
          Email *
        </label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          className="demo-input-field"
          id="exampleInputPassword1"
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="exampleInputPassword1" className="demo-label">
          Mobile (optional)
        </label>
        <input
          value={contact}
          onChange={(e) => setContact(e.target.value.replace(/\D/g, ''))}
          type="tel"
          className="demo-input-field"
          id="exampleInputPassword1"
          maxLength={10}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="exampleInputPassword1" className="demo-label">
          What do you need help with?
        </label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="demo-textarea-field"
          id="exampleInputPassword1"
        />
      </div>

      {formSubmitted ? (
        <div className="text-center alert alert-success" role="alert">
          Details submitted successfully ✅
        </div>
      ) : (
        <div className="col-12 text-center">
          <button
            type="submit"
            className={`btn text-white requestDemo-btn ${
              allFieldsFilled() ? 'active' : 'disabled'
            }`}
          >
            Ask Question
          </button>
        </div>
      )}
    </form>
  );
};

export default RequestDemo;
