import React, { useEffect, useState } from 'react';
import { NoteCard } from '@abhijeet_hyperlearn/hlf_lib';
import './EntityShowcase.css';
import { hlbClient } from '../../../Clients/hlbClient';
import Pagination from '../../BasicComponents/Pagination';
import { useHistory } from 'react-router';
import { X } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import { setFavouriteNotes } from '../../../reducers/userFavouriteNotes.reducer';
import { Favourite } from '../dashboardSections/Dashboard';


interface EntityShowcaseProps {
    title: string;
    entityType: "note" | "webinar" | "session";
    search?: string,
    ids?: string[],
    filter?: any[],
    closeShowcase?: () => void;
    showcaseItems?: any[];
}

const EntityShowcase = ({
    title,
    entityType,
    search = "",
    ids = [],
    filter = [],
    closeShowcase,
    showcaseItems: providedShowcaseItems = [],
}: EntityShowcaseProps) => {
    const [showcaseItems, setShowcaseItems] = useState(providedShowcaseItems);
    const [totalEntities, setTotalEntities] = useState(0);
    const [paginationOffset, setPaginationOffset] = useState(0);
    const history = useHistory();
    const dispatch = useDispatch();
    const userFavNoteIds: string[] = useSelector((state: any) => state.favNoteIds.favouriteNoteIds || []);

    const toggleFavourite = async (entityId: string) => {
        try {
            const resp = await hlbClient().post('/api/favourites', {
                entityType: "note",
                entityId,
            });
            const favList: Favourite[] = resp.data.result.favourites || [];
            const noteList = favList.filter((item) => item.entityType === "note")?.map((item) => item.entityId);
            dispatch(setFavouriteNotes({
                favouriteNoteIds: noteList,
            }));
        } catch (error) {
            console.error("Something went wrong while toggling favourites!", error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resp = await hlbClient().post('/api/entities/getEntities', {
                    type: entityType,
                    offset: paginationOffset,
                    search: [search],
                    ids,
                    filter,
                });
                setShowcaseItems(resp?.data?.data?.entities);
                setTotalEntities(resp?.data?.data?.total);
            } catch {
                console.error("Something went wrong!!");
            }
        }

        providedShowcaseItems.length === 0 && fetchData();
    }, [entityType, paginationOffset, userFavNoteIds]);    
    
    return (
        <div className="entity__showcase">
            <div className="showcase__header">
                <h3 className="showcase__title">
                    {title}
                </h3>
                {
                    closeShowcase && (
                        <X className="showcase__action" size={20} onClick={closeShowcase}/>
                    )
                }
            </div>
            <div className="showcase__container">
                {
                    (entityType === "note" && showcaseItems.length > 0) && 
                        showcaseItems.map((el: any) => (
                            <NoteCard
                                key={el?._id || ""}
                                id={el?._id || ""}
                                image={el?.image?.url || ""}
                                isTrending={false}
                                isFavourite={userFavNoteIds.includes(el?._id) || false}
                                title={el?.name || ""}
                                subscriberCount={el?.subscriberCount || 0}
                                cardCount={el?.cardCount || 0}
                                tags={el?.tags || []}
                                buttonText={'Explore'}
                                profileImage={el?.mentorProfileDetails?.profilePic?.url || el?.mentorImage?.url || ""}
                                description={el?.description || ""}
                                creatorName={el?.mentorDetails?.name || ""}
                                creatorIntro={el?.mentorProfileDetails?.shortBio || ""}
                                handleClicked={() => history.push(`notes/flow-mode-v2/${el?._id}`)}
                                handleFavourite={() => toggleFavourite(el?._id)}
                                cards={el?.previewCards?.map((card: any) => (
                                    <div
                                        key={card?.deckId}
                                        style={{
                                            width: '240px',
                                            height: '385px',
                                            borderRadius: '4px',
                                            boxShadow: '6px 6px 8px 0px rgba(0, 0, 0, 0.05)',
                                            background: 'hsl(260, 65%, 74%)',
                                        }}
                                    >
                                        <p>{card?.questionText}</p>
                                    </div>
                                )) || []}
                            />
                        )
                    )
                }
            </div>
            {
                (showcaseItems.length > 0 && showcaseItems.length < totalEntities) && (
                    <div className="showcase__pagination">
                        <Pagination
                            setOffset={setPaginationOffset}
                            limit={25}
                            totalCount={totalEntities}
                            offset={paginationOffset}
                        />
                    </div>
                )
            }
        </div>
    );
};

export default EntityShowcase;