import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import './cardSwipe.css';
import _ from 'lodash';
import HtmlParser from '../../../BasicComponents/htmlParser';

type props = {
  answer: any;
  question: any;
  queImg?: { file: string };
  ansImg?: { file: string };
  hint?: string;
  NextArrow?: () => void;
  cardId?: string;
  getTime?: (k: string, k1: string) => any;
  getCardIndex?: (k: any) => any;
  PrevArrow?: () => void;
  currentCard?: number;
  totalCard?: number;
  setIsConclusion?: Dispatch<SetStateAction<boolean>>;
  type?: string;
  isWrittingAnswer?: boolean;
};

const Card: React.FC<props> = ({
  answer,
  question,
  queImg = null,
  ansImg = null,
  hint = '',
  NextArrow,
  cardId = '',
  getTime,
  getCardIndex,
  PrevArrow,
  currentCard = 0,
  totalCard = 0,
  setIsConclusion,
  type = '',
  isWrittingAnswer = false,
}) => {
  const [isAnswer, setIsAnswer] = useState(false);
  const [showHint, setShowHint] = useState(false);

  useEffect(() => {
    if (currentCard) {
      setIsAnswer(false);
      setShowHint(false);
    }
  }, [currentCard]);

  useEffect(() => {
    if (type === 'preview only') {
      setIsAnswer(isWrittingAnswer);
    }
  }, [type, isWrittingAnswer]);

  return (
    <div className="mobile__anki--card-container">
      <style>
        {`
      .anki-hard-disabled-btn {
          background: #68686852 !important;
          color: #9b9b9b !important;
          border: 2px solid #9b9b9b !important;
      }
      `}
      </style>
      {!isAnswer ? (
        <div className="">
          {queImg?.file && queImg?.file?.length !== 0 && (
            <div className="mobile__anki--image-Container">
              <img
                src={queImg?.file}
                alt=""
                className="mobile__anki--card-container-image"
              />
            </div>
          )}
          <p className="mobile__anki--paragraph">
            <HtmlParser text={question} />
          </p>
          {hint && (
            <div
              role="presentation"
              className="mobile__anki--hint-container"
              onClick={() => {
                setShowHint(true);
                getTime?.('SHOW_HINT_CLICKED', cardId);
              }}
            >
              <p className="mobile__anki--hint-paragraph">
                {showHint ? hint : 'Click here to see hint!'}
              </p>
            </div>
          )}
          <div className="mobile__anki--btn-container">
            <button
              disabled={type === 'preview only'}
              type="button"
              className="mobile__anki--btn-purple"
              onClick={() => {
                setIsAnswer(!isAnswer);
                getTime?.('SHOW_ANSWER', cardId);
              }}
            >
              Show Answer
            </button>
          </div>
        </div>
      ) : (
        <div className="">
          {ansImg?.file && ansImg?.file?.length !== 0 && (
            <div className="mobile__anki--image-Container">
              <img
                src={ansImg?.file}
                alt=""
                className="mobile__anki--card-container-image"
              />
            </div>
          )}
          <p className="mobile__anki--paragraph">
            <HtmlParser text={answer} />
          </p>
          <div className="mobile__anki--btn-container">
            {type !== 'preview only' ? (
              <button
                type="button"
                className={
                  currentCard < 2
                    ? 'mobile__anki--btn-hard anki-hard-disabled-btn'
                    : 'mobile__anki--btn-hard'
                }
                disabled={currentCard < 2}
                onClick={() => {
                  PrevArrow?.();
                  getTime?.('PREVIOUS_BUTTON_CLICKED', cardId);
                  getCardIndex?.((prev: any) => prev + 1);
                }}
              >
                Previous
              </button>
            ) : (
              <button
                type="button"
                className={
                  currentCard < 2
                    ? 'mobile__anki--btn-hard anki-hard-disabled-btn'
                    : 'mobile__anki--btn-hard'
                }
                disabled={type === 'preview only'}
              >
                Previous
              </button>
            )}
            {currentCard !== totalCard ? (
              <button
                disabled={type === 'preview only'}
                type="button"
                className="mobile__anki--btn-easy"
                onClick={() => {
                  NextArrow?.();
                  getTime?.('NEXT_BUTTON_CLICKED', cardId);
                  getCardIndex?.((prev: any) => prev + 1);
                }}
              >
                Next
              </button>
            ) : (
              <button
                disabled={type === 'preview only'}
                type="button"
                className="mobile__anki--btn-easy"
                onClick={() => {
                  setIsConclusion?.(true);
                  getTime?.('DONE_BUTTON_CLICKED', cardId);
                }}
              >
                Done
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
