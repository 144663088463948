/* eslint-disable react/jsx-key */
import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SocialMedia from '../BasicComponents/socialMedia';
import { tick, edit, plus, info } from '../BasicComponents/icons';
import SessionCard from '../BasicComponents/sessionCard';
import CheckListCard from '../BasicComponents/checkListCard';
import { hlbClient } from '../../Clients/hlbClient';
import './mentorProfileEdit.css';
import MentorDetailForm from '../MentorDetailForm/MentorDetailForm';
import VideoCard from '../BasicComponents/videoCard/VideoCard';
import FeaturedWorkSlider from '../BasicComponents/featuredWorkSlider';
import { AuthContext } from '../../provider/AuthContext';
import FirstSession from '../firstSession/firstSession';
import VideoUploader from '../BasicComponents/videoUploader/videoUploader';
import NavbarContainer from '../BasicComponents/navbar';
import ToastMessage from '../BasicComponents/toastMessage';
import HtmlParser from '../BasicComponents/htmlParser';
import WhatsappPlugin from '../BasicComponents/WhatsappPlugin';
import Tooltip from '../BasicComponents/Tooltip';
import MyDeck from '../hyperNotes/manage/myDeck';
import AnnouncementPopUp from '../BasicComponents/announcementPopUp';
import AnnouncementCard from '../BasicComponents/announcementCard';

function mentorProfileEdit() {
  const [data, setData] = useState<any>([]);
  const [expand, setExpand] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [editShow, setEditShow] = useState<boolean>(false);
  const [showMoreCards, setShowMoreCards] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(0);
  const [isReadMore, setIsReadMore] = useState<boolean>(true);
  const [modalType, setModalType] = useState<string>('');
  const [popUpHeading, setPopUpHeading] = useState<string>('');
  const { authState, setStateUpdate, stateUpdate } = useContext(AuthContext);
  const [decks, getDecks] = useState<any>([]);
  // eslint-disable-next-line no-undef
  const token: string | null = localStorage.getItem('token');

  useEffect(() => {
    if (token) {
      setStateUpdate(!stateUpdate);
    }
  }, []);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const updateDimensions = () => {
    // eslint-disable-next-line no-undef
    setWidth(window.innerWidth);
  };
  const fullScreen = true;
  function seeMore() {
    setExpand(!expand);
    setShowMoreCards(!showMoreCards);
  }
  const id = authState._id;
  const getMyDeck = async (mentorId: string) => {
    try {
      const resp = await hlbClient().get(`/api/srs/mentor/${mentorId}/decks`);
      if (resp.data.success) {
        getDecks(resp.data.result);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');
    }
  };
  useEffect(() => {
    if (data?.user) {
      getMyDeck(data?.user);
    }
  }, [data?.user]);
  useEffect(() => {
    async function fetchData() {
      try {
        const resp = await hlbClient().get(`/api/users/${id}/profile`);
        setData(resp.data[0]);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('oops something went wrong');
        /* eslint-disable no-undef */
        // window.localStorage.setItem('hlUserId', null);
        // window.localStorage.setItem('hlUserType', '');
      }
    }
    fetchData();
  }, [`${id}`]);
  const apiCall = async () => {
    try {
      const resp = await hlbClient().get(`/api/users/${id}/profile`);
      setData(resp.data[0]);
      setEditShow(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');

      /* eslint-disable no-undef */
      // window.localStorage.setItem('hlUserId', null);
      // window.localStorage.setItem('hlUserType', '');
    }
  };
  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateDimensions);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);
  const announcementData = {
    description: _.get(data, 'announcement', ''),
    link: _.get(data, 'announcementCTA', ''),
    entityType: 'editPage',
    announcementEnabled: _.get(data, 'announcementEnabled', ''),
  };
  const callProfileEditModal = () => {
    setEditShow(!editShow);
  };
  const expYears = _.get(data, 'expInYears', null) || null;

  const [newOranigaztion, setNewOrganization] = useState([]);
  useEffect(() => {
    let getdata: any = [];
    if (data.organisations) {
      const img = new Image();
      // eslint-disable-next-line array-callback-return
      data.organisations.map((list: any) => {
        img.src = `${list.url}`;
        const imgHeight = img.naturalHeight;
        const imgWidth = img.naturalWidth;
        const aspectRatio = imgWidth / imgHeight;
        const newData = { ...list, aspectRatio };

        getdata = [...getdata, { ...newData }];
      });
    }
    setNewOrganization(getdata);
  }, [data.organisations]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <NavbarContainer />
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="row d-flex justify-content-center-profile">
          <div className="spacing" />
          <div className="col-5 col-md-3 col-lg-3 col-xl-3 col-xxl-3 profile-pic-on-mobile">
            <div className="row g-0 justify-content-end">
              <div className="fixed-Right-section col-md-3">
                <div className="scroll-right-section__v2">
                  <div className="mentor-profile-box d-flex">
                    <img
                      src={_.get(data, 'profilePic.url', '')}
                      alt=""
                      className="mentor-proile-pic"
                    />
                  </div>
                  <div className="icon-box hide-on-small-screen">
                    <SocialMedia
                      apiCall={apiCall}
                      socials={_.get(data, 'socials', '')}
                      entityType="editPage"
                    />
                    <Link
                      className="view__public__profile--button btn mt-3"
                      to={`/mentor/${id}`}
                    >
                      View Public Profile
                    </Link>
                  </div>
                  <div className="divider-bar" />
                  <div className="skills-box d-flex hide-on-small-screen">
                    <p className="paragraph__semi-bold hide-on-small-screen mt-1">
                      Core Skills
                      <img
                        onClick={() => {
                          setEditShow(true);
                          setModalType('editProfile');
                          setPopUpHeading('Edit your profile');
                        }}
                        onKeyPress={() => {
                          setEditShow(true);
                        }}
                        role="presentation"
                        src={edit}
                        alt=""
                        className="float-end"
                        style={{ marginLeft: '20px' }}
                      />
                    </p>
                  </div>
                  <div className="button-box d-flex flex-wrap hide-on-small-screen mt-1">
                    {data.skills &&
                      _.get(data, 'skills', '').map((skill: any) => (
                        <span className="skills-button hide-on-small-screen">
                          {skill}
                        </span>
                      ))}
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="skills-box d-flex hide-on-small-screenmt-4">
                        <p className="paragraph__semi-bold hide-on-small-screen">
                          Organisations
                          <img
                            onClick={() => {
                              setEditShow(true);
                              setModalType('editProfile');
                              setPopUpHeading('Edit your profile');
                            }}
                            onKeyPress={() => {
                              setEditShow(true);
                            }}
                            role="presentation"
                            src={edit}
                            alt=""
                            className="float-end"
                            style={{ marginLeft: '20px' }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="icon-company-box hide-on-small-screen mt-1 mb-3">
                    {newOranigaztion &&
                      newOranigaztion.map((organization: any) => (
                        <img
                          src={_.get(organization, 'url', '')}
                          alt=""
                          className={
                            organization.aspectRatio === 1
                              ? 'icon-company-img'
                              : 'icon-company-img-rect'
                          }
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-7 hide-on-big-screen">
            <h4 className="mentor-name">
              {_.get(data, 'userName', '')} <img src={tick} alt="tic" />
              <img
                onClick={() => {
                  setEditShow(true);
                  setModalType('editProfile');
                  setPopUpHeading('Edit your profile');
                }}
                onKeyPress={() => {
                  setEditShow(true);
                }}
                role="presentation"
                src={edit}
                alt=""
                className="float-end"
                style={{ marginRight: '-25px' }}
              />
            </h4>
            <p style={{ color: '#444444' }}>{_.get(data, 'shortBio', '')}</p>
            {/* <div className="hide-on-big-screen">
              <MentorRating rating={_.get(data, 'rating', '0.1')} />
              {' '}
              {parseFloat(_.get(data, 'rating', '0.1')).toFixed(1)}
            </div> */}
            <div className="icon-box">
              <SocialMedia
                apiCall={apiCall}
                socials={_.get(data, 'socials', '')}
                entityType="editPage"
              />
            </div>
          </div>
          <div className="col-md-4 col-4 hide-on-big-screen ">
            <p
              className="paragraph"
              style={{ color: '#8C8C8C', fontWeight: 500 }}
            >
              Experience
            </p>
            <p className="paragraph">
              {expYears > 10 ? '10+' : expYears}{' '}
              {expYears <= 1 ? 'year' : 'years'}
            </p>
          </div>
          <div className="col-md-6 col-6 hide-on-big-screen">
            <p
              className="paragraph"
              style={{ color: '#8C8C8C', fontWeight: 500 }}
            >
              Languages
            </p>
            <p className="paragraph">
              {data.languages &&
                _.get(data, 'languages', '').map(
                  (language: any, index: number) => (
                    <span>{(index ? ', ' : '') + language}</span>
                  )
                )}
            </p>
          </div>
          <div className=" col-12 hide-on-big-screen">
            <div className="skills-box hide-on-big-screen">
              <p className="paragraph__semi-bold hide-on-big-screen">
                Core Skills
                <img
                  onClick={() => {
                    setEditShow(true);
                    setModalType('editProfile');
                    setPopUpHeading('Edit your profile');
                  }}
                  onKeyPress={() => {
                    setEditShow(true);
                  }}
                  role="presentation"
                  src={edit}
                  alt=""
                  className="float-end"
                  style={{ marginRight: '60px' }}
                />
              </p>
            </div>
            <div className="button-box d-flex flex-wrap justify-content-center-profile hide-on-big-screen mt-1">
              {data.skills &&
                _.get(data, 'skills', '').map((skill: any) => (
                  <span className="skills-button hide-on-big-screen">
                    {skill}
                  </span>
                ))}
            </div>
            <div
              className="skills-box d-flex hide-on-big-screen mt-3"
              style={{ marginLeft: '0px' }}
            >
              <p className="paragraph__semi-bold hide-on-big-screen">
                Organisations
                <img
                  onClick={() => {
                    setEditShow(true);
                    setModalType('editProfile');
                    setPopUpHeading('Edit your profile');
                  }}
                  onKeyPress={() => {
                    setEditShow(true);
                  }}
                  role="presentation"
                  src={edit}
                  alt=""
                  className="float-end"
                  style={{ marginLeft: '20px' }}
                />
              </p>
            </div>
            <div
              className="icon-company-box hide-on-big-screen mt-3"
              style={{ marginLeft: '0px' }}
            >
              {newOranigaztion &&
                newOranigaztion.map((organization: any) => (
                  <img
                    src={_.get(organization, 'url', '')}
                    alt=""
                    className={
                      organization.aspectRatio === 1
                        ? 'icon-company-img'
                        : 'icon-company-img-rect'
                    }
                  />
                ))}
            </div>
            <a
              href="#session"
              className="btn purple-outline-btn mt-3"
              style={{ width: '100%', padding: '15px' }}
            >
              Select Session
            </a>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-5">
            <div className="row">
              <div className="col-md-9 col-8 hide-on-small-screen">
                <h4 className="mentor-name">
                  {_.get(data, 'userName', '')}{' '}
                  {_.get(data, 'verified', '') === true && (
                    <img src={tick} alt="tic" />
                  )}
                </h4>
                <p style={{ color: '#444444' }}>
                  {_.get(data, 'shortBio', '')}
                </p>
                <div className="row">
                  <div className="col-md-4 col-lg-4">
                    <p
                      className="paragraph"
                      style={{ color: '#8C8C8C', fontWeight: 500 }}
                    >
                      Experience
                    </p>
                    <p className="paragraph">
                      {expYears > 10 ? '10+' : expYears}{' '}
                      {expYears <= 1 ? 'year' : 'years'}
                    </p>
                  </div>
                  <div className="col-md-8 col-lg-8">
                    <p
                      className="paragraph"
                      style={{ color: '#8C8C8C', fontWeight: 500 }}
                    >
                      Languages
                    </p>
                    <p className="paragraph">
                      {data.languages &&
                        _.get(data, 'languages', '').map(
                          (language: any, index: number) => (
                            <span>{(index ? ', ' : '') + language}</span>
                          )
                        )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 hide-on-small-screen">
                {/* <MentorRating rating={_.get(data, 'rating', '0.1')} />
                {' '}
                {parseFloat(_.get(data, 'rating', '')).toFixed(1)} */}
                <img
                  onClick={() => {
                    setEditShow(true);
                    setModalType('editProfile');
                    setPopUpHeading('Edit your profile');
                  }}
                  onKeyPress={() => {
                    setEditShow(true);
                  }}
                  role="presentation"
                  src={edit}
                  alt=""
                  className="float-end"
                />
              </div>
            </div>
            <p className="paragraph mt-4">
              {isReadMore ? (
                <HtmlParser
                  text={_.get(data, 'description', '').slice(0, 200)}
                />
              ) : (
                <HtmlParser text={_.get(data, 'description', '')} />
              )}
              {_.get(data, 'description', '').length >= 150 && (
                <button
                  type="button"
                  onClick={toggleReadMore}
                  className="show-hide-text"
                >
                  {' '}
                  {isReadMore ? 'read more' : ' show less'}
                </button>
              )}
            </p>
            <div className="profile__edit-addsession mt-3">
              <p className="profile__edit-addsession--text">My Introduction</p>
              <VideoUploader
                data={_.get(data, 'introVideo', '')}
                apiCall={apiCall}
              />
            </div>
            <VideoCard
              data={_.get(data, 'introVideo', '')}
              entityType="editPage"
            />
            {decks?.length !== 0 && (
              <>
                <p className="paragraph mt-5" style={{ marginBottom: '-35px' }}>
                  My Notes{' '}
                  <Tooltip
                    content="Get a quick revision, with flashcards inside the note."
                    direction="top"
                  >
                    <img src={info} alt="info" />
                  </Tooltip>
                </p>
                <MyDeck
                  data={decks}
                  entityType="mentorProfile"
                />
              </>
            )}
            <FeaturedWorkSlider
              featured={_.get(data, 'featured', '')}
              entityType="editPage"
              apiCall={apiCall}
            />
          </div>
          <div className="col-md-3 col-lg-3" id="session">
            <div className="edit__right-side--width-control">
              <div className="profile__edit-status">
                <p className="profile__edit-status--text">Profile Checklist</p>
                <p className="profile__edit-status--percentage">
                  {_.get(data, 'percentage', 0).toFixed(1) * 100}
                  {''}%
                </p>
              </div>
              <div className="mt-2 mb-2">
                <CheckListCard
                  // callProfileEditModal={callProfileEditModal}
                  checkList={_.get(data, 'checkList', '')}
                  setEditShow={setEditShow}
                  setModalType={setModalType}
                  setPopUpHeading={setPopUpHeading}
                  // apiCall={apiCall}
                />
              </div>
              <div className="profile__edit-addsession mt-3">
                <p className="profile__edit-addsession--text">
                  Enlisted Sessions
                </p>
                <img
                  onClick={() => {
                    setEditShow(true);
                    setModalType('addSession');
                    setPopUpHeading('Create your mentoring session');
                  }}
                  onKeyPress={() => {
                    setEditShow(true);
                  }}
                  role="presentation"
                  src={plus}
                  alt="add session"
                  className="profile__edit-addsession--edit"
                />
              </div>
              <div
                className={`centerlized-card ${
                  expand === true
                    ? 'scroll-card-see-more-desktop'
                    : 'scroll-card'
                } `}
              >
                <div className="">
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {expand === true ? (
                    width <= 414 ? (
                      <SessionCard
                        apiCall={apiCall}
                        data={_.get(data, 'sessionDetails', '').slice(0, 2)}
                        // showMore={showMoreCards}
                      />
                    ) : (
                      // eslint-disable-next-line max-len
                      <SessionCard
                        apiCall={apiCall}
                        data={data.sessionDetails}
                        // showMore={showMoreCards}
                      />
                    )
                  ) : (
                    <SessionCard
                      apiCall={apiCall}
                      data={_.get(data, 'sessionDetails', '').slice(0, 2)}
                      // showMore={showMoreCards}
                    />
                  )}
                  <button
                    type="button"
                    onClick={() => {
                      setShow(true);
                    }}
                    className=" btn btn-outline-green hide-on-big-screen mt-2 mb-2"
                  >
                    View all session
                  </button>
                  {_.get(data, 'sessionDetails', '').length >= 2 && (
                    <>
                      {!expand && (
                        <button
                          type="button"
                          onClick={seeMore}
                          className=" btn btn-outline-green hide-on-small-screen mt-2 mb-2"
                        >
                          View all session
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="profile__edit-addsession mt-3">
                <p className="profile__edit-addsession--text">Announcement</p>
                <AnnouncementPopUp
                  apiCall={apiCall}
                  isValue={announcementData?.announcementEnabled}
                />
              </div>
              <AnnouncementCard data={announcementData} />
              <div className="mb-4" />
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} fullscreen={fullScreen} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>Select your session</Modal.Title>
          <button
            type="button"
            onClick={() => setShow(false)}
            className="btn-close float-end"
          />
        </Modal.Header>
        <Modal.Body>
          <div className="centerlized-card scroll-card-see-more">
            <div className="card-bg">
              <SessionCard
                apiCall={apiCall}
                data={data.sessionDetails}
                // showMore={showMoreCards}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* edit profile modal */}
      <Modal
        show={editShow}
        size={modalType === 'editProfile' ? 'lg' : 'sm'}
        centered
        onHide={() => setShow(false)}
      >
        <Modal.Header style={{ paddingBottom: '0px' }}>
          <Modal.Title style={{ fontWeight: 500, fontSize: '28px' }}>
            {popUpHeading}
          </Modal.Title>
          <button
            type="button"
            onClick={() => setEditShow(false)}
            className="btn-close float-end"
          />
        </Modal.Header>
        <Modal.Body style={{ paddingTop: '0px' }}>
          {modalType === 'addSession' && (
            <>
              <FirstSession entityType="editPage" apiCall={apiCall} />
            </>
          )}
          {modalType === 'addAnnouncement' && (
            <>
              <AnnouncementPopUp apiCall={apiCall} />
            </>
          )}
          {modalType === 'editProfile' && (
            <>
              <MentorDetailForm
                entityType="editPage"
                data={data}
                // setEditShow={!editShow}
                apiCall={apiCall}
              />
            </>
          )}
        </Modal.Body>
      </Modal>
      <ToastMessage />
      <WhatsappPlugin />
    </>
  );
}

export default mentorProfileEdit;
