import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './cardSwipe.css';
import { truncate } from 'lodash';
import Card from './card';
import { XCircle, leftArrow, rightArrow } from '../../../BasicComponents/icons';
import ExitModal from '../../common/exitModal';
import SingleChoiceCard from './singleChoiceCard';
import ConfirmPopUp from './confirmPopUp';
import MultipleChoiceCard from './multipleChoiceCard';
import TypeAnswerCard from './typeAnswerCard';

const PrevArrow = ({ className, style, onClick }: { className: any, style: any, onClick: any }) => (
  <div
    role="presentation"
    className={className}
    style={{ ...style }}
    onClick={onClick}
  >
    <img src={leftArrow} alt="left arrow" className="slick-arrow slick-prev" />
  </div>
);

const NextArrow = ({ className, style, onClick }: { className: any, style: any, onClick: any }) => (
  <div
    role="presentation"
    className={className}
    style={{ ...style }}
    onClick={onClick}
  >
    <img src={rightArrow} alt="left arrow" className="slick-arrow slick-next" />
  </div>
);
type props = {
  data: {
    answerText?: any,
    questionText?: any,
    questionImage?: { file: string; url: string; name: string },
    answerImage?: { file: string; url: string; name: string },
    hintText?: string,
    questionOption?: any[],
    cardType?: string,
    _id?: string,
  }[],
  name: string,
  innHeight: number,
  getTime: (k1: string, k2: string) => any,
  getCardIndex: Dispatch<SetStateAction<number>>,
  setIsConclusion: Dispatch<SetStateAction<boolean>>,
  noteId: string,
}
const CardContainer: React.FC<props> = ({
  data = [], name, innHeight, getTime, getCardIndex, setIsConclusion, noteId,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [exitModal, toggleExitModal] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [singleChoiceData, getSingleChoiceData] = useState({});
  const [correctAnswer, getCorrectAnswer] = useState('');
  const [conclusionType, setConclusionType] = useState('single');
  const [isMultiCorrect, setIsMultiFalse] = useState(false);
  const [userAnswer, setUserAnswer] = useState('');

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    nextArrow: <NextArrow className={undefined} style={undefined} onClick={undefined} />,
    prevArrow: <PrevArrow className={undefined} style={undefined} onClick={undefined} />,
  };
  const slider = useRef<any>();
  const next = () => {
    slider.current.slickNext();
    // eslint-disable-next-line no-unused-expressions
    (currentPage < data?.length) && setCurrentPage(currentPage + 1);
  };

  const prev = () => {
    slider.current.slickPrev();
    // eslint-disable-next-line no-unused-expressions, react/prop-types
    setCurrentPage(currentPage - 1);
  };

  const exitFlow = () => {
    toggleExitModal(!exitModal);
  };

  const getCards = (card: any) => (
    card?.cardType === 'writeAnswer' ? (
      <TypeAnswerCard
        queImg={card?.questionImage}
        questionOption={card?.questionOption}
        questionText={card?.questionText}
        getSingleChoiceData={getSingleChoiceData}
        setOpen={setOpen}
        getCorrectAnswer={getCorrectAnswer}
        setConclusionType={setConclusionType}
        answer={card?.answerText}
        setUserAnswer={setUserAnswer}
      />
    ) : (
      <>
        {card?.questionOption.filter((option: any) => option && option?.isCorrect).length === 1
          ? (
            <SingleChoiceCard
              queImg={card?.questionImage}
              questionOption={card?.questionOption}
              questionText={card?.questionText}
              getSingleChoiceData={getSingleChoiceData}
              setOpen={setOpen}
              getCorrectAnswer={getCorrectAnswer}
              setConclusionType={setConclusionType}
              isSingleChoice={singleChoiceData}
            />
          ) : (
            <MultipleChoiceCard
              queImg={card?.questionImage}
              questionOption={card?.questionOption}
              questionText={card?.questionText}
              getSingleChoiceData={getSingleChoiceData}
              setOpen={setOpen}
              getCorrectAnswer={getCorrectAnswer}
              setConclusionType={setConclusionType}
              setIsMultiFalse={setIsMultiFalse}
              isMultiCorrect={isMultiCorrect}
            />
          )}
      </>
    )
  );

  return (
    <div className="mobile__anki--view-container">
      <style>
        {`
          .mobile__anki--card-container {
            height: ${innHeight - 77}px
          }
        `}
      </style>
      <div className="mobile__anki--header">
        <div>
          {truncate(name, { length: 30 })}
        </div>
        <p style={{ color: '#8C52FF' }}>
          {currentPage}
          /
          {/* eslint-disable-next-line react/prop-types */}
          {data?.length}
          <span style={{ color: '#3b3b3b', marginLeft: '10px' }}>
            <XCircle size={28} onClick={() => exitFlow()} />
          </span>
        </p>
      </div>
      <Slider
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...settings}
        // eslint-disable-next-line no-return-assign
        ref={(c: any) => (slider.current = c)}
      >
        {data && data?.map((card) => (
          <>
            {card?.cardType === 'review' ? (
              <Card
                answer={card?.answerText}
                question={card?.questionText}
                queImg={card?.questionImage}
                ansImg={card?.answerImage}
                hint={card?.hintText}
                NextArrow={next}
                PrevArrow={prev}
                cardId={card?._id}
                getTime={getTime}
                getCardIndex={getCardIndex}
                currentCard={currentPage}
                totalCard={data?.length}
                setIsConclusion={setIsConclusion}
                type=''
              // getSingleChoiceData={getSingleChoiceData}
              // setOpen={setOpen}
              // getCorrectAnswer={getCorrectAnswer}
              />
            ) : (
              getCards(card)
            )}
          </>
        ))}
      </Slider>
      {open && (
        <ConfirmPopUp {...{
          open,
          setOpen,
          singleChoiceData,
          correctAnswer,
          next,
          currentCard: currentPage,
          totalCard: data?.length,
          setIsConclusion,
          conclusionType,
          isMultiCorrect,
          userAnswer,
        }}
        />
      )}
      {exitModal && (
        <ExitModal {...{
          exitModal,
          toggleExitModal,
          // noteId, 
          setIsConclusion,
        }}
        />
      )}
    </div>
  );
}

export default CardContainer;
