// multiple chocie card

import React, { useState } from "react";
import HtmlParser from "../../../BasicComponents/htmlParser";
import _ from "lodash";
import { MCQCardV2, OptionTypeV2 } from "../../types";
import { handleBase64ToImg } from "../../../Utils/cardUtils";

const MultipleChoiceV2Card: React.FC<MCQCardV2> = ({
  description, medias, options,
  verifyAnswer, id, reason, disableInteraction = false, done,
}) => {

  const [background, setBackground] =  useState<{background: string, color: string }>({background: '', color: ''});

  const [selectedOption, setSelectedOption] = useState<OptionTypeV2[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number[]>([])

  const wrongAnswerColor = {
    background: 'red',
    color: '#fff',
  };

  const correctAnswerColor = {
    background: 'green',
    color: '#fff',
  }

  const pickedOption = (index: number) => {
    setSelectedIndex((prev) => [...prev, index])
  };

  const removeOption = (index: number) => {
      const newArray = selectedIndex.filter((id: number) => id !== index);
      setSelectedIndex(newArray);
  };

  const selectOption = (option: OptionTypeV2) => {
    setSelectedOption([...selectedOption, option])
  }

  const checkIfAllOptionsAreCorrect = () => {
    // const questionCorrect = options.filter(
    //   (x: OptionType) => x && x.isCorrect
    // );
    // const answerCorrect = SelectedOption.filter(
    //   (x: OptionType) => x && x.isCorrect
    // );

    const result= verifyAnswer('multiplechoicev2', { selectedOption, options });

    if (result) {
      setBackground(correctAnswerColor);
    } else {
      setBackground(wrongAnswerColor);
    }

    done('mcq', result);
  };

  return  (   // eslint-disable-next-line react/jsx-filename-extension
  <div className="mobile__anki--card-container">
    <style>
      {`
      .multiple-option--done-btn {
        position: relative;
        bottom: 0;
        left: 0;
        transform: none;
      }
      `}
    </style>
    <div className="">
      <p className="mobile__anki--paragraph">
        <HtmlParser text={handleBase64ToImg(description, medias)} />
      </p>
      {options &&
        options.map((item: OptionTypeV2, index: number) => (
                  <div
                    role="presentation"
                    className={
                      !selectedIndex.includes(index)
                        ? 'single-option-dev'
                        : 'single-option-dev-green'
                    }
                    style={
                      selectedIndex.includes(index)
                        ? background
                        : {}
                    }
                    key={index.toString()}
                    onClick={() => {
                      if(!disableInteraction) {
                        if (!(selectedIndex.includes(index))) {
                          pickedOption(index);
                          selectOption(item);
                        } else {
                          removeOption(index);
                        }
                      }

                    }}
                  >
                    <p className="single-option-dev--text">
                      {item.text}
                    </p>
                  </div>
            ))}
        <button
          className="multiple-option--done-btn"
          type="button"
          onClick={() => {
            if (!disableInteraction){
              checkIfAllOptionsAreCorrect();
            }
          }}
        >
          Done
        </button>
    </div>
  </div>
);
}

export default MultipleChoiceV2Card;