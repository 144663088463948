/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import './sessionCard.css';
import {
  edit, deleteIcon, clock,
} from '../icons';
import { hlbClient } from '../../../Clients/hlbClient';
import FirstSession from '../../firstSession/firstSession';
import { addToast } from '../../../reducers/toast.reducer';

function SessionCard({ data, apiCall }) {
  const [sessionId, setSessionId] = useState('');
  const [sessionData, setSessionData] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);

  const addSessionId = (id) => {
    setSessionId(id);
  };
  const dispatch = useDispatch();
  const sessionDelete = async () => {
    try {
      await hlbClient().delete(`/api/scheduler/session/${sessionId}`);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');
    }
    dispatch(addToast({ type: 'success', message: 'Session Deleted Successfully' }));
    apiCall();
    setShowDelete(false);
  };
  const closeEditSession = () => {
    setShow(false);
  };
  if (data) {
    return (
      <>
        {
      data && data.map((sessionEdit) => (
        <div className="session-edit-card__container mb-4">
          <div className="session-edit-card">
            <p className="session-edit-card--sessiontype">{_.get(sessionEdit, 'sessionType', '')}</p>
            <div className="session__edit__card__buttons">
              <button onClick={() => { setShowDelete(true); addSessionId(_.get(sessionEdit, '_id', '')); }} type="button" className="session__edit__card__buttons--button"><img src={deleteIcon} alt="" /></button>
              <button onClick={() => { setShow(true); addSessionId(_.get(sessionEdit, '_id', '')); setSessionData(sessionEdit); }} type="button" className="session__edit__card__buttons--button"><img src={edit} alt="" /></button>
            </div>
          </div>
          <div className="sessionCardEdit__body">
            <p className="sessionCardEdit__body--sessionName">{_.get(sessionEdit, 'name', '')}</p>
            <p>
              {
                _.get(sessionEdit, 'isFree', false) ? (
                  <span className="sessionCardEdit__body--price">
                    Free
                  </span>
                ) : (
                  <>
                    {
                      _.get(sessionEdit, 'discountPrice') && _.get(sessionEdit, 'price') ? (
                        <>
                          <span className="sessionCardEdit__body--price">
                            ₹
                            {' '}
                            {
                              _.get(sessionEdit, 'discountPrice', 0) ? _.get(sessionEdit, 'discountPrice', 0) : _.get(sessionEdit, 'price', 0)
                            }
                            {/* {_.get(sessionEdit, 'discountPrice', '')} */}
                          </span>
                          <span className="sessionCardEdit__body--off mx-4">
                            {' '}
                            ₹
                            {' '}
                            {_.get(sessionEdit, 'price', 0)}
                          </span>
                        </>
                      ) : (
                        <span className="sessionCardEdit__body--price">
                          {' '}
                          ₹
                          {' '}
                          {_.get(sessionEdit, 'price', 0)}
                        </span>
                      )
                    }
                  </>
                )
              }
            </p>
            <p className="sessionCardEdit__body--duration">
              <img src={clock} alt="clock" style={{ marginRight: '15px' }} />
              {_.get(sessionEdit, 'duration', '')}
              {' '}
              minutes
            </p>
            <p className="sessionCardEdit__body--paragraph" style={{ fontSize: '14px' }}>{_.get(sessionEdit, 'description', '')}</p>
          </div>
        </div>
      ))
}
        <Modal show={show} size="md" centered onHide={() => setShow(false)}>
          <Modal.Header style={{ paddingBottom: '0px' }}>
            <Modal.Title>Edit session</Modal.Title>
            <button
              type="button"
              onClick={() => setShow(false)}
              className="btn-close float-end"
            />
          </Modal.Header>
          <Modal.Body style={{ paddingTop: '0px' }}>
            <FirstSession entityType="editPage" compType="sessionCard" sessionData={sessionData} sessionId={sessionId} apiCall={apiCall} closeEditSession={closeEditSession} />
          </Modal.Body>
        </Modal>
        {/* delete session confirmation */}
        <Modal show={showDelete} centered onHide={() => setShowDelete(false)} className="custom-modal-style">
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <button
                  type="button"
                  onClick={() => setShowDelete(false)}
                  className="btn-close float-end"
                />
              </div>
            </div>
            <div className="col-12 text-center">
              <p className="sessionCard__deleteMessage">Are you sure you want to delete?</p>
              <p className="sessionCard__deleteMessage-sub-text">After deleting the session you will not be able to get it back.</p>
              <button type="button" className=" btn sessionCard__cancel-btn" onClick={() => setShowDelete(false)}>Cancel</button>
              <button type="button" className="btn sessionCard__delete-btn" onClick={() => { sessionDelete(); }}>Delete</button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  return (
    <>
      {
  data && data.map((sessionEdit) => (
    <div className="session-edit-card__container mb-4">
      <div className="session-edit-card">
        <p className="session-edit-card--sessiontype">{_.get(sessionEdit, 'sessionType', '')}</p>
        <div className="session__edit__card__buttons">
          <button type="button" className="session__edit__card__buttons--button"><img src={deleteIcon} alt="" /></button>
          <button type="button" className="session__edit__card__buttons--button"><img src={edit} alt="" /></button>
        </div>
      </div>
      <div className="sessionCardEdit__body">
        <p className="sessionCardEdit__body--sessionName">{_.get(sessionEdit, 'sessionName', '')}</p>
        <p>
          <span className="sessionCardEdit__body--price">
            ₹
            {' '}
            {_.get(sessionEdit, 'price', '')}
          </span>
          {' '}
          <span className="sessionCardEdit__body--off mx-4">
            ₹
            {' '}
            {_.get(sessionEdit, 'markedPrice', '600')}
          </span>
        </p>
        <p className="sessionCardEdit__body--duration">
          <img src={clock} alt="clock" style={{ marginRight: '15px' }} />
          {_.get(sessionEdit, 'duration', '')}
          {' '}
          minutes
        </p>
        <p className="sessionCardEdit__body--paragraph" style={{ fontSize: '14px' }}>{_.get(sessionEdit, 'description', '')}</p>
      </div>
    </div>
  ))
}
    </>
  );
}
SessionCard.propTypes = {
  data: PropTypes.arrayOf,
  apiCall: PropTypes.func,
};
SessionCard.defaultProps = {
  apiCall: '',
  data: [],
};
export default SessionCard;
