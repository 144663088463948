import React, { useState } from 'react';
import HtmlParser from '../../../BasicComponents/htmlParser';
import { ReviewCardV2 as RCV2 } from '../../types';
import { handleBase64ToImg } from '../../../Utils/cardUtils';

const ReviewV2Card: React.FC<RCV2> = ({
  id,
  cardType,
  disableInteraction,
  description,
  medias,
  answerDescription,
  cardFace = 'question_side',
  reason = '',
  index,
  totalCards,
  previous,
  next,
  done,
}) => {
  const [cardFaceState, setCardFaceState] = useState(cardFace);

  return (
    <>
      <div className="card__body">
        {cardFaceState === 'question_side' && (
          <HtmlParser text={handleBase64ToImg(description, medias)} />
        )}
        {cardFaceState === 'answer_side' && (
          <>
            <p style={{ fontWeight: 600 }}>Answer :</p>
            <HtmlParser text={handleBase64ToImg(answerDescription, medias)} />
          </>
        )}
      </div>
      <div className="card__extras">
        {cardFaceState === 'question_side' && (
          <div className="card__btn-grp">
            <button
              className="card__btn-primary"
              onClick={() => setCardFaceState('answer_side')}
            >
              Show Answer
            </button>
          </div>
        )}

        {cardFaceState === 'answer_side' && (
          <div className="card__btn-grp">
            <button
              className="card__btn-secondary"
              onClick={() => setCardFaceState('question_side')}
            >
              Show Question
            </button>
            <button
              className="card__btn-primary"
              disabled={disableInteraction}
              onClick={() => {
                done('review', null);
              }}
            >
              Done
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ReviewV2Card;
