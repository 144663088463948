import React, { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './editor.css';

import ImageResize from 'quill-image-resize-module-react';

Quill.register('modules/imageResize', ImageResize);

type Props = {
  editorText: string;
  onTextChangeHandler: (k: any) => any;
};

const Editor: React.FC<Props> = ({ editorText, onTextChangeHandler }) => {
  const quillRef: any = useRef();
  const modules = {
    syntax: true,
    toolbar: [
      [{ header: [2, 3, 4, 5, 6, false] }, { font: [] }],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['code-block'],
      [
        {
          color: [
            '#000000',
            '#e60000',
            '#ff9900',
            '#ffff00',
            '#008a00',
            '#f4511e',
            '#f57c00',
            '#f9a825',
            'custom-color',
          ],
        },
      ],
      ['link', 'image'],
    ],
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
  };
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'code-block',
    'color',
    'indent',
    'align',
    'link',
    'image',
    'video',
  ];

  return (
    <>
      <ReactQuill
        value={editorText}
        theme="snow"
        ref={quillRef}
        modules={modules}
        formats={formats}
        onChange={(value) => {
          onTextChangeHandler(value);
        }}
        className="quill-editor"
      />
    </>
  );
};

export default Editor;
