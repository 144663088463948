import React from 'react';
import { capitalize, truncate } from 'lodash';
import './UserProfile.css';

type UserProfileProps = {
  name: string;
  user_type: string;
};

const UserProfile = ({ name = '', user_type = '' }: UserProfileProps) => {
  const getInitial = () => {
    if (window.location.pathname === "/myDashboard/showcase") {
      return "G";
    } else if (name) {
      return name.charAt(0);
    } else {
      return 'G';
    }
  };

  const getUserType = () => {
    if (window.location.pathname === "/myDashboard/showcase") {
      return "Guest";
    } else if (user_type) {
      return capitalize(user_type);
    } else {
      return "Guest";
    }
  }
  return (
    <div className="userProfile">
      <div className="userProfile__avatar">
        {getInitial().toUpperCase()}
      </div>
      <div className="userProfile__info">
        <p className="userProfile__username">{truncate(name, { length: 14, separator: '...' })}</p>
        <p className="userProfile__email">{`${getUserType()} account`}</p>
      </div>
    </div>
  );
};

export default UserProfile;
