import React, { Dispatch, SetStateAction } from 'react';

type props = {
  setIsWatchTutorial: Dispatch<SetStateAction<boolean>>;
}

const WatchTutorialDesktop: React.FC<props> = ({ setIsWatchTutorial }) => (
  <div className="mobile__anki--view-container">
    <style>
      {`
        .mobile__anki--view-container {
            width: 290px;
    height: 450px;
    margin: 0 auto;
        }
      .mobile__anki--card-container {
        width: 250px;
    height: 400px;
      }
      .mobile__anki--btn-purple {
        font-size: 1em;
      }
    `}
    </style>
    <div className="mobile__anki--card-container">
      <video style={{ borderRadius: '8px' }} width="100%" height="350px" controls>
        <source src="movie.mp4" type="video/mp4" />
      </video>
      <div className="note__tutorial-video--btn-container">
        <button
          type="button"
          style={{ width: '48%', borderRadius: '8px' }}
          className="mobile__anki--btn-purple"
          onClick={() => setIsWatchTutorial(false)}
        >
          Back
        </button>
        <button
          type="button"
          style={{ width: '48%', borderRadius: '8px' }}
          className="mobile__anki--btn-purple"
          onClick={() => setIsWatchTutorial(false)}
        >
          Got it
        </button>
      </div>
    </div>
  </div>
);
export default WatchTutorialDesktop;
