import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

/* eslint-disable react/jsx-props-no-spreading */
const ProtectedRoute = ({
  mentorRoute, component: Component, ...rest
// eslint-disable-next-line arrow-body-style
}) => {
  /* eslint-disable no-undef */
  const userId = window.localStorage.getItem('hlUserId');
  const userType = window.localStorage.getItem('hlUserType');

  return (
    <Route
      {...rest}
      // eslint-disable-next-line consistent-return
      render={(props) => {
        if (userId && mentorRoute) {
          if (userId && userType === 'mentor') {
            return <Component {...props} />;
          }
          return (
            <Redirect
              to={{
                pathname: '/searchMentor',
                state: {
                  // eslint-disable-next-line react/prop-types
                  from: props.location,
                },
              }}
            />
          );
        }
        if (userId) {
          return <Component {...props} />;
          // eslint-disable-next-line no-else-return
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  // eslint-disable-next-line react/prop-types
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};
ProtectedRoute.propTypes = {
  mentorRoute: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ProtectedRoute.defaultProps = {
  mentorRoute: false,
};

export default ProtectedRoute;
