import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

function TimeSlot({
  time, setTime, setShow, booked, timeZone, innWidth,
}) {
  return (
    <div className="container">
      <div className="row">
        <div className={innWidth ? 'd-grid gap-4' : ''}>
          <button
            onClick={() => { if (!booked) { setTime(time); setShow(true); } }}
            onKeyDown={() => { if (!booked) { setTime(time); setShow(true); } }}
            type="button"
            className={`btn ${booked ? 'disabled-btn slots--btn' : 'purple-outline-btn'} ${innWidth ? 'mt-1 btn-lg px-5 py-4 fw-bolder' : 'w-100 py-3'}`}
          >
            {dayjs(time).tz(timeZone).format('hh:mm A')}
          </button>
        </div>
      </div>
    </div>
  );
}

TimeSlot.propTypes = {
  time: PropTypes.number.isRequired,
  booked: PropTypes.bool.isRequired,
  setTime: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  timeZone: PropTypes.string.isRequired,
  innWidth: PropTypes.number.isRequired,
};
export default TimeSlot;
