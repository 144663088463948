import { hlbClient } from "../../Clients/hlbClient";
import jwt_decode from 'jwt-decode';

export const PHONE_REGEX = /^[0-9]{10}$/g;

export const EMAIL_REGEX =
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/;

export const setHlDetails = (token: any) => {
    const decoded: { user: { id: string, user_type: string } } = jwt_decode(token);
    window.localStorage.setItem('hlUserId', decoded.user.id);
    window.localStorage.setItem('hlUserType', decoded.user.user_type);
};

export const maskEmail = (email: string): string => {
    const parts = email.split('@');
    const maskedEmail = parts[0][0] + '*'.repeat(parts[0].length - 2) + parts[0][parts[0].length - 1] + '@' + parts[1];
    return maskedEmail || "";
}

export const maskPhoneNumber = (number: string): string => {
    return number.replace(/\d(?=\d{4})/g, "*");
}

export const handlePhoneSubmission = async (
    phoneNo: string,
    successCallback: () => void,
    errorCallback: (errorMessage?: string) => void,
    mode?: 'userLogin' | 'userSignup',
) => {
    if (phoneNo && phoneNo.match(PHONE_REGEX)) {
        try {
            const response = await hlbClient().post(mode === 'userSignup' ? '/api/users/phone/otp?verify=true' : '/api/users/phone/otp', {
                phone: phoneNo,
            });
            if (response.data) {
                successCallback();
            }
        } catch (error: unknown) {
            if (error instanceof Error) {
                const { response }: any = error;

                console.log(response);

                if (response?.data?.msg === "User Not exists") {
                    errorCallback("User does not exists");
                } else {
                    errorCallback('Something went wrong, Please try again in few minutes!');
                }
            } else {
                errorCallback("An error occurred. Please try again later!");
            }
        }
    } else {
        errorCallback('Please enter a valid number!');
    }
};

export const handleEmailSubmission = async (
    email: string,
    successCallback: () => void,
    errorCallback: (errorMessage?: string) => void
) => {
    if (email && email.match(EMAIL_REGEX)) {
        try {
            const response = await hlbClient().post('/api/users/email/otp', {
                email,
            });
            if (response.data) {
                successCallback();
            }
        } catch (error) {
            if (error instanceof Error) {
                const { response }: any = error;
                if (response?.data?.msg === "user not found") {
                    errorCallback("User does not exists");
                } else {
                    errorCallback('Something went wrong, Please try again in few minutes!');
                }
            }
        }
    } else {
        errorCallback('Please enter a valid Email Id');
    }
}

export const handlePhoneOtpSubmission = async (
    phoneNo: string,
    otp: string,
    successCallback: () => void,
    errorCallback: (errorMessage?: string) => void,
    mode?: 'userLogin' | 'userSignup',
) => {
    const API_ENDPOINT = mode === 'userSignup' ? '/api/users/verify' : '/api/auth/login';
    const payload = mode === 'userSignup' ? { phone: phoneNo, otp } : JSON.stringify({
        otp, 
        query: {
            phone: phoneNo,
        }
    });

    try {
        const response = await hlbClient().post(API_ENDPOINT, payload);
        if (response.data) {
            if (mode !== "userSignup") {
                window.localStorage.setItem('token', response.data.token);
                setHlDetails(response.data.token);
            }
            successCallback();
        }
    } catch (error) {
        if (error instanceof Error) {
            const { response }: any = error;
            
            if (response?.data?.msg === 'Enter Valid OTP') {
                errorCallback('Enter Valid OTP');
            } else {
                errorCallback('Something went wrong, Please try again in few minutes!');
            }
        } else {
            errorCallback('An error occurred. Please try again later!');
        }
    }
};

export const handleEmailOtpSubmission = async (
    email: string,
    otp: string,
    successCallback: () => void,
    errorCallback: (errorMessage?: string) => void
) => {
    try {
        const response = await hlbClient().post('/api/auth/login', {
            otp,
            query: {
              email,
            },
        });
        if (response.data) {
            window.localStorage.setItem('token', response.data.token);
            setHlDetails(response.data.token);
            successCallback();
        }
    } catch (error) {
        if (error instanceof Error) {
            const { response }: any = error;
            
            if (response?.data?.msg === 'Enter Valid OTP') {
                errorCallback('Enter Valid OTP');
            } else {
                errorCallback('Something went wrong, Please try again in few minutes!');
            }
        } else {
            errorCallback('An error occurred. Please try again later!');
        }
    }
};

export const handleNameEmailSubmission = async (
    name: string,
    email: string,
    phone: string,
    successCallback: () => void,
    errorCallback?: () => void,
) => {
    try {
        const response = await hlbClient().post('/api/users/studentSignup', {
            name, email, phone,
        });
        if (response.data) {
            window.localStorage.setItem('token', response.data.token);
            setHlDetails(response.data.token);
            successCallback();
        }
    } catch (error) {
        errorCallback && errorCallback();
        console.log('Something went wrong while submitting Name & Email');
    }
};

export const handleMentorSignup = async (phone: string, successCallback: () => void, errorCallback?: () => void) => {
    try {
        const response = await hlbClient().post('/api/users/mentorSignup', {
            phone,
        });
        if (response.data) {
            window.localStorage.setItem('token', response.data.token);
            setHlDetails(response.data.token);
            successCallback();
        }
    } catch (error) {
        errorCallback && errorCallback();
        console.error("Something went wrong while mentor signup");
    }
};

export const handleSignup = async (
    signupMode: 'student' | 'mentor',
    phone: string,
    errorCallback: any,
    successCallback: () => void,
    name?: string,
    email?: string,
) => {
    const body = signupMode === "mentor" ? { phone } : { name, email, phone };
    const API_URL = signupMode === "mentor" ? '/api/users/mentorSignup' : '/api/users/studentSignup'
    try {
        const response = await hlbClient().post(API_URL, {
            ...body,
        });
        if (response.data) {
            window.localStorage.setItem('token', response.data.token);
            setHlDetails(response.data.token);
            successCallback();
        }
    } catch (error: any) {
        if (error.response.data.msg === 'User Already exits') {
            errorCallback('User Already exits');
          } else {
            errorCallback('Please enter a valid Email');
          }
    }
};
