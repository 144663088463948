import React, { useEffect, useState } from 'react';
import { EmptyFavourites } from '../emptyStates';
import { AuthModal } from '../../Auth/Modal';
import { hlbClient } from '../../../Clients/hlbClient';
import FavouriteCard from '../favouriteCard';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setFavouriteNotes as dispatchFavouriteNotes } from '../../../reducers/userFavouriteNotes.reducer';
import { Favourite } from './Dashboard';

interface FavouritesProps {
    searchTerm?: string;
}

const Favourites = ({ searchTerm = ""}: FavouritesProps) => {
    const [favouriteNotes, setFavouriteNotes] = useState<any[]>([]);

    const [showAuthModal, setShowAuthModal] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const token = window.localStorage.getItem('token');
    const userFavNoteIds = useSelector((state: any) => state.favNoteIds.favouriteNoteIds);

    const closeAuthModal = () => setShowAuthModal(false);

    const fetchNotes = async () => {
        try {
            const response = await hlbClient().post('/api/entities/getEntities', {
                type: "note",
                ids: userFavNoteIds,
            });
            const entities: any[] = response.data.data.entities || [];
            if (entities.length > 0) setFavouriteNotes(entities);
        } catch (error) {
            console.error("Something went wrong while fetching your favourite notes!", error);
        }
    };

    const toggleFavourite = async (entityId: string) => {
        try {
            const resp = await hlbClient().post('/api/favourites', {
                entityType: "note",
                entityId,
            });
            const favList: Favourite[] = resp.data.result.favourites || [];
            const noteList = favList.filter((item) => item.entityType === "note")?.map((item) => item.entityId);
            dispatch(dispatchFavouriteNotes({
                favouriteNoteIds: noteList,
            }));
        } catch (error) {
            console.error("Something went wrong while toggling favourites!", error);
        }
    };

    useEffect(() => {
        if (!token) {
            setShowAuthModal(true);
        }
    }, [token]);

    useEffect(() => {
        if (userFavNoteIds.length > 0) {
            fetchNotes();
        }
    }, [userFavNoteIds]);

    return (
        <>
            {
                userFavNoteIds.length === 0 ? (
                    <EmptyFavourites />
                ) : (
                    <>
                        <AuthModal open={showAuthModal} closeModal={closeAuthModal} />
                        <div className='favourites__container'>
                            <h2 className='favourites__header'>Favourites</h2>
                            <div className="favourites__list">
                                {
                                    favouriteNotes.map((note) => (
                                        <FavouriteCard
                                            title={note?.name}
                                            subscribers={note?.subscriberCount}
                                            totalCards={note?.cardCount}
                                            key={note?._id}
                                            cardType='note'
                                            previewImage={note?.image?.url}
                                            handleExplore={() => history.push(`/notes/flow-mode-v2/${note?._id}`)}
                                            handleToggleFavourite={() => toggleFavourite(note?._id)}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
}

export default Favourites;