import React from 'react';

/* eslint-disable react/require-default-props */
type ToggleProps = {
  onChangeHandler: any;
  isChecked: boolean;
  nameValue?: string;
  entityType?: string;
  index?: number;
}

const Toggle = ({
  onChangeHandler, isChecked, nameValue = '', entityType = '', index,
}: ToggleProps) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <>
    <style>
      {`
      .global-checkbox[type="checkbox"] {
        -webkit-appearance: none;
        width: 20px;
        height: 20px;
        border: 1px solid #7B45E7;
        outline: none;
        cursor: pointer;
        transition: .2s ease;
        border-radius: 4px;
    }
    .global-checkbox[type='checkbox']:hover {
        box-shadow: 0 0 5px 0px #ad86fa inset;
      }
      
      .global-checkbox[type='checkbox']:before {
        content: '';
        display: block;
        width: 60%;
        height: 60%;
        margin: 19.8% auto;
        transition: .2s ease;
      }
      
      .global-checkbox[type='checkbox']:checked:before {
        background: #7B45E7;
        border-radius: 2px;
      }
      `}
    </style>
    {entityType !== 'multiArgs'
      ? <input name={nameValue} type="checkbox" className="global-checkbox" checked={isChecked} onChange={(e) => onChangeHandler(e.target.checked)} />
      : <input name={nameValue} type="checkbox" className="global-checkbox" checked={isChecked} onChange={(e) => onChangeHandler(index, e)} />}
  </>
);

export default Toggle;
