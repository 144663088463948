import {
  CircleWavy, CircleWavyCheck, CircleWavyWarning, X,
} from 'phosphor-react';
import React from 'react';

export type PaymentStatusProps = {
  status: 'success' | 'failure';
  message: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  primaryOnClick: () => void;
  secondaryOnClick: () => void;
}

const PaymentStatus = ({
  status, message, primaryButtonText, secondaryButtonText, primaryOnClick, secondaryOnClick,
}: PaymentStatusProps) => (
  <div className="payment__status">
    <X onClick={primaryOnClick} size={20} color="#767676" className="status__action--close" />
    <div className="status__info">
      <div className="info__icon">
        <CircleWavy className="info__icon--wrapper" color={status === 'success' ? '#00D37F' : '#FF2E00'} />
        {status === 'success' ? <CircleWavyCheck className="info__icon--status-icon" color="#00D37F" weight="fill" /> : <CircleWavyWarning color="#FF2E00" className="info__icon--status-icon" weight="fill" />}
      </div>
      <h2 className="info__heading">{status === 'success' ? 'Booking Confirmed' : 'Booking failed'}</h2>
      <p className="info__message">{message}</p>
    </div>
    <button onClick={primaryOnClick} type="button" className="status__action status__action--primary">{primaryButtonText}</button>
    <button onClick={secondaryOnClick} type="button" className="status__action status__action--secondary">{secondaryButtonText}</button>
  </div>
);

export default PaymentStatus;
