/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import PropTypes from 'prop-types';
import _, { noop } from 'lodash';
import {
  Dropdown, Modal, Button,
} from 'react-bootstrap';
import Calendar from '../Calendso';
import TimeSlot from '../TimeSlot';
import {
  back, clock, earth, cross, calendarImage, session,
} from '../../BasicComponents/icons';
import Confirmation from './Confirmation';
import PaymentDialog from '../../PaymentDialog/index.ts';
import { hlbClient } from '../../../Clients/hlbClient';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(timezone);

function CalendarContainer(props) {
  const {
    showForm, setShowForm, data, expertId, selectedTime, duration, setName,
    name, email, setEmail, description, setDescription, timeSlots, selectedDate, setTime,
    setDate, availability, disabledDays, timeZone,
    successBooking, failedBooking,
    setIsLoading, capitalize,
  } = props;

  const [show, setShow] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [hostDetails, setHostDetails] = useState({});
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchHostDetails = async () => {
      try {
        const response = await hlbClient().get(`/api/users/${data?.mentorId}/profile`);
        setHostDetails(response?.data[0]);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    // eslint-disable-next-line no-unused-expressions
    data?.mentorId && fetchHostDetails();
  }, [data?.mentorId]);

  const entityDetails = {
    title: data?.name,
    description: data?.description,
    price: data?.price,
    discountPrice: data?.discountPrice,
    isFree: data?.isFree,
    mentorName: hostDetails?.userName,
    mentorId: data?.mentorId,
    sessionDuration: duration,
    sessionType: data?.sessionType,
    selectedSessionDate: selectedDate,
    attendeeEmail: email,
    entityId: data?._id,
  };

  const checkPattern = (enteredEmail) => {
    const pattern = /\S+@\S+\.\S+/;
    return pattern.test(enteredEmail);
  };

  const handleClose = () => setShow(false);
  const handleClick = () => history.push(`/mentor/${expertId}`);

  const handleNewEmailValue = (e) => {
    if (e.key === 'Enter') {
      if (checkPattern(emailValue)) {
        if (!email.includes(emailValue)) {
          setEmail((prev) => [...prev, emailValue]);
        }
      }
      setEmailValue('');
    }
  };

  const handleDeleteEmail = (index) => {
    setEmail(
      email.filter((el, i) => i !== index),
    );
  };

  if (successBooking || failedBooking) {
    return ReactDOM.createPortal(
      <Confirmation isSuccessful={successBooking} />,
      // eslint-disable-next-line no-undef
      document.getElementById('portal-root'),
    );
  // eslint-disable-next-line no-else-return
  } else {
    return (
      <div className="session__container">
        <div className={`session ${showForm ? 'session__form--active' : ''}`}>
          {
            showForm ? (
              <button
                data-toggle="tooltip"
                data-placement="top"
                title="Back"
                className="btn back__button"
                type="button"
                onClick={() => setShowForm(false)}
              >
                <img src={back} alt="Back Button" />
              </button>
            ) : (
              <Link
                className="btn back__button"
                to={`/mentor/${expertId}`}
                data-toggle="tooltip"
                data-placement="top"
                title="Close"
              >
                <img src={back} alt="Back Button" />
              </Link>
            )
          }
          <button
            type="button"
            className="close__button"
            data-toggle="tooltip"
            data-placement="top"
            title="Cancel"
            onClick={() => setShow(true)}
          >
            <img src={cross} alt="Close button" />
          </button>
          {
            show && (
              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton={false}>
                  <Modal.Title>Are you sure, you want to exit?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Continue
                  </Button>
                  <Button variant="danger" onClick={handleClick}>
                    Yes
                  </Button>
                </Modal.Footer>
              </Modal>
            )
          }
          <div className="session__booking">
            <p className="session__text">Session details</p>
            <div className={`session__booking--details ${showForm ? 'form__active' : ''}`}>
              <div className="session__head--wrapper">
                <p className="session__type">{data.sessionType && capitalize(data.sessionType)}</p>
                <h3 className="session__heading">
                  {data.name && capitalize(data.name)}
                </h3>
              </div>
              <div className="session__info">
                <div className="session__price">
                  {
                    _.get(data, 'isFree', false) ? (
                      <span className="session__cost">
                        Free
                      </span>
                    ) : (
                      <>
                        {
                        _.get(data, 'discountPrice') && _.get(data, 'price') ? (
                          <>
                            <span className="session__cost">
                              ₹
                              {' '}
                              {data.discountPrice}
                            </span>
                            <span className="session__cost--markthrough">
                              ₹
                              {' '}
                              {data.price}
                            </span>
                          </>
                        ) : (
                          <span className="session__cost">
                            ₹
                            {' '}
                            {data.price}
                          </span>
                        )
                      }
                      </>
                    )
                  }
                </div>
                <p className="session__duration">
                  <img src={clock} alt="Clock" />
                  {data.duration}
                  {' '}
                  minutes
                </p>
              </div>
              <div className="session__description">
                <p>
                  {data.description && capitalize(data.description)}
                </p>
              </div>
            </div>
            {
              showForm && (
                <div className="form__data">
                  <p className="form--schedule">
                    <img src={calendarImage} alt="calendar" />
                    {' '}
                    {dayjs(selectedTime).tz(timeZone).format('hh:mm A ')}
                    -
                    {dayjs(selectedTime).tz(timeZone).add(duration, 'minute').format('hh:mm A dddd,MMMM D YYYY')}
                  </p>
                  <Dropdown className="text-black dropdown form__data--dropdown" style={{ transform: 'translateX(-10px)' }}>
                    <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                      <img src={earth} alt="Earth Icon" />
                      {timeZone}
                      {/* <img src={down} alt="Down arrow" /> */}
                    </Dropdown.Toggle>
                    {/* <Dropdown.Menu className="dropdown--menu">
                      { timeZones.map(
                        (zone) => (
                          // eslint-disable-next-line max-len
                          <Dropdown.Item
                            onClick={() => { changeAvailability(zone); setZone(zone); }}
                          >
                            {zone}
                          </Dropdown.Item>
                        ),
                      )}
                    </Dropdown.Menu> */}
                  </Dropdown>
                </div>
              )
            }
          </div>
          {showForm ? (
            <>
              <div className="session__form">
                <h3 className="form--header">Enter details</h3>
                <form className="form__container">
                  <div className="form__field">
                    <div className="form__field--group">
                      <label className="form__field--label" htmlFor="name">
                        Name
                        <sup> *</sup>
                      </label>
                      <input
                        type="text"
                        className="input w-100 form__field--input"
                        value={name}
                        id="name"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <div className="form__field--group">
                      <label className="form__field--label" htmlFor="email">
                        Email
                        <sup> *</sup>
                      </label>
                      <div className={`form__tagged--input ${email.length >= 2 ? 'wrapNeeded' : ''}`}>
                        {
                          _.compact(email).map((el, i) => (
                            <span className="tagged__input--filled" key={Math.random()}>
                              { el }
                              <button
                                type="button"
                                className="tagged__input--icon"
                                onClick={() => handleDeleteEmail(i)}
                                disabled={i === 0}
                              >
                                <img src={cross} alt="remove" />
                              </button>
                            </span>
                          ))
                        }
                        <input
                          type="email"
                          className="input form__field--input"
                          value={emailValue}
                          id="email"
                          onChange={(e) => {
                            setEmailValue(e.target.value);
                          }}
                          onKeyDown={handleNewEmailValue}
                          required
                          autoComplete="off"
                          disabled={_.get(data, 'sessionType', 'one-on-one') === 'one-on-one'}
                        />
                      </div>
                    </div>
                    <div className="form__field--group">
                      <label className="form__field--label" htmlFor="desc">What do you need help with?</label>
                      <textarea
                        id="desc"
                        value={description}
                        rows="4"
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        className="form__field--textarea"
                        required
                      />
                    </div>
                  </div>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      setIsLoading(true);
                      setIsPaymentModalOpen(true);
                    }}
                    role="button"
                    onKeyDown={() => {
                      setIsLoading(true);
                      setIsPaymentModalOpen(true);
                    }}
                    tabIndex="-1"
                  >
                    <input
                      type="button"
                      className="btn form--submit"
                      value={`${data.isFree
                        ? 'Book Session'
                        : `Pay ₹${data.price && data.discountPrice ? data.discountPrice : data.price}`}`}
                    />
                  </a>
                </form>
              </div>
            </>
          ) : (
            <>
              <div className="session__calendar">
                <h3 className="calendar__heading">Select a Date & Time</h3>
                <Calendar
                  className="calendar"
                  setDate={setDate}
                  selectedDate={selectedDate}
                  daysAllowed={(_.get(availability[0], 'bookingAllowedTill', 30) / 24) - 1}
                  startDay={_.get(availability[0], 'bookingAllowedBefore', 0) / 24}
                  disabledDays={disabledDays}
                  timeZone={timeZone}
                />
                <div className="dropdown__container">
                  <Dropdown className="text-black dropdown">
                    <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                      <img src={earth} alt="Earth Icon" />
                      {timeZone}
                      {/* <img src={down} alt="Down arrow" /> */}
                    </Dropdown.Toggle>

                    {/* <Dropdown.Menu className="dropdown--menu">
                      { timeZones.map(
                        (zone) => (
                          // eslint-disable-next-line max-len
                          <Dropdown.Item
                            onClick={() => { changeAvailability(zone); setZone(zone); }}
                          >
                            {zone}
                          </Dropdown.Item>
                        ),
                      )}
                    </Dropdown.Menu> */}
                  </Dropdown>
                </div>
              </div>
              { selectedDate
            && (
            <div className="session__slots">
              <div className="slots__container">
                <h3 className="session__date">
                  {dayjs(selectedDate).format('dddd,MMMM D')}
                </h3>
                <div className="slots">
                  {(timeSlots.length > 0 ? (timeSlots.map((slot) => (
                    // eslint-disable-next-line react/jsx-key
                    <div className="text-center mt-2">
                      <TimeSlot
                        time={slot.time}
                        setTime={setTime}
                        setShow={setShowForm}
                        booked={slot.booked}
                        timeZone={timeZone}
                      />
                    </div>
                  ))) : <div style={{ textAlign: 'center' }}>No Slots Available </div>)}
                </div>
              </div>
            </div>
            )}
            </>
          )}
        </div>
        <PaymentDialog
          open={isPaymentModalOpen}
          onClose={() => setIsPaymentModalOpen(false)}
          entityType="session"
          entityDetails={entityDetails}
          thumbnail={session}
        />
      </div>
    );
  }
}

CalendarContainer.propTypes = {
  showForm: PropTypes.bool.isRequired,
  setShowForm: PropTypes.bool.isRequired,
  timeZone: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  expertId: PropTypes.oneOfType(PropTypes.bool, PropTypes.string, PropTypes.number).isRequired,
  selectedTime: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  name: PropTypes.array.isRequired,
  setName: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  setDescription: PropTypes.string.isRequired,
  timeSlots: PropTypes.arrayOf(PropTypes.oneOfType(
    [PropTypes.number, PropTypes.string],
  )).isRequired,
  selectedDate: PropTypes.oneOfType(PropTypes.string,
    PropTypes.objectOf(PropTypes.object)).isRequired,
  setTime: PropTypes.string.isRequired,
  setDate: PropTypes.oneOfType(PropTypes.string, PropTypes.objectOf(PropTypes.object)).isRequired,
  availability: PropTypes.arrayOf(PropTypes.oneOfType(
    [PropTypes.number, PropTypes.string],
  )).isRequired,
  disabledDays: PropTypes.arrayOf(PropTypes.oneOfType(
    [PropTypes.number, PropTypes.string],
  )).isRequired,
  changeAvailability: PropTypes.func.isRequired,
  setZone: PropTypes.func.isRequired,
  successBooking: PropTypes.bool.isRequired,
  setSuccessBooking: PropTypes.bool.isRequired,
  failedBooking: PropTypes.bool.isRequired,
  setFailedBooking: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.bool.isRequired,
  capitalize: PropTypes.func.isRequired,
};
export default CalendarContainer;
