import React, { useEffect, useState } from 'react';
import NavbarContainer from '../BasicComponents/navbar';
import './aboutAndContact.css';

const AboutAndContact: React.FC = () => {
  const [currentNavBar, setCurrentNavBar] = useState<string | undefined>('');

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const currentPage = window.location.href;
    setCurrentNavBar(currentPage.split('#').pop());
  // eslint-disable-next-line no-undef
  }, [window.location.href]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <NavbarContainer />
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-4 mt-4">
            <ul className="About__sideNavbar">
              <li><a href="#about" className={currentNavBar === 'about' ? 'About__active' : ''}>About</a></li>
              <li><a href="#contact" className={currentNavBar === 'contact' ? 'About__active' : ''}>Contact Us</a></li>
            </ul>
          </div>
          <div className="col-md-8 mt-4">
            <section id="about">
              <h1 className="aboutAndContact__heading">About</h1>
              <p className="contactAndAbout__text">
                Welcome to Hyperlearn, your number one source for all your learning
                needs. We&apos;re dedicated to providing you the best of experts,
                with a focus on dependability. customer service and quality
                learning.
              </p>
              <p className="contactAndAbout__text">
                Sincerely,
                <br />
                Abhijeet De Sarkar
              </p>
            </section>
            <section id="contact">
              <h1 className="aboutAndContact__heading">Contact</h1>
              <p className="contactAndAbout__text">
                <i className="fa fa-envelope" />
                {' '}
                support@hyperlearn.in
              </p>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutAndContact;
