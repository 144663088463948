/* eslint-disable react/jsx-key */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-undef */
import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import './MentorProfile.css';
import { useParams, Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import BookSessionCard from '../BasicComponents/bookSessionCard';
import FeaturedWorkSlider from '../BasicComponents/featuredWorkSlider';
// import MentorRating from '../BasicComponents/mentorRating';
import { AuthContext } from '../../provider/AuthContext';
import VideoCard from '../BasicComponents/videoCard';
import SocialMedia from '../BasicComponents/socialMedia';
import { tick, announcement, edit, info } from '../BasicComponents/icons';
import { hlbClient } from '../../Clients/hlbClient';
import NavbarContainer from '../BasicComponents/navbar';
import HtmlParser from '../BasicComponents/htmlParser';
import WhatsappPlugin from '../BasicComponents/WhatsappPlugin';
import Tooltip from '../BasicComponents/Tooltip';
import MyDeck from '../hyperNotes/manage/myDeck';
import ToastMessage from '../BasicComponents/toastMessage';

function MentorProfile() {
  const [expand, setExpand] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [showMoreCards, setShowMoreCards] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(0);
  const [data, setData] = useState<any>([]);
  const [isReadMore, setIsReadMore] = useState<boolean>(true);
  const [isBookingAvailable, setIsBookingAvailable] = useState<boolean>(true);
  const [isMentorAcc, setIsMentorAcc] = useState<boolean>(false);
  const [decks, getDecks] = useState<any>([]);
  const { authState, setStateUpdate, stateUpdate } = useContext(AuthContext);
  // eslint-disable-next-line no-undef
  const token: string | null = localStorage.getItem('token');
  useEffect(() => {
    if (token) {
      setStateUpdate(!stateUpdate);
    }
  }, []);
  const currentUserId: string = authState._id;

  const { id } = useParams<{ id: string }>();
  const getMyDeck = async (mentorId: string) => {
    try {
      const resp = await hlbClient().get(`/api/srs/mentor/${mentorId}/decks`);
      if (resp.data.success) {
        getDecks(resp.data.result);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');
    }
  };
  useEffect(() => {
    if (data?.user) {
      getMyDeck(data?.user);
    }
  }, [data?.user]);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  const fullScreen = true;
  function seeMore() {
    setExpand(!expand);
    setShowMoreCards(!showMoreCards);
  }
  const mentorCustomUrl = id.split('@').pop();
  useEffect(() => {
    async function fetchData() {
      try {
        if (id.includes('@')) {
          const resp = await hlbClient().get(`/api/users/${mentorCustomUrl}`);
          setData(resp.data[0]);
        } else {
          const resp = await hlbClient().get(`/api/users/${id}/profile`);
          setData(resp.data[0]);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('oops something went wrong');
      }
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    if (data && currentUserId) {
      if (_.get(data, 'user', '') === currentUserId) {
        setIsMentorAcc(true);
      }
    }
  }, [data, currentUserId]);

  useEffect(() => {
    if (data) {
      setIsBookingAvailable(_.get(data, 'availability', true));
    }
  }, [data]);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);
  const expYears = _.get(data, 'expInYears', null) || null;

  const [newOranigaztion, setNewOrganization] = useState([]);
  useEffect(() => {
    let getdata: any = [];
    if (data.organisations) {
      const img = new Image();
      // eslint-disable-next-line array-callback-return
      data.organisations.map((list: any) => {
        img.src = `${list.url}`;
        const imgHeight: number = img.naturalHeight;
        const imgWidth: number = img.naturalWidth;
        const aspectRatio: number = imgWidth / imgHeight;
        const newData = { ...list, aspectRatio };

        getdata = [...getdata, { ...newData }];
      });
    }
    setNewOrganization(getdata);
  }, [data.organisations]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <NavbarContainer />
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="row d-flex justify-content-center-profile">
          <div className="spacing" />
          <div className="col-5 col-md-3 col-lg-3 col-xl-3 col-xxl-3 profile-pic-on-mobile">
            <div className="row g-0 justify-content-end">
              <div className="fixed-Right-section col-md-3">
                <div className="scroll-right-section__v2">
                  <div className="mentor-profile-box d-flex">
                    <img
                      src={_.get(data, 'profilePic.url', '')}
                      alt=""
                      className="mentor-proile-pic"
                    />
                  </div>
                  <div className="icon-box hide-on-small-screen">
                    <SocialMedia socials={_.get(data, 'socials', [])} />
                  </div>
                  <div className="divider-bar" />
                  <div className="skills-box d-flex hide-on-small-screen">
                    <p
                      className="paragraph__semi-bold hide-on-small-screen mt-1"
                      style={{ marginBottom: '12px' }}
                    >
                      Core Skills
                    </p>
                  </div>
                  <div className="button-box d-flex flex-wrap hide-on-small-screen">
                    {data.skills &&
                      _.get(data, 'skills', '').map((skill: any) => (
                        <span className="skills-button hide-on-small-screen">
                          {skill}
                        </span>
                      ))}
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="skills-box d-flex hide-on-small-screenmt-4">
                        <p
                          className="paragraph__semi-bold hide-on-small-screen"
                          style={{ marginBottom: '12px' }}
                        >
                          Organisations
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="icon-company-box hide-on-small-screen mb-3">
                    {newOranigaztion &&
                      newOranigaztion.map((organization: any) => (
                        <img
                          src={_.get(organization, 'url', '')}
                          alt=""
                          className={
                            organization.aspectRatio === 1
                              ? 'icon-company-img'
                              : 'icon-company-img-rect'
                          }
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-7 hide-on-big-screen">
            <h4 className="mentor-name">
              {_.get(data, 'userName', '')} <img src={tick} alt="tic" />
              {currentUserId === id && (
                <Link to="/mentor/editProfile">
                  <img src={edit} alt="" className="float-end" />
                </Link>
              )}
            </h4>
            <p style={{ color: '#444444' }}>{_.get(data, 'shortBio', '')}</p>
            {/* <div className="hide-on-big-screen">
              <MentorRating rating={_.get(data, 'rating', '0.1')} />
              {' '}
              {parseFloat(_.get(data, 'rating', '0.1')).toFixed(1)}
            </div> */}
            <div className="icon-box">
              <SocialMedia socials={_.get(data, 'socials', [])} />
            </div>
          </div>
          {expYears && (
            <div className="col-md-4 col-4 hide-on-big-screen ">
              <p
                className="paragraph"
                style={{ color: '#8C8C8C', fontWeight: 500 }}
              >
                Experience
              </p>
              <p className="paragraph">
                {expYears > 10 ? '10+' : expYears}{' '}
                {expYears <= 1 ? 'year' : 'years'}
              </p>
            </div>
          )}
          <div className="col-md-6 col-6 hide-on-big-screen">
            <p
              className="paragraph"
              style={{ color: '#8C8C8C', fontWeight: 500 }}
            >
              Languages
            </p>
            <p className="paragraph">
              {data.languages &&
                _.get(data, 'languages', '').map(
                  (language: any, index: number) => (
                    <span>{(index ? ', ' : '') + language}</span>
                  )
                )}
            </p>
          </div>
          <div className=" col-12 hide-on-big-screen">
            <div className="skills-box hide-on-big-screen">
              <p className="paragraph__semi-bold hide-on-big-screen">
                Core Skills
              </p>
            </div>
            <div className="button-box d-flex flex-wrap justify-content-center-profile hide-on-big-screen mt-1">
              {data.skills &&
                _.get(data, 'skills', '').map((skill: any) => (
                  <span className="skills-button hide-on-big-screen">
                    {skill}
                  </span>
                ))}
            </div>
            <div
              className="skills-box d-flex hide-on-big-screen mt-3"
              style={{ marginLeft: '0px' }}
            >
              <p className="paragraph__semi-bold hide-on-big-screen">
                Organisations
              </p>
            </div>
            <div
              className="icon-company-box hide-on-big-screen mt-3"
              style={{ marginLeft: '0px' }}
            >
              {newOranigaztion &&
                newOranigaztion.map((organization: any) => (
                  <img
                    src={_.get(organization, 'url', '')}
                    alt=""
                    className={
                      organization.aspectRatio === 1
                        ? 'icon-company-img'
                        : 'icon-company-img-rect'
                    }
                  />
                ))}
            </div>
            <a
              href="#session"
              className="btn purple-outline-btn mt-3"
              style={{ width: '100%', padding: '15px' }}
            >
              Select Session
            </a>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-5">
            <div className="row">
              <div className="col-md-6 col-8 hide-on-small-screen">
                <h4 className="mentor-name">
                  {_.get(data, 'userName', '')}{' '}
                  {_.get(data, 'verified', '') === true && (
                    <img src={tick} alt="tic" />
                  )}
                </h4>
                <p style={{ color: '#444444' }}>
                  {_.get(data, 'shortBio', '')}
                </p>
                <div className="row">
                  {expYears && (
                    <div className="col-md-4 col-lg-4">
                      <p
                        className="paragraph"
                        style={{ color: '#8C8C8C', fontWeight: 500 }}
                      >
                        Experience
                      </p>
                      <p className="paragraph">
                        {expYears > 10 ? '10+' : expYears}{' '}
                        {expYears <= 1 ? 'year' : 'years'}
                      </p>
                    </div>
                  )}
                  <div className="col-md-8 col-lg-8">
                    <p
                      className="paragraph"
                      style={{ color: '#8C8C8C', fontWeight: 500 }}
                    >
                      Languages
                    </p>
                    <p className="paragraph">
                      {data.languages &&
                        _.get(data, 'languages', '').map(
                          (language: any, index: number) => (
                            <span>{(index ? ', ' : '') + language}</span>
                          )
                        )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-5 hide-on-small-screen">
                {/*  <MentorRating rating={_.get(data, 'rating', '0.1')} />
                {' '}
                {parseFloat(_.get(data, 'rating', '')).toFixed(1)}
                <img src={dots} alt="" className="float-end" /> */}
                {currentUserId === id && (
                  <Link to="/mentor/editProfile">
                    <img src={edit} alt="" className="float-end" />
                  </Link>
                )}
              </div>
            </div>
            <p className="paragraph mt-4">
              {isReadMore ? (
                <HtmlParser
                  text={_.get(data, 'description', '').slice(0, 200)}
                />
              ) : (
                <HtmlParser text={_.get(data, 'description', '')} />
              )}
              {_.get(data, 'description', '').length >= 150 && (
                <button
                  type="button"
                  onClick={toggleReadMore}
                  className="show-hide-text"
                >
                  {' '}
                  {isReadMore ? 'read more' : ' show less'}
                </button>
              )}
            </p>
            {_.get(data, 'introVideo', '') && (
              <>
                <p className="paragraph mt-5 mb-4">My Introduction</p>
                <VideoCard data={_.get(data, 'introVideo', '')} type="link" />
              </>
            )}
            {decks?.length !== 0 && (
              <>
                <p className="paragraph mt-5" style={{ marginBottom: '-35px' }}>
                  My Notes{' '}
                  <Tooltip
                    content="Get a quick revision, with flashcards inside the note."
                    direction="top"
                  >
                    <img src={info} alt="info" />
                  </Tooltip>
                </p>
                <MyDeck
                  data={decks}
                  entityType={currentUserId === id ? "mentorProfile": "redirect"}
                />
              </>
            )}
            {_.get(data, 'featured', '').length !== 0 && (
              <>
                <FeaturedWorkSlider featured={_.get(data, 'featured', '')} />
              </>
            )}
          </div>
          <div className="col-md-3 col-lg-3" id="session">
            <div
              className={`centerlized-card ${
                expand === true ? 'scroll-card-see-more-desktop' : 'scroll-card'
              } `}
            >
              <div className="card-bg">
                {/* eslint-disable-next-line no-nested-ternary */}
                {expand === true ? (
                  width <= 414 ? (
                    <BookSessionCard
                      isBookingAvailable={isBookingAvailable}
                      data={_.get(data, 'sessionDetails', '').slice(0, 2)}
                      mentorId={_.get(data, 'user', '')}
                      // showMore={showMoreCards}
                      isMentorAcc={isMentorAcc}
                    />
                  ) : (
                    <BookSessionCard
                      isBookingAvailable={isBookingAvailable}
                      data={data.sessionDetails}
                      mentorId={_.get(data, 'user', '')}
                      // showMore={showMoreCards}
                      isMentorAcc={isMentorAcc}
                    />
                  )
                ) : (
                  <BookSessionCard
                    isBookingAvailable={isBookingAvailable}
                    data={_.get(data, 'sessionDetails', '').slice(0, 2)}
                    mentorId={_.get(data, 'user', '')}
                    // showMore={showMoreCards}
                    isMentorAcc={isMentorAcc}
                  />
                )}
                <button
                  type="button"
                  onClick={() => {
                    setShow(true);
                  }}
                  className=" btn btn-outline-green hide-on-big-screen"
                >
                  View All Session
                </button>
                {!expand && _.get(data, 'sessionDetails', '').length > 1 && (
                  <button
                    type="button"
                    onClick={seeMore}
                    className=" btn btn-outline-green hide-on-small-screen"
                  >
                    View All Session
                  </button>
                )}
              </div>
            </div>
            {_.get(data, 'announcementEnabled', '') === true && (
              <div className="annuncement-card mt-4 mb-4 hide-on--screen">
                <div className="announcement-box">
                  <img src={announcement} alt="announcement" />
                </div>
                <p
                  className="announcement-text"
                  style={{ marginBottom: '0px' }}
                >
                  {_.get(data, 'announcement', '')}
                </p>
                {_.get(data, 'announcementCTA', '') && (
                  <a
                    href={_.get(data, 'announcementCTA', '')}
                    target="_blank"
                    className="announcement-text"
                    rel="noreferrer"
                  >
                    <i>Click here</i>
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal show={show} fullscreen={fullScreen} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>Select your session</Modal.Title>
          <button
            type="button"
            onClick={() => setShow(false)}
            className="btn-close float-end"
          />
        </Modal.Header>
        <Modal.Body>
          <div className="centerlized-card scroll-card-see-more">
            <div className="card-bg">
              {/* eslint-disable-next-line max-len */}
              <BookSessionCard
                isBookingAvailable={isBookingAvailable}
                data={data.sessionDetails}
                // showMore={showMoreCards}
                isMentorAcc={isMentorAcc}
                mentorId={_.get(data, 'user', '')}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastMessage />
      <WhatsappPlugin />
    </>
  );
}

export default MentorProfile;
