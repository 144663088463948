/* eslint-disable react/jsx-key */
import React from 'react';
import _ from 'lodash';
import {
  twitter,
  linkedIn,
  gitHub,
  youtube,
  reddit,
  medium,
  telegram,
  instagram,
  dribbble,
  discord,
  behance,
  facebook,
  portfolio,
} from '../icons';
import Socials from '../socials';

export type Platforms =
  | 'facebook'
  | 'twitter'
  | 'linkedIn'
  | 'github'
  | 'youtube'
  | 'reddit'
  | 'telegram'
  | 'instagram'
  | 'dribble'
  | 'discord'
  | 'medium'
  | 'behance'
  | 'portfolio';

export type SocialType = {
  active?: boolean;
  platform: Platforms;
  url: string;
};

type SocialMediaProps = {
  socials: SocialType[];
  entityType?: string;
  apiCall?: () => void;
};

const iconMap = {
  facebook,
  twitter,
  linkedIn,
  github: gitHub,
  youtube,
  reddit,
  telegram,
  instagram,
  dribble: dribbble,
  discord,
  medium,
  behance,
  portfolio,
};
function SocialMedia({
  socials,
  entityType = 'normalPage',
  apiCall = () => {
    //empty
  },
}: SocialMediaProps) {
  const socialMedia = socials.length || null;
  return (
    <div>
      {entityType === 'editPage' ? (
        <>
          {socialMedia ? (
            <>
              {socials &&
                socials.map(
                  (social) =>
                    _.get(social, 'active', '') && (
                      // eslint-disable-next-line react/jsx-key
                      <a href={social.url} target="_blank" rel="noreferrer">
                        <img
                          src={iconMap[social.platform]}
                          alt="icon"
                          className="icon-img"
                        />
                      </a>
                    )
                )}
              <Socials
                apiCall={apiCall}
                entityType="editButton"
                socialList={socials}
              />
            </>
          ) : (
            <>
              <Socials apiCall={apiCall} />
            </>
          )}
        </>
      ) : (
        <>
          {socialMedia ? (
            <>
              {socials &&
                socials.map(
                  (social) =>
                    _.get(social, 'active', '') && (
                      <a href={social.url} target="_blank" rel="noreferrer">
                        <img
                          src={iconMap[social.platform]}
                          alt="icon"
                          className="icon-img"
                        />
                      </a>
                    )
                )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
}

export default SocialMedia;
