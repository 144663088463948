import React, { useState } from 'react';
import _ from 'lodash';
// eslint-disable-next-line no-unused-vars
import { Link } from 'react-router-dom';
import {
  clock, assistanceGray, calendarImage, earth, userImage,
  // eslint-disable-next-line no-unused-vars
  zoom, meet, teams, reschedule,
} from '../BasicComponents/icons';

type props = {
  elData: {
    mentorId: {profile: {profilePic: {url: string }},
    name: string,
  },
  sessionId: {
    name: string, sessionType: string, duration: number,
    isFree: string, description: string
  },
  startTime: string,
  endTime: string,
  orderId: {price: number},
  comments: string,
},
  correctTime: (k: string) => string,
  getSessionType: (k: string) => string,
  capitalize: (k: string) => string,
  studentName: string,
  studentEmail: string,
  limitWordLength: (k: string) => string,
}

const BookingsAccordion: React.FC<props> = ({
  elData, correctTime, getSessionType, capitalize, studentName, studentEmail, limitWordLength,
}) => {
  const [isActive, setIsActive] = useState(false);
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  return (
    <div className="upcoming__session">
      <div
        className={`session__overview ${isActive ? 'session__overview--expanded' : ''}`}
        onClick={() => setIsActive(!isActive)}
      >
        <div className="session__overview--mentee">
          <div className="mentee--img">
            <img src={_.get(elData, 'mentorId.profile.profilePic.url', userImage)} alt="user profile" />
          </div>
          <div className="mentee__details">
            <h2 className="mentee__details--name">{limitWordLength(capitalize(_.get(elData, 'mentorId.name', '')))}</h2>
          </div>
        </div>
        <div className="session__overview--type">
          <p>
            {limitWordLength(capitalize(_.get(elData, 'sessionId.name', '')))}
            {' '}
            {getSessionType(_.get(elData, 'sessionId.sessionType', ''))}
          </p>
        </div>
        <div className="session__overview--duration">
          <img src={clock} alt="clock" />
          <p>
            {_.get(elData, 'sessionId.duration', 0)}
            {' '}
            minutes
          </p>
        </div>
        <div className="session__overview--starts">
          <p>{correctTime(_.get(elData, 'startTime', ''))}</p>
        </div>
      </div>
      <div className={`${isActive ? 'session__details show' : 'session__details'}`}>
        <div className="details__sec details__sec--1">
          <div className="details__sec--grouping">
            <h2 className="details--header">Booking details</h2>
          </div>
          <div className="details__sec--grouping">
            <div className="details__type details__box">
              <img src={assistanceGray} alt="help" />
              <p className="details__type--text">{capitalize(_.get(elData, 'sessionId.sessionType', ''))}</p>
            </div>
            <div className="details__duration details__box">
              <img src={clock} alt="time" />
              <p className="details__duration--text">
                {_.get(elData, 'sessionId.duration', 0)}
                {' '}
                minutes
              </p>
            </div>
            <div className="details__calendar details__box">
              <img src={calendarImage} alt="calendar" />
              <p className="details__calendar--text">
                {days[new Date(_.get(elData, 'startTime', new Date())).getDay()]}
                {', '}
                {months[new Date(_.get(elData, 'startTime', new Date())).getMonth()]}
                {' '}
                {new Date(_.get(elData, 'startTime', new Date())).getDate()}
                &nbsp;&nbsp;
                {correctTime(_.get(elData, 'startTime', ''))}
                {' - '}
                {correctTime(_.get(elData, 'endTime', ''))}
              </p>
            </div>
            <div className="details__time details__box">
              <img src={earth} alt="timezone" />
              <p className="details__time--text">Indian Standard Time</p>
            </div>
            <div className="details__price details__box">
              <p className="details__price--text">
                Price
                {
                  _.get(elData, 'sessionId.isFree', false) ? (
                    <p>
                      <span>Free</span>
                    </p>
                  ) : (
                    <p>
                      <span>₹</span>
                      {_.get(elData, 'orderId.price', 0)}
                    </p>
                  )
                }
              </p>
            </div>
          </div>
          <div className="details__sec--grouping">
            <div className="details__desc">
              <p className="details__desc--text">
                {_.get(elData, 'comments', '')}
              </p>
            </div>
          </div>
        </div>
        <div className="details__sec details__sec--2">
          <h2 className="details--header">Session details</h2>
          <div className="details__user">
            <h3 className="details__user--name">{studentName}</h3>
            <p className="details__user--email">{studentEmail}</p>
          </div>
          <div className="details__desc">
            <p className="details__desc--text">
              {_.get(elData, 'sessionId.description', '')}
            </p>
          </div>
          <div className="details__footer">
            {/* <div className="details__footer--links">
              <Link to="/">
                <img src={zoom} alt="Zoom" />
              </Link>
              <Link to="/">
                <img src={meet} alt="Google Meet" />
              </Link>
              <Link to="/">
                <img src={teams} alt="Microsoft Teams" />
              </Link>
            </div> */}
            {/* <div className="details__footer--reschedule">
              <a href="/">
                <img src={reschedule} alt="clock" />
                Reschedule
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingsAccordion;
