// eslint-disable-next-line react/prop-types
const FeatureFlag = ({ name, children }) => {
  // eslint-disable-next-line no-undef
  const features = JSON.parse(localStorage.getItem('featureFlags'));
  const feature = features[0].flags.find((item) => item.name === name);

  if (feature && feature.active) {
    return children;
  }

  return null;
};

export default FeatureFlag;
