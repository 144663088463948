import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import SocialMedia, { SocialType } from '../BasicComponents/socialMedia';
import { earth, tick } from '../BasicComponents/icons';
import HtmlParser from '../BasicComponents/htmlParser';

type props = {
  mentor: {
    profileDetails: {
      expInYears: number,
      socials: SocialType[],
      verified: boolean,
      shortBio: string,
      description: string,
      skills: string[],
      languages: string[],
      publicUrl: string,
      profilePic: { url: string }
     },
    minSessionPrice: number,
    name: string,
  }
}

const mentorSearchCardMobile: React.FC<props> = ({ mentor }) => (
  <div className="mentorSearchCardMobile__container mt-4">
    <div className="search__mentor--profile-box">
      <div className="mentor-profile-box d-flex">
        <img
          src={_.get(mentor, 'profileDetails.profilePic.url', '')}
          alt=""
          className="search__mentor--proile-pic"
        />
      </div>
      <p className="search__mentor--detail-box--user-name justify-content-center">
        {_.capitalize(mentor.name)}
        {' '}
        {_.get(mentor, 'profileDetails.verified', '') === true && (
        <img src={tick} alt="tic" style={{ paddingLeft: '5px' }} />
        )}
      </p>
      <p className="search__mentor--detail-box--tagLine justify-content-center">
        {_.get(mentor, 'profileDetails.shortBio', '')}
      </p>
      {/* <div className="search__mentor--Rating text-center">
          <MentorRating
            rating={_.get(mentor, 'profileDetails.rating', '0.1')}
          />
          {' '}
          {parseFloat(_.get(mentor, 'profileDetails.rating', '0.1')).toFixed(1)}
        </div> */}
      <div className="icon-box text-center">
        <SocialMedia socials={_.get(mentor, 'profileDetails.socials', [])} />
      </div>
    </div>
    <div className="divider" />
    <span className="search__mentor--detail-box--bio">
      <HtmlParser text={_.get(mentor, 'profileDetails.description', '')} />
    </span>
    {_.get(mentor, 'profileDetails.skills', []) && _.get(mentor, 'profileDetails.skills', []).slice(0, 3).map((skill: string, index: number) => (
      <span key={index.toString()} className="search__mentor--skills-button mb-4">{skill}</span>
    ))}
    {_.get(mentor, 'profileDetails.skills', '').length > 3 && (
    <span className="search__mentor--skills-button">
      +
      {_.get(mentor, 'profileDetails.skills', '').length - 3}
    </span>
    )}
    {/* <div style={{ display: 'flex', width: '100%' }}>
        <p className="search__mentor--time-zone-and-session" style={{ marginBottom: '5px' }}>
          {_.get(mentor, 'totalSessionsTaken', '0')}
          {' '}
          sessions taken
        </p>
      </div> */}
    <div style={{ display: 'flex', width: '100%' }}>
      <p className="search__mentor--time-zone-and-session" style={{ float: 'right' }}>
        <img src={earth} alt="earth" />
        {' '}
        <span style={{ marginLeft: '10px' }}>Indian Standard Time</span>
        {' '}
      </p>
    </div>
    <Link
      to={{
        pathname: `/mentor/@${_.get(mentor, 'profileDetails.publicUrl', '')}`,
      }}
      className="btn search__mentor--session-box-btn"
      style={{ width: '100%' }}
    >
      View Profile
    </Link>
  </div>
);

export default mentorSearchCardMobile;
