import React from 'react';
import './FavouriteCard.css';
import { BookBookmark, Heart, HeartStraight } from 'phosphor-react';
import { noop } from 'lodash';

type FavouriteCardProps = {
    cardType: "note";
    title: string;
    subscribers: number;
    totalCards: number;
    previewImage: string;
    handleExplore?: () => void;
    handleToggleFavourite?: () => void;
}

const FavouriteCard = ({ cardType, subscribers, title, totalCards, previewImage, handleExplore = noop, handleToggleFavourite = noop }: FavouriteCardProps) => {
    return (
        <div className='favourite__card'>
            <div className="card__type--wrapper">
                {cardType === "note" && (
                    <BookBookmark size={16} weight="fill" color="white" className="emptyContainer__icon"  />
                )}
            </div>
            <img className="card__image" src={previewImage} />
            <div className="card__info">
                <h4 className="info__title">{title}</h4>
                <div className="info__tags">
                    <p className="tag__subscriber">{subscribers} Subscribers</p>
                    <p className="tag__cards">{totalCards} Cards</p>
                </div>
                <div className="card__actions">
                    <button className="action__explore" onClick={handleExplore}>Explore</button>
                    <button className="action__favourite" onClick={handleToggleFavourite}>
                        <HeartStraight size={18} weight="fill" color='#F61C1C' />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FavouriteCard;