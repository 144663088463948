/* eslint-disable react/prop-types */
import {
  ArrowSquareOut, Calendar, CircleWavyQuestion, Clock, StackSimple, User, Users, X,
} from 'phosphor-react';
import React, { useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import './PaymentDialog.css';
import dayjs from 'dayjs';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { noop } from 'lodash';
import { logo } from '../BasicComponents/icons';
import { hlbClient } from '../../Clients/hlbClient';
import loadScript from './PaymentUtils';
import { myConfig } from '../../config';
import { AuthContext } from '../../provider/AuthContext';
import { getTimeDifference } from '../Utils';
import PaymentStatus from './PaymentStatus';

export type EntityDetailsPillProps = {
  icon: React.ReactNode;
  bgColor: string;
  text: string;
}

export type PaymentPortalProps = {
  open: boolean;
  onClose: () => void;
  // eslint-disable-next-line react/require-default-props
  onContinue?: () => void;
  entityType: string;
  entityDetails: any;
  thumbnail: string;
};

export const EntityDetailsPill = ({ icon, bgColor, text }: EntityDetailsPillProps) => (
  <div className="entity__pill" style={{ backgroundColor: bgColor }}>
    {icon}
    <span className="entity__pill--text">{text}</span>
  </div>
);

export const calculateDiscountPercentage = (discountPrice: number, originalPrice: number) => {
  const priceDifference = originalPrice - discountPrice;
  return `${((priceDifference / originalPrice) * 100).toFixed(0)}%`;
};

const PaymentPortal = ({
  // eslint-disable-next-line no-unused-vars
  open, onClose, onContinue = noop, entityType, entityDetails, thumbnail,
}: PaymentPortalProps) => {
  const [billView, setBillView] = useState(false);
  const [billDetails, setBillDetails] = useState<any>({});
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [bookingSuccessful, setBookingSuccessful] = useState(false);
  const [bookingFailed, setBookingFailed] = useState(false);
  const { authState } = useContext(AuthContext);
  const history = useHistory();
  const originalPrice = billDetails?.result?.lineItems[0]?.itemOriginalPrice;
  const discountPrice = billDetails?.result?.lineItems[0]?.itemRate;
  const priceDifference = originalPrice - discountPrice;

  // eslint-disable-next-line consistent-return
  const getOptions = (payment: any, orderId: any) => {
    if (entityType === 'webinar') {
      return {
        paymentId: payment,
        orderId,
        info: {
          webinarId: entityDetails?.entityId,
          userId: authState?._id,
        },
      };
    }
    if (entityType === 'session') {
      return {
        orderId,
        info: {
          mentorId: entityDetails?.mentorId,
          startTime: dayjs(entityDetails?.selectedSessionDate).toISOString(),
          endTime:
            dayjs(entityDetails?.selectedSessionDate)
              .add(entityDetails?.sessionDuration)
              .toISOString(),
          status: 'completed',
          orderId,
          attendees: entityDetails?.attendeeEmail,
          sessionId: entityDetails?.entityId,
          comments: entityDetails?.description,
        },
      };
    }
    if (entityType === 'notes') {
      return {
        orderId,
      };
    }
  };

  const updatePayment = async (payment: any, orderId: any) => {
    try {
      await hlbClient().put('/api/payment', { ...getOptions(payment, orderId) });
      setBookingSuccessful(true);
      setBookingFailed(false);
      setPaymentLoading(false);
    } catch (error) {
      setBookingSuccessful(false);
      setBookingFailed(true);
      setPaymentLoading(false);
      // eslint-disable-next-line no-console
      console.error('update payment', error);
    }
  };

  const DisplayRazorpay = async (payment: any) => {
    const options = {
      key: myConfig.razorpay,
      currency: 'INR',
      amount: payment?.data?.result?.amount,
      order_id: payment?.data?.result?.paymentModeId,
      name: `Book the ${billDetails?.result?.lineItems[0]?.entityType}`,
      description: `Book the ${billDetails?.result?.lineItems[0]?.entityType}`,
      image: logo,
      handler() {
        updatePayment(payment?.data?.result?.paymentId, payment?.data?.result?.orderId);
      },
      modal: {
        ondismiss() {
          setPaymentLoading(false);
        },
      },
      prefill: {
        name: authState?.name,
        email: authState?.email,
        contact: authState?.phone,
      },
    };
    const paymentObject = new (window as any).Razorpay(options);
    paymentObject.open();
    paymentObject.on('payment.failed', () => {
      updatePayment(payment?.data?.result?.paymentId, payment?.data?.result?.orderId);
      // paymentObject.close();
      setBookingFailed(true);
      setBookingSuccessful(false);
      setPaymentLoading(false);
    });
  };

  const makePayment = async () => {
    const response = await hlbClient().post('/api/payment', {
      orderId: billDetails?.result?._id,
      payeeId: billDetails?.result?.lineItems[0]?.entityOwner,
      buyerId: billDetails?.result?.userId,
      paymentMode: billDetails?.result?.total > 0 ? 'razorpay' : 'free',
    });
    if (billDetails?.result?.price > 0) {
      setPaymentLoading(true);
      DisplayRazorpay(response);
    } else {
      updatePayment(response?.data?.result?.paymentId, response?.data?.result?.orderId);
      setPaymentLoading(false);
      setBookingSuccessful(true);
    }
  };

  const InitiateRazorpay = async () => {
    try {
      await loadScript('https://checkout.razorpay.com/v1/checkout.js');
      makePayment();
      setPaymentLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleCreateBill = async () => {
    try {
      const response = await hlbClient().post('/api/orders/getbill', {
        lineItems: [
          {
            entityType,
            entityId: entityDetails?.entityId,
            itemQuantity: '1',
          },
        ],
      });
      setBillDetails(response.data);
      setBillView(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleMakePayment = async () => {
    try {
      await hlbClient().post('/api/payment', {
        orderId: billDetails?.result?._id,
        payeeId: billDetails?.result?.lineItems[0]?.entityOwner,
        buyerId: billDetails?.result?.userId,
        paymentMode: 'razorpay',
      });
      InitiateRazorpay();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleCloseDialog = () => {
    setBillView(false);
    onClose();
  };

  // eslint-disable-next-line consistent-return
  const renderEntityName = () => (
    <div className="entity__details--primary">
      <div className="entity__details--name">
        <h3>{entityDetails?.title}</h3>
        <ArrowSquareOut className="entity__details--link" size={20} color="#676767" />
      </div>
      <div className="entity__details--author">
        <span className={`entity__details--type type--${entityType}`}>{entityType}</span>
        {' '}
        by
        {' '}
        <span className="entity__details--author-name">
          {entityDetails?.mentorName || ''}
        </span>
      </div>
    </div>
  );

  // eslint-disable-next-line no-shadow, consistent-return
  const renderEntityPills = (entityType: string) => {
    if (entityType === 'webinar') {
      return (
        <>
          <EntityDetailsPill icon={<Clock size={18} color="#000000" />} bgColor="#E8DCFF" text={getTimeDifference(entityDetails?.startTime, entityDetails?.endTime)} />
          <EntityDetailsPill icon={<Calendar size={18} color="#000000" />} bgColor="#E8DCFF" text={`${dayjs(entityDetails?.startTime).format('D MMM, YYYY')} at ${dayjs(entityDetails?.startTime).format('hh:mmA')}`} />
        </>
      );
    }
    if (entityType === 'session') {
      return (
        <>
          <EntityDetailsPill icon={<Clock size={18} color="#000000" />} bgColor="#F4DCFF" text={`${entityDetails?.sessionDuration} Minutes`} />
          <EntityDetailsPill icon={<Users size={18} color="#000000" />} bgColor="#F4DCFF" text={`${entityDetails?.sessionType} Session`} />
        </>
      );
    }
    if (entityType === 'notes') {
      return (
        <>
          <EntityDetailsPill icon={<StackSimple size={18} color="#000000" />} bgColor="#F4DCFF" text={`${entityDetails?.cards} cards`} />
          <EntityDetailsPill icon={<User size={18} color="#000000" />} bgColor="#F4DCFF" text={`${entityDetails?.subscribers} Subscribers`} />
          <EntityDetailsPill icon={<CircleWavyQuestion size={18} color="#000000" />} bgColor="#F4DCFF" text={`Difficulty: ${entityDetails?.difficulty}`} />
        </>
      );
    }
  };

  // eslint-disable-next-line consistent-return
  const renderEntityPrice = () => (
    <h4>
      Price:
      {' '}
      <span className={`entity__details--price-original ${entityDetails?.discountPrice > 0 && 'striked'}`}>
        ₹
        {entityDetails?.price}
      </span>
      {' '}
      {entityDetails?.discountPrice > 0 && (
        <span className="entity__details--price-discounted">
          ₹
          {entityDetails?.discountPrice}
        </span>
      )}
    </h4>
  );

  const renderEntityDetails = () => (
    <>
      <div className="container__image">
        <img src={thumbnail} alt={entityType} />
        {entityType === 'notes' && <h2 className="container__image--info">{`${entityDetails?.cards || 0} cards`}</h2>}
      </div>
      <div className="entity__details">
        {renderEntityName()}
        <div className="entity__details--secondary">
          <p className="entity__details--info">
            {entityDetails?.description}
          </p>
          <div className="entity__details--perks">
            {renderEntityPills(entityType)}
          </div>
          <div className="entity__details--price">
            {renderEntityPrice()}
          </div>
        </div>
      </div>
    </>
  );

  // eslint-disable-next-line no-shadow
  const renderDialogAction = (onCancel: () => void, onContinue: () => void) => (
    <div className="dialog__action">
      <button
        type="button"
        className="dialog__action--cancel"
        onClick={onCancel}
      >
        Cancel
      </button>
      <button
        type="button"
        className="dialog__action--continue"
        onClick={onContinue}
        disabled={paymentLoading}
      >
        Continue
        {
          paymentLoading ? <Spinner animation="border" size="sm" variant="light" /> : null
        }
      </button>
    </div>
  );

  return (
    <>
      {open ? (
        <div className="payment__portal payment__portal--wrapper">
          {
            billView && (
              <div className={`payment__dialog dialog__bill ${(bookingSuccessful || bookingFailed) && 'status-mode'}`}>
                {
                  bookingSuccessful ? (
                    <PaymentStatus
                      status="success"
                      message="We have booked your session for selected time. You will receive a confirmation email with booking details."
                      primaryButtonText="Done"
                      secondaryButtonText="My orders"
                      primaryOnClick={() => {
                        setBookingSuccessful(false);
                        setBookingFailed(false);
                        setBillView(false);
                        onClose();
                      }}
                      secondaryOnClick={() => history.push('/user/profile')}
                    />
                  ) : null
                }
                {
                  bookingFailed ? (
                    <PaymentStatus
                      status="failure"
                      message="An Error occurred while processing your payment! Please try again later."
                      primaryButtonText="Cancel"
                      secondaryButtonText="Start over"
                      primaryOnClick={() => {
                        setBookingFailed(false);
                        setBookingSuccessful(false);
                        setBillView(false);
                        onClose();
                      }}
                      // eslint-disable-next-line no-undef
                      secondaryOnClick={() => window.location.reload()}
                    />
                  ) : null
                }
                {!bookingSuccessful && !bookingFailed ? (
                  <>
                    <div className="dialog__header">
                      <h4 className="dialog__header--heading">Confirm your payment</h4>
                      <div className="dialog__header--action">
                        <X
                          size={16}
                          onClick={() => {
                            setBillView(false);
                            onClose();
                          }}
                        />
                      </div>
                    </div>
                    <div className="entity__details">
                      {renderEntityName()}
                    </div>
                    <div className="bill__table">
                      <div className="table__row">
                        <span className="table__row--title">Original Price</span>
                        <span className="table__row--value">
                          ₹
                          {billDetails?.result?.lineItems[0]?.itemOriginalPrice || 0}
                        </span>
                      </div>
                      <div className="table__row">
                        <span className="table__row--title">
                          Discount
                          {' '}
                          {`(${calculateDiscountPercentage(discountPrice || 0, originalPrice || 0)})`}
                        </span>
                        <span className="table__row--value">
                          {priceDifference > 0 ? `-₹${priceDifference}` : `₹${priceDifference}`}
                        </span>
                      </div>
                      <div className="table__row">
                        <span className="table__row--title">Applicable Tax</span>
                        <span className="table__row--value">
                          ₹
                          {billDetails?.result?.taxAmount || 0}
                        </span>
                      </div>
                      <div className="table__row">
                        <span className='table__row--title highlighted'>Final Amount</span>
                        <span className='table__row--value highlighted'>
                          ₹
                          {billDetails?.result?.total || 0}
                        </span>
                      </div>
                    </div>
                    {renderDialogAction(handleCloseDialog, handleMakePayment)}
                  </>
                ) : null}
              </div>
            )
          }
          {
            !billView && (!bookingSuccessful || !bookingFailed) ? (
              <div className="payment__dialog dialog__details">
                <div className="dialog__header">
                  <h4 className="dialog__header--heading">Confirm your payment</h4>
                  <div className="dialog__header--action">
                    <X
                      size={16}
                      onClick={() => {
                        setBillView(false);
                        onClose();
                      }}
                    />
                  </div>
                </div>
                <div className="details__container">
                  {renderEntityDetails()}
                </div>
                {renderDialogAction(handleCloseDialog, handleCreateBill)}
              </div>
            ) : null
          }
        </div>
      ) : null}
    </>
  );
};

const PaymentDialog = ({
  open, onClose, onContinue, entityType, entityDetails, thumbnail,
// eslint-disable-next-line no-undef
}: PaymentPortalProps) => {
  // eslint-disable-next-line no-undef
  const portalRoot = document.querySelector('#portal-root');

  if (!portalRoot) {
    return null; // or handle the case when portalRoot is null
  }

  return ReactDOM.createPortal(
    <PaymentPortal
      open={open}
      onClose={onClose}
      onContinue={onContinue}
      entityType={entityType}
      entityDetails={entityDetails}
      thumbnail={thumbnail}
    />,
    portalRoot,
  );
};

export default PaymentDialog;
