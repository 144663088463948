/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { createContext, useEffect, useState } from 'react';
import { hlbClient } from '../Clients/hlbClient';

export const AuthContext = createContext();

function AuthProdiver(props) {
  const [authState, setAuthState] = useState([null]);
  // eslint-disable-next-line no-undef
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [stateUpdate, setStateUpdate] = useState(false);

  useEffect(() => {
    setToken(localStorage.getItem('token'));
    async function fetchData() {
      try {
        const resp = await hlbClient().get('/api/auth');
        setAuthState(resp.data);
        localStorage.setItem('hlUserId', resp.data._id);
        localStorage.setItem('hlUserType', resp.data.user_type);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }fetchData();
  }, [token, stateUpdate]);

  return (
    <AuthContext.Provider value={{
      authState, setAuthState, token, setToken, setStateUpdate, stateUpdate,
    }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthProdiver;
