import React, { useState } from 'react'
import HtmlParser from '../../../BasicComponents/htmlParser';
import { ReviewCard as RCT } from '../../types';

const  ReviewCard: React.FC<RCT> = ({
    answerText,
    questionText,
    questionImg = null,
    answerImg = null,
    hintText,
    next,
    previous,
    id,
    disableInteraction = false,
    index,
    totalCards,
    reason = null,
    done
}) => {
    const [showHint, setShowHint] = useState(false);
    const [isAnswer, setIsAnswer] = useState(false);

    const allowPrevious = index === 0 || disableInteraction;
    const allowNext = index + 1 === totalCards || disableInteraction;

  return (
        <div className="mobile__anki--card-container">
      <style>
        {`
      .anki-hard-disabled-btn {
          background: #68686852 !important;
          color: #9b9b9b !important;
          border: 2px solid #9b9b9b !important;
      }
      `}
      </style>
      {!isAnswer ? (
        <div className="">
          {questionImg?.file && questionImg?.file?.length !== 0 && (
            <div className="mobile__anki--image-Container">
              <img
                src={questionImg?.file}
                alt=""
                className="mobile__anki--card-container-image"
              />
            </div>
          )}
          <p className="mobile__anki--paragraph">
            <HtmlParser text={questionText} />
          </p>
          {hintText && (
            <div
              role="presentation"
              className="mobile__anki--hint-container"
              onClick={() => {
                setShowHint(true);
              }}
            >
              <p className="mobile__anki--hint-paragraph">
                {showHint ? hintText : 'Click here to see hint!'}
              </p>
            </div>
          )}
          <div className="mobile__anki--btn-container">
            <button
              disabled={disableInteraction}
              type="button"
              className="mobile__anki--btn-purple"
              onClick={() => {
                setIsAnswer(!isAnswer);
              }}
            >
              Show Answer
            </button>
          </div>
        </div>
      ) : (
        <div className="">
          {answerImg?.file && answerImg?.file?.length !== 0 && (
            <div className="mobile__anki--image-Container">
              <img
                src={answerImg?.file}
                alt=""
                className="mobile__anki--card-container-image"
              />
            </div>
          )}
          <p className="mobile__anki--paragraph">
            <HtmlParser text={answerText} />
          </p>
          <div className="mobile__anki--btn-container">
              <button
                type="button"
                className={
                    allowPrevious
                    ? 'mobile__anki--btn-hard anki-hard-disabled-btn'
                    : 'mobile__anki--btn-hard'
                }
                disabled={allowPrevious}
                onClick={() => { previous(); }}
              >
                Previous
              </button>

              <button
                disabled={allowNext}
                type="button"
                className="mobile__anki--btn-easy"
                onClick={() => {
                  done('review', null)
                }}
              >
                Next
              </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReviewCard