import React, {
  useEffect, useRef, useState, useCallback, useContext,
} from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import _ from 'lodash';
import ReactCrop from 'react-image-crop';
import FormData from 'form-data';
import './profile.css';
import { userImage, redCautionIcon } from '../BasicComponents/icons';
import { hlbClient } from '../../Clients/hlbClient';
import { AuthContext } from '../../provider/AuthContext';

function Profile() {
  const [data, setData] = useState([]);
  const [userPhoto, setUserPhoto] = useState([]);
  const [displayPic, setDisplayPic] = useState(_.get(data, 'profilePic.url', ''));
  const [pic, setPic] = useState(_.get(data, 'profilePic.url', []));
  const [crop, setCrop] = useState({ unit: 'px', width: 102, aspect: 1 / 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [cropModal, setCropModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(_.get(data, 'userName', ''));
  const [email, setEmail] = useState(_.get(data, 'email', ''));
  const [skills, setSkills] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [experience, setExperience] = useState('');
  const [languages, setLanguages] = useState([]);
  const [description, setDescripton] = useState('');
  const [shortBio, setShortBio] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const { authState, setStateUpdate, stateUpdate } = useContext(AuthContext);
  // eslint-disable-next-line no-undef
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (token) {
      setStateUpdate(!stateUpdate);
    }
  }, []);

  const id = authState._id;
  useEffect(() => {
    async function fetchData() {
      try {
        const resp = await hlbClient().get(`/api/users/${id}/profile`);
        setData(resp.data[0]);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('oops something went wrong');
      }
    }
    fetchData();
  }, [`${id}`]);

  useEffect(() => {
    if (data) {
      setName(_.get(data, 'userName', ''));
      setPic(_.get(data, 'profilePic.url', []));
      setEmail(_.get(data, 'email', ''));
      setPic(data, 'profilePic.url', []);
      setDisplayPic(_.get(data, 'profilePic.url', ''));
      setSkills(_.get(data, 'skills', []));
      setOrganizations(_.get(data, 'organisations', []));
      setExperience(_.get(data, 'expInYears', 'Select Experience'));
      setLanguages(_.get(data, 'languages', []));
      setDescripton(_.get(data, 'description', ''));
      setShortBio(_.get(data, 'shortBio', ''));
    }
  }, [data]);

  const emailValidate = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/.test(email);

  const checkValidation = () => {
    if (name === '') {
      setNameError('Enter Name*');
    } else {
      setNameError('');
    }
    if (!emailValidate) {
      setEmailError('Enter Valid Email');
    } else {
      setEmailError('');
    }
  };

  useEffect(() => {
    if (name !== '') {
      setNameError('');
    }
    if (email !== '') {
      setEmailError('');
    }
  }, [name, email]);

  const uploadFile = async (e) => {
    setUserPhoto(e.target.files[0].name);
  };
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      // eslint-disable-next-line no-undef
      const reader = new window.FileReader();
      reader.addEventListener('load', () => setDisplayPic(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    // eslint-disable-next-line no-shadow
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    // eslint-disable-next-line no-undef
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
  }, [completedCrop]);
  function saveProfilePic(canvas) {
    canvas.toBlob(
      (blob) => {
        // eslint-disable-next-line no-undef
        const previewUrl = window.URL.createObjectURL(blob);
        setDisplayPic(previewUrl);
        // eslint-disable-next-line no-undef
        const profilePic = new File([blob], userPhoto, { type: ['image/jpeg', 'image/peg', 'image/png'], lastModified: new Date() });
        setPic(profilePic);
      },
      userPhoto,
      1,
    );
  }
  const formHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append('expInYears', experience);
    formData.append('name', name);
    formData.append('email', email);
    formData.append('image', pic);
    formData.append('description', description);
    formData.append('shortBio', shortBio);
    formData.append('languages', JSON.stringify(languages));
    formData.append('skills', JSON.stringify(skills));
    formData.append('organisations', JSON.stringify(organizations));
    try {
      await hlbClient('multipart/form-data').put('/api/users/profile', formData);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('oops something went wrong');
    }
    setIsLoading(false);
  };
  return (
    <>
      <form onSubmit={formHandler} className="mb-5">
        <label htmlFor="upload Image" style={{ display: 'block' }} className="settings__form-label">Profile Picutre</label>
        <img src={displayPic || userImage} alt=".." className="setting__image-round" />
        <div className="" style={{ display: 'inline-block' }}>
          <label htmlFor="file">
            <span className="btn setting__upload-button">
              Upload Photo
            </span>
          </label>
          <input type="file" id="file" onChange={(e) => { uploadFile(e); onSelectFile(e); setCropModal(true); }} accept="image/*" />
        </div>
        <div className="col-md-12 mb-2 mt-3">
          <label htmlFor="exampleDataList" className="form-label__v2">Email</label>
          <input type="Email" className="form-input" value={email} onChange={(e) => { setEmail(e.target.value); }} placeholder="Enter your email" style={{ border: `${(emailError && '1px solid #F71C1C')}` }} />
          {emailError && (
          <label style={{ color: '#F71C1C' }}>
            <img src={redCautionIcon} alt=".." style={{ marginTop: '-2px' }} />
            {' '}
            {emailError}
          </label>
          )}
        </div>
        <div className="col-md-12 mb-2">
          <label htmlFor="exampleDataList" className="form-label__v2">Name</label>
          <input type="text" className="form-input" value={name} onChange={(e) => { setName(e.target.value); }} placeholder="Enter your name" style={{ border: `${(nameError && '1px solid #F71C1C')}` }} />
          {nameError && (
          <label style={{ color: '#F71C1C' }}>
            <img src={redCautionIcon} alt=".." style={{ marginTop: '-2px' }} />
            {' '}
            {nameError}
          </label>
          )}
        </div>
        {displayPic
          ? (
            <button type="submit" className="btn settings__btn--save">
              {isLoading === false ? 'Save'
                : <Spinner animation="border" role="status" />}
            </button>
          ) : (
            <button type="submit" className="btn settings__btn--save--disabled" onClick={checkValidation}>
              Save
            </button>
          )}
      </form>
      <Modal show={cropModal} centered backdrop="static">
        <Modal.Body>
          <ReactCrop
            src={displayPic}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
          />
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                width: Math.round(completedCrop ? 102 : 0),
                height: Math.round(completedCrop ? 102 : 0),
                margin: '8% 40% 1% 40%',
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              setCropModal(false);
              saveProfilePic(previewCanvasRef.current);
            }}
            className="btn purple-btn"
            type="button"
          >
            {' '}
            Save

          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Profile;
